<template>
    <div class="row margin-bottom">
        <label class="control-label col-md-3">
            {{$t('comp.settings.tabRisk.riskAutoAcceptThreshold.label')}}
        </label>
        <div class="col-lg-5 col-md-7" v-cloak>
            <div class="checkbox">
                <label>
                    <input type="checkbox" :checked="threshold > 0" @change="allow" ref="input" />
                    {{$t('comp.settings.tabRisk.riskAutoAcceptThreshold.allow')}}
                </label>
            </div>

            <div style="width: 95%; margin-top: 20px; padding: 10px;" v-show="threshold">
                <div style="width: 95%; margin: auto" ref="slider"></div>
                <div style="padding-top: 10px; text-align: center;"><strong>{{$t('comp.settings.tabRisk.riskAutoAcceptThreshold.treshold')}}</strong> {{ threshold }} ({{thresholdPercentage}}%)</div>

                <span class="help-block" v-html="$t('comp.settings.tabRisk.riskAutoAcceptThreshold.help', {threshold: threshold + ` (${thresholdPercentage}%)`})"></span>
            </div>

            <div class="form-group" v-if="showButtons">
                <div v-show="threshold">
                    <div class="checkbox">
                        <label class="text-sm">
                            <input type="checkbox" v-model="includeManual" />
                            {{$t('comp.settings.tabRisk.riskAutoAcceptThreshold.includeManual')}}
                        </label>
                    </div>
                </div>

                <button class="btn btn-sm btn-success ajax" @click="save">
                    {{$t('common.save')}} <template v-if="threshold">{{$t('comp.settings.tabRisk.riskAutoAcceptThreshold.andUpdateRisks')}}</template>
                </button>
                <button class="btn btn-sm btn-warning" @click="cancel">
                    {{$t('common.cancel')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import noUiSlider from 'nouislider';
    import GlobalBus from "GlobalBus";

    export default {
        name: 'RiskClassification',
        data() {
            return {
                slider: null,
                threshold: 0,
                thresholdPercentage: 0,
                defaultThreshold: 0,
                maxThreshold: 1,
                includeManual: false
            };
        },
        created() {
            this.load();
        },
        watch: {
            ['threshold']: function () {
                this.thresholdPercentage = Math.round(this.threshold / this.maxThreshold * 100);
            }
        },
        computed: {
            showButtons: function () {
                return this.threshold !== this.defaultThreshold;
            }
        },
        mounted: function () {
            this.slider = this.$refs.slider;

            if(this.threshold && !this.slider.noUiSlider) {
                this.createSlider();
            }
        },
        methods: {
            load: function () {
                this.$api.get("config/risk-auto-accept-threshold").then(({data}) => {
                    this.threshold = data.data.threshold;
                    this.defaultThreshold = data.data.threshold;
                    this.maxThreshold = data.data.maxThreshold;
                    this.createSlider();
                });
            },
            save: function () {
                GlobalBus.$emit("loading", true);
                this.$api.post("config/risk-auto-accept-threshold", null, {
                    params: {
                        threshold: this.threshold,
                        includeManual: this.includeManual ? 1 : 0,
                    }
                }).then(({data}) => {
                    if(data.status === 'ok') {
                        this.defaultThreshold = this.threshold;
                        GlobalBus.$emit("loading", false);
                    }
                });
            },
            cancel: function () {
                this.threshold = this.defaultThreshold;
                this.slider.noUiSlider.set([this.threshold]);
            },
            allow: function () {
                if(this.$refs.input.checked) {
                    this.threshold = this.defaultThreshold ? this.defaultThreshold : 40;

                    if(this.threshold && !this.slider.noUiSlider) {
                        this.createSlider();
                    }

                    this.slider.noUiSlider.set([this.threshold]);
                } else {
                    this.threshold = null;
                }
            },
            createSlider: function () {
                let self = this;

                noUiSlider.create(this.slider, {
                    start: this.threshold,
                    behaviour: 'tap',
                    connect: [true, false],
                    step: 1,
                    range: {
                        'min': 1,
                        'max': this.maxThreshold
                    }
                }).on('update', function (values) {
                    self.threshold = Math.trunc(values[0]);
                });
            }
        }
    }
</script>