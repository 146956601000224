import { render, staticRenderFns } from "./AssetsModal.vue?vue&type=template&id=e5d79070&scoped=true&"
import script from "./AssetsModal.vue?vue&type=script&lang=js&"
export * from "./AssetsModal.vue?vue&type=script&lang=js&"
import style0 from "./AssetsModal.vue?vue&type=style&index=0&id=e5d79070&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5d79070",
  null
  
)

export default component.exports