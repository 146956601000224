<template>
    <div class="box box-primary vld-parent">
        <div class="box-header">
            <h2 class="box-title" v-if="unit">
                <i class="fas fa-address-book"></i>
                {{unit.name}} <small>| {{$t('comp.orgStructure.assignPerson.subTitle')}}</small>  
            </h2>
        </div>

        <div class="box-body">
            <form class="form-horizontal" action="#" @submit.prevent="save()">
                <div class="form-group">
                    <label for="systemUser" class="col-md-3 control-label">{{$t('comp.orgStructure.assignPerson.selectPerson')}}</label>
                    <div class="col-md-6">
                        <csa-select :options="peopleToAssign" v-model="selectedPerson" label="name">
                        </csa-select>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6 col-md-push-3 text-center">
                        <button class="btn btn-link" @click.prevent="cancelEdit">{{$t('common.cancel')}}</button>
                        <button class="btn btn-success"><i class="fa fa-save"></i> {{$t('common.save')}}</button>
                    </div>
                </div>
            </form>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import NavigationBus from './NavigationBus.js';
    import CsaSelect from 'Controls/CsaMultiSelect.vue'

    export default {
        name: 'assign-person',
        components: {
            CsaSelect
        },
        watch: {

        },
        data() {
            return {
                isLoading: false,
                unitId: null,
                unit: null,
                peopleToAssign: [],
                selectedPerson: null
            };
        },
        methods: {
            cancelEdit() {
                this.$router.push({name: 'listing', params: {unitId: this.unitId}});
            },
            async loadUnit() {
                await this.$api.get("org-unit/" + this.unitId).then(({data})=>{
                    if(data.status === 'ok') {
                        this.unit = data.data;
                    }
                });
            },
            async fetchPeopleToAssign() {
                await this.$api.get("org-person/" + this.unitId + "/fetchPeopleToAssign").then(({data})=>{
                    if(data.status === 'ok') {
                        this.peopleToAssign = data.data;
                    }
                });
            },
            async load() {
                this.isLoading = true;
                await this.loadUnit();
                await this.fetchPeopleToAssign().then(()=>{
                    this.isLoading = false;
                });
            },
            save() {               
                
                if(this.selectedPerson){
                    this.$api.post("org-person/" + this.selectedPerson.id + "/assignPersonToUnit", null, {
                        params: {
                            unitId: this.unitId
                        }
                    }).then(({data})=>{
                        if(data.status === 'ok') {
                            let person = {
                                id: this.selectedPerson.id,
                                name: this.selectedPerson.name
                            };
                            NavigationBus.$emit('create-person', {person: person, unitId: this.unit.id});
                            NavigationBus.$emit('remove-person-under-root', this.selectedPerson.id);
                            this.cancelEdit();
                        }
                    });
                }
            }
        },
        beforeMount() {
            this.unitId = this.$route.params.unitId
            this.load();
        }
    }
</script>