<template>
  <div class="modal fade" role="dialog" ref="modal" v-if="show">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <i class="fas fa-tag"></i>
            <span v-if="personGroup.id">
              {{personGroup.name}}
            </span>
            <span v-else>
              {{$t('comp.orgStructure.managePersonGroup.index.newGroup')}}
            </span>
          </h4>
        </div>

        <div class="modal-body">
          <div class="row mt2">
              <div class="col-md-3 text-bold text-right">
                  {{$t('comp.orgStructure.managePersonGroup.index.name')}}
              </div>
              <div class="col-md-6">
                  <input class="form-control"
                      type="text"
                      name="name"
                      :placeholder="$t('comp.orgStructure.managePersonGroup.index.namePlaceholder')"
                      v-model="personGroup.name"
                      :data-vv-as="$t('comp.orgStructure.managePersonGroup.index.name-data-vv')"
                      v-validate="creatingMode ? null : 'required'" />

                <span class="text-red">{{ errors.first('name') }}</span>
              </div>
          </div>

          <div class="row mt2">
              <div class="col-md-3 text-bold text-right">
                  {{$t('comp.orgStructure.managePersonGroup.index.color')}}
              </div>
              <div class="col-md-6">
                  <swatches v-model="personGroup.color"
                            class="colorPreview"
                            :swatch-size="30"
                            :triggerStyle="{width: '30px', height: '30px'}"
                            name="color"
                            v-validate="'required'"
                            :data-vv-as="$t('comp.orgStructure.managePersonGroup.index.color-data-vv')"></swatches>

                  <span class="text-red">{{ errors.first('color') }}</span>
              </div>
          </div>

          <div class="row mt2">
              <label for="notDeleteOnSync" class="col-md-3 control-label" style="padding-top: 0;">{{$t('comp.orgStructure.managePersonGroup.index.notDeleteOnSync')}}</label>
              <div class="col-md-6">
                  <input name="notDeleteOnSync"
                          id="notDeleteOnSync"
                          type="checkbox"
                          v-model="personGroup.notDeleteOnSync" />
              </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="btn btn-link pull-left" @click="cancel">
            {{$t('common.cancel')}}
          </div>
          <div class="pull-right" >
            <div class="btn btn-success" @click="save" :disabled="isLoading" v-if="personGroup.id">
              {{$t('common.save')}}
            </div>
            <div class="btn btn-success" @click="create" :disabled="isLoading" v-if="!personGroup.id">
              {{$t('common.create')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <loading :active="isLoading" :is-full-page="false"></loading>
  </div>
</template>

<script>
import Bus from './Bus.js';
import {mapActions, mapGetters} from 'vuex';
import Swatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.min.css"
import ManagePersonGroupBus from './Bus.js';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation.vue';

export default {
  name: 'manage-person-group',
  components: {
    Swatches,
    DeleteConfirmation
  },
  provide() {
    return {$validator: this.$validator}
  },
  watch: {
    show: function (v) {
        
    }
  },
  data() {
    return {
      show: false,
      isLoading: false,
      personGroup: {
         id: null,
         name: null,
         color: null,
         notDeleteOnSync: false
      }
    };
  },
  computed: {

  },
  mounted() {
    Bus.$on('show', (personGroupToEdit) => {

      if(personGroupToEdit){
        this.personGroup.id = personGroupToEdit.id;
        this.personGroup.name = personGroupToEdit.name;
        this.personGroup.color = personGroupToEdit.color;
        this.personGroup.notDeleteOnSync = personGroupToEdit.notDeleteOnSync;
      }
      else {
        this.resetPersonGroup();
      }

      this.show = true;

      this.$nextTick(() => {
        this.modal = this.$refs.modal;
        $(this.modal).modal('show');

        $(this.modal).on('hidden.bs.modal', () => {
          this.show = false;
        });
      });
    });

    Bus.$on('hide', () => {
      this.cancel();
    });
  },
  methods: {
    async create() {
      this.$validator.validate().then(async result => {
        if (result) {
          this.isLoading = true;
          await this.$api.post("org-person-group", this.personGroup).then(({data}) => {
              if (data.status === 'ok') {
                  ManagePersonGroupBus.$emit('reload-groups');
                  this.cancel();
              }
          });
          this.isLoading = false;       
        }
      });
    },
    async save() {
      this.$validator.validate().then(async result => {
        if (result) {
          this.isLoading = true;
          await this.$api.put("org-person-group", this.personGroup).then(({data}) => {
              if (data.status === 'ok') {
                  ManagePersonGroupBus.$emit('reload-groups');
                  this.cancel();
              }
          });
          this.isLoading = false;   
        }
      });
    },
    cancel() {
      $(this.modal).modal('hide');
    },
    resetPersonGroup() {
      this.personGroup.id = null;
      this.personGroup.name = null;
      this.personGroup.color = null;
      this.personGroup.notDeleteOnSync = false;
    }
  }
}
</script>


<style scoped lang="less">

</style>