<template>
    <div class="modal test-modal" role="dialog" style="display: block;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <template v-if="id">{{ $t("testEditing") }}</template>
                        <template v-if="!id">{{ $t("newTest") }}</template>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="name">{{ $t("testTemplate") }}</label>
                        <input type="text" class="form-control disabled" v-if="id" :value="templateName" disabled/>

                        <vue-select
                                v-if="!id"
                                name="test"
                                id="test"
                                v-model="testTemplate"
                                :options="tests"
                                :placeholder="$t('chooseTestTemplate')"
                                @input="selectedTemplate"
                                label="name"
                                v-validate="'required'"
                                data-vv-validate-on="none"
                                :filter-by="filterBy">
                                <span slot="no-options">
                                    {{ $t('noneOfTestTemplate') }}
                                </span>
                            <i slot="spinner" class="icon icon-spinner"></i>
                            <template slot="option" slot-scope="option">
                                [{{option.id}}] {{ option.name }}
                            </template>
                        </vue-select>
                        <span class="text-red">{{ errors.first('test') }}</span>
                    </div>

                    <template v-if="testTemplate">
                        <hr/>

                        <div class="form-group">
                            <label for="name">{{ $t('testName') }}</label>
                            <input type="text"
                                   class="form-control"
                                   id="name"
                                   name="testName"
                                   v-validate="'required'"
                                   data-vv-validate-on="none"
                                   v-model="name"/>
                            <span class="text-red">{{ errors.first('testName') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="description">{{ $t('testDescription') }}</label>
                            <vue-ckeditor
                                    id="description"
                                    v-model="description"
                                    :config="config">

                            </vue-ckeditor>
                        </div>
                        <div class="form-group">
                            <label for="time_limit">{{ $t('timeLimit') }} [{{$t('comp.manageElearningTests.testModal.min')}}]</label>

                            <div class="input-group">
                                <input type="text" class="form-control"
                                       id="time_limit"
                                       name="timeLimit"
                                       :readonly="!hasTimeLimit"
                                       v-model="timeLimit"
                                       v-validate="{ required: hasTimeLimit, numeric: hasTimeLimit}"
                                       data-vv-validate-on="none"
                                       :filter-by="filterSelect"/>

                                <div class="input-group-addon bg-maroon-active">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" v-model="hasTimeLimit"/>
                                        <strong>{{ $t('timeLimit') }}</strong>
                                    </label>
                                </div>
                            </div>
                            <span class="text-red">{{ errors.first('timeLimit') }}</span>
                        </div>

                        <div>
                            <div class="box box-danger">
                                <div class="box-header">
                                    <h3 class="box-title">{{ $t('basicTestEvaluation') }}</h3>

                                    <div class="box-tools">
                                        <button class="btn btn-success btn-xs" v-if="evaluationType != evaluationType_MinForSuccess" @click="switchOnMinForSuccessEvaluation">
                                            {{ $t("switchOn") }}
                                        </button>
                                        <button class="btn btn-danger btn-xs" v-else @click="switchOffMinForSuccessEvaluation">
                                            {{ $t("switchOff") }}
                                        </button>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="form-group">
                                        <label>{{ $t("minQuestionsCount") }}:</label>
                                        <div class="input-group">
                                            <input type="number"
                                                min=1
                                                :max="evaluatedQuestionsCount"
                                                class="form-control"
                                                v-model.number="minForSuccess"
                                                :disabled="evaluationType != evaluationType_MinForSuccess"
                                                name="minForSuccess_input"
                                                v-validate="{
                                                    min_value: 1,
                                                    max_value: evaluatedQuestionsCount
                                                }"
                                                data-vv-as="počtu" />

                                            <div class="input-group-addon">
                                                max <strong>{{evaluatedQuestionsCount}}</strong>
                                            </div>
                                        </div>

                                        <span class="help-block text-sm">*{{ $t("whichQuestionsAreEvaluated") }}</span>
                                        <span class="text-red">{{ errors.first('minForSuccess_input') }}</span>
                                    </div>
                                </div>
                            </div>


                            <div class="box box-danger">
                                <div class="box-header">
                                    <h3 class="box-title">{{ $t('scaleTestEvaluation') }}</h3>

                                    <div class="box-tools">
                                        <button class="btn btn-success btn-xs" 
                                                v-if="!isEvaluationType_Scale" 
                                                @click="switchOnScaleEvaluation">
                                            {{ $t("switchOn") }} 
                                        </button>
                                        <button class="btn btn-danger btn-xs" v-else @click="switchOffScaleEvaluation">
                                            {{ $t("switchOff") }} 
                                        </button>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="radio-group">
                                        <span class="radio-label">{{$t('comp.manageElearningTests.testModal.scaleEvaluationStepsTitle')}}</span>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleRadioOptions" value=2 v-model="scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!isEvaluationType_Scale || evaluatedQuestionsCount < 1">
                                            <abbr >2</abbr>
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleRadioOptions" value=3 v-model="scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!isEvaluationType_Scale || evaluatedQuestionsCount < 2">
                                            <abbr >3</abbr>
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleRadioOptions" value=4 v-model="scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!isEvaluationType_Scale || evaluatedQuestionsCount < 3">
                                            <abbr >4</abbr>
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleRadioOptions" value=5 v-model="scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!isEvaluationType_Scale || evaluatedQuestionsCount < 4">
                                            <abbr >5</abbr>
                                        </label>
                                    </div>

                                    <div class="radio-group">
                                        <span class="radio-label">{{$t('comp.manageElearningTests.testModal.scaleEvaluationTypeTitle')}}</span>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleEvaluationTypeRadioOptions" :value="evaluationType_ScaleTextAndEmoji" v-model="evaluationType" :disabled="!isEvaluationType_Scale">
                                            <abbr >{{$t('comp.manageElearningTests.testModal.evaluationType_ScaleTextAndEmoji')}}</abbr>
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleEvaluationTypeRadioOptions" :value="evaluationType_ScaleEmoji" v-model="evaluationType" :disabled="!isEvaluationType_Scale">
                                            <abbr >{{$t('comp.manageElearningTests.testModal.evaluationType_ScaleEmoji')}}</abbr>
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="scaleEvaluationTypeRadioOptions" :value="evaluationType_ScaleText" v-model="evaluationType" :disabled="!isEvaluationType_Scale">
                                            <abbr >{{$t('comp.manageElearningTests.testModal.evaluationType_ScaleText')}}</abbr>
                                        </label>
                                    </div>


                                    <div v-if="isEvaluationType_Scale" > 
                                        <table class="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th style="width: 50px;" v-if="evaluationType == evaluationType_ScaleTextAndEmoji || evaluationType == evaluationType_ScaleEmoji"></th>
                                                <th>
                                                    <span v-if="evaluationType == evaluationType_ScaleTextAndEmoji || evaluationType == evaluationType_ScaleText">
                                                        {{$t('comp.manageElearningTests.testModal.headerEvaluationType_ScaleText')}}
                                                    </span>
                                                </th>
                                                <th style="width: 110px;">{{$t('comp.manageElearningTests.testModal.minPoints')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody >
                                                <tr v-for="scaleStep in scaleEvaluationSteps">
                                                    <td class="evaluation-icon" v-if="evaluationType == evaluationType_ScaleTextAndEmoji || evaluationType == evaluationType_ScaleEmoji">
                                                        <i :class="scaleStep.icon" ></i>
                                                    </td>
                                                    <td class="text-bold" >
                                                        <div class="form-group" v-if="evaluationType == evaluationType_ScaleTextAndEmoji || evaluationType == evaluationType_ScaleText">
                                                            <input type="text"
                                                                class="form-control"
                                                                v-model="scaleStep.evaluationText"
                                                                :placeholder="scaleStep.placeholder"
                                                                :disabled="!isEvaluationType_Scale"
                                                                :name="'scaleStepText_input' + scaleStep.step"
                                                                :key="'scaleStepText_input' + scaleStep.step"
                                                                v-validate.persistent="'required'"
                                                                data-vv-validate-on="input"
                                                                :data-vv-as="$t('comp.manageElearningTests.testModal.validationEvaluationType_ScaleText')" />
                                                            <span class="text-red">{{ errors.first('scaleStepText_input' + scaleStep.step) }}</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-bold">
                                                        <div class="form-group">
                                                            <input type="number"
                                                                :min="getScaleStepMin(scaleStep)"
                                                                :max="getScaleStepMax(scaleStep)"
                                                                class="form-control"
                                                                v-model.number="scaleStep.minLimit"
                                                                :disabled="!isEvaluationType_Scale || scaleStep.step == 1"
                                                                :name="'scaleStepMax_input' + scaleStep.step"
                                                                :key="'scaleStepMax_input' + scaleStep.step"      
                                                                v-validate.persistent="'required'"
                                                                v-validate="{
                                                                    min_value: getScaleStepMin(scaleStep),
                                                                    max_value: getScaleStepMax(scaleStep)
                                                                }" 
                                                                :data-vv-as="$t('comp.manageElearningTests.testModal.minPoints')"/>
                                                            <span class="text-red">{{ errors.first('scaleStepMax_input' + scaleStep.step) }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <span class="help-block text-sm">*{{ $t("whichQuestionsAreEvaluated") }}</span>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-default pull-left"
                            @click="close">
                        <i class="fas fa-times"></i>
                        {{ $t('cancel') }}
                    </button>
                    <button class="btn btn-success"
                            v-if="!id"
                            @click="save">
                        <i class="fas fa-plus"></i>
                        {{ $t('create') }}
                    </button>
                    <button class="btn btn-primary"
                            v-if="id"
                            @click="save">
                        <i class="fas fa-save"></i>
                        {{ $t('save') }}
                    </button>
                </div>
                <loading :active.sync="isLoading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import VueCkeditor from 'vue-ckeditor2';
    import VueSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    export default {

        components: {
            Loading,
            VueCkeditor,
            VueSelect
        },
        data() {
            return {
                isLoading: false,
                tests: [],
                testTemplate: null,
                name: null,
                description: "",
                timeLimit: null,
                hasTimeLimit: true,
                templateName: null,
                evaluatedQuestionsCount: null,
                evaluationType: 0,
                scaleEvaluationSteps: [],
                scaleEvaluationStepsCount: null,
                minForSuccess: null,
                config: {
                    toolbar: [
                        ['Bold', 'Italic', 'Underline', 'Strike', 'BulletedList', 'NumberedList']
                    ],
                    height: 100
                },

                evaluationType_None: 0,
                evaluationType_MinForSuccess: 1,
                evaluationType_ScaleTextAndEmoji: 2,
                evaluationType_ScaleText: 3,
                evaluationType_ScaleEmoji: 4,
                isEvaluationType_Scale: false,  
                step1: {id: 0, step: 1, evaluationText: null, placeholder: this.$t("scaleStep1Placeholder"), minLimit: 0, icon: "far fa-frown-open"},
                step2: {id: 0, step: 2, evaluationText: null, placeholder: this.$t("scaleStep2Placeholder"), minLimit: null, icon: "far fa-frown"},
                step3: {id: 0, step: 3, evaluationText: null, placeholder: this.$t("scaleStep3Placeholder"), minLimit: null, icon: "far fa-meh"},
                step4: {id: 0, step: 4, evaluationText: null, placeholder: this.$t("scaleStep4Placeholder"), minLimit: null, icon: "far fa-smile"},
                step5: {id: 0, step: 5, evaluationText: null, placeholder: this.$t("scaleStep5Placeholder"), minLimit: null, icon: "far fa-grin"},

            }
        },
        created() {
            this.setupScale();
        },
        beforeMount: function () {
            if (this.id) {
                this.loadTest();
            } else {
                this.loadTestTemplates();
            }
        },
        props: {
            show: Boolean,
            id: String,
            courseId: String,
        },
        methods: {
            close: function () {
                this.$emit("close");
            },
            save: function () {
                this.$validator.validate().then(result => {
                    if (!result) {
                       alert(this.$t("fixFormErrors"));
                    } else {
                        if (!this.id) {
                            this.$api.post("elearning-tests/", {
                                name: this.name,
                                description: this.description,
                                timeLimit: this.hasTimeLimit ? this.timeLimit : null,
                                evaluationType: this.evaluationType,
                                scaleEvaluationSteps: this.scaleEvaluationSteps,
                                scaleEvaluationStepsCount: this.scaleEvaluationStepsCount,
                                minForSuccess: this.minForSuccess,
                                templateId: this.testTemplate.id,
                                courseId: this.courseId,
                            })
                                .then(({data}) => {
                                    this.isLoading = false;

                                    this.$emit("reload");
                                    this.$emit("close");
                                })
                                .catch((error) => {
                                    alert(this.$t("unexpectedEror"));
                                });
                        } else {
                            this.$api.put("elearning-tests/" + this.id, {
                                name: this.name,
                                description: this.description,
                                timeLimit: this.hasTimeLimit ? this.timeLimit : null,
                                evaluationType: this.evaluationType,
                                scaleEvaluationSteps: this.scaleEvaluationSteps,
                                scaleEvaluationStepsCount: this.scaleEvaluationStepsCount,
                                minForSuccess: this.minForSuccess,
                            })
                                .then(({data}) => {
                                    this.isLoading = false;

                                    this.$emit("reload");
                                    this.$emit("close");
                                })
                                .catch((error) => {
                                    alert(this.$t("unexpectedEror"));
                                });
                        }
                    }
                });
            },
            loadTestTemplates: function () {
                this.isLoading = true;

                this.$api.get("elearning-tests/test-templates")
                    .then(({data}) => {
                        this.tests = data;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        alert(this.$t("unexpectedEror"));
                    });
            },
            loadTest: function () {
                this.isLoading = true;

                this.$api.get("elearning-tests/" + this.id)
                    .then(({data}) => {
                        this.testTemplate = data.data;

                        this.name = data.data.name;
                        this.description = data.data.description;
                        this.timeLimit = data.data.timeLimit;
                        this.templateName = data.data.templateName;
                        this.evaluatedQuestionsCount = data.data.evaluatedQuestionsCount;
                        this.evaluationType = data.data.evaluationType;
                        this.scaleEvaluationSteps = data.data.scaleEvaluationSteps;
                        this.scaleEvaluationStepsCount = data.data.scaleEvaluationSteps.length;
                        this.minForSuccess = data.data.minForSuccess;
   
                        if (this.timeLimit) {
                            this.hasTimeLimit = true;
                        } else {
                            this.hasTimeLimit = false;
                        }

                        this.checkScaleEvaluation();
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        alert(this.$t("unexpectedEror"));
                    });
            },
            selectedTemplate: function () {
                this.name = this.testTemplate.name;
                this.description = this.testTemplate.description;
                this.timeLimit = this.testTemplate.timeLimit;
                this.evaluatedQuestionsCount = this.testTemplate.evaluatedQuestionsCount;
                this.evaluationType = this.testTemplate.evaluationType;
                this.scaleEvaluationSteps = this.testTemplate.scaleEvaluationSteps;
                this.scaleEvaluationStepsCount = this.testTemplate.scaleEvaluationSteps.length;
                this.minForSuccess = this.testTemplate.minForSuccess;

                if (this.timeLimit) {
                    this.hasTimeLimit = true;
                } else {
                    this.hasTimeLimit = false;
                }

                this.checkScaleEvaluation();
            },
            filterBy: (option, label, search) => {
                return label.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    option.id.toString().indexOf(search.toLowerCase()) > -1;
            },
            switchOnMinForSuccessEvaluation: function () {
                this.switchOffScaleEvaluation();            // Disable othe evaluation option

                this.evaluationType = this.evaluationType_MinForSuccess;
            },
            switchOffMinForSuccessEvaluation: function () {
                this.evaluationType = this.evaluationType_None;
                this.minForSuccess = null;
            },
            switchOnScaleEvaluation: function () {
                this.switchOffMinForSuccessEvaluation();     // Disable othe evaluation option

                // Default
                this.evaluationType = this.evaluationType_ScaleTextAndEmoji;
                this.scaleEvaluationStepsCount = 2; 
                this.isEvaluationType_Scale = true;

                this.setupScale();
            },
            switchOffScaleEvaluation: function () {
                this.evaluationType = this.evaluationType_None;
                this.scaleEvaluationSteps = [];
                this.scaleEvaluationStepsCount = null;     
                this.isEvaluationType_Scale = false;
            },
            getScaleStepMin: function(scaleStep){
                if(scaleStep){
                    if(scaleStep.step == 1) return 0;

                    let stepIndex = this.scaleEvaluationSteps.findIndex(x=>x.step == scaleStep.step);
                    if(stepIndex > 0)
                    {
                        return this.scaleEvaluationSteps[stepIndex - 1].minLimit + 1;
                    }
                }
   
                return 0;
            },
            getScaleStepMax: function(scaleStep){
                if(scaleStep){
                    if(scaleStep.step == 1) return 0;
                    if(scaleStep.step == 5) return this.evaluatedQuestionsCount;

                    let stepIndex = this.scaleEvaluationSteps.findIndex(x=>x.step == scaleStep.step);
                    if(stepIndex >= 0 && stepIndex < (this.evaluatedQuestionsCount-1) && this.scaleEvaluationSteps[stepIndex + 1].minLimit)
                    {
                        return this.scaleEvaluationSteps[stepIndex + 1].minLimit - 1;
                    }
                }
   
                return this.evaluatedQuestionsCount;
            },
            checkScaleEvaluation(){
                if(this.evaluationType == this.evaluationType_ScaleTextAndEmoji ||
                    this.evaluationType == this.evaluationType_ScaleText ||
                    this.evaluationType == this.evaluationType_ScaleEmoji) {
                    this.isEvaluationType_Scale = true;

                    this.setupScale();
                } else {
                    this.isEvaluationType_Scale = false;
                }
            },
            setupScale(){                
                let scaleEvaluationSteps = [];
                let step = null;      
                
                // Removes old inputs on scale changed
                this.scaleEvaluationSteps.forEach(item => this.$validator.detach(`scaleStepMax_input${item.step}`));

                // Step 1
                step = this.scaleEvaluationSteps.find(x => x.step == 1);
                if(step) {
                    this.step1.id = step.id;
                    this.step1.minLimit = step.minLimit;
                    this.step1.evaluationText = step.evaluationText;
                }
                scaleEvaluationSteps.push(this.step1);

                // Step 2
                if(this.scaleEvaluationStepsCount == 5 ||
                   this.scaleEvaluationStepsCount == 4){ 
                    step = this.scaleEvaluationSteps.find(x => x.step == 2);
                    if(step) {
                        this.step2.id = step.id;
                        this.step2.minLimit = step.minLimit;
                        this.step2.evaluationText = step.evaluationText;
                    }
                    scaleEvaluationSteps.push(this.step2);
                }

                // Step 3
                if(this.scaleEvaluationStepsCount == 5 ||
                   this.scaleEvaluationStepsCount == 4 ||
                   this.scaleEvaluationStepsCount == 3){ 
                    step = this.scaleEvaluationSteps.find(x => x.step == 3);
                    if(step) {
                        this.step3.id = step.id;
                        this.step3.minLimit = step.minLimit;
                        this.step3.evaluationText = step.evaluationText;
                    }
                    scaleEvaluationSteps.push(this.step3);
                }
                
                // Step 4
                if(this.scaleEvaluationStepsCount == 5){ 
                    step = this.scaleEvaluationSteps.find(x => x.step == 4);
                    if(step) {
                        this.step4.id = step.id;
                        this.step4.minLimit = step.minLimit;
                        this.step4.evaluationText = step.evaluationText;
                    }
                    scaleEvaluationSteps.push(this.step4);
                }

                // Step 5
                step = this.scaleEvaluationSteps.find(x => x.step == 5);
                if(step) {
                    this.step5.id = step.id;
                    this.step5.minLimit = step.minLimit;
                    this.step5.evaluationText = step.evaluationText;
                }
                scaleEvaluationSteps.push(this.step5);


                this.scaleEvaluationSteps.splice(0, this.scaleEvaluationSteps.length);
                scaleEvaluationSteps.forEach(element => {
                    this.scaleEvaluationSteps.push(element);
                });
            }
        }
    }
</script>

<style lang="less">
.radio-group {
    margin-bottom: 10px;
}

.radio-label {
    font-weight: bold;
    margin-right: 10px;
}

.evaluation-icon {
    font-size: 30px;
}

.test-modal .modal-body {
    min-height: 300px;
    max-height: calc(80vh);
    overflow: auto;
}
</style>