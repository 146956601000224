<template>
    <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
            <router-link :to="{name: 'assets'}" tag="li"><a>{{$t('comp.settings.settings.assets')}}</a></router-link>
            <router-link :to="{name: 'risks'}" tag="li"><a>{{$t('comp.settings.settings.risks')}}</a></router-link>
        </ul>
        <div class="tab-content">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
    </div>
</template>


<script>
    import VueRouter from 'vue-router';
    import TabAssets from './TabAssets.vue';
    import TabRisks from './TabRisks.vue';

    const router = new VueRouter({
        linkExactActiveClass: 'active',
        linkActiveClass: 'active',
        routes: [
            {
                path: '/',
                redirect: '/assets'
            },
            {
                name: "assets",
                component: TabAssets,
                path: '/assets',
                meta: {
                    showModal: true
                }
            },
            {
                name: "risks",
                path: '/risks',
                component: TabRisks,
                meta: {
                    showModal: true
                }
            }
        ],
    });


    export default {
        router
    }
</script>