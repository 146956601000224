<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        components: {
            BaseQuestion
        },
    }
</script>