<template>
    <div>
        <table class="table" v-if="answer && isVisible">
            <tbody>
                <tr>
                    <td
                        :title="$t('comp.kbAudit.answerType.implemented')"
                        class="choice"
                    >
                        <div class="pretty p-icon p-curve">
                            <input
                                type="radio"
                                v-bind:name="question.id"
                                value="implemented"
                                v-model="answer.choice"
                                v-bind:disabled="readonly"
                                @click="radioClicked($event)"
                            />

                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td
                        :title="$t('comp.kbAudit.answerType.inImplementation')"
                        class="choice"
                    >
                        <div class="pretty p-icon p-curve">
                            <input
                                type="radio"
                                v-bind:name="question.id"
                                value="in_implementation"
                                v-model="answer.choice"
                                v-bind:disabled="readonly"
                                @click="radioClicked($event)"
                            />

                            <div class="state p-primary">
                                <i class="icon fa fa-minus"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td
                        :title="$t('comp.kbAudit.answerType.notApplicable')"
                        class="choice"
                    >
                        <div class="pretty p-icon p-curve">
                            <input
                                type="radio"
                                v-bind:name="question.id"
                                value="not_applicable"
                                v-model="answer.choice"
                                v-bind:disabled="readonly"
                                @click="radioClicked($event)"
                            />

                            <div class="state p-warning">
                                <i class="icon fa fa-times"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td
                        :title="$t('comp.kbAudit.answerType.notImplemented')"
                        class="choice"
                    >
                        <div class="pretty p-icon p-curve">
                            <input
                                type="radio"
                                v-bind:name="question.id"
                                value="not_implemented"
                                v-model="answer.choice"
                                v-bind:disabled="readonly"
                                @click="radioClicked($event)"
                            />

                            <div class="state p-danger">
                                <i class="icon fa fa-exclamation"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td class="question">
                        {{ question.value }}

                        <div
                            class="text-sm text-gray"
                            v-if="question.reference"
                        >
                            {{ question.reference }}
                        </div>
                    </td>
                    <td class="text-right" style="width: 150px">
                        <span v-if="countryLaw == 'CZ'">
                            <span
                                class="label"
                                v-bind:class="{
                                    'label-warning':
                                        question.relevantFor.kii === 'yes',
                                    'label-default':
                                        question.relevantFor.kii === 'no',
                                    'label-info':
                                        question.relevantFor.kii === 'optional'
                                }"
                                >{{ $t("comp.kbAudit.kbQuestion.kii") }}</span
                            >
                            <span
                                class="label"
                                v-bind:class="{
                                    'label-warning':
                                        question.relevantFor.vis === 'yes',
                                    'label-default':
                                        question.relevantFor.vis === 'no',
                                    'label-info':
                                        question.relevantFor.vis === 'optional'
                                }"
                                >{{ $t("comp.kbAudit.kbQuestion.vis") }}</span
                            >
                            <span
                                class="label"
                                v-bind:class="{
                                    'label-warning':
                                        question.relevantFor.dsp === 'yes',
                                    'label-default':
                                        question.relevantFor.dsp === 'no',
                                    'label-info':
                                        question.relevantFor.dsp === 'optional'
                                }"
                                >{{ $t("comp.kbAudit.kbQuestion.dsp") }}</span
                            >
                        </span>
                        <span v-if="countryLaw == 'SK'">
                            <span v-if="question.relevantFor.zokb">
                                <span
                                    class="label"
                                    v-bind:class="{
                                        'label-warning':
                                            question.relevantFor.zokb ==
                                            settings.relevantFor.zokb,
                                        'label-default':
                                            question.relevantFor.zokb !=
                                            settings.relevantFor.zokb
                                    }"
                                    >{{
                                        $t("comp.kbAudit.kbQuestion.zokb")
                                    }}</span
                                >
                                <span
                                    v-bind:key="item"
                                    v-for="item in question.relevantFor
                                        .categories"
                                    class="label"
                                    v-bind:class="{
                                        'label-warning':
                                            item ==
                                            settings.relevantFor.categoryZokb,
                                        'label-default':
                                            item !=
                                            settings.relevantFor.categoryZokb
                                    }"
                                    >{{ item }}</span
                                >
                            </span>
                            <span v-if="question.relevantFor.itvs">
                                <span
                                    class="label"
                                    v-bind:class="{
                                        'label-warning':
                                            question.relevantFor.itvs ==
                                            settings.relevantFor.itvs,
                                        'label-default':
                                            question.relevantFor.zokb !=
                                            settings.relevantFor.itvs
                                    }"
                                    >{{
                                        $t("comp.kbAudit.kbQuestion.itvs")
                                    }}</span
                                >
                                <span
                                    v-bind:key="item"
                                    v-for="item in question.relevantFor
                                        .categories"
                                    class="label"
                                    v-bind:class="{
                                        'label-warning':
                                            item ==
                                            settings.relevantFor.categoryItvs,
                                        'label-default':
                                            item !=
                                            settings.relevantFor.categoryItvs
                                    }"
                                    >{{ item }}</span
                                >
                            </span>
                        </span>

                        <button
                            class="btn btn-xs mt1"
                            @click="showModal = true"
                            :class="{
                                'btn-success': answer.measures.length,
                                'btn-warning':
                                    !answer.measures.length && answer.comment,
                                'btn-default':
                                    !answer.measures.length && !answer.comment
                            }"
                        >
                            {{ $t("comp.kbAudit.kbQuestion.measureBtn") }}
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                    </td>
                    <td class="text-right" style="width: 20px; text-align: right;">
                            <input type="checkbox" style="margin-top: 15px;" @change="handleCheckboxChange"
                            v-model="answer.selected"/>
                    </td>
                </tr>
            </tbody>
        </table>

        <edit-question
            :showModal="showModal"
            v-if="showModal"
            @hide="showModal = false"
            :answer="answer"
        ></edit-question>
    </div>
</template>

<script>
import EditQuestion from "./EditQuestion.vue";
import { EventBus } from './event-bus.js';
import { mapGetters, mapActions } from "vuex";

export default {
    name: "KbQuestion",
    components: {
        EditQuestion
    },
    data() {
        return {
            previouslySelected: null,
            showModal: false,
            readonly: false,
            answer: null,
        };
    },
    props: {
        question: Object,
        readonly: {
            type: Boolean,
            required: true
        },
        countryLaw: {
            // 'CZ', 'SK'
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters("auditKB", {
            getAnswerByQuestion: "getAnswerByQuestionZoKB",
            settings: "getSettings"
        }),
        isVisible() {
            if (this.countryLaw == "CZ") {
                if (
                    this.settings.relevantFor.kii &&
                    (this.question.relevantFor.kii === "yes" ||
                        this.question.relevantFor.kii === "optional")
                ) {
                    return true;
                }
                if (
                    this.settings.relevantFor.vis &&
                    (this.question.relevantFor.vis === "yes" ||
                        this.question.relevantFor.vis === "optional")
                ) {
                    return true;
                }
                if (
                    this.settings.relevantFor.dsp &&
                    (this.question.relevantFor.dsp === "yes" ||
                        this.question.relevantFor.dsp === "optional")
                ) {
                    return true;
                }
            }
            if (this.countryLaw == "SK") {
                if (
                    this.settings.relevantFor.zokb &&
                    this.question.relevantFor.zokb &&
                    this.question.relevantFor.categories.indexOf(
                        this.settings.relevantFor.categoryZokb
                    ) >= 0
                ) {
                    return true;
                }
                if (
                    this.settings.relevantFor.itvs &&
                    this.question.relevantFor.itvs &&
                    this.question.relevantFor.categories.indexOf(
                        this.settings.relevantFor.categoryItvs
                    ) >= 0
                ) {
                    return true;
                }
            }

            return false;
        }
    },
    mounted() {
        if (this.getAnswerByQuestion(this.question.id)) {
            this.answer = this.getAnswerByQuestion(this.question.id);
            this.previouslySelected = this.answer.choice;
        } else {
            this.answer = {
                questionId: this.question.id,
                choice: null,
                comment: null,
                measures: []
            };
        }

        this.$watch("answer.choice", v => {
            this.$emit("answerChanged");
            this.$api.post("audit-kb/answer?type=zokb", this.answer);
        });
    },
    watch: {
        isVisible: {
            handler(newVal, oldVal) {
                if (this.answer?.selected) {
                    this.answer.selected = newVal;
                    this.handleCheckboxChange();
                }
            },
            immediate: true
        }
    },
    methods: {
        radioClicked: function(e) {
            if (e.target.value === this.previouslySelected) {
                e.target.checked = null;
                this.previouslySelected = null;
                this.answer.choice = null;
            } else {
                this.previouslySelected = e.target.value;
            }
        },
        handleCheckboxChange() {
            EventBus.$emit('checkboxChanged', this.answer);
        },
    }
};
</script>

<style lang="less" scoped>
.pretty {
    font-size: 25px;
    display: block;
    margin-right: 0;
}

.choice {
    vertical-align: middle;
    width: 55px;
    text-align: center;
    margin-top: auto;
}

td.question {
    //cursor: pointer !important;
}

</style>
