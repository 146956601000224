<template>
    <div>
        <impact-calculation-method></impact-calculation-method>
        <asset-evaluation-propagation></asset-evaluation-propagation>
        <guarantor-primary-asset-creation></guarantor-primary-asset-creation>
        <guarantor-can-modify-declaration-of-applicability></guarantor-can-modify-declaration-of-applicability>
    </div>
</template>

<script>
    import ImpactCalculationMethod from './TabAssets/ImpactCalculationMethod.vue';
    import AssetEvaluationPropagation from './TabAssets/AssetEvaluationPropagation.vue';
    import GuarantorPrimaryAssetCreation from './TabAssets/GuarantorPrimaryAssetCreation.vue';
    import GuarantorCanModifyDeclarationOfApplicability from './TabAssets/GuarantorCanModifyDeclarationOfApplicability.vue';

    export default {
        components: {
            ImpactCalculationMethod,
            AssetEvaluationPropagation,
            GuarantorPrimaryAssetCreation,
            GuarantorCanModifyDeclarationOfApplicability
        },
    }
</script>
