<template>
    <div class="box box-default">
        <div class="box-header with-border">
            <h3 class="box-title">{{$t('comp.kbAudit.overallChart.title')}} {{questionsCount}}</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body">
            <div class="row">
                <div class="col-lg-6">
                    <canvas ref="chart"></canvas>

                    <div class="text-center">
                        <em v-html="$t('comp.kbAudit.overallChart.label')"></em>
                    </div>
                </div>
                <div class="col-lg-6">
                    <canvas ref="chart2"></canvas>
                </div>
            </div>
        </div>
        <!-- /.box-body -->
    </div>
</template>


<script>
    export default {
        name: 'overallChart',
        data() {
            return {
                chart: null,
                chart2: null
            }
        },
        props: {
            data: Object
        },
        watch: {
            data(v) {
                this.updateChart();
            }
        },
        mounted() {
            this.showChart();
        },
        methods: {
            showChart: function () {

                let labels = [
                    this.$t('comp.kbAudit.answerType.implemented'),
                    this.$t('comp.kbAudit.answerType.inImplementation'),
                    this.$t('comp.kbAudit.answerType.notApplicable'),
                    this.$t('comp.kbAudit.answerType.notImplemented'),
                    this.$t('comp.kbAudit.answerType.notAnswered')
                ];
                this.chart = overAllChart(this.$refs.chart, labels);

                this.updateChart();
            },
            updateChart: function () {
                if(!this.data) {
                    return;
                }

                this.chart.data.datasets[0].data = [
                    this.data.choices.implemented,
                    this.data.choices.in_implementation,
                    this.data.choices.not_applicable,
                    this.data.choices.not_implemented,
                    this.data.choices.not_answered
                ];

                if(this.data.count) {
                    this.chart.options.elements.center.text = Math.round(((
                        this.data.choices.implemented + this.data.choices.not_applicable) * 100 / this.data.count)) + "%";
                }

                this.chart.update();
            },
        }
    }



    function overAllChart(chartDiv, labels)
    {
        var config = {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [],
                    backgroundColor: [
                        "#5cb85c",
                        "#337ab7",
                        "#f0ad4e",                     
                        "#d9534f",
                        "#dddddd",
                    ],
                    hoverBackgroundColor: [
                        "#5cb85c",
                        "#337ab7",
                        "#f0ad4e",                  
                        "#d9534f",
                        "#dddddd",
                    ],
                    hoverBorderColor: [
                        "#5cb85c",
                        "#337ab7",
                        "#f0ad4e",                
                        "#d9534f",
                        "#dddddd",
                    ]
                }],
                labels: labels
            },
            options: {
                responsive: true,
                legend: {
                    display: true
                },
                elements: {
                    center: {
                        text: '',
                        color: '#a9a9a9', // Default is #000000
                        fontStyle: 'Arial', // Default is Arial
                        sidePadding: 20 // Defualt is 20 (as a percentage)
                    }
                }
            }
        };

        var ctx = chartDiv.getContext('2d');
        return new Chart(ctx, config);
    }

</script>