<template>
    <div class="row margin-bottom" v-if="value !== undefined">
        <label class="control-label col-md-3">
            {{$t('comp.settings.tabAssets.impactCalculationMethod.label')}}
        </label>
        <div class="col-lg-5 col-md-7" id="defaultClassification" v-cloak>
            <em v-if="!isEdit">{{ items[value] }}</em>

            <template v-if="!isEdit && !readonly">
                (<button class="btn-link no-padding" @click="editMode">{{$t('common.change')}}</button>)
            </template>

            <template v-if="isEdit">
                <div class="form-group">
                    <select class="form-control input-sm" v-model="tempValue">
                        <option v-for="(name, index) in items" :value="index">
                            {{name}}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <button class="btn btn-sm btn-success ajax" @click="save">
                        {{$t('common.save')}}
                    </button>
                    <button class="btn btn-sm btn-warning" @click="cancel">
                        {{$t('common.cancel')}}
                    </button>
                </div>
            </template>

            <div class="help-block text-sm" v-html="$t('comp.settings.tabAssets.impactCalculationMethod.help')"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImpactCalculationMethod',
        data() {
            return {
                value: null,
                tempValue: null,
                isEdit: false,
                items: []
            };
        },
        created() {
            this.load();
        },
        methods: {
            load: function () {
                this.$api.get("config/impact-calculation-method").then(({data}) => {
                    this.value = data.data;
                });
                this.$api.get("config/impact-calculation-method/methods").then(({data}) => {
                    this.items = data.data;
                });
            },
            save: function () {
                this.$api.post("config/impact-calculation-method", null, {
                    params: {
                        value: this.tempValue
                    }
                }).then(({data}) => {
                    if(data.status === 'ok') {
                        this.value = this.tempValue;
                        this.cancel();
                    }
                });
            },
            cancel: function () {
                this.isEdit = false;
                this.tempValue = null;
            },
            editMode: function () {
                this.isEdit = true;
                this.tempValue = this.value;
            }
        }
    }
</script>