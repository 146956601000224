<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th>{{$t('comp.testEditor.questionYesNo.answer')}}</th>
                <th style="width: 150px;">{{$t('comp.testEditor.questionYesNo.correctAnswer')}}</th>
            </tr>
            </thead>
            <tbody>
                <tr v-bind:class="{success: question.correct === 'yes'}">
                    <td>
                        <span class="label label-default" style="width: 60px; display: block;">{{ $t("yes") }}</span>
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon p-smooth" style="font-size: 20px;">
                            <input
                                    :name="questionIndex + '_answerCorrect'"
                                    type="radio"
                                    v-model="question.correct"
                                    value="yes"
                                    v-validate="'required'"
                                    :disabled="!editable" />
                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-bind:class="{success: question.correct === 'no'}">
                    <td>
                        <span class="label label-default" style="width: 60px; display: block;">{{ $t("no") }}</span>
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon p-smooth" style="font-size: 20px;">
                            <input
                                    :name="questionIndex + '_answerCorrect'"
                                    type="radio"
                                    v-model="question.correct"
                                    value="no"
                                    v-validate="'required'"
                                    :disabled="!editable"  />
                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr style="background: none">
                    <td>
                        <button class="btn btn-xs btn-link"
                                v-if="!isShowSubstantiation() && editable"
                                @click="showSubstantiation()">{{ $t("addSubstantiation") }}</button>

                        <div v-if="isShowSubstantiation()">
                            <div class="input-group input-group-sm">
                                <span class="input-group-addon">
                                    {{ $t("substantiation") }}
                                </span>

                                <input class="form-control" type="text" v-model="question.substantiation" maxlength="256" :disabled="!editable" />

                                <span class="input-group-btn">
                                    <button class="btn btn-xs btn-warning"
                                            @click="showSubstantiation(false)" :disabled="!editable">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td></td>
                </tr>
                <tr v-show="errors.has(questionIndex + '_answerCorrect')">
                    <td colspan="2" class="text-center">
                        <span class="text-red">
                            {{ $t("chooseCorrectAnswer") }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        inheritAttrs: false,
        components: {
            BaseQuestion
        },
        data: () => {
            return {
                showSubst: false
            };
        },
        methods: {
            isShowSubstantiation: function () {
                return this.showSubst || (this.question.substantiation && this.question.substantiation.length);
            },
            showSubstantiation: function (show = true) {
                this.showSubst = show;

                if(!show) {
                    this.$set(this.question, 'substantiation', null);
                }
            }
        }
    }
</script>