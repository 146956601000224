<template>
    <div class="question">
        <h3 class="box-title">{{question.value}}</h3>

        <div class="form-group">
            <span class="text-red">{{ errors.first(questionIndex + '_question') }}</span>
        </div>

        <slot/>
    </div>
</template>

<script>
    export default {
        components: {

        },
        data() {
            return {

            }
        },
        props: {
            question: Object,
            questionIndex: Number,
            total: Number,
        },
        created () {
            //this.$validator = this.parentValidator
        }
    }
</script>

<style lang="less">
    .question {
        min-height: 200px;
    }
</style>