<template>
    <div class="panel" :class="{'panel-default': !answer || !answer.length, 'panel-success': answer && answer.length}">
        <div class="panel-heading" @click="opened = !opened" style="cursor: pointer">
            <h4 class="panel-title text-sm">
                {{ index + 1 }}. {{$t(question.value)}}
            </h4>
        </div>
        <div class="panel-body no-padding" v-if="opened">
            <ckeditor :editor="editor" v-model="answer" :config="editorConfig"></ckeditor>
        </div>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/cs';
    import Question from "./Question.vue";

    export default {
        components: {
            Question,
            ckeditor: CKEditor.component
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            value: null
        },
        watch: {
            answer: function(v) {
                this.$emit("input", v)
            },
            value: function(v) {
                this.answer = v;
            }
        },
        created() {
            this.answer = this.value;
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'cs',
                    toolbar: ['bold', 'italic', "bulletedList", "numberedList"]
                },
                opened: false,
                answer: null
            };
        }
    }
</script>