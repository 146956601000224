<template>
  <div class="control">
    <label>{{$t('comp.assetModal.controls.practitioner.label')}}</label>

    <csa-select
        v-model="selected"
        :options="options"
        trackBy="id"
        label="name"
    >
    </csa-select>
  </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "asset-supplier-control",
        components: {
            CsaSelect
        },
        data() {
            return {
                selected: null
            };
        },
        props: {
            value: null,
        },
        watch: {
            selected: function (v) {
                this.$emit('input', v);
            },
            value: function (v) {
                if (Number.isInteger(v)) {
                    v = this.getById(v);
                }
                this.selected = v;
            }
        },
        created() {
            this.selected = this.value;
        },
        computed: {
            ...mapGetters("suppliers", {
                options: "getSuppliers",
                getById: 'getSupplierById'
            }),
        },
        methods: {
            getOption: function (id) {
                if(!id) {
                    return null;
                }

                return this.options.find((i)=>{return i.id === id;});
            }
        }
    }
</script>