<template id="main">
    <div>
        <div class="modal fade" role="dialog" ref="modal">
            <div class="modal-dialog modal-xl" role="document">
                <form class="modal-content" @submit="submit" v-if="initialised">
                    <div class="modal-header">
                        <h4 class="modal-title" v-if="plan.id">
                            {{$t('comp.continuityPlanModal.index.continuityPlanEdit')}}
                        </h4>
                        <h4 class="modal-title" v-else>
                            {{$t('comp.continuityPlanModal.index.continuityPlanCreate')}}
                        </h4>
                    </div>

                    <div class="modal-body no-padding">
                        <div class="nav-tabs-custom no-margin">
                            <ul class="nav nav-tabs">
                                <li :class="{active: tab === 0}"><a href="#" @click.prevent="tab = 0">{{$t('comp.continuityPlanModal.index.tabGeneral')}}</a></li>
                                <li :class="{active: tab === 1}"><a href="#" @click.prevent="tab = 1">{{$t('comp.continuityPlanModal.index.tabAssets')}}</a></li>
                                <li :class="{active: tab === 2}"><a href="#" @click.prevent="tab = 2">{{$t('comp.continuityPlanModal.index.tabAnalyticTeam')}}</a></li>
                                <li :class="{active: tab === 3}"><a href="#" @click.prevent="tab = 3">{{$t('comp.continuityPlanModal.index.tabExecutionTeam')}}</a></li>
                                <li :class="{active: tab === 4}"><a href="#" @click.prevent="tab = 4">{{$t('comp.continuityPlanModal.index.tabControlTeam')}}</a></li>
                                <li :class="{active: tab === 5}"><a href="#" @click.prevent="tab = 5">{{$t('comp.continuityPlanModal.index.tabProcess')}}</a></li>
                                <li :class="{active: tab === 6}"><a href="#" @click.prevent="tab = 6">{{$t('comp.continuityPlanModal.index.tabAttachments')}}</a></li>
                            </ul>
                            <div class="tab-content" style="min-height: 400px">
                              <keep-alive>
                                <general-tab :plan="plan" :tags="tags" v-if="tab === 0" />
                                <assets-tab :plan="plan" :assets="assets" v-if="tab === 1" />
                                <analytic-team-tab :plan="plan" :people="people" v-if="tab === 2" />
                                <execution-team-tab :plan="plan" :people="people" v-if="tab === 3" />
                                <control-team-tab :plan="plan" :people="people" v-if="tab === 4" />
                                <process-tab :plan="plan" @deleteFile="deleteFile" v-if="tab === 5" />
                                <attachments-tab :plan="plan" @deleteFile="deleteFile" v-if="tab === 6" />
                              </keep-alive>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer text-right" :key="tab">
                        <button class="btn btn-link" type="button" @click="cancel" :disabled="isLoading">{{$t('common.cancel')}}</button>

                        <template>
                            <button class="btn btn-default pull-left" type="button" @click="tab--" v-if="tab > 0">
                                <i class="fas fa-chevron-left"></i>
                                {{$t('common.previousStep')}}
                            </button>

                            <div class="pull-right">
                                <button class="btn btn-primary" type="button" @click="tab++" v-if="tab < 6">
                                    {{$t('common.nextStep')}}
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                                <button class="btn btn-success" type="submit" v-if="tab === 6 && !plan.id" :disabled="isLoading">
                                    {{$t('common.create')}}
                                </button>
                                <button class="btn btn-success" type="submit" v-if="plan.id" :disabled="isLoading">
                                    {{$t('common.save')}}
                                </button>
                            </div>
                        </template>
                    </div>

                    <loading :active="isLoading" :is-full-page="false"></loading>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/cs';
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';
    import GeneralTab from "./tabs/General.vue";
    import AssetsTab from "./tabs/Assets.vue";
    import AnalyticTeamTab from "./tabs/AnalyticTeam.vue";
    import ExecutionTeamTab from "./tabs/ExecutionTeam.vue";
    import ControlTeamTab from "./tabs/ControlTeam.vue";
    import ProcessTab from "./tabs/Process.vue";
    import AttachmentsTab from "./tabs/Attachments.vue";

    export default {
        components: {
            GeneralTab,
            AssetsTab,
            AnalyticTeamTab,
            ExecutionTeamTab,
            ControlTeamTab,
            ProcessTab,
            AttachmentsTab,
            ckeditor: CKEditor.component,
            CsaSelect
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'cs',
                    toolbar: ['bold', 'italic', "bulletedList", "numberedList"]
                },
                isLoading: false,
                initialised: false,
                showModal: false,
                tab: 0,
                modal: null,
                files_to_delete: [],
                plan: {
                    id: null,
                    custom_id: '',
                    name: '',
                    description: '',
                    tags: [],
                    assets: [],
                    analytic_team: [],
                    execution_team: [],
                    control_team: [],
                    process_description: '',
                    process_attachments: [],
                    attachments: [],
                },
                assets: [],
                tags: [],
                people: [],
            }
        },
        mounted: function () {
            this.modal = this.$refs.modal;

            if(this.showModal) {
                this.show();
            }
        },
        watch: {
            showModal: function(showModal) {
                if(showModal) {
                    this.show();
                } else {
                    this.cancel();
                }
            }
        },
        computed: {
            notSelectedAssets() {
                return this.assets.filter((a) => {
                    return !this.plan.assets.includes(a)
                })
            },
            ...mapGetters("orgStructure", {
                orgStructure: "getStructure",
                getParsonById: "getById",
            }),
        },
        methods: {
            ...mapActions("orgStructure", ["fetchStructure"]),
            async open(id) {
                await this.reset()

                //id
                if(id) {
                    let {data} = await this.$api.get('continuity-plan/' + id);
                    Object.assign(this.plan, data.data)

                    this.$api.get('continuity-plan/' + id + '/attachments').then(({data}) => {
                        this.$set(this.plan, 'attachments', data.data)
                    })
                    this.$api.get('continuity-plan/' + id + '/process-attachments').then(({data}) => {
                        this.$set(this.plan, 'process_attachments', data.data)
                    })
                }

                this.showModal = true
            },
            cancel: function () {
                $(this.modal).modal("hide");
                this.showModal = false
            },
            deleteFile(file) {
                if(file instanceof File === false) {
                    this.files_to_delete.push(file)
                }
            },
            async show() {
                this.isLoading = true
                await this.$api.get('asset/fetch-assets-extended').then(({data}) => {
                    this.assets = data.data
                })
                await this.$api.get('continuity-plan/tags').then(({data}) => {
                    this.tags = data.data
                })

                await this.$api.get("org-people-grouped").then(({ data }) => {
                  this.people = data.data
                })

                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });

                this.initialised = true
                this.isLoading = false
            },
            submit(e) {
                e.preventDefault()

                if(this.plan.id) {
                    this.save()
                } else {
                    this.create()
                }
            },
            async create() {
                if(this.plan.name.length === 0) {
                    alert(this.$t('comp.continuityPlanModal.index.selectPlanNameAlert'))
                    this.tab = 0
                    return
                }

                this.isLoading = true
                let id = await this.$api.post('continuity-plan', this.plan).then(({data}) => {
                    return data.data.id
                })

                await this.processAttachments(id)
                this.isLoading = false

                this.$emit('reload')
            },
            async save() {
                if(this.plan.name.length === 0) {
                    alert(this.$t('comp.continuityPlanModal.index.selectPlanNameAlert'))
                    this.tab = 0
                    return
                }

                this.isLoading = true
                await this.$api.put('continuity-plan/' + this.plan.id, this.plan).then(({data}) => {
                    return data.data.id
                })

                await this.processAttachments(this.plan.id)
            },
            async processAttachments(planId) {
                this.isLoading = true

                let files = [...this.files_to_delete]
                for (const file of files) {
                    await this.$api.delete('continuity-plan/attachments/' + file.id).then(({data}) => {
                        this.files_to_delete.splice(this.files_to_delete.indexOf(file), 1)
                    })
                }

                //save new
                for (const file of this.plan.process_attachments) {
                    if(file instanceof File === false) {
                        continue
                    }

                    let formData = new FormData()
                    formData.append('file', file)

                    let result = await this.$api.post('continuity-plan/' + planId + '/attachments', formData, {
                        params: {
                            processAttachment: true
                        }
                    }).then(({data}) => {
                        if(data.status === 'error' && data.code === 'not_image') {
                            alert(this.$t('comp.continuityPlanModal.index.notImage', {fileName: file.name}))

                            this.plan.process_attachments.splice(this.plan.process_attachments.indexOf(file), 1)
                            return false
                        }

                        return true
                    })

                    if(!result) {
                        this.isLoading = false
                        return false
                    }
                }
                for (const file of this.plan.attachments) {
                    if(file instanceof File === false) {
                        continue
                    }

                    let formData = new FormData()
                    formData.append('file', file)

                    await this.$api.post('continuity-plan/' + planId + '/attachments', formData).then(({data}) => {

                    })
                }

                this.reset()
                this.cancel()
                this.$emit('reload')
            },
            reset() {
                this.plan = {
                    id: null,
                    custom_id: '',
                    name: '',
                    description: '',
                    tags: [],
                    assets: [],
                    analytic_team: [],
                    execution_team: [],
                    control_team: [],
                    process_description: '',
                    process_attachments: [],
                    attachments: [],
                };

                this.files_to_delete = []

                setTimeout(() => {this.tab = 0}, 300)
            }
        }
    }
</script>

<style>
    .control {
        margin-top: 5px;
    }
</style>
