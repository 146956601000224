<template>
    <div class="box box-solid">
        <div class="box-header">
            <div :class="{'input-group input-group-sm': searchedTerm}">
                <input class="form-control input-sm" :placeholder="$t('comp.orgStructure.navigation.searchPersonPlaceholder')" v-model="searchedTerm" v-on:keyup.enter="search(searchedTerm)" />
                <div class="input-group-btn" v-if="searchedTerm">
                    <button class="btn btn-default" @click="searchedTerm = ''">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="box-body"  style="overflow:auto !important; min-height: auto !important">
            <tree :options="options"
                   @node:clicked="nodeSelected"
                   ref="tree"
                   @node:dragging:finish="dragFinish"
                   @node:dragging:start="dragOn"
                   @node:expanded="nodeExpanded">

                <div class="tree-scope" slot-scope="{ node }">
                    <template v-if="node.data.isRoot">
                        <span class="text">
                            <i class="fas fa-building"></i>
                            {{ orgName }}

                            <span class="tools">
                                <button type="button" class="btn btn-default btn-xs"  style="margin-left: 10px;" @click.stop="addUnit(node)">
                                    <i class="fas fa-plus-square"></i>
                                </button>
                                <button type="button" class="btn btn-default btn-xs" @click.stop="addPerson(node)">
                                    <i class="fas fa-user-plus"></i>
                                </button>
                            </span>
                        </span>

                        <span class="release" v-if="!node.hasChildren()">
                          {{ node.data.release }}
                        </span>
                    </template>
                    <template v-else>
                        <div class="text">
                            <template v-if="node.data.isUnit">
                                <i class="fas fa-address-book"></i>
                            </template>

                           <i class="fas fa-user" v-else></i>

                            {{ node.text }}

                            <div class="tools" v-if="node.data.isUnit">
                                <div style="float: right; margin-left: 10px;">
                                    <button type="button" class="btn btn-default btn-xs" @click.stop="removeUnit(node)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <button type="button" class="btn btn-default btn-xs" @click.stop="editUnit(node)">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                    <button type="button" class="btn btn-default btn-xs" @click.stop="addUnit(node)">
                                        <i class="fas fa-plus-square"></i>
                                    </button>
                                    <button type="button" class="btn btn-default btn-xs" @click.stop="addPerson(node)">
                                        <i class="fas fa-user-plus"></i>
                                    </button>
                                    <button type="button" class="btn btn-default btn-xs" @click.stop="assignPerson(node)">
                                        <i class="fas fa-user-check"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="tools" v-else>
                                <div style="float: right; margin-left: 10px;">
                                    <button type="button" class="btn btn-default btn-xs" v-if="!node.parent.data.isRoot" @click.stop="unassignPerson(node)">
                                        <i class="fas fa-user-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <span class="release">
                            {{ node.data.release }}
                        </span>
                    </template>
                </div>
            </tree>
        </div>
        <!-- /.box-body -->

        <delete-confirmation :ref="'delconfUnit'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>
    </div>
    
</template>

<script>
    import LiquorTree from 'liquor-tree'
    import {mapGetters, mapActions} from 'vuex';
    import NavigationBus from './NavigationBus.js';
    import _ from 'lodash';
    import ImportModalBus from './ImportModal/ImportModalBus';
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

    export default {
        components: {
            LiquorTree,
            DeleteConfirmation
        },
        data() {
            return {
                searchedTerm: undefined,
                tree: null,
                orgName: null,
                options: {
                    dnd: {
                        onDragFinish(targetNode, destinationNode, position) {
                            if(destinationNode.id === 'root' && position !== 'drag-on') {
                                return false;
                            }

                            if(position === 'drag-on') {
                                return true;
                            }

                            if(targetNode.parent === destinationNode.parent) {
                                return false;
                            }

                            return destinationNode.parent === null || destinationNode.parent.isUnit;
                        }
                    },
                    checkbox: false,
                    parentSelect: true,
                    fetchData: (node) => {
                        return this.getData();
                    }
                }
            }
        },
        watch: {
            searchedTerm: _.debounce(function (value) {
                this.search(value);
            }, 350)
        },
        computed: {
            ...mapGetters("orgStructure", {
                items: "getStructure",
                getById: "getById",
            }),
        },
        mounted(){
            this.searchedTerm = this.$route.query.q;
            this.tree = this.$refs.tree;

            this.$api.get("config/general").then(({data}) => {
                if(data.status === 'ok') {
                    this.orgName = data.data.organization_name;
                }
            });

            NavigationBus.$on('select-node', (id)=>{
                let node = null;

                if(!id) {
                    node = this.tree.find({id: 'root'})[0];
                }
                else {
                    node = this.tree.find({data: { id, isUnit: true }})[0];
                }

                if(!node) {
                    return;
                }

                node.select();
            });

            NavigationBus.$on('expand-to-node', id => {
                let node = this.tree.find({data: { id, isUnit: true }})[0];

                if(!node) {
                    return;
                }

                while(node) {
                    node.expand();
                    node = node.parent;
                }
            });

            NavigationBus.$on('redraw-node', ({id, isUnit}) => {
                let node = this.tree.find({ data: {id: id, isUnit} })[0];

                if(node.data.isUnit) {
                    this.$api.get("org-unit/" + node.data.id).then(({data}) => {
                        node.text = data.data.name;
                    });
                } else {
                    this.$api.get("org-person/" + node.data.id).then(({data}) => {
                        node.text = data.data.name;
                    });
                }
            });

            NavigationBus.$on('remove-unit', id => {
                let node = this.tree.find({data: {id: id, isUnit: true }})[0];

                this.tree.remove(node);

                // Reload people under root
                this.$api.get("org-person/fetch-people-in-unit").then(({data}) => {
                    let people = data.data;
                    let root = this.tree.find({id: 'root'})[0];

                    for(let i = 0; i < people.length; i++) {
                        let person = this.tree.find({ data: {id: people[i].id, isUnit: false} })[0];
                     
                        if(!person){
                            root.append({
                                text: people[i].name,
                                data: {
                                    id: people[i].id,
                                    isUnit: false
                                },
                                state: {
                                    "dropable": false,
                                },
                            });
                        }
                    }

                    if(node.selected()) {
                        if(node.parent) {
                            this.$router.push({name: 'listing', params: {unitId: node.parent.id}});
                        } else {
                            this.$router.push({name: 'listing', params: {unitId: null}});
                        }
                    }
                });
            });

            NavigationBus.$on('remove-person-under-root', id => {

                let nodes = this.tree.findAll({data: {id: id, isUnit: false}});
                for(let i = 0; i < nodes.length; i++) {
                    let node = nodes[i];
                    
                    if(node.parent.data.isRoot){
                        this.tree.remove(node);
                    }
                }
            });

            NavigationBus.$on('remove-person', id => {
                let nodes = this.tree.findAll({data: {id: id, isUnit: false}});
                let parentUnitId = null;

                for(let i = 0; i < nodes.length; i++) {
                    let node = nodes[i];
                    if(node.selected()) {
                        if(node.parent) {
                            parentUnitId = node.parent.data.id;
                        }
                    }

                    this.tree.remove(node);
                }

                this.$router.push({name: 'listing', params: {unitId: parentUnitId}});
            });

            NavigationBus.$on('unassign-person', ({personId, unitId}) => {
                let nodes = this.tree.findAll({data: {id: personId, isUnit: false}});
                let node = null;

                for(let i = 0; i < nodes.length; i++) {
                    let item = nodes[i];
                    if(item.parent && item.parent.data){
                        if(item.parent.data.id == unitId){
                            node = item;
                            break;
                        }
                    }
                }

                if(node){
                    if(node.selected()) {
                        if(node.parent) {
                            this.$router.push({name: 'listing', params: {unitId: node.parent.data.id}});
                        } else {
                            this.$router.push({name: 'listing', params: {unitId: null}});
                        }
                    }

                    this.tree.remove(node);

                    // Move node under root
                    if(nodes.length == 1){
                        let rootNode = this.tree.find({id: 'root'})[0];
                        rootNode.append({
                            text: node.text,
                            data: {
                                id: node.data.id,
                                isUnit: false
                            },
                            state: {
                                "dropable": false,
                            },
                        });
                    }
                }
            });

            NavigationBus.$on('create-person', ({person, unitId}) => {
                let parentNode = null;

                if(unitId) {
                    parentNode = this.tree.find({data: {id: unitId, isUnit: true}})[0];
                } else {
                    parentNode = this.tree.find({id: 'root'})[0];
                }

                parentNode.append({
                    text: person.name,
                    data: {
                        id: person.id,
                        isUnit: false
                    },
                    state: {
                        "dropable": false,
                    },
                });

                parentNode.expand();
            });

            NavigationBus.$on('create-unit', unit => {
                let parentNode = null;

                if(unit.parentUnitId) {
                    parentNode = this.tree.find({ data: {id: unit.parentUnitId, isUnit: true} })[0];
                } else {
                    parentNode = this.tree.find({id: 'root'})[0];
                }

                parentNode.append({
                    text: unit.name,
                    data: {
                        id: unit.id,
                        isUnit: true,
                    },
                    state: {
                        "dropable": true,
                    },
                });

                parentNode.expand();

                this.$nextTick(()=>{
                    $('.dropdown-toggle').dropdown();
                });
            });

            NavigationBus.$on('reload-org-structure', _ => {
                this.getData().then((data) => {
                    this.tree.setModel(data);
                });
            });

            ImportModalBus.$on('import-finished', ()=>{
                this.getData().then((data) => {
                    this.tree.setModel(data);
                });
            });
        },
        methods: {
            ...mapActions("orgStructure", ["fetchStructure"]),
            async getData() {
                return await this.$api.get('/org-structure')
                            .then(({data}) => {

                                let isRootSelected = !this.$route.params.unitId && !this.$route.params.personId;

                                return {
                                    id: 'root',
                                    text: '',
                                    data: {
                                        isRoot: true
                                    },
                                    children: this.transform(data.data),
                                    state: {
                                        selected: isRootSelected,
                                        dragging: false,
                                        draggable: false,
                                        dropable: true,
                                        expanded: true,
                                    }
                                };
                            });
            },
            transform(orgItems) {
                if(!orgItems) {
                    return null;
                }

                let children = [];

                orgItems.forEach((i) => {
                    let node = this.transformNode(i);
                    node.children = this.transform(i.children);

                    // Check for selection
                    if(node.children && node.children.length > 0){
                        for(let n = 0; n < node.children.length; n++) {
                            let child = node.children[n];

                            let isNodeSelected = false;
                            if(this.$route.params.unitId == child.data.id && child.data.isUnit && !this.$route.params.personId){
                                isNodeSelected = true;      // Selected unit
                            }
                            else if(this.$route.params.personId == child.data.id && !child.data.isUnit && node.data.id == this.$route.params.unitId){
                                isNodeSelected = true;      // Selected person in unit
                            }
                            child.state.selected = isNodeSelected;

                            if(child.state.selected || child.state.expanded){
                                node.state.expanded = true;
                                break;
                            }
                        }
                    }
                    
                    if(this.$route.params.unitId == node.data.id && node.data.isUnit && !this.$route.params.personId){
                        node.state.selected = true;      // Selected unit under root
                    }
                    else if(!this.$route.params.unitId && !node.data.isUnit && this.$route.params.personId == node.data.id){
                        node.state.selected = true;      // Selected person under root
                    }

                    children.push(node);
                });

                return children;
            },
            transformNode(i) {
                return {
                    text: i.name,
                    data: {
                        id: i.id,
                        isUnit: i.node === 'unit'
                    },
                    state: {
                        dropable: i.node === 'unit'
                    },
                };
            },
            search(value){
                if(value) {
                    this.$router.push({name: 'listing', params: {unitId: this.$route.params.unitId}, query: {q: value}});
                } else {
                    this.$router.push({name: 'listing', params: {unitId: this.$route.params.unitId}});
                }
            },
            nodeSelected(node) {
                if(node.data.isRoot) {
                    if(this.$route.name != 'listing' || (this.$route.name == 'listing' && this.$route.params.unitId != null)){
                        this.$router.push({name: 'listing', params: {unitId: null}});
                    }
                }
                else if(node.data.isUnit) {
                    if(this.$route.name != 'listing' || (this.$route.name == 'listing' && this.$route.params.unitId != node.data.id)){
                        this.$router.push({name: 'listing', params: {unitId: node.data.id}});
                    }
                } else {
                    let unitId = node.data.isRoot ? null : node.parent.data.id;
                    if(this.$route.name != 'person' || (this.$route.name == 'person' && (this.$route.params.personId != node.data.id || this.$route.params.unitId != unitId))){
                        this.$router.push({name: 'person', params: {personId: node.data.id, unitId: unitId}});
                    }
                }
            },
            editUnit(node) {
                this.$router.push({name: 'unit', params: {unitId: node.data.id}});
            },
            dragOn(targetNode) {

                // Store drag from parent id
                if(targetNode.parent && targetNode.parent.data.isUnit){
                    targetNode.data.sourceParentId = targetNode.parent.data.id;
                }
                else {
                    targetNode.data.sourceParentId = null;
                }

                //console.log('onDragOn', targetNode);
            },
            dragFinish(targetNode, destinationNode, position) {
                //console.log('onDragFinish', targetNode.text,  targetNode.data.sourceParentId, destinationNode.text, position);

                if(position === 'drag-on') {
                    this.moveNode(targetNode, destinationNode);
                    return true;
                } else {
                    if(destinationNode.parent) {
                        this.moveNode(targetNode, destinationNode.parent);
                    } else {
                        this.moveNode(targetNode, null);
                    }
                }
            },
            moveNode(node, parentNode) {
                if(node.data.isUnit) {
                    this.$api.post("org-unit/" + node.data.id + "/move", null, {
                        params: {
                          parentId: parentNode.data.isRoot ? null : parentNode.data.id
                        }
                    }).then(() => {
                        this.nodeSelected(node);
                    });
                } else {
                    this.$api.post("org-person/" + node.data.id + "/move", null, {
                        params: {
                            sourceParentId: node.data.sourceParentId,
                            targetParentId: parentNode.data.isRoot ? null : parentNode.data.id
                        }
                    }).then(() => {
                        this.nodeSelected(node);
                    });
                }
            },
            addUnit(node) {
                this.$router.push({name: 'createUnit', params: {unitId: node.data.isRoot ? null : node.data.id}});
            },
            addPerson(node) {
                this.$router.push({name: 'createPerson', params: {unitId: node.data.isRoot ? null : node.data.id}});
            },
            assignPerson(node) {
                this.$router.push({name: 'assignPerson', params: {unitId: node.data.id}});
            },
            unassignPerson(node) {
                if(node.parent.data){
                    this.$api.post("org-person/" + node.data.id + "/unassignPersonFromUnit", null, {
                        params: {
                            unitId: node.parent.data.id
                        }
                    }).then(()=>{
                        let person = {
                            id: node.data.id,
                            name: node.data.text
                        };
                        NavigationBus.$emit('unassign-person', {personId: node.data.id, unitId: node.parent.data.id})
                        NavigationBus.$emit('reload-listing');
                    });
                }
            },
            nodeExpanded() {
                this.$nextTick(()=>{
                    $('.dropdown-toggle').dropdown()
                });
            },
            closeDropdown($event) {
                $($event.target).closest('.dropdown').removeClass('open')
            },
            removeUnit(unit) {
                let modalWindow = this.$refs.delconfUnit;
                modalWindow.show(unit.data.id);
            },
            deleteConfirmed(unitId) {
                this.$api.delete("org-unit/" + unitId).then(({data})=>{
                    if(data.status === 'ok') {
                        NavigationBus.$emit('remove-unit', unitId)
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .tree-content {
        padding: 0;
    }

    /deep/ .tree {
        min-height: 100%;

        .tree-node {
            .tree-anchor {
                margin-left: 0;
                padding-left: 0;
            }
        }

        .tree-anchor {
            .tools {
                display: none;
                padding-left: 5px;
            }

            &:hover .tools {
                display: inline;
            }
        }

        .tree-node.selected > .tree-content {
            background: #f5000b;
            border-radius: 3px;

            .tree-scope {
                color: #ffffff;
            }

            .tools {
                color: black;
            }
        }

        .tree-node {
            white-space: normal;
        }

        .dropdown-menu {
            right: 0 !important;
            left: auto !important;
        }
    }

    .box-body {
        overflow: scroll;
        max-height: 90vh;
        min-height: 500px;
    }
</style>