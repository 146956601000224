<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th style="width: 50px;"></th>
                <th>{{ $t("answer") }}</th>
                <th style="width: 150px;">{{ $t("correctAnswer") }}</th>
                <th style="width: 100px;" class="text-right"></th>
            </tr>
            </thead>
            <tbody id="answers">
                <tr v-for="(answer, index) in question.answers" v-bind:class="{success: question.answers[index].correct}">
                    <td class="text-bold">{{index+1}}.</td>
                    <td>
                        <input
                                :name="questionIndex + '_answer_' + index"
                                type="text"
                                v-model="question.answers[index].value"
                                class="form-control"
                                v-validate="'required'"
                                :data-vv-as="$t('answerField')"
                                maxlength="256"
                                :disabled="!editable" />

                        <button class="btn btn-xs btn-link"
                                v-if="!isShowSubstantiation(answer) && editable"
                                @click="showSubstantiation(answer)">{{ $t("addSubstantiation") }}</button>

                        <div class="mt1" v-if="isShowSubstantiation(answer)">
                            <div class="input-group input-group-sm">
                                <span class="input-group-addon">
                                    {{ $t("substantiation") }}
                                </span>

                                <input class="form-control" type="text" v-model="answer.substantiation" maxlength="256" :disabled="!editable" />

                                <span class="input-group-btn">
                                    <button class="btn btn-xs btn-warning"
                                            @click="showSubstantiation(answer, false)" :disabled="!editable">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </span>
                            </div>
                        </div>

                        <span class="text-red">{{ errors.first(questionIndex + '_answer_' + index) }}</span>
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon p-smooth" style="font-size: 20px;">
                            <input
                                    :name="questionIndex + '_answerCorrect'"
                                    type="checkbox"
                                    v-model="question.answers[index].correct"
                                    v-validate="'required'"
                                    :disabled="!editable" />
                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="btn-group" v-if="editable">
                            <button class="btn btn-danger" @click="removeAnswer(answer)" v-bind:disabled="question.answers.length < 3">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button class="btn btn-success" @click="appendAnswer(answer)">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr  v-show="errors.has(questionIndex + '_answerCorrect')">
                    <td colspan="4" class="text-center">
                        <span class="text-red">
                            {{ $t("chooseLeastOneCorrectAnswer") }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        inheritAttrs: false,
        components: {
            BaseQuestion
        },
        mounted: function () {
            if(!this.question.answers.length) { //create first question
                this.addAnswer();
                this.addAnswer();
            }
        },
        data: () => {
            return {

            };
        },
        methods: {
            getAnswerKey: function(answer){
                let i;

                for(i = 0; i < this.question.answers.length; i++) {
                    let a = this.question.answers[i];

                    if(a === answer) {
                        break;
                    }
                }

                return i;
            },
            addAnswer: function () {
                this.question.answers.push({
                    value: "",
                    correct: false,
                    showSubstantiation: false,
                    substantiation: null,
                });
            },
            appendAnswer: function (answer) {
                let i = this.getAnswerKey(answer);

                this.question.answers.splice(i+1, 0, {
                    value: "",
                    correct: false,
                    showSubstantiation: false,
                    substantiation: null,
                });
            },
            removeAnswer: function (answer) {
                let i = this.getAnswerKey(answer);

                this.question.answers.splice(i, 1);
            },
            isShowSubstantiation: function (answer) {
                return answer.showSubstantiation || (answer.substantiation && answer.substantiation.length);
            },
            showSubstantiation: function (answer, show = true) {
                this.$set(answer, 'showSubstantiation', show);

                if(!show) {
                    this.$set(answer, 'substantiation', null);
                }
            }
        }
    }
</script>

<style scoped>
    #answers td {
        vertical-align: middle;
    }
</style>