<template>
    <div style="width: 100%">
        <div class="pull-right">
            <button class="btn btn-success" @click="add">
                <i class="fa fa-plus"></i> {{ $t("common.add") }}
            </button>
        </div>
        <div class="pull-left" style="padding-top: 12px">
            <button
                    class="btn btn-xs btn-danger"
                    @click="removeSelected"
                    :disabled="currentSelections.length > 0 ? disabled : ''">
                <i class="fa fa-trash"></i> {{ $t("common.delete") }}
            </button>
        </div>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th style="width: 2%"></th>
                    <th style="width: 40%">
                        {{ $t("comp.manageMeasures.tagListing.name") }}
                    </th>
                    <th style="width: 40%">
                        {{ $t("comp.manageMeasures.tagListing.link") }}
                    </th>
                    <th style="width: 20%"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <label class="form-checkbox" style="margin-bottom: 0px">
                            <input
                                   type="checkbox"
                                   :checked="isAllSelected"
                                   @click="selectAll" />
                            <i class="form-icon"></i>
                        </label>
                    </td>
                    <td>
                        <input
                               class="form-control input-sm"
                               v-model="filter.name"
                               :disabled="isEditing"
                               :placeholder="$t('comp.manageMeasures.tagListing.searchPlaceholder')" />
                    </td>
                    <td></td>
                    <td>
                        <button
                                class="btn btn-danger btn-xs"
                                @click="cancelFilter"
                                v-if="isFiltered"
                                :disabled="isEditing">
                            {{ $t("common.cancelFilter") }}
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <template v-for="tag in tags">
                    <tag
                         v-if="tag.id"
                         :item="tag"
                         :key="tag.id"
                         @removed="remove"
                         @reload="reload">
                    </tag>
                    <tag
                         v-else
                         :item="tag"
                         :key="tag.key"
                         @removed="remove"
                         @reload="reload">
                    </tag>
                </template>
            </tbody>
        </table>

        <div class="pull-right" v-if="paginator.itemCount > 1">
            {{ paginator.offset + 1 }}-{{ paginator.offset + paginator.length }}/{{
                paginator.itemCount
            }}
            <div class="btn-group" style="padding-right: 20px">
                <button
                        class="btn btn-default btn-sm"
                        @click="paginator.page--"
                        :disabled="paginator.page === 1">
                    <i class="fa fa-chevron-left"></i>
                </button>
                <button
                        class="btn btn-default btn-sm"
                        @click="paginator.page++"
                        :disabled="paginator.page === paginator.pageCount">
                    <i class="fa fa-chevron-right"></i>
                </button>
            </div>
            <div id="measureTagsPerPage_LocalStorage" class="btn-group">
                <select class="form-control" v-model="paginator.perPage">
                    <option
                            v-for="perPage in perPageOptions"
                            :value="perPage"
                            :key="perPage">
                        {{ perPage }}
                    </option>
                </select>
            </div>
        </div>
        <loading :active="isLoading" :is-full-page="false"></loading>

        <delete-confirmation
                             :ref="'massDelConfMeasureTag'"
                             v-on:confirmed="massDeleteConfirmed($event)"></delete-confirmation>
    </div>
</template>

<script>
import Tag from "./Tag.vue";
import { mapActions, mapGetters } from "vuex";
import store from "../../store";
import EventBus from "./EventBus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation.vue";

export default {
    name: "TagListing",
    store,
    components: {
        Tag,
        DeleteConfirmation,
    },
    data() {
        return {
            tags: [],
            key: 0,
            filter: {
                name: null,
            },
            paginator: {
                perPage: 25,
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100],
            isLoading: false,
            watchFilter: true,
            currentSelections: [],
        };
    },
    computed: {
        isFiltered: function () {
            return this.filter.name;
        },
        isEditing() {
            return (
                this.tags.filter((t) => {
                    return t.editMode === true;
                }).length > 0
            );
        },
        isAllSelected() {
            //checked/(unchecked) checkBox selectAll if every/(more then one) item on items is checked/(unchecked)
            if (this.currentSelections.length <= 0) {
                return false;
            }
            return this.tags.every((item) => item.selected);
        },
    },
    watch: {
        ["filter.name"]: function () {
            if (this.watchFilter) {
                this.debouncedFilterName();
            }
        },
        ["paginator.page"]: function () {
            this.fetchFilteredTags();
        },
        ["paginator.perPage"]: function () {
            this.fetchFilteredTags();
            localStorage.setItem(
                "measureTagsPerPage_LocalStorage",
                this.paginator.perPage
            );
        },
        tags: {
            handler() {
                this.currentSelections = this.tags
                    .filter((item) => item.selected)
                    .map((item) => item.id);
            },
            deep: true,
        },
    },
    mounted: function () {
        if (localStorage.getItem("measureTagsPerPage_LocalStorage")) {
            try {
                this.paginator.perPage = localStorage.getItem(
                    "measureTagsPerPage_LocalStorage"
                );
            } catch (e) {
                localStorage.removeItem("measureTagsPerPage_LocalStorage");
            }
        }
    },
    created: function () {
        this.fetchFilteredTags();

        this.debouncedFilterName = _.debounce(this.fetchFilteredTags, 700);

        EventBus.$on("imported", () => {
            this.fetchFilteredTags(false);
        });
    },
    methods: {
        add: function () {
            this.tags.unshift({
                id: null,
                name: null,
                link: null,
                key: this.key++,
            });
        },
        remove: function (item) {
            this.tags.splice(this.tags.indexOf(item), 1);
        },
        removeSelected: function () {
            let modalWindow = this.$refs.massDelConfMeasureTag;
            modalWindow.show();
        },
        massDeleteConfirmed: function () {
            this.isLoading = true;

            this.$api
                .post("measure-tags/mass-delete", this.currentSelections)
                .then((res) => {
                    if (res.data.status == "ok") {
                        this.showDeleted();
                        this.reload(true);
                    } else if (res.data.status == "error") {
                        this.showDeleteError();
                    }
                    this.isLoading = false;
                });
        },
        fetchFilteredTags: function (showLoading = true) {
            this.isLoading = showLoading;
            this.filterTags({ filter: this.filter, paginator: this.paginator }).then(
                (tags) => {
                    tags.items.forEach((tag) => {
                        tag.selected = false;
                    });
                    this.isLoading = false;
                    this.tags = tags.items;
                    this.paginator = tags.paginator;
                }
            );
        },
        ...mapActions("measureTags", {
            filterTags: "fetchFilteredTags",
        }),
        selectAll() {
            let selected = !this.isAllSelected;
            //check/uncheck all item checkBox on items if selectAll is checked/(unchecked)
            this.tags.forEach((item) => (item.selected = selected));
        },
        cancelFilter: function () {
            this.watchFilter = false;

            this.filter = {
                name: null,
            };

            this.fetchFilteredTags();

            this.$nextTick(() => {
                this.watchFilter = true;
            });
        },
        reload: function () {
            this.fetchFilteredTags(false);
        },
    },
    notifications: {
        showDeleted: {
            message: "common.deletedItems",
            type: "success",
        },
        showDeleteError: {
            message: "common.deleteError",
            type: "error",
        },
    },
};
</script>

<style></style>
