<template>
    <div class="control">
        <span class="text-sm"
            >{{ $t("comp.assetModal.tabs.generalTab.subcategory") }}:
        </span>
        <span v-if="countryLaw === 'CZ'">
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    :value="null"
                    v-model="selected"
                />
                <abbr>{{ $t("enums.assetSubClassification.none") }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="is_kii"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.is_kii-l')">{{
                    $t("enums.assetSubClassification.is_kii")
                }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="ks_kii"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.ks_kii-l')">{{
                    $t("enums.assetSubClassification.ks_kii")
                }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="vis"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.vis-l')">{{
                    $t("enums.assetSubClassification.vis")
                }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="iszs"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.iszs-l')">{{
                    $t("enums.assetSubClassification.iszs")
                }}</abbr>
            </label>
        </span>
        <span v-if="countryLaw === 'SK'">
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    :value="null"
                    v-model="selected"
                />
                <abbr>{{ $t("enums.assetSubClassification.none") }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="category1"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.category1-l')">{{
                    $t("enums.assetSubClassification.category1")
                }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="category2"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.category2-l')">{{
                    $t("enums.assetSubClassification.category2")
                }}</abbr>
            </label>
            <label class="radio-inline">
                <input
                    type="radio"
                    name="inlineRadioOptions"
                    value="category3"
                    v-model="selected"
                />
                <abbr :title="$t('enums.assetSubClassification.category3-l')">{{
                    $t("enums.assetSubClassification.category3")
                }}</abbr>
            </label>
        </span>
    </div>
</template>

<script>
export default {
    name: "asset-subclassification-control",
    props: {
        value: null,
        countryLaw: null
    },
    data() {
        return {
            selected: null
        };
    },
    created() {
        this.selected = this.value;
    },
    watch: {
        selected: function(v) {
            this.$emit("input", v);
        },
        value: function(v) {
            this.selected = v;
        }
    }
};
</script>
