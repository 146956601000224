<template id="main">
    <div>
        <textarea v-model="internalValue"
            @keydown="$forceUpdate()"
            @paste="$forceUpdate()"
            @show="$forceUpdate()"
            :class="[{'limited': true}, {'form-control': setFormControl}]"
            :maxlength="max"></textarea>
        <p v-if="showCounter" class="align-right"><span v-bind:class="{'text-danger': errClass }">{{charsRemaining}}</span></p>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ""
        },
        max: {
            type: Number,
            default: 256
        },
        setFormControl: {
            type: Boolean,
            default: false
        },
        showCounter: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            charsRemaining: this.value ? this.max - this.value.length : this.max,
            errClass: false
        };
    },
    computed: {
        internalValue: {
            get: function () {
                return this.value;
            },
            set: function (aModifiedValue) {
                if (aModifiedValue.length >= this.max) {
                    this.$emit("input", aModifiedValue.substring(0, this.max));
                    this.charsRemaining = 0;
                    this.errClass = true;
                }
                else {
                    this.$emit("input", aModifiedValue);
                    this.charsRemaining = this.max - aModifiedValue.length;
                    this.errClass = false;
                }
            }
        }
    }
}
</script>