<template id="main">
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{ $t('comp.kbAudit.editQuestion.modalTitle') }}
                    </h4>
                </div>
                <div class="margin">
                    <div class="pull-right">
                        <button class="btn btn-danger btn-xs margin-bottom" v-if="isSelectionMode" @click="toggleSelectionMode">
                            {{ $t('comp.kbAudit.editQuestion.cancelSelectMeasures') }}
                        </button>
                        <button class="btn btn-warning btn-xs margin-bottom" v-else @click="toggleSelectionMode">
                            {{ $t('comp.kbAudit.editQuestion.selectMeasures') }}
                        </button>
                    </div>
                    <div v-if="isSelectionMode">
                        <div class="margin-bottom">
                            <input class="form-control input-sm" v-model="searchFilter"
                                :placeholder="$t('comp.kbAudit.editQuestion.searchPlaceholder')" />
                        </div>
                        <div class="margin-bottom">
                            <multiselect v-model="tagIdsFilter"
                                        :options="tags.map(tag => tag.id)"
                                        :custom-label="opt => getTagById(opt).name"
                                        :multiple="true"
                                        :taggable="false"
                                        :closeOnSelect="false">
                            </multiselect>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <td style="width: 2%">
                                        <label class="form-checkbox" style="margin-bottom: 0px">
                                        <input
                                            type="checkbox"
                                            :checked="isAllSelected"
                                            @click="toggleSelectAll"
                                        />
                                        <i class="form-icon"></i>
                                        </label>
                                    </td>
                                    <td width="50%">
                                        {{ $t("comp.kbAudit.editQuestion.name") }}
                                    </td>
                                    <td width="50%">
                                        {{ $t("comp.kbAudit.editQuestion.tags") }}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in filteredMeasureRows" :key="row.measure.id">
                                    <td style="width: 2%">
                                        <input type="checkbox" class="checkbox" v-model="row.isSelected"/>
                                    </td>
                                    <td class="text-sm">
                                        {{ row.measure.name }}
                                    </td>
                                    <td class="text-sm">
                                        <span class="label label-default mr2" v-for="tag in row.tags" :key="tag.id">
                                            {{ tag.name }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table class="table table-striped" style="margin-top: 10px;" v-else>
                        <thead>
                            <tr>
                                <td width="50%">
                                    {{ $t("comp.kbAudit.editQuestion.name") }}
                                </td>
                                <td width="50%">
                                    {{ $t("comp.kbAudit.editQuestion.tags") }}
                                </td>
                                <td width="20px"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in selectedMeasureRows" :key="row.measure.id">
                                <td class="text-sm">
                                    {{ row.measure.name }}
                                </td>
                                <td class="text-sm">
                                    <span class="label label-default mr2" v-for="tag in row.tags" :key="tag.id">
                                        {{ tag.name }}
                                    </span>
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-danger btn-xs" @click="deselectMeasure(row)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer text-right">
                    <button class="btn btn-link" @click="cancel">{{ $t('common.cancel') }}</button>
                    <button class="btn btn-success" @click="save">
                        {{ $t('common.save') }}
                    </button>
                </div>
                <loading :active="isLoading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions} from 'vuex';
import Multiselect from '../../Controls/CsaMultiSelect.vue';

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            riskIds: [],
            measureRows: [],
            filteredMeasureRows: [],
            isSelectionMode: true,
            tagIdsFilter: [],
            searchFilter: null,
            isLoading: false,
            modal: null,
            showModal: false
        }
    },
    computed: {
        ...mapGetters('measures', {
            measures: 'getAll',
            getMeasureById: 'getById'
        }),
        ...mapGetters('measureTags', {
            tags: 'getAll',
            getTagById: 'getById'
        }),
        selectedMeasureRows: function () {
            return this.measureRows.filter(m => m.isSelected);
        },
        isAllSelected() {
                if (this.filteredMeasureRows.length <= 0) {
                    return false;
                }
                return this.filteredMeasureRows.every((item) => item.isSelected);
            },
    },
    mounted: function () {
        this.modal = this.$refs.modal;

        if (this.showModal) {
            this.show();
        }
    },
    watch: {
        '$route.meta'({ showModal }) {
            this.showModal = showModal
        },
        showModal: function (showModal) {
            if (showModal) {
                this.show();
            } else {
                this.cancel();
            }
        },
        selectedMeasure: function (measureId) {
            if (measureId !== null) {
                this.selectedMeasures.push(this.getMeasureById(measureId));
                this.selectedMeasure = null;
            }
        },
        ['tagIdsFilter']: function () {
            this.filterMeasureRows();
        },
        ['searchFilter']: function() {
            this.filterMeasureRows();
        },
    },
    methods: {
        ...mapActions("measures", {
            fetchMeasures: "fetchMeasures",
        }),
        ...mapActions("measureTags", {
            fetchTags: "fetchTags"
        }),
        open: async function (riskIds) {
            this.isLoading = true;
            await this.fetchMeasures();
            await this.fetchTags();
            this.showModal = true;
            this.riskIds = riskIds;
            this.isLoading = false;

            this.createMeasureRows();
        },
        createMeasureRows: function () {
            this.measureRows = this.measures.map((m) => {
                let tags = m.tags.map((t) => {
                    return this.getTagById(t);
                });

                return {
                    measure: m,
                    tags: tags,
                    isSelected: false,
                    searchText: m.name + ' ' + tags.map(t => t.name).join(' ')
                }
            });

            this.filterMeasureRows();
        },
        filterMeasureRows: function () {
            let rows = this.measureRows;

            if (this.searchFilter !== null && this.searchFilter.trim().length > 0) {
                rows = rows.filter((row) => {
                    return row.searchText.toLowerCase().includes(this.searchFilter.toLowerCase());
                });
            }

            if (this.tagIdsFilter.length > 0) {
                rows = rows.filter((row) => {
                    return row.tags.some(t => this.tagIdsFilter.includes(t.id));
                });
            }

            this.filteredMeasureRows = rows;
        },
        cancel: function () {
            this.showModal = false;
            $(this.modal).modal("hide");
        },
        show: function () {
            $(this.modal).modal({
                backdrop: false,
                keyboard: false,
            });
        },
        deselectMeasure(measureRow) {
            if (!confirm(this.$t('comp.kbAudit.editQuestion.removeMeasureConfirm'))) {
                return;
            }

            measureRow.isSelected = false;
        },
        toggleSelectionMode: function () {
            this.isSelectionMode = !this.isSelectionMode;
        },
        toggleSelectAll() {
            let isSelected = !this.isAllSelected;
            this.filteredMeasureRows.forEach((x) => (x.isSelected = isSelected));
        },
        save() {
            this.isLoading = true;

            if(this.riskIds.length > 0 && this.selectedMeasureRows.length > 0){
                let data = {
                    riskIds: this.riskIds,
                    measureIds: this.selectedMeasureRows.map(m => m.measure.id)
                };

                this.$api.put("risk/mass-risks/", data).then(response => {
                    if (response.data.status == 'ok') {
                        this.showSaved();
                    } else {
                        this.showSavedError();
                    }

                    this.isLoading = false;
                    this.$emit("reload");
                    this.cancel();
                });
            } else {
                this.isLoading = false;
                this.cancel();
            }
        },
    },
    notifications: {
        showSaved: {
            message: 'common.saved',
            type: 'success'
        },
        showSavedError: {
            message: 'common.saveFailed',
            type: 'error'
        }
    }
}
</script>

<style scoped lang="less">
.label {
    white-space: normal;
}

.margin-bottom {
    margin-bottom: 10px;
}
.margin {
    margin: 10px 10px 0px 10px;
}

.multiselect {
    font-size: 12px;

    /deep/ .multiselect__tags {
        min-height: 30px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    /deep/ .multiselect__select {
        height: 30px;
    }

    /deep/ .multiselect__placeholder {
        font-size: 12px;
    }

    /deep/ .multiselect__input {
        font-size: 12px;
    }

    /deep/ .multiselect__tag {
        font-size: 12px;
    }
}
</style>
