<template>
    <div>
        <p id="addBindings">
            <button class="btn btn-warning pull-right" @click="addAllBindings" :disabled="isLoading || asset.type == null">
                <i class="fa fa-refresh" aria-hidden="true"></i> {{$t('comp.assetModal.threatsTab.assetModal.addBindings')}}
            </button>
        </p>

        <h4>{{$t('common.add')}}</h4>

        <template>
            <selected-threat :fixed="true" :manual="manual" :data="selectedThreat" @add="appendThreat" :asset="asset" :isShowHashIds="isShowHashIds"></selected-threat>
        </template>

        <h4 class="mt4">{{$t('comp.assetModal.threatsTab.threatsTab.identified')}}</h4>

        <div class="text-center" v-if="!asset.threats.length">
            <em>{{$t('comp.assetModal.threatsTab.threatsTab.none')}}&hellip;</em>
        </div>

        <template>
            <template v-for="selectedThreat in asset.threats">
                <selected-threat :data="selectedThreat" @remove="removeThreat" :asset="asset" :key="selectedThreat.id" :isShowHashIds="isShowHashIds"></selected-threat>
            </template>
        </template>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import SelectedThreat from './SelectedThreat.vue'
    import {mapGetters, mapActions} from 'vuex'

    export default {
        components: {
            CsaSelect,
            SelectedThreat
        },
        props: {
            asset: {
                type: Object,
                required: true
            },
            isShowHashIds: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                selectedThreat: {
                    threat: null,
                    vulnerabilities: [],
                },
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters('threats', {
                threats: 'getAll'
            }),
            ...mapGetters('vulnerabilities', {
                vulnerabilitiesWithBindings: 'getAllWithBindings'
            }),
        },
        methods: {
            ...mapActions('threats', ['fetchThreatsWithAssetTypes']),
            ...mapActions('vulnerabilities', ['fetchVulnerabilitiesWithBindings']),
            appendThreat: function () {
                this.asset.threats.unshift(this.selectedThreat);
                this.selectedThreat = {
                    threat: null,
                    vulnerabilities: [],
                };
            },
            removeThreat: function (selectedThreat) {
                let index = this.asset.threats.indexOf(selectedThreat);
                this.asset.threats.splice(index, 1);
            },
            setLoading: function (status) {
                this.isLoading = status;
            },
            addAllBindings: async function () {
                this.isLoading = true;

                let threatsAdded = false;
                for (let i = 0; i < this.threats.length; i++) {
                    if (this.threats[i].threatAssociated) {
                        threatsAdded = true;
                        await this.addBindingsByThreat(this.threats[i]);
                    }
                }

                if (!threatsAdded) {
                    this.showNoBindings();
                }

                this.isLoading = false;
            },
            addBindingsByThreat: async function (threat) {
                let params = {'assetType': this.asset.type.id, 'threat': threat.id, 'getAll': false};
                await this.fetchVulnerabilitiesWithBindings(params);

                if (this.vulnerabilitiesWithBindings.length > 0) {
                    // is this threat already present or shall we create a new one?
                    let i = this.findAssetThreatIdxById(threat.id);
                    if (i == null) {
                        this.asset.threats.unshift({threat: threat, vulnerabilities: []});
                        i = 0;
                    }

                    for (let k = 0; k < this.vulnerabilitiesWithBindings.length; k++) {
                        if (!this.isVulnerabilityBoundToThreat(this.asset.threats[i].vulnerabilities, this.vulnerabilitiesWithBindings[k].id)) {
                            this.asset.threats[i].vulnerabilities.unshift(this.vulnerabilitiesWithBindings[k]);
                        }
                    }
                }
            },
            findAssetThreatIdxById: function (id) {
                for (var i = 0; i < this.asset.threats.length; i++) {
                    if (this.asset.threats[i].threat.id == id)
                        return i;
                }
                return null;
            },
            isVulnerabilityBoundToThreat: function (vulnerabilities, vulnId) {
                for (var i = 0; i < vulnerabilities.length; i++) {
                    if (vulnerabilities[i].id == vulnId)
                        return true;
                }
                return false;
            }
        },
        notifications: {
            showNoBindings: {
                message: 'comp.assetModal.notifications.noBindings',
                type: 'info'
            }
        }
    }
</script>

<style scoped>

p#addBindings {
    float:right;
    margin-bottom: 20px;
}

</style>
