<template>
    <div class="box box-default vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>

        <div class="box-header with-border">
            <i class="fas fa-boxes"></i>

            <h3 class="box-title">{{$t('comp.measureRiskPlan.riskResources.index.resources')}}</h3>

            <button type="button" class="ajax pull-right btn btn-warning btn-xs" v-if="!editMode"
                    @click="toggleEditMode">
                <i class="far fa-edit"></i>
                {{$t('common.edit')}}
            </button>

            <div class="pull-right" v-if="editMode">
                <button class="ajax btn btn-danger btn-xs margin-r-5" @click="toggleEditMode">
                    <i class="fas fa-times"></i>
                    {{$t('common.cancel')}}
                </button>
                <button class="btn btn-success btn-xs" @click="save">
                    <i class="far fa-save"></i> {{$t('common.save')}}
                </button>
            </div>
        </div>
    <!-- /.box-header -->

        <div class="box-body">
            <table class="table table-striped" style="margin-bottom: 20px;" v-if="getFilteredFinancialResources.length || editMode">
                <thead>
                    <tr>
                        <th colspan="4" class="text-bold">
                            <i class="fas fa-dollar-sign"></i> {{$t('comp.measureRiskPlan.riskResources.index.financialResources')}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="editMode">
                        <td width="">
                            <input ref="newFinancialResourceName" v-on:keyup.enter="createFinancialResource" type="text" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.namePlaceholder')"/>
                        </td>
                        <td width="200px">
                            <input ref="newFinancialResourceCost" v-on:keyup.enter="createFinancialResource" type="number" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.costPlaceholder')"/>
                        </td>
                        <td width="50%">
                            <multiselect
                                    v-model="newFinancialResourceRiskMeasureIds"
                                    :options="riskMeasures.map(x => x.id)"
                                    :custom-label="opt => riskMeasures.find(x => x.id == opt).label"
                                    :multiple="true"
                                    :taggable="true"
                                    :closeOnSelect="false">
                            </multiselect>
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-success btn-xs pull-right" @click="createFinancialResource">
                            <i class="fas fa-plus"></i> {{$t('common.add')}}
                            </button>
                        </td>
                    </tr>

                    <template v-for="(line, index) in getFilteredFinancialResources">
                        <tr v-if="!line.edit" v-bind:key="index">
                            <td width="">
                                {{ line.name }}
                            </td>
                            <td width="200px">
                                {{ line.cost }} {{$t('comp.measureRiskPlan.riskResources.index.currency')}}
                            </td>
                            <td width="50%">
                                <ul>
                                    <li v-for="riskMeasureId in line.riskMeasureIds">
                                        {{getRiskMeasureLabel(riskMeasureId)}}
                                    </li>
                                </ul>
                            </td>
                            <td class="text-right" width="30px">
                                <template v-if="editMode">
                                    <button class="btn btn-danger btn-xs" @click="removeFinancialResource(line)"><i class="fas fa-trash"></i></button>
                                    <button class="btn btn-warning btn-xs" @click="toggleEditFinancialResource(line)"><i class="fas fa-pencil-alt"></i>
                                    </button>
                                </template>
                            </td>
                        </tr>

                        <tr v-if="line.edit" v-bind:key="index">
                            <td width="">
                                <input v-bind:ref="'financialResourceNameInput' + line.id" v-model="line.editName" type="text" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.namePlaceholder')"/>
                            </td>
                            <td width="200px">
                                <input v-model="line.editCost" type="number" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.costPlaceholder')"/>
                            </td>
                            <td width="50%">
                                <multiselect
                                    v-model="line.editRiskMeasureIds"
                                    :options="riskMeasures.map(x => x.id)"
                                    :custom-label="opt => riskMeasures.find(x => x.id == opt).label"
                                    :multiple="true"
                                    :taggable="true"
                                    :closeOnSelect="false">
                                </multiselect>
                            </td>
                            <td class="text-right" width="30px">
                                <button class="btn btn-danger btn-xs" @click="cancelEditFinancialResource(line)"><i class="fas fa-times"></i></button>
                                <button class="btn btn-success btn-xs" @click="toggleEditFinancialResource(line)"><i class="fas fa-save"></i></button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <table class="table table-striped" style="margin-bottom: 20px;" v-if="getFilteredHumanResources.length || editMode">
                <thead>
                    <tr>
                        <th colspan="4" class="text-bold">
                            <i class="fas fa-users"></i> {{$t('comp.measureRiskPlan.riskResources.index.humanResources')}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="editMode">
                        <td width="">
                            <input ref="newHumanResourceName" v-on:keyup.enter="createHumanResource" type="text" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.namePlaceholder')"/>
                        </td>
                        <td width="200px">
                            <input ref="newHumanResourceManDays" v-on:keyup.enter="createHumanResource" type="number" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.manDaysPlaceholder')"/>
                        </td>
                        <td width="50%">
                            <multiselect
                                    v-model="newHumanResourceRiskMeasureIds"
                                    :options="riskMeasures.map(x => x.id)"
                                    :custom-label="opt => riskMeasures.find(x => x.id == opt).label"
                                    :multiple="true"
                                    :taggable="true"
                                    :closeOnSelect="false">
                            </multiselect>
                        </td>
                        <td class="text-right" width="">
                            <button type="button" class="btn btn-success btn-xs pull-right" @click="createHumanResource">
                            <i class="fas fa-plus"></i> {{$t('common.add')}}
                            </button>
                        </td>
                    </tr>

                    <template v-for="(line, index) in getFilteredHumanResources">
                        <tr v-if="!line.edit" v-bind:key="index">
                            <td width="">
                                {{ line.name }}
                            </td>
                            <td width="200px">
                                {{ line.manDays }} {{$t('comp.measureRiskPlan.riskResources.index.manDaysShort')}}
                            </td>
                            <td width="50%">
                                <ul>
                                    <li v-for="riskMeasureId in line.riskMeasureIds">
                                        {{getRiskMeasureLabel(riskMeasureId)}}
                                    </li>
                                </ul>
                            </td>
                            <td class="text-right" width="30px">
                                <template v-if="editMode">
                                    <button class="btn btn-danger btn-xs" @click="removeHumanResource(line)"><i class="fas fa-trash"></i></button>
                                    <button class="btn btn-warning btn-xs" @click="toggleEditHumanResource(line)"><i class="fas fa-pencil-alt"></i>
                                    </button>
                                </template>
                            </td>
                        </tr>

                        <tr v-if="line.edit" v-bind:key="index">
                            <td width="">
                                <input v-bind:ref="'humanResourceNameInput' + line.id" v-model="line.editName" type="text" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.namePlaceholder')"/>
                            </td>
                            <td width="200px">
                                <input v-model="line.editManDays" type="number" class="form-control input-sm" :placeholder="$t('comp.measureRiskPlan.riskResources.index.manDaysPlaceholder')"/>
                            </td>
                            <td width="50%">
                                <multiselect
                                    v-model="line.editRiskMeasureIds"
                                    :options="riskMeasures.map(x => x.id)"
                                    :custom-label="opt => riskMeasures.find(x => x.id == opt).label"
                                    :multiple="true"
                                    :taggable="true"
                                    :closeOnSelect="false">
                                </multiselect>
                            </td>
                            <td class="text-right" width="30px">
                                <button class="btn btn-danger btn-xs" @click="cancelEditHumanResource(line)"><i class="fas fa-times"></i></button>
                                <button class="btn btn-success btn-xs" @click="toggleEditHumanResource(line)"><i class="fas fa-save"></i></button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <table class="table table-striped" style="margin-top: 10px;" v-if="techResources || editMode">
                <thead>
                    <tr>
                        <th class="text-bold">
                            <i class="fas fa-cogs"></i> {{$t('comp.measureRiskPlan.riskResources.index.technicalResources')}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-bind:class="{'no-padding': editMode}">
                            <vue-ckeditor v-model="techResources" :config="config" v-show="editMode"></vue-ckeditor>
                            <div v-if="!editMode" v-html="techResources"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import VueCkeditor from 'vue-ckeditor2';
import Multiselect from '../../Controls/CsaMultiSelect.vue'

export default {
  components: {
    VueCkeditor,
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      editMode: false,
      financialResources: [],
      humanResources: [],
      riskMeasures: [],
      techResources: "",
      newFinancialResourceRiskMeasureIds: [],
      newHumanResourceRiskMeasureIds: [],
      config: {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'BulletedList', 'NumberedList']
        ],
        height: 100
      },
    }
  },
  props: {
    saveLink: String,
    loadLink: String,
    paramPrefix: String,
  },
  beforeMount: function () {
    this.load();
  },
  computed: {
    getFilteredFinancialResources: function () {
      return this.financialResources.filter(function (item) {
        return item.toRemove !== true;
      });
    },
    getFilteredHumanResources: function () {
      return this.humanResources.filter(function (item) {
        return item.toRemove !== true;
      });
    },
  },
  methods: {
    load: function () {
      this.isLoading = true;

      this.axios.get(this.loadLink).then(({data}) => {
        this.isLoading = false;
        this.financialResources = data.financialResources;
        this.humanResources = data.humanResources;
        this.techResources = data.technicalResources;
        this.riskMeasures = data.riskMeasures;
      });
    },
    getChangedFinancialResources: function () {
      return this.financialResources.filter(function (item) {
        return item.changed || item.id === undefined || item.toRemove;
      });
    },
    getChangedHumanResources: function () {
      return this.humanResources.filter(function (item) {
        return item.changed || item.id === undefined || item.toRemove;
      });
    },
    toggleEditMode: function () {
      this.editMode = !this.editMode;

      if (!this.editMode) {
        this.load();
      }
    },
    toggleEditFinancialResource: function (line) {
        this.$set(line, 'edit', !line.edit);

        if (line.edit) {
            line.editName = line.name;
            line.editCost = line.cost;
            this.$set(line, 'editRiskMeasureIds', line.riskMeasureIds);

            var self = this;
            Vue.nextTick(function () {
                self.$refs['financialResourceNameInput' + line.id][0].focus();
            })
        } else {
            line.name = line.editName;
            line.cost = line.editCost;
            line.riskMeasureIds = line.editRiskMeasureIds;

            this.$set(line, 'changed', true);
        }
    },
    cancelEditFinancialResource: function (line) {
        this.$set(line, 'edit', false);
    },
    toggleEditHumanResource: function (line) {
        this.$set(line, 'edit', !line.edit);

        if (line.edit) {
            line.editName = line.name;
            line.editManDays = line.manDays;
            this.$set(line, 'editRiskMeasureIds', line.riskMeasureIds);

            var self = this;
            Vue.nextTick(function () {
                self.$refs['humanResourceNameInput' + line.id][0].focus();
            })
        } else {
            line.name = line.editName;
            line.manDays = line.editManDays;
            line.riskMeasureIds = line.editRiskMeasureIds;

            this.$set(line, 'changed', true);
        }
    },
    cancelEditHumanResource: function (line) {
        this.$set(line, 'edit', false);
    },
    createFinancialResource: function () {
      let $name = this.$refs['newFinancialResourceName'];
      let $cost = this.$refs['newFinancialResourceCost'];

      if (!$name.value || $name.value === "") {
        alert(this.$t('comp.measureRiskPlan.riskResources.index.enterResourceNameAlert'));
        $name.focus();
        return;
      }

      if (isNaN(parseInt($cost.value))) {
        alert(this.$t('comp.measureRiskPlan.riskResources.index.enterResourceValueAlert'));
        $cost.focus();
        return;
      }

      let $newItem = {
        name: $name.value,
        cost: parseInt($cost.value),
        riskMeasureIds: this.newFinancialResourceRiskMeasureIds
      };

      this.financialResources.push($newItem);

      $name.value = "";
      $cost.value = "";
      this.newFinancialResourceRiskMeasureIds = [];
      $name.focus();
    },
    createHumanResource: function () {
      let $name = this.$refs['newHumanResourceName'];
      let $manDays = this.$refs['newHumanResourceManDays'];

      if ($name.value === "") {
        alert(this.$t('comp.measureRiskPlan.riskResources.index.enterResourceNameAlert'));
        $name.focus();
        return;
      }

      if (isNaN(parseInt($manDays.value))) {
        alert(this.$t('comp.measureRiskPlan.riskResources.index.enterResourceValueAlert'));
        $manDays.focus();
        return;
      }

      let $newItem = {
        name: $name.value,
        manDays: parseInt($manDays.value),
        riskMeasureIds: this.newHumanResourceRiskMeasureIds
      };

      this.humanResources.push($newItem);

      $name.value = "";
      $manDays.value = "";
      this.newHumanResourceRiskMeasureIds = [];
      $name.focus();
    },
    removeFinancialResource: function (line) {
      if (!confirm(this.$t('comp.measureRiskPlan.riskResources.index.removeFinancialResourceConfirm'))) {
        return;
      }

      this.$set(line, 'toRemove', true);
    },
    removeHumanResource: function (line) {
      if (!confirm(this.$t('comp.measureRiskPlan.riskResources.index.removeHumanResourceConfirm'))) {
        return;
      }

      this.$set(line, 'toRemove', true);
    },
    async save() {
      this.isLoading = true;

      await this.axios.get(this.saveLink, {
        params: {
          [this.paramPrefix + "financialResources"]: this.getChangedFinancialResources(),
          [this.paramPrefix + "humanResources"]: this.getChangedHumanResources(),
          [this.paramPrefix + "technicalResources"]: this.techResources,
        },
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      this.isLoading = true;
      this.editMode = false;
      await this.load()
    },
    getRiskMeasureLabel(riskMeasureId){
        let riskMeasure = this.riskMeasures.find(x => x.id == riskMeasureId);

        if(riskMeasure){
            return riskMeasure.label;
        }

        return null;
    }
  }
}
</script>
