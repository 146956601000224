<template id="main">
    <div>
        <div class="modal fade" role="dialog" ref="modal">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            {{ $t('comp.editAssetsModal.index.title') }}
                        </h4>
                    </div>

                    <div class="modal-body">
                        <div class="row mt2">
                            <div class="col-md-6">
                                <classification-control v-model="classification" :value="classification" />
                                <asset-sub-classification v-model="assetSubClassification"
                                                          v-if="classification && classification.isPrimary" />
                            </div>
                            <div class="col-md-6">
                                <type-control v-model="type" :value="type" />
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-6">
                                <guarantor-control v-model="guarantor" :value="guarantor" />
                            </div>
                            <div class="col-md-6">
                                <asset-tags-control v-model="assetTags" :value="assetTags" />
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-6">
                                <way-of-dispose-control v-model="wayOfDispose" :value="wayOfDispose" />
                            </div>
                            <div class="col-md-6">
                                <location-control v-model="location" :value="location" />
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer text-right">
                        <button class="btn btn-link" @click="cancel">{{ $t('common.cancel') }}</button>
                        <button class="btn btn-success" @click="save">
                            {{ $t('common.save') }}
                        </button>
                    </div>

                    <loading :active="isLoading" :is-full-page="false"></loading>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import GuarantorControl from "../AssetModal/Controls/Guarantor.vue";
import ClassificationControl from "../AssetModal/Controls/Classification.vue";
import TypeControl from "../AssetModal/Controls/Type.vue";
import WayOfDisposeControl from "../AssetModal/Controls/WayOfDispose.vue";
import LocationControl from "../AssetModal/Controls/Location.vue";
import AssetSubClassification from "../AssetModal/Controls/AssetSubClassification.vue";
import AssetTagsControl from "../AssetModal/Controls/AssetTags.vue";
import { mapGetters, mapActions } from 'vuex';
import CsaSelect from "../../Controls/CsaMultiSelect.vue";

export default {
    components: {
        ClassificationControl,
        TypeControl,
        WayOfDisposeControl,
        LocationControl,
        GuarantorControl,
        AssetSubClassification,
        AssetTagsControl,
        CsaSelect
    },
    data() {
        return {
            isLoading: false,
            showModal: false,
            modal: null,
            editMode: false,
            items: [],
            classification: null,
            assetSubClassification: null,
            type: null,
            wayOfDispose: null,
            location: null,
            guarantor: null,
            assetTags: [],
        }
    },
    computed: {
        ...mapGetters("assetTags", {
            assetTags: "getAll",
        })
    },
    mounted: function () {
        this.modal = this.$refs.modal;

        if (this.showModal) {
            this.show();
        }
    },
    watch: {
        '$route.meta'({ showModal }) {
            this.showModal = showModal
        },
        showModal: function (showModal) {
            if (showModal) {
                this.show();
            } else {
                this.cancel();
            }
        }
    },
    methods: {
        ...mapActions("assetTags", ["fetchAssetTags"]),
        open: function (items) {
            this.showModal = true;
            this.items = items;

            this.fetchAssetTags();
            this.initAsset();
        },
        cancel: function () {
            this.showModal = false;
            $(this.modal).modal("hide");
        },
        show: function () {
            $(this.modal).modal({
                backdrop: false,
                keyboard: false,
            });
        },
        initAsset() {
            this.classification = null;
            this.assetSubClassification = null;
            this.type = null;
            this.wayOfDispose = null;
            this.location = null;
            this.guarantor = null;
            this.assetTags = []
        },
        save() {
            this.isLoading = true;

            let dataToEdit = {
                ids: this.items,
                classificationId: this.classification != null ? this.classification.id : null,
                assetSubClassification: this.assetSubClassification,
                typeId: this.type != null ? this.type.id : null,
                wayOfDisposeId: this.wayOfDispose != null ? this.wayOfDispose.id : null,
                locationId: this.location != null ? this.location.id : null,
                guarantorId: this.guarantor != null ? this.guarantor.id : null,

                assetTagIds: this.assetTags.map((a) => {
                    return a.id;
                })
            };

            this.$api.put("asset/mass-edit/", dataToEdit).then(response => {
                if (response.data.status == 'ok') {
                    this.showSaved();
                } else {
                    this.showSavedError();
                }

                this.isLoading = false;
                this.$emit('reload');
                this.cancel();
            });
        }
    },
    notifications: {
        showSaved: {
            message: 'common.saved',
            type: 'success'
        },
        showSavedError: {
            message: 'common.saveFailed',
            type: 'error'
        }
    }
}
</script>

<style>
.control {
    margin-top: 5px;
}
</style>
