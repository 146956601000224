<template>
    <div class="control">
        <label>{{$t('comp.assetModal.controls.type.label')}}</label>

        <csa-select
                v-model="selected"
                :options="options"
                trackBy="id"
                label="name"
                @tag="create"
                :taggable="$app.user.role === 'mcs'"
                :tag-placeholder="$t('comp.assetModal.controls.type.selectPlaceholder')"
                :disabled="disabled"
        >
        </csa-select>
    </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "asset-type-control",
        components: {
            CsaSelect
        },
        props: {
            value: null,
            disabled: false,
        },
        data() {
            return {
                selected: null
            };
        },
        watch: {
            selected: function (v) {
                if (v !== undefined && v != null) {
                    this.fetchThreatsWithAssetTypes(v);
                }
                this.$emit('input', v);
            },
            value: function (v) {
                this.selected = v;
            }
        },
        created() {
            this.fetchTypes();
            this.selected = this.value;
        },
        computed: {
            ...mapGetters("assetType", {
                options: "getTypes"
            }),
        },
        methods: {
            ...mapActions("assetType", ["fetchTypes", "addType"]),
            ...mapActions('threats', ['fetchThreatsWithAssetTypes']),
            create: function (name) {
                this.addType(name).then((type) => {
                    this.selected = type;
                });
            }
        }
    }
</script>
