<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
    <textarea
            v-model="question.correctByParticipant"
            class="form-control"
            :placeholder="$t('answer')"
            disabled="disabled"
            style="resize: vertical;"></textarea>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        components: {
            BaseQuestion
        }
    }
</script>