<template>
    <div class="control">
        <label>{{$t('comp.assetModal.controls.location.label')}}</label>

        <csa-select
                v-model="selected"
                @open="opened"
                :options="options"
                trackBy="id"
                label="name"
                @tag="create"
                :taggable="$app.user.role === 'mcs'"
                :tag-placeholder="$t('comp.assetModal.controls.location.selectPlaceholder')"
        >
        </csa-select>
    </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "asset-location-control",
        components: {
            CsaSelect
        },
        data() {
            return {
                selected: null
            };
        },
        props: {
            value: null
        },
        watch: {
            selected: function (v) {
                this.$emit('input', v);
            },
            value: function (v) {
                if (Number.isInteger(v)) {
                    v = this.getById(v);
                }
                this.selected = v;
            }
        },
        created(){
            this.fetchLocations();
            this.selected = this.value;
        },
        computed: {
            ...mapGetters("location", {
                options: "getLocations",
                getById: "getLocationById"
            }),
        },
        methods: {
            ...mapActions("location", ["fetchLocations", "addLocation"]),
            opened: function () {
                /*if(!this.options.length)  {
                    this.fetchLocations();
                }*/
            },
            create: function (name) {
                this.addLocation(name).then((location) => {
                    this.selected = location;
                });
            }
        }
    }
</script>