<template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog vld-parent" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{$t('comp.orgStructure.importModal.importModal.title')}}
                    </h4>
                </div>

                <template v-if="view === 'selectFile'">
                    <div class="modal-body">
                        <p class="text-bold text-danger">
                            {{$t('comp.orgStructure.importModal.importModal.info')}}
                        </p>

                        <div class="form-group mt3" :class="{'has-error': result && result.errors.length}">
                            <label for="file">{{$t('comp.orgStructure.importModal.importModal.selectFile')}}</label>
                            <input type="file" id="file" ref="file" @change="fileChanged" />
                            <div class="help-block">*.csv</div>

                            <template v-if="result">
                                <span class="help-block" v-for="error in result.errors">
                                    {{error}}
                                </span>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default pull-left" @click="close">{{$t('common.close')}}</button>
                        <button class="btn btn-warning pull-left" @click="template">{{$t('common.importExport.downloadTemplate')}}</button>
                        <button class="btn btn-success pull-right" @click="startImport" :disabled="!fileSelected">{{$t('common.importExport.import')}}</button>
                    </div>
                </template>

                <template v-if="view === 'finished'">
                    <div class="modal-body">
                        <div class="text-center text-success">
                            <i class="far fa-check-circle fa-5x"></i> <br />
                            <strong>{{$t('common.importExport.importDone')}}</strong>
                        </div>

                        <dl class="dl-horizontal-info">
                           <!-- <dt>aktualizováno</dt>
                            <dd>{{result.stats.updated}}</dd> !-->
                            <dt>{{$t('common.importExport.newOnes')}}</dt>
                            <dd>{{result.stats.created}}</dd>
                        </dl>

                        <div class="clearfix"></div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default pull-left" @click="close">{{$t('common.close')}}</button>
                    </div>
                </template>
            </div>

            <loading :active="isLoading" :is-full-page="false"></loading>
        </div>
    </div>
</template>

<script>
    import EventBus from './ImportModalBus';

    export default {
        name: 'ImportModal',
        data() {
            return {
                modal: null,
                isLoading: false,
                view: 'selectFile',
                result: null,
                fileSelected: false
            }
        },
        mounted: function () {
            this.modal = this.$refs.modal;
            this.fileInput = this.$refs.file;


            EventBus.$on("show", ()=>{
                this.show();
            });
            EventBus.$on("hide", ()=>{
                this.close();
            });
        },
        methods: {
            close: function () {
                $(this.modal).modal("hide");
            },
            show: function () {
                this.fileSelected = null;
                this.result = null;
                this.view = 'selectFile';

                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });
            },
            template: function (){
                this.$api.get("org-person/import/template", {
                    responseType: 'blob'
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.$t('comp.orgStructure.importModal.importModal.fileName')); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(()=>{
                        this.isLoading = false;
                    });
            },
            startImport: function () {
                this.isLoading = true;
                this.result = null;
                let formData = new FormData;
                let file = this.$refs.file;

                formData.append("file", file.files[0]);

                this.$api.post("org-person/import", formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then(({data})=>{
                    if(data.status === 'ok') {
                        let result = data.data;
                        this.result = result;

                        if(!result.errors.length) {
                            this.view = 'finished';
                            EventBus.$emit("import-finished");
                        }
                    }


                    this.isLoading = false;
                });
            },
            fileChanged: function (f) {
                this.fileSelected = f.target.files.length > 0;
            }
        }
    }
</script>