import { render, staticRenderFns } from "./AttributesTab.vue?vue&type=template&id=ffe54be2&scoped=true&"
import script from "./AttributesTab.vue?vue&type=script&lang=js&"
export * from "./AttributesTab.vue?vue&type=script&lang=js&"
import style0 from "./AttributesTab.vue?vue&type=style&index=0&id=ffe54be2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffe54be2",
  null
  
)

export default component.exports