<template>
  <div>
    <div class="row" style="margin-bottom: 15px;">
      <div class="col-md-6">
        <label>{{$t('comp.continuityPlanModal.tabs.executionTeam.selectMemberLabel')}}:</label>
        <multiselect
            v-model="value"
            :options="availablePeople"
            group-values="people"
            group-label="org_path"
            :group-select="false"
            label="name"
            track-by="id"
            :multiple="false"
            @select="add"
        >
        </multiselect>
      </div>
    </div>


    <table class="table table-striped table-hover">
      <tbody>
      <tr v-for="person in plan.execution_team">
        <td>
          {{ person.name }}

          <p v-if="personDetail && personDetail.id === person.id" style="margin-top: 15px">
            <strong>{{$t('comp.continuityPlanModal.tabs.executionTeam.email')}}:</strong> {{ personDetail.email }}
          </p>
        </td>
        <td class="text-right">
          <button class="btn btn-danger btn-sm" type="button" @click="remove(person)">
            <i class="fas fa-trash"></i>
          </button>
          <button class="btn btn-primary btn-sm" type="button" @click="detail(person)">
            <i class="fas fa-search"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Multiselect from '../../../Controls/CsaMultiSelect.vue'

export default {
  components: {
    Multiselect
  },
  props: ['plan', 'people'],
  data() {
    return {
      personDetail: null,
      value: null,
      peopleCopy: [],
    }
  },
  mounted() {
    this.peopleCopy = JSON.parse(JSON.stringify(this.people))
  },
  computed: {
    availablePeople() {
      this.peopleCopy.forEach(g => {
        g.people.forEach(p => {
          p.$isDisabled = this.plan.execution_team.map(p => p.id).includes(p.id)
        })
      })

      return this.peopleCopy
    }
  },
  methods: {
    ...mapActions("orgStructure", ["fetchPerson"]),
    add(selected_person) {
      if (!this.plan.execution_team.includes(selected_person)) {
        this.plan.execution_team.push(selected_person)
      }

      this.$nextTick(() => {
        this.value = null
      })
    },
    remove(person) {
      this.plan.execution_team.splice(this.plan.execution_team.indexOf(person), 1)
    },
    async detail(person) {
      if (this.personDetail && this.personDetail.id === person.id) {
        this.personDetail = null
        return
      }

      let {data} = await this.$api.get("org-person/" + person.id);
      this.personDetail = data.data
    }
  }
}
</script>