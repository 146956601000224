<template>
  <tr class="vld-parent">
    <template v-if="item.editMode">
      <td></td>
      <td>
        <input
          v-model="editedItem.name"
          type="text"
          id="name"
          name="name"
          ref="name"
          class="form-control"
          :placeholder="$t('comp.manageMeasures.tag.namePlaceholder')"
          @keypress.enter="save"
          v-validate="'required|max:256'"
          :data-vv-as="$t('comp.manageMeasures.tag.name-data-vv')"
        />

        <span class="help-block text-red">{{ errors.first("name") }}</span>
      </td>
      <td>
        <input
          v-model="editedItem.link"
          type="text"
          id="link"
          name="link"
          ref="link"
          class="form-control"
          :placeholder="$t('comp.manageMeasures.tag.linkPlaceholder')"
          @keypress.enter="save"
        />
      </td>
      <td class="text-right" id="save">
        <button class="btn btn-success btn-xs" @click="save">
          <i class="fas fa-save"></i>
        </button>
        <button class="btn btn-default btn-xs" @click="cancel">
          <i class="fas fa-times"></i>
        </button>
      </td>
    </template>
    <template v-else>
      <td>
        <input type="checkbox" class="checkbox" v-model="item.selected" />
      </td>
      <td class="text-sm">
        {{ item.name }}
      </td>
      <td class="text-sm">
        <a v-bind:href="item.link" v-if="item.link" target="_blank">{{
          item.link
        }}</a>
      </td>

      <td class="text-right">
        <template v-if="item.methodology">
          <div class="label text-black pull-left">
            {{ $t("common.methodology") }} <img src="/favicon.ico" />
          </div>
          <button
            title="nezobrazeno"
            @click="hide"
            class="btn btn-primary btn-xs"
            v-if="item.hidden"
          >
            <i aria-hidden="true" class="far fa-eye-slash fa-fw"></i>
          </button>
          <button
            title="zobrazeno"
            @click="hide"
            class="btn btn-primary btn-xs"
            v-else
          >
            <i aria-hidden="true" class="far fa-eye fa-fw"></i>
          </button>
        </template>

        <template v-else>
          <button
            class="btn btn-danger btn-xs"
            @click="remove(item)"
            :disabled="isLoading"
          >
            <i class="fas fa-trash"></i>
          </button>

          <button
            class="btn btn-warning btn-xs"
            @click="toggleEdit"
            :disabled="isLoading"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </template>
      </td>
    </template>
    <delete-confirmation
      :ref="'delconfTag'"
      v-on:confirmed="deleteConfirmed($event)"
    ></delete-confirmation>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation.vue";

export default {
  name: "create-measure",
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  components: {
    DeleteConfirmation,
  },
  beforeMount: function () {
    if (!this.item.id) {
      this.$set(this.item, "editMode", true);
    } else {
      this.$set(this.item, "editMode", false);
    }

    this.editedItem = Object.assign({}, this.item);
  },
  mounted() {
    if (this.item.editMode) {
      this.$refs.name.focus();
    }
  },
  data() {
    return {
      editedItem: null,
    };
  },
  methods: {
    toggleEdit: function () {
      if (this.item.editMode) {
        this.$set(this.item, "editMode", false);
      } else {
        this.$set(this.item, "editMode", true);
      }

      if (this.editMode) {
        this.editedItem = Object.assign({}, this.item);
      }
    },
    save: function () {
      this.$validator.validate().then((result) => {
        if (!result) {
          return;
        }

        this.item.name = this.editedItem.name;
        this.item.link = this.editedItem.link;

        if (this.item.id) {
          this.updateTag(this.item);
        } else {
          //create
          this.addTag(this.item).then((tag) => {
            this.item.id = tag.id;
          });
        }

        this.showSaved();
        this.toggleEdit();
      });
    },
    cancel: function () {
      if (this.item.id) {
        this.toggleEdit();
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.$emit("removed", this.item);
      }
    },
    remove: function (item) {
      let modalWindow = this.$refs.delconfTag;
      modalWindow.show(item);
    },
    deleteConfirmed: function (item) {
      this.isLoading = true;

      if (item.id) {
        this.removeTag(item).then(() => {
          this.$emit("removed", item);
          this.isLoading = false;
        });
      } else {
        this.$emit("removed", item);
        this.isLoading = false;
      }
    },
    hide: function () {
      this.isLoading = true;

      this.item.hidden = !this.item.hidden;

      this.updateTag(this.item).then(() => {
        this.isLoading = false;
      });
    },
    ...mapActions("measureTags", ["removeTag", "updateTag", "addTag"]),
  },
  notifications: {
    showSaved: {
      message: "common.saved",
      type: "success",
    },
  },
};
</script>

<style scoped lang="less">
#name,
#link {
  height: 40px;
}

#save {
  vertical-align: middle;
}
</style>
