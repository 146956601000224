<template>
    <tbody>
        <tr :class="{success: measure.applicable}">
            <td class="text-center">
                <div class="pretty p-icon p-smooth p-bigger">
                    <input type="checkbox" v-model="measure.applicable" @change="applicableChange" />
                    <div class="state p-success">
                        <i class="icon fa fa-check"></i>
                        <label></label>
                    </div>
                </div>
            </td>
            <td>
                {{measure.name}}
                <div class="label text-black pull-right" v-if="measure.methodology">
                    {{$t('common.methodology')}} <img src="/favicon.ico">
                </div>
                <div v-if="measure.description">    
                    <strong>{{$t('comp.riskMeasureListing.measure.description')}}</strong>
                    {{measure.description}}
                </div>

                <measure-application-confirmation
                        @accept="applicableAccept"
                        @abort="applicableAbort"
                        :show="showAcceptConfirmationDialog"
                        :value="measure.applicable"
                        :riskSubId="riskSubId"
                        :riskMeasures="confirmationDialogRiskMeasures"></measure-application-confirmation>
            </td>
            <td>
                <label class="label label-default mr2" v-for="tagSubId in measure.tags" v-if="getTagById(tagSubId)">
                    {{getTagById(tagSubId).name}}
                </label>
            </td>
            <td>
                <strong>{{measure.decreaseOf}}</strong> %

                <span class="help-block text-blue" v-if="riskValue !== null">
                    {{$t('comp.riskMeasureListing.measure.riskAfterApplication')}} <strong>{{riskValue}}%</strong>
                </span>
            </td>
            <td>
                <button class="btn btn-warning btn-xs">
                    <i class="fas fa-search"></i> {{$t('comp.riskMeasureListing.measure.planDetail')}}
                </button>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import Multiselect from 'Controls/CsaMultiSelect.vue'
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'RiskMeasureListing',
        components: {
            Multiselect
        },
        props: {
            measure: {
                type: Object,
                require: true
            },
            readonly: {
                type: Boolean
            },
            riskValue: {
                type: Number
            },
            riskSubId: {
                type: String,
                required: true
            }
        },
        watch: {
            ['measure.decreaseOf']: _.debounce(function() {
                this.$emit("changedValue");
            }, 250)
        },
        created: function () {
            this.showDescription = this.measure.description !== null;
        },
        data() {
            return {
                isLoading: false,
                showDescription: false,
                showAcceptConfirmationDialog: false,
                confirmationDialogRiskMeasures: [],
            };
        },
        methods: {
            changeApplicable: function () {
                if(this.measure.applicable) {
                    this.$api.post("risk-measure/" + this.measure.riskMeasureSubId + '/mark-applicable')  ;
                } else {
                    this.$api.post("risk-measure/" + this.measure.riskMeasureSubId + '/mark-unapplicable')  ;
                }
            },
            applicableChange() {
                this.$api.get('measures/' + this.measure.subId + '/assignation-in-risks')
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            if(data.data.length === 1 && data.data[0].riskSubId === this.riskSubId) {
                                this.changeApplicable();
                            }
                            else if(data.data.length > 0) {
                                this.showAcceptConfirmationDialog = true;
                                this.confirmationDialogRiskMeasures = data.data;
                            }
                            else {
                                this.changeApplicable();
                            }
                        }
                    });
            },
            applicableAbort() {
                this.measure.applicable = !this.measure.applicable;
                this.showAcceptConfirmationDialog = false;
                this.changeApplicable();
            },
            applicableAccept() {
                this.showAcceptConfirmationDialog = false;
                this.changeApplicable();
            }
        },
        computed: {
            ...mapGetters('measureTags', {
                tags: 'getAll',
                getTagById: 'getById'
            })
        }
    }
</script>

<style scoped lang="less">
    #name {
        height: 40px;
    }

    #save {
        vertical-align: middle;
    }

    .table > tbody > tr > td {
        vertical-align: middle;

        .input-group {
            margin-top: 5px;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s
    }
    .fade-enter, .fade-leave-to  {
        opacity: 0
    }

    .label {
        white-space: normal;
    }
</style>