<template>
  <div class="control">
    <label>{{ $t("comp.assetModal.controls.assetTags.label") }}</label>

    <csa-select
      v-model="selected"
      :options="options"
      label="name"
      trackBy="id"
      :multiple="true"
      :close-on-select="false"
    >
    </csa-select>
  </div>
</template>

<script>
import CsaSelect from "Controls/CsaMultiSelect.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "asset-tags-control",
  components: {
    CsaSelect,
  },
  data() {
    return {
      selected: null,
    };
  },
  props: {
    value: null,
  },
  watch: {
    selected: function (v) {
      this.$emit("input", v);
    },
    value: function (v) {
      this.selected = v;
    },
  },
  created() {
    this.fetchTags();
    this.selected = this.value;
  },
  computed: {
    ...mapGetters("assetTags", {
      options: "getAll",
    }),
  },
  methods: {
    ...mapActions("assetTags", {
      fetchTags: "fetchTags",
    }),
  },
};
</script>
