<template>
    <div class="vld-parent" style="width: 100%">
        <div class="pull-right">
            <button class="btn btn-success" @click="add">
                <i class="fa fa-plus"></i> {{ $t('common.add') }}
            </button>
        </div>
        <div class="pull-left" style='padding-top:12px'>
            <button class="btn btn-xs btn-danger" @click="removeSelected"
                    :disabled="currentSelections.length > 0 ? disabled : ''">
                <i class="fa fa-trash"></i> {{ $t('common.delete') }}
            </button>
        </div>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th style="width: 20px"></th>
                    <th style="width: 60px" v-if="isShowHashIds">
                        {{ $t('comp.manageVulnerabilities.vulnerabilityListing.id') }}</th>
                    <th style="width: 40%">{{ $t('comp.manageVulnerabilities.vulnerabilityListing.name') }}</th>
                    <th style="width: 30%">{{ $t('comp.manageVulnerabilities.vulnerabilityListing.tags') }}</th>
                    <th style="width: 200px">{{ $t('comp.manageVulnerabilities.vulnerabilityListing.evalation') }}</th>
                    <th style="white-space: nowrap; width: 80px;">{{
                        $t("comp.manageVulnerabilities.vulnerabilityListing.riskRegister") }}</th>
                    <th style="white-space: nowrap; width: 80px;">{{
                        $t("comp.manageVulnerabilities.vulnerabilityListing.risks") }}</th>
                    <th style="width: 80px"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="width: 2%">
                        <label class="form-checkbox" style="margin-bottom: 0px">
                            <input type="checkbox" :checked="isAllSelected" @click="selectAll" />
                            <i class="form-icon"></i>
                        </label>
                    </td>
                    <td v-if="isShowHashIds">
                    </td>
                    <td>
                        <input class="form-control input-sm" v-model="filter.name" :disabled="isEditing"
                               :placeholder="$t('comp.manageVulnerabilities.vulnerabilityListing.searchPlaceholder')" />
                    </td>
                    <td style="height: 60px;">
                        <multiselect
                                     v-model="filter.tags"
                                     :disabled="isEditing"
                                     :options="getAllTags.map(tag => tag.id)"
                                     :custom-label="opt => getAllTags.find(x => x.id == opt).name"
                                     :multiple="true"
                                     :taggable="false"
                                     :closeOnSelect="false">
                        </multiselect>
                    </td>
                    <td>
                        <select class="form-control input-sm" v-model="filter.classification" :disabled="isEditing">
                            <option v-for="classification in getAllScales" :value="classification.id"
                                    :key="classification.id">
                                {{ classification.name }}
                            </option>
                        </select>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <button class="btn btn-danger btn-xs" @click="cancelFilter" v-if="isFiltered">
                            {{ $t('common.cancelFilter') }}
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <template v-for="vulnerability in vulnerabilities">
                    <vulnerability v-if="vulnerability.id" :item="vulnerability" :isShowHashIds="isShowHashIds"
                                   :key="vulnerability.id" @removed="remove"
                                   @reload="reload"></vulnerability>
                    <vulnerability v-else :item="vulnerability" :isShowHashIds="isShowHashIds" :key="vulnerability.key"
                                   @removed="remove"
                                   @reload="reload"></vulnerability>
                </template>
            </tbody>
        </table>

        <div class="pull-right" v-if="paginator.itemCount > 1">
            {{ paginator.offset + 1 }}-{{ paginator.offset + paginator.length }}/{{ paginator.itemCount }}
            <div class="btn-group" style="padding-right: 20px">
                <button class="btn btn-default btn-sm" @click="paginator.page--" :disabled="paginator.page === 1">
                    <i class="fa fa-chevron-left"></i>
                </button>
                <button class="btn btn-default btn-sm" @click="paginator.page++"
                        :disabled="paginator.page === paginator.pageCount">
                    <i class="fa fa-chevron-right"></i>
                </button>
            </div>
            <div id="vulnerabilitiesPerPage_LocalStorage" class="pull-right">
                <select class="form-control" v-model="paginator.perPage">
                    <option v-for="perPage in perPageOptions" :value="perPage" :key="perPage"> {{ perPage }} </option>
                </select>
            </div>
        </div>
        <loading :active="isLoading"
                 :is-full-page="false"></loading>

        <delete-confirmation :ref="'massDelConfVulnerability'"
                             v-on:confirmed="massDeleteConfirmed($event)"></delete-confirmation>
    </div>
</template>

<script>
import Vulnerability from './Vulnerability.vue';
import Multiselect from '../../Controls/CsaMultiSelect.vue';
import { mapActions, mapGetters } from 'vuex';
import store from '../../store';
import _ from 'lodash';
import ImportModal from "./ImportModal.vue";
import EventBus from './EventBus';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

export default {
    store,
    components: {
        Vulnerability,
        Multiselect,
        DeleteConfirmation
    },
    data() {
        return {
            items: [],
            vulnerabilities: [],
            key: 0,
            filter: {
                name: null,
                tags: [],
                classification: null
            },
            paginator: {
                perPage: 25,
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100],
            isLoading: false,
            watchFilter: true,
            classifications: [],
            currentSelections: []
        }
    },
    watch: {
        ['filter.name']: function () {
            if (this.watchFilter) {
                this.debouncedFilterName();
            }
        },
        ['filter.tags']: function () {
            if (this.watchFilter) {
                this.fetchFilteredVulnerabilities();
            }
        },
        ['filter.classification']: function () {
            if (this.watchFilter) {
                this.fetchFilteredVulnerabilities();
            }
        },
        ['paginator.page']: function () {
            this.fetchFilteredVulnerabilities();
        },
        ['paginator.perPage']: function () {
            this.fetchFilteredVulnerabilities();
            localStorage.setItem('vulnerabilitiesPerPage_LocalStorage', this.paginator.perPage);
        },
        vulnerabilities: {
            handler() {
                this.currentSelections = this.vulnerabilities.filter(item => item.selected && !item.methodology).map(item => item.id);
            },
            deep: true
        }
    },
    mounted: function () {
        if (localStorage.getItem('vulnerabilitiesPerPage_LocalStorage')) {
            try {
                this.paginator.perPage = localStorage.getItem('vulnerabilitiesPerPage_LocalStorage');
            } catch (e) {
                localStorage.removeItem('vulnerabilitiesPerPage_LocalStorage');
            }
        }
    },
    computed: {
        ...mapGetters('vulnerabilities', {
            fetchVulnerabilities: 'fetchVulnerabilities',
        }),
        ...mapGetters('userSettings', {
            isShowHashIds: 'isShowHashIds',
        }),
        ...mapGetters('vulnerabilityTags', {
            getAllTags: 'getAll'
        }),
        ...mapGetters('vulnerabilityClassification', {
            getAllScales: 'getClassificationsWithZero'
        }),
        isFiltered: function () {
            return this.filter.name || this.filter.tags.length || this.filter.classification;
        },
        isEditing() {
            return this.vulnerabilities.filter((v) => { return v.editMode === true; }).length > 0;
        },
        isAllSelected() {
            //checked/(unchecked) checkBox selectAll if every/(more then one) item on items is checked/(unchecked)
            if (this.currentSelections.length <= 0) {
                return false;
            }
            return this.vulnerabilities.filter(item => !item.methodology).every(item => item.selected);
        }
    },
    created: function () {
        this.fetchIsShowHashIds();
        this.fetchFilteredVulnerabilities();

        this.debouncedFilterName = _.debounce(this.fetchFilteredVulnerabilities, 700);

        this.fetchTags();
        this.fetchClassification();

        EventBus.$on("imported", () => {
            this.fetchFilteredVulnerabilities(false);
            this.fetchTags();
            this.fetchClassification();
        });
    },
    methods: {
        add: function () {
            this.vulnerabilities.unshift({
                id: null,
                name: null,
                description: null,
                hidden: false,
                tags: [],
                key: this.key++,
                classification: null
            });
        },
        remove: function (item) {
            this.vulnerabilities.splice(
                this.vulnerabilities.indexOf(item),
                1
            )
        },
        removeSelected: function () {
            let modalWindow = this.$refs.massDelConfVulnerability;
            modalWindow.show();
        },
        massDeleteConfirmed: function () {
            this.isLoading = true;

            this.$api.post("vulnerabilities/mass-delete", this.currentSelections).then(res => {
                if (res.data.status == "ok") {
                    this.showDeleted();
                    this.reload(true);
                }
                else if (res.data.status == "error") {
                    if (res.data.code == "PartiallyDeleted") {
                        this.showCanNotDeleteInUse();
                        this.reload(true, true);
                    }
                    else {
                        this.showDeleteError();
                    }
                }
                this.isLoading = false;
            });
        },
        fetchFilteredVulnerabilities: function (showLoading = true, keepSelection = false) {
            this.isLoading = showLoading;

            this.filterVulnerabilities({ filter: this.filter, paginator: this.paginator }).then((vulnerabilities) => {
                vulnerabilities.items.forEach(vulnerability => {
                    if (keepSelection) {
                        vulnerability.selected = this.currentSelections.indexOf(vulnerability.id) >= 0;
                    }
                    else {
                        vulnerability.selected = false;
                    }
                });
                this.isLoading = false;
                this.vulnerabilities = vulnerabilities.items;
                this.paginator = vulnerabilities.paginator;
            });
        },
        selectAll() {
            let selected = !this.isAllSelected;
            //check/uncheck all item checkBox on items if selectAll is checked/(unchecked)
            this.vulnerabilities.forEach(item => item.selected = selected);
        },
        ...mapActions('vulnerabilities', {
            filterVulnerabilities: 'filterVulnerabilities',
        }),
        ...mapActions('vulnerabilityTags', {
            fetchTags: 'fetchTags',
        }),
        ...mapActions('vulnerabilityClassification', {
            fetchClassification: 'fetchClassification'
        }),
        ...mapActions('userSettings', {
            fetchIsShowHashIds: 'fetchIsShowHashIds'
        }),
        cancelFilter: function () {
            this.watchFilter = false;

            this.filter = {
                name: null,
                tags: [],
                classification: null
            };

            this.fetchFilteredVulnerabilities();

            this.$nextTick(() => {
                this.watchFilter = true;
            });
        },
        reload: function (showLoading = false, keepSelection = false) {
            this.fetchFilteredVulnerabilities(showLoading, keepSelection);
        },
    },
    notifications: {
        showDeleted: {
            message: 'common.deletedItems',
            type: 'success'
        },
        showDeleteError: {
            message: 'common.deleteError',
            type: 'error'
        },
        showCanNotDeleteInUse: {
            message: 'common.oneOrMoreCanNotDeleteInUse',
            type: 'error'
        }
    }
}
</script>


<style scoped lang="less">
.multiselect {
    font-size: 12px;

    /deep/ .multiselect__tags {
        min-height: 30px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    /deep/ .multiselect__select {
        height: 30px;
    }

    /deep/ .multiselect__placeholder {
        font-size: 12px;
    }

    /deep/ .multiselect__input {
        font-size: 12px;
    }

    /deep/ .multiselect__tag {
        font-size: 12px;
    }
}
</style>
