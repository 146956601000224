<template>
    <div class="vld-parent">
        <div class="callout callout-warning" v-if="!editable">
            <h4>{{ $t("testHasBeenAssignedToCourse") }}</h4>

            <p>{{ $t("editForbidden") }}</p>
        </div>


        <loading :active.sync="isLoading" :is-full-page="false"></loading>

        <div class="text-right margin-bottom" v-if="!editId">
            <button class="btn btn-danger btn-xs" @click="clear">
                <i class="fas fa-save"></i>

                {{ $t("clearData") }}
            </button>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div v-sticky>
                <general :test="test" :editable="editable"></general>
                <evaluation :test="test" :editable="editable"></evaluation>

                <div class="box box-default">
                    <div class="box-body">
                        <dl class="dl-horizontal-info">
                            <dt>{{ $t("questionsTotalCount") }}</dt>
                            <dd><strong>{{questions.length}}</strong></dd>
                            <dt>{{ $t("evaluatedQuestionsCount") }}</dt>
                            <dd><strong>{{evaluatedQuestions.length}}</strong></dd>
                        </dl>
                    </div>
                </div>

                <div class="text-center mb2">
                    <button class="btn btn-success btn-lg" @click="save" v-if="!editId" :disabled="errors.any()">
                        <i class="fas fa-plus"></i>
                        {{ $t("createTest") }}
                    </button>
                    <button class="btn btn-success btn-lg" @click="save" v-if="editId && editable" :disabled="errors.any()">
                        <i class="fas fa-save"></i>
                        {{ $t("save") }}
                    </button>
                </div>

                <div class="callout callout-warning" v-if="!editId">
                    <p v-html="$t('editForbiddenAfterTestAssignation')">
                    </p>
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <template v-for="(question, index) in questions">
                    <question-choices
                            v-if="question.type === 'choices'"
                            :question="question"
                            v-on:appendQuestion="appendQuestion"
                            v-on:removeQuestion="removeQuestion"
                            v-on:moveUp="moveQuestionUp"
                            v-on:moveDown="moveQuestionDown"
                            :questionIndex = "index"
                            :key= "question.key"
                            :total = "test.questions.length"
                            :editable="editable"
                    ></question-choices>
                    <question-fulltext
                            v-if="question.type === 'fulltext'"
                            :question="question"
                            v-on:appendQuestion="appendQuestion"
                            v-on:removeQuestion="removeQuestion"
                            v-on:moveUp="moveQuestionUp"
                            v-on:moveDown="moveQuestionDown"
                            :questionIndex = "index"
                            :key= "question.key"
                            :total = "test.questions.length"
                            :editable="editable"
                    ></question-fulltext>
                    <question-yes-no
                            v-if="question.type === 'yesNo'"
                            :question="question"
                            v-on:appendQuestion="appendQuestion"
                            v-on:removeQuestion="removeQuestion"
                            v-on:moveUp="moveQuestionUp"
                            v-on:moveDown="moveQuestionDown"
                            :questionIndex = "index"
                            :key= "question.key"
                            :total = "test.questions.length"
                            :editable="editable"
                    ></question-yes-no>
                </template>

                <p class="text-center" v-if="editable">
                    <button class="btn btn-primary btn-lg" @click="addQuestion">
                        <i class="fas fa-plus"></i> {{ $t("addQuestion") }}
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import General from './General.vue';
    import QuestionChoices from './inputs/QuestionChoices.vue';
    import QuestionFulltext from './inputs/QuestionFulltext.vue';
    import QuestionYesNo from './inputs/QuestionYesNo.vue';
    import Sticky from 'vue-sticky-directive'
    import Evaluation from "./Evaluation.vue";

    export default {
        components: {
            Evaluation,
            Loading,
            General,
            QuestionChoices,
            QuestionFulltext,
            QuestionYesNo
        },
        directives: {
            Sticky
        },
        data() {
            return {
                isLoading: false,
                editable: true,
                test: {
                    questions: [],
                    scaleEvaluationSteps: [],
                    scaleEvaluationStepsCount: null
                },
                questionKeyCounter: 0,    
            }
        },
        watch: {
          test: {
              handler: function () {
                  if(!this.editId) {
                    const parsed = JSON.stringify(this.test);
                    localStorage.setItem('test', parsed);
                  }
              },
              deep: true
          }
        },
        created() {
            //this.$validator.localize(validator);
        },
        mounted: function () {
            if(this.editId) {
                this.load();
                return;
            }
            if(this.duplicateId) {
                this.clear();
                this.load();
                return;
            }

            if (localStorage.getItem('test')) {
                try {
                    this.test = JSON.parse(localStorage.getItem('test'));
                } catch(e) {
                    localStorage.removeItem('test');
                }
            }

            if(!this.test.questions.length) { //create first question
                this.addQuestion();
            }
        },
        props: {
            saveLink: String,
            loadLink: String,
            editId: String,
            duplicateId: String,
        },
        provide () {
            return { parentValidator: this.$validator }
        },
        computed: {
            questions: function () {
                return this.test.questions.filter(function (q) {
                    return !q.removed;
                });
            },
            evaluatedQuestions: function () {
                return this.test.questions.filter(function (q) {
                    return q.type === 'choices' || q.type === 'yesNo' && !q.removed;
                });
            }
        },
        methods: {
            getQuestionKey: function(question){
                let i;

                for(i = 0; i < this.test.questions.length; i++) {
                    let q = this.test.questions[i];

                    if(q === question) {
                        break;
                    }
                }

                return i;
            },
            addQuestion: function () {
                this.test.questions.push({
                    key: this.questionKeyCounter++,
                    type: 'choices',
                    answers: []
                });
            },
            appendQuestion: function (question) {
                let i = this.getQuestionKey(question);

                this.test.questions.splice(i+1, 0, {
                    key: this.questionKeyCounter++,
                    type: 'choices',
                    answers: []
                });
            },
            removeQuestion: function (question) {
                let i = this.getQuestionKey(question);

                if(this.editId) {
                    this.$set(question, "removed", true);
                } else {
                    this.test.questions.splice(i, 1);
                }
            },
            moveQuestionUp: function (question) {
                let i = this.getQuestionKey(question);

                if(i-1 < 0) {
                    return;
                }

                this.test.questions.splice(i, 1);
                this.test.questions.splice(i-1, 0, question);
            },
            moveQuestionDown: function (question) {
                let i = this.getQuestionKey(question);

                if(i+1 > this.test.questions.length-1) {
                    return;
                }

                this.test.questions.splice(i, 1);
                this.test.questions.splice(i+1, 0, question);
            },
            submit: function () {
                this.isLoading = true;

                if (this.test.time == ""){
                    this.test.time = null;
                }

                if (this.test.minForSuccess == ""){
                    this.test.minForSuccess = null;
                }

                this.axios.post(this.saveLink, this.test)
                    .then(({data}) => {
                        if(data.result === "ok") {
                            if(!this.editId) {
                                this.clear();

                                $.notify({
                                    message: this.$t('comp.testEditor.testEditor.testCreated'),
                                }, {
                                    type: 'success'
                                });
                            }
                            else {
                                $.notify({
                                    message: this.$t('comp.testEditor.testEditor.saved'),
                                }, {
                                    type: 'info'
                                });
                            }

                            window.location = data.redirect;
                            this.isLoading = false;
                        } else {
                            alert("error");
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            save: function () {
                this.$validator.validate().then(result => {
                    if (!result) {
                        $.notify({
                            message: this.$t('comp.testEditor.testEditor.fixErrors'),
                        }, {
                            type: 'danger'
                        });
                    } else {
                        this.submit();
                    }
                });
            },
            load: function () {
                this.isLoading = true;

                this.axios.get(this.loadLink)
                    .then(({data}) => {
                        this.test = data;

                        if(this.editId) {
                            this.editable = data.editable;
                        }

                        this.isLoading = false;
                        this.questionKeyCounter = this.test.questions.length + 1;
                    });
            },
            clear: function () {
                localStorage.removeItem('test');

                this.test.name = null;
                this.test.description = "";
                this.test.time = null;

                this.$set(this.test, 'questions', []);
                this.questionKeyCounter = 0;

                this.$set(this.test, 'evaluationType', 0);          // TestEvaluationType::None
                this.$set(this.test, 'minForSuccess', null);      
                this.$set(this.test, 'scaleEvaluationSteps', []);
                this.$set(this.test, 'scaleEvaluationStepsCount', null);   
       
                this.$nextTick(function () {
                    this.addQuestion();
                });
            }
        }
    }
</script>