<template>
    <div id="riskClassification">
        <div class="box box-success">
            <div class="box-header with-border">
                <h3 class="box-title">{{$t('comp.riskClassification.editModal.title')}}</h3>

                <router-link class="btn btn-success pull-right" :to="{name: 'create'}">
                    <i class="fas fa-plus"></i> {{$t('common.create')}}
                </router-link>
            </div>
            <div class="box-body">
                <ul class="todo-list ui-sortable" v-if="items.length">
                    <li v-for="item in items">
                        <span class="pull-left">{{item.name}}</span>
                        <span class="pull-right">
                            <button class="btn btn-danger btn-xs" @click="remove(item)" :disabled="item.isMethodology">{{$t('common.delete')}}</button>
                            <router-link class="btn btn-warning btn-xs" :to="{name: 'edit', params:{id: item.id}}">{{$t('common.edit')}}</router-link>
                        </span>
                        <span class="pull-right margin-r-5 text-sm" v-if="item.isMethodology">
                            {{$t('common.methodology')}} <img src='/favicon.ico'/>
                        </span>

                        <delete-confirmation :ref="'delconf-' + item.id" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>

                        <div class="clearfix"></div>
                    </li>
                </ul>

                <div v-else>
                    <em>{{$t('comp.riskClassification.editModal.empty')}}</em>
                    <router-link class="btn btn-link p0" :to="{name: 'create'}">{{$t('common.create')}}</router-link>?
                </div>
            </div>
        </div>

        <edit-modal v-if="showModal" @reload="load"></edit-modal>
    </div>
</template>

<script>
    import EditModal from './EditModal.vue';
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';
    import VueRouter from 'vue-router';
    import Bus from './Bus';

    const router = new VueRouter({
        routes: [
            {
                name: 'listing',
                path: '/',
                meta: {
                    showModal: false
                }
            },
            {
                name: 'create',
                path: '/create',
                meta: {
                    showModal: true
                }
            },
            {
                name: 'edit',
                path: '/edit/:id',
                component: EditModal,
                props: true,
                meta: {
                    showModal: true
                }
            }
        ]
    });

    export default {
        name: 'risk-classification',
        router,
        components: {
            EditModal,
            DeleteConfirmation
        },
        data() {
            return {
                items: [],
                showModal: this.$route.meta.showModal
            };
        },
        watch: {
            '$route.meta' ({showModal}) {
                this.showModal = showModal;
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            createNew() {

            },
            load() {
                this.$api.get("risk-classification").then(({data})=>{
                    if(data.status === 'ok') {
                        this.items = data.data;
                    }
                });
            },
            remove(classification) {
                let delconfname = 'delconf-' + classification.id;
                let modalWindow = this.$refs[delconfname][0];
                modalWindow.show(classification);
            },
            deleteConfirmed(classification = null) {
                this.$api.delete("risk-classification/" + classification.id).then(({data})=>{
                    if (data.status === 'ok') {
                        let index = this.items.indexOf(classification);
                        this.items.splice(index, 1);

                        this.classificationRemoved();
                    } else {
                        alert(this.$t('comp.riskClassification.editModal.classificationIsUsed'))
                    }
                });
            }
        },
        notifications: {
            classificationRemoved: {
                message: 'comp.riskClassification.editModal.deleted',
                type: 'info'
            }
        }
    }
</script>