<template>
    <div id="popover" :style="{top: top + 'px', left: left + 'px', display: display ? 'block':'none'}">
        <strong class="text-danger">{{$t('comp.riskPopover.index.title')}}</strong>

        <template v-if="assets">
            <table class="table text-sm text-left">
                <tr>
                    <th v-if="isShowHashIds">{{$t('comp.riskPopover.index.id')}}</th>
                    <th>{{$t('comp.riskPopover.index.name')}}</th>
                    <th>{{$t('comp.riskPopover.index.maxRisk')}}</th>
                    <th v-if="showButton"></th>
                </tr>
                <tr v-for="asset in assets" :key="asset.id">
                    <td v-if="isShowHashIds">{{asset.hashId}}</td>
                    <td>{{asset.name}}</td>
                    <td>
                        <span :title="asset.riskClassificationDescription_maxRiskValue" class="label text-white" :style="{'background-color': asset.riskClassificationColor_maxRiskValue}">
                            {{asset.riskClassificationName_maxRiskValue}} / {{asset.maxRiskValue}} ({{asset.maxRiskValuePercentage}}%)
                        </span>
                    </td>
                    <td v-if="showButton">
                        <button class="btn btn-default" @click="detail(asset.id)" :title="$t('comp.riskPopover.index.filterRisks')" >
                            <i class="fas fa-filter"></i>
                        </button>
                    </td>
                </tr>
            </table>
        </template>

        <template v-else>
            <i class="fas fa-spinner fa-spin"></i>
        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        data(){
            return {
                top: 0,
                left: 0,
                display: false,
                assetId: null,
                assets: null
            };
        },
        props: {
            showButton: {
                type: Boolean,
                default: true
            }
        },
        mounted(){
            //window.addEventListener('mousemove',this.mouseMoving);
        },
        computed: {
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            })
        },
        created: function () {
            this.fetchIsShowHashIds();
        },
        methods: {
            ...mapActions('userSettings', {
                fetchIsShowHashIds: 'fetchIsShowHashIds'
            }),
            show(assetId) {
                this.assetId = assetId;
                this.display = true;
                this.$api.get('asset/fetchAssetsWithHigherRisk/' + assetId).then(
                    ({data}) => {
                        if(data.status === 'ok') {
                            this.assets = data.data;
                        }
                        else {
                            this.assets = [];
                        }
                    }, 
                    error => {
                        this.assets = [];
                    }
                );
            },
            hide() {
                this.display = false;
                this.assetId = null;
                this.assets = null;
            },
            position(x, y) {
                this.top = y;
                this.left = x - 320;
            },
            mouseMoving(e){
                this.top = e.clientY;
                this.left = e.clientX - 320;
            },
            detail(assetId) {
                this.$emit("detail", assetId);

                let urlParams = new URLSearchParams(window.location.search);
                urlParams.delete('view');
                urlParams.set('filteredAssetId', assetId);
                window.location.search = urlParams;
            }
        }
    }
</script>

<style scoped lang="less">
    #popover {
        width: 300px;
        position: fixed;
        background: white;
        box-shadow: 0 0 5px #DDDDDD;
        border: 1px solid #DDDDDD;
        padding: 5px;
        text-align: center;
    }

    button {
        display: block;
        width: 100%;
        padding: 0;
    }

    table {
        margin-top: 12px;

        td {
            padding: 2px 0 2px 0;
        }
    }
</style>