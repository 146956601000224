<template>
    <div class="row">
        <div class="col-md-12">
            <div class="pull-right">
                <label class="btn btn-default btn-sm" for="files2">
                    <input type="file" id="files2" style="display: none" multiple @change="selected" />
                    <i class="fas fa-paperclip"></i> {{$t('comp.continuityPlanModal.tabs.attachments.selectFiles')}}
                </label>
            </div>

            <div class="clearfix"></div>

            <table class="table" style="margin-top: 20px" v-if="plan.attachments.length">
                <tbody>
                <tr v-for="file in plan.attachments" :key="file.name">
                    <td v-if="isUploaded(file)">
                        {{file.name + '.' + file.extension}}
                    </td>
                    <td v-else>
                        {{file.name}}
                    </td>
                    <td class="text-right">
                        <button v-if="isUploaded(file)" class="btn btn-primary btn-xs" @click.prevent="download(file)">
                            <i class="fas fa-download"></i>
                        </button>
                        <button class="btn btn-danger btn-xs" type="button" @click="remove(file)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <p v-else class="text-center">
                <em>{{$t('comp.continuityPlanModal.tabs.attachments.noAttachmentsSelected')}}</em>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['plan'],
        methods: {
            selected(e) {
                this.plan.attachments.push(...e.target.files)
            },
            remove(file) {
                if(!(file instanceof File)) {
                    this.$emit('deleteFile', file)
                }

                this.plan.attachments.splice(this.plan.attachments.indexOf(file), 1)
            },
            isUploaded(file) {
                return !(file instanceof File);
            },
            download(file) {
                this.$api.get('continuity-plan/attachments', {
                    responseType: 'blob', // important
                    params: {
                      attachment_id: file.id
                    }
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', file.name + '.' + file.extension); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }
</script>