<template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog vld-parent" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{$t('comp.manageThreatVulnRegister.bindingModal.title')}}
                    </h4>
                </div>

                <div class="modal-body">
                    <div v-if="phase == 1">
                        <span class="active">{{$t('comp.manageThreatVulnRegister.bindingModal.selectAssetType')}}</span>
                    </div>
                    <div v-else-if="phase == 2">
                        <span class="finished">{{$t('comp.manageThreatVulnRegister.bindingModal.assetType')}} {{assetType.name}} <i class="fa fa-check" aria-hidden="true"></i></span>
                        <span class="delimiter">&rarr;</span>
                        <span class="active">{{$t('comp.manageThreatVulnRegister.bindingModal.selectThreatOrVuln')}}</span>
                    </div>
                    <div v-else>
                        <span class="finished">{{$t('comp.manageThreatVulnRegister.bindingModal.assetType')}} {{assetType.name}} <i class="fa fa-check" aria-hidden="true"></i></span>
                        <span class="delimiter">&rarr;</span>
                        <span class="finished" v-if="threat != null">{{$t('comp.manageThreatVulnRegister.bindingModal.threat')}} {{threat.name}} <i class="fa fa-check" aria-hidden="true"></i></span>
                        <span class="finished" v-else>{{$t('comp.manageThreatVulnRegister.bindingModal.vulnerability')}} {{vulnerability.name}} <i class="fa fa-check" aria-hidden="true"></i></span>
                        <span class="delimiter">&rarr;</span>
                        <span class="active" v-if="threat == null">{{$t('comp.manageThreatVulnRegister.bindingModal.selectRelatedThreats')}}</span>
                        <span class="active" v-else>{{$t('comp.manageThreatVulnRegister.bindingModal.selectRelatedVuln')}}</span>
                    </div>


                    <template v-if="phase == 1">
                        <table class="table table-striped phase-1">
                            <thead>
                                <tr>
                                    <th colspan="2">{{$t('comp.manageThreatVulnRegister.bindingModal.assetTypes')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="80%">
                                        <input class="form-control input-sm" v-model="assetTypesFilter.name" :placeholder="$t('comp.manageThreatVulnRegister.bindingModal.searchPlaceholder')"/>
                                    </td>
                                    <td width="20%">
                                        <button class="btn btn-danger btn-xs" @click="cancelFilter('assetTypes')" v-if="isAssetTypesFiltered">
                                            {{$t('common.cancelFilter')}}
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <ul class="bind-dialog bind-dialog-left phase-1">
                                            <li class="btn btn-default" v-for="type in assetTypes" :key="type.id" @click="selectAssetType(type)">
                                                {{type.name}} <span class="totals">{{type.totalBindings}}</span>
                                            </li>
                                        </ul>
                                        <div class="pull-right" v-if="assetTypesPaginator.pageCount > 1">
                                            {{assetTypesPaginator.offset}}-{{assetTypesPaginator.offset+assetTypesPaginator.length}}/{{assetTypesPaginator.itemCount}}
                                            <div class="btn-group">
                                                <button class="btn btn-default btn-sm" @click="assetTypesPaginator.page--" :disabled="assetTypesPaginator.page === 1">
                                                    <i class="fa fa-chevron-left"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm" @click="assetTypesPaginator.page++"
                                                        :disabled="assetTypesPaginator.page === assetTypesPaginator.pageCount">
                                                    <i class="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <template v-if="phase == 2">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th width="50%" colspan="3">{{$t('comp.manageThreatVulnRegister.bindingModal.threats')}}</th>
                                    <th width="50%" colspan="3">{{$t('comp.manageThreatVulnRegister.bindingModal.vulns')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="20%">
                                        <input class="form-control input-sm" v-model="filterThreat.name" :placeholder="$t('comp.manageThreatVulnRegister.bindingModal.searchPlaceholder')"/>
                                    </td>
                                    <td width="20%">
                                        <multiselect
                                                v-model="filterThreat.tags"
                                                :options="getAllThreatTags.map(tag => tag.id)"
                                                :custom-label="opt => getAllThreatTags.find(x => x.id == opt).name"
                                                :multiple="true"
                                                :taggable="false"
                                                :closeOnSelect="false">
                                        </multiselect>
                                    </td>
                                    <td width="10%">
                                        <button class="btn btn-danger btn-xs" @click="cancelFilter('threat')" v-if="isThreatFiltered">
                                            {{$t('common.cancelFilter')}}
                                        </button>
                                    </td>
                                    <td width="20%">
                                        <input class="form-control input-sm" v-model="filterVuln.name" :placeholder="$t('comp.manageThreatVulnRegister.bindingModal.searchPlaceholder')"/>
                                    </td>
                                    <td  width="20%">
                                        <multiselect
                                                v-model="filterVuln.tags"
                                                :disabled="isEditing"
                                                :options="getAllVulnerabilityTags.map(tag => tag.id)"
                                                :custom-label="opt => getAllVulnerabilityTags.find(x => x.id == opt).name"
                                                :multiple="true"
                                                :taggable="false"
                                                :closeOnSelect="false">
                                        </multiselect>
                                    </td>
                                    <td width="10%">
                                        <button class="btn btn-danger btn-xs" @click="cancelFilter('vuln')" v-if="isVulnFiltered">
                                            {{$t('common.cancelFilter')}}
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <ul class="bind-dialog bind-dialog-left phase-2">
                                            <li class="btn btn-default" v-for="threat in threats" :key="threat.id" @click="selectThreat(threat)">
                                                {{getNameWithHashId(threat)}}
                                                <i class="fas fa-info-circle description-icon" v-if="threat.description" :title="threat.description"></i>
                                                <span class="totals">{{threat.totalBindings}}</span>
                                            </li>
                                        </ul>
                                        <div class="pull-right" v-if="paginatorThreat.pageCount > 1">
                                            {{paginatorThreat.offset}}-{{paginatorThreat.offset+paginatorThreat.length}}/{{paginatorThreat.itemCount}}
                                            <div class="btn-group">
                                                <button class="btn btn-default btn-sm" @click="paginatorThreat.page--" :disabled="paginatorThreat.page === 1">
                                                    <i class="fa fa-chevron-left"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm" @click="paginatorThreat.page++"
                                                        :disabled="paginatorThreat.page === paginatorThreat.pageCount">
                                                    <i class="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <ul class="bind-dialog bind-dialog-right phase-2">
                                            <li class="btn btn-default" v-for="vuln in vulnerabilities" :key="vuln.id" @click="selectVulnerability(vuln)">
                                                {{getNameWithHashId(vuln)}}
                                                <i class="fas fa-info-circle description-icon" v-if="vuln.description" :title="vuln.description"></i>
                                                <span class="totals">{{vuln.totalBindings}}</span>
                                            </li>
                                        </ul>
                                        <div class="pull-right" v-if="paginatorVuln.pageCount > 1">
                                            {{paginatorVuln.offset}}-{{paginatorVuln.offset+paginatorVuln.length}}/{{paginatorVuln.itemCount}}
                                            <div class="btn-group">
                                                <button class="btn btn-default btn-sm" @click="paginatorVuln.page--" :disabled="paginatorVuln.page === 1">
                                                    <i class="fa fa-chevron-left"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm" @click="paginatorVuln.page++"
                                                        :disabled="paginatorVuln.page === paginatorVuln.pageCount">
                                                    <i class="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <template v-if="phase == 3">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th width="50%" colspan="3" v-if="threat != null">{{$t('comp.manageThreatVulnRegister.bindingModal.availableVulns')}}</th>
                                    <th width="50%" colspan="3" v-else>{{$t('comp.manageThreatVulnRegister.bindingModal.availableThreats')}}</th>
                                    <th width="50%" colspan="3" v-if="threat != null">{{$t('comp.manageThreatVulnRegister.bindingModal.relatedVulns')}}</th>
                                    <th width="50%" colspan="3" v-else>{{$t('comp.manageThreatVulnRegister.bindingModal.relatedThreats')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="20%">
                                        <input class="form-control input-sm" v-model="itemsAvailableFilter.name" :placeholder="$t('comp.manageThreatVulnRegister.bindingModal.searchPlaceholder')" />
                                    </td>
                                    <td width="20%">
                                        <template v-if="threat != null">
                                            <multiselect
                                                    v-model="itemsAvailableFilter.tags"
                                                    :disabled="isEditing"
                                                    :options="getAllVulnerabilityTags.map(tag => tag.id)"
                                                    :custom-label="opt => getAllVulnerabilityTags.find(x => x.id == opt).name"
                                                    :multiple="true"
                                                    :taggable="false"
                                                    :closeOnSelect="false">
                                            </multiselect>
                                        </template>
                                        <template v-else>
                                            <multiselect
                                                    v-model="itemsAvailableFilter.tags"
                                                    :options="getAllThreatTags.map(tag => tag.id)"
                                                    :custom-label="opt => getAllThreatTags.find(x => x.id == opt).name"
                                                    :multiple="true"
                                                    :taggable="false"
                                                    :closeOnSelect="false">
                                            </multiselect>
                                        </template>
                                    </td>
                                    <td width="10%">
                                        <button class="btn btn-danger btn-xs" @click="cancelFilter('available')" v-if="isAvailableFiltered">
                                            {{$t('common.cancelFilter')}}
                                        </button>
                                    </td>
                                    <td width="20%">
                                        <input class="form-control input-sm" v-model="itemsBoundFilter.name" :placeholder="$t('comp.manageThreatVulnRegister.bindingModal.searchPlaceholder')"/>
                                    </td>
                                    <td width="20%">
                                        <template v-if="threat != null">
                                            <multiselect
                                                    v-model="itemsBoundFilter.tags"
                                                    :disabled="isEditing"
                                                    :options="getAllVulnerabilityTags.map(tag => tag.id)"
                                                    :custom-label="opt => getAllVulnerabilityTags.find(x => x.id == opt).name"
                                                    :multiple="true"
                                                    :taggable="false"
                                                    :closeOnSelect="false">
                                            </multiselect>
                                        </template>
                                        <template v-else>
                                            <multiselect
                                                    v-model="itemsBoundFilter.tags"
                                                    :options="getAllThreatTags.map(tag => tag.id)"
                                                    :custom-label="opt => getAllThreatTags.find(x => x.id == opt).name"
                                                    :multiple="true"
                                                    :taggable="false"
                                                    :closeOnSelect="false">
                                            </multiselect>
                                        </template>
                                    </td>
                                    <td width="10%">
                                        <button class="btn btn-danger btn-xs" @click="cancelFilter('bound')" v-if="isBoundFiltered">
                                            {{$t('common.cancelFilter')}}
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <ul class="bind-dialog bind-dialog-left phase-3">
                                            <li class="btn btn-warning" v-for="item in itemsAvailable" :key="item.id" @click="moveToBound(item)">
                                                {{getNameWithHashId(item)}}
                                                <i class="fas fa-info-circle description-icon" v-if="item.description" :title="item.description"></i>
                                            </li>
                                        </ul>
                                        <div class="pull-right" v-if="itemsAvailablePaginator.pageCount > 1">
                                            {{itemsAvailablePaginator.offset}}-{{itemsAvailablePaginator.offset+itemsAvailablePaginator.length}}/{{itemsAvailablePaginator.itemCount}}
                                            <div class="btn-group">
                                                <button class="btn btn-default btn-sm" @click="itemsAvailablePaginator.page--" :disabled="itemsAvailablePaginator.page === 1">
                                                    <i class="fa fa-chevron-left"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm" @click="itemsAvailablePaginator.page++"
                                                        :disabled="itemsAvailablePaginator.page === itemsAvailablePaginator.pageCount">
                                                    <i class="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <ul class="bind-dialog bind-dialog-right phase-3">
                                            <li class="btn btn-success" v-for="item in itemsBound" :key="item.id" @click="moveToUnbound(item)">
                                                {{getNameWithHashId(item)}}
                                                <i class="fas fa-info-circle description-icon" v-if="item.description" :title="item.description"></i>
                                            </li>
                                        </ul>
                                        <div class="pull-right" v-if="itemsBoundPaginator.pageCount > 1">
                                            {{itemsBoundPaginator.offset}}-{{itemsBoundPaginator.offset+itemsBoundPaginator.length}}/{{itemsBoundPaginator.itemCount}}
                                            <div class="btn-group">
                                                <button class="btn btn-default btn-sm" @click="itemsBoundPaginator.page--" :disabled="itemsBoundPaginator.page === 1">
                                                    <i class="fa fa-chevron-left"></i>
                                                </button>
                                                <button class="btn btn-default btn-sm" @click="itemsBoundPaginator.page++"
                                                        :disabled="itemsBoundPaginator.page === itemsBoundPaginator.pageCount">
                                                    <i class="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <hr class="clear" />
                </div>

                <div class="modal-footer">
                    <button class="btn btn-default pull-left" @click="setState(1)" v-if="phase == 2">&lt; {{$t('comp.manageThreatVulnRegister.bindingModal.selectAnotherAssetType')}}</button>
                    <button class="btn btn-default pull-left" @click="setState(2)" v-if="phase == 3">&lt; {{$t('comp.manageThreatVulnRegister.bindingModal.selectAnotherThreatOrVuln')}}</button>
                    <button class="btn btn-default pull-right" @click="$emit('hidden')">{{$t('common.close')}}</button>
                </div>
            </div>

            <loading :active="isLoading" :is-full-page="false"></loading>
        </div>
    </div>
</template>

<script>
    import store from '../../store';
    import Multiselect from '../../Controls/CsaMultiSelect.vue';
    import {mapActions, mapGetters} from 'vuex';

    export default {
        store,
        components:{
            Multiselect,
        },

        data() {
            return {
                modal: null,
                isLoading: false,

                assetTypes: null,
                assetType: null,
                threats: null,
                threat: null,
                vulnerabilities: null,
                vulnerability: null,
                itemsBound: null,
                itemsAvailable: null,
                itemsTags: [],

                assetTypesPaginator: {
                    perPage: 10,
                    page: 1
                },
                assetTypesFilter: {
                    name: null,
                    showHidden: false
                },

                filterThreat: {
                    name: null,
                    tags: [],
                    showHidden: false
                },
                filterVuln: {
                    name: null,
                    tags: [],
                    showHidden: false
                },
                paginatorThreat: {
                    perPage: 10,
                    page: 1
                },
                paginatorVuln: {
                    perPage: 10,
                    page: 1
                },

                itemsBoundFilter: {
                    name: null,
                    tags: [],
                    binding: {
                        assetTypeId: null,
                        threatId: null,
                        vulnerabilityId: null,
                        type: "bound"
                    }
                },
                itemsBoundPaginator: {
                    perPage: 10,
                    page: 1
                },
                itemsAvailableFilter: {
                    name: null,
                    tags: [],
                    binding: {
                        assetTypeId: null,
                        threatId: null,
                        vulnerabilityId: null,
                        type: "available"
                    }
                },
                itemsAvailablePaginator: {
                    perPage: 10,
                    page: 1
                },

                phase: 1,
                watchFilter: true
            }
        },
        watch: {
            ['assetTypesFilter.name']: function () {
                if (this.watchFilter) {
                    this.debouncedFilterAssetTypeName();
                }
            },
            ['assetTypesPaginator.page']: function () {
                this.fetchFilteredAssetTypes();
            },
            ['filterThreat.name']: function () {
                if (this.watchFilter) {
                    this.debouncedFilterThreatName();
                }
            },
            ['filterThreat.tags']: function () {
                if (this.watchFilter) {
                    this.fetchFilteredThreats();
                }
            },
            ['filterVuln.name']: function () {
                if (this.watchFilter) {
                    this.debouncedFilterVulnName();
                }
            },
            ['filterVuln.tags']: function () {
                if (this.watchFilter) {
                    this.fetchFilteredVulnerabilities();
                }
            },
            ['itemsBoundFilter.name']: function () {
                if (this.watchFilter) {
                    this.debouncedFilterItemBoundName();
                }
            },
            ['itemsBoundFilter.tags']: function () {
                if (this.watchFilter) {
                    this.fetchFilteredItemsBound();
                }
            },
            ['itemsAvailableFilter.name']: function () {
                if (this.watchFilter) {
                    this.debouncedFilterItemAvailableName();
                }
            },
            ['itemsAvailableFilter.tags']: function () {
                if (this.watchFilter) {
                    this.fetchFilteredItemsAvailable();
                }
            },
            ['paginatorVuln.page']: function () {
                this.fetchFilteredVulnerabilities();
            },
            ['paginatorThreat.page']: function () {
                this.fetchFilteredThreats();
            },
            ['itemsAvailablePaginator.page']: function () {
                this.fetchFilteredItemsAvailable();
            },
            ['itemsBoundPaginator.page']: function () {
                this.fetchFilteredItemsBound();
            }
        },
        created: function() {
            this.debouncedFilterAssetTypeName = _.debounce(this.fetchFilteredAssetTypes, 700);
            this.debouncedFilterThreatName = _.debounce(this.fetchFilteredThreats, 700);
            this.debouncedFilterVulnName = _.debounce(this.fetchFilteredVulnerabilities, 700);
            this.debouncedFilterItemBoundName = _.debounce(this.fetchFilteredItemsBound, 700);
            this.debouncedFilterItemAvailableName = _.debounce(this.fetchFilteredItemsAvailable, 700);

            this.fetchIsShowHashIds();
            this.fetchThreatTags();
            this.fetchVulnerabilityTags();
        },
        computed: {
            ...mapGetters('vulnerabilityTags', {
                getAllVulnerabilityTags: 'getAll'
            }),
            ...mapGetters('threatTags', {
                getAllThreatTags: 'getAll'
            }),
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            }),
            isThreatFiltered: function () {
                return this.filterThreat.name || this.filterThreat.tags.length;
            },
            isVulnFiltered: function () {
                return this.filterVuln.name || this.filterVuln.tags.length;
            },
            isBoundFiltered: function () {
                return this.itemsBoundFilter.name || this.itemsBoundFilter.tags.length;
            },
            isAvailableFiltered: function () {
                return this.itemsAvailableFilter.name || this.itemsAvailableFilter.tags.length;
            },
            isAssetTypesFiltered: function() {
                return this.assetTypesFilter.name;
            }
        },
        async mounted () {
            this.modal = this.$refs.modal;
        },
        methods: {
            resetData: function(keepAsset = true) {
                if (!keepAsset) {
                    this.assetTypes = null;
                    this.assetType = null;
                }
                this.threat = null;
                this.vulnerability = null;
                this.items = null;
                this.itemsBound = null;
                this.itemsAvailable = null;
                this.itemsTags = [];
            },
            close: function () {
                this.resetData();
                this.phase = 1;
                $(this.modal).modal("hide");
            },
            show: function () {
                this.loadData().then(() => {
                    this.phase = 1;
                    $(this.modal).modal({
                        backdrop: false,
                        keyboard: false,
                    });
                })
            },
            setState: async function (state) {
                if (state == 1) {
                    // restore to asset type selection
                    await this.changePhase(1);
                    this.resetData(false);
                    await this.fetchFilteredAssetTypes();
                } else if (state == 2) {
                    await this.changePhase(2);
                    this.resetData();
                    await this.fetchFilteredThreats();
                    await this.fetchFilteredVulnerabilities();
                }
            },
            async loadData() {
                await this.fetchFilteredAssetTypes();
            },
            async changePhase(newPhase) {
                this.phase = newPhase;
            },
            fetchFilteredAssetTypes: async function(showLoading = true) {
                this.isLoading = showLoading;
                await this.filterAssetTypes({filter: this.assetTypesFilter,
                                            paginator: this.assetTypesPaginator,
                                            showTotals: true}).then((types) => {
                    this.isLoading = false;
                    this.assetTypes = types.items;
                    this.assetTypesPaginator = types.paginator;
                });
            },
            selectAssetType: async function(type) {
                this.assetType = type;
                this.itemsBoundFilter.binding.assetTypeId = type.id;
                this.itemsAvailableFilter.binding.assetTypeId = type.id;
                await this.fetchFilteredThreats();
                await this.fetchFilteredVulnerabilities();
                await this.changePhase(2);
            },
            fetchFilteredVulnerabilities: async function (showLoading = true) {
                this.isLoading = showLoading;
                await this.filterVulnerabilities({filter: this.filterVuln,
                                        paginator: this.paginatorVuln,
                                        showTotalsForAssetTypeId : this.assetType.id}).then((vulnerabilities) => {
                    this.isLoading = false;
                    this.vulnerabilities = vulnerabilities.items;
                    this.paginatorVuln = vulnerabilities.paginator;
                });
            },
            fetchFilteredThreats: async function (showLoading = true) {
                this.isLoading = showLoading;
                await this.filterThreats({filter: this.filterThreat,
                                        paginator: this.paginatorThreat,
                                        showTotalsForAssetTypeId : this.assetType.id}).then((threats) => {
                    this.isLoading = false;
                    this.threats = threats.items;
                    this.paginatorThreat = threats.paginator;
                });
            },
            fetchFilteredItemsBound: async function (showLoading = true) {
                this.isLoading = showLoading;
                if (this.threat != null) {
                    await this.filterVulnerabilities({filter: this.itemsBoundFilter, paginator: this.itemsBoundPaginator}).then((vulnerabilities) => {
                        this.isLoading = false;
                        this.itemsBound = vulnerabilities.items;
                        this.itemsBoundPaginator = vulnerabilities.paginator;
                    });
                } else if (this.vulnerability != null) {
                    await this.filterThreats({filter: this.itemsBoundFilter, paginator: this.itemsBoundPaginator}).then((threats) => {
                        this.isLoading = false;
                        this.itemsBound = threats.items;
                        this.itemsBoundPaginator = threats.paginator;
                    });
                }
            },
            fetchFilteredItemsAvailable: async function (showLoading = true) {
                this.isLoading = showLoading;
                if (this.threat != null) {
                    await this.filterVulnerabilities({filter: this.itemsAvailableFilter, paginator: this.itemsAvailablePaginator}).then((vulnerabilities) => {
                        this.isLoading = false;
                        this.itemsAvailable = vulnerabilities.items;
                        this.itemsAvailablePaginator = vulnerabilities.paginator;
                    });
                } else if (this.vulnerability != null) {
                    await this.filterThreats({filter: this.itemsAvailableFilter, paginator: this.itemsAvailablePaginator}).then((threats) => {
                        this.isLoading = false;
                        this.itemsAvailable = threats.items;
                        this.itemsAvailablePaginator = threats.paginator;
                    });
                }
            },
            selectThreat: async function(threat) {
                this.threat = threat;
                this.vulnerability = null;
                this.itemsBoundFilter.binding.threatId = threat.id;
                this.itemsAvailableFilter.binding.threatId = threat.id;
                await this.fetchFilteredItemsBound();
                await this.fetchFilteredItemsAvailable();
                await this.changePhase(3);
            },
            selectVulnerability: async function(vuln) {
                this.vulnerability = vuln;
                this.threat = null;
                this.itemsBoundFilter.binding.vulnerabilityId = vuln.id;
                this.itemsAvailableFilter.binding.vulnerabilityId = vuln.id;
                await this.fetchFilteredItemsBound();
                await this.fetchFilteredItemsAvailable();
                await this.changePhase(3);
            },
            ...mapActions('vulnerabilities', {
                filterVulnerabilities: 'filterVulnerabilities',
            }),
            ...mapActions('threats', {
                filterThreats: 'filterThreats',
            }),
            ...mapActions('assetType', {
                filterAssetTypes: 'filterTypes',
            }),
            ...mapActions('threatTags', {
                fetchThreatTags: 'fetchTags'
            }),
            ...mapActions('vulnerabilityTags', {
                fetchVulnerabilityTags: 'fetchTags'
            }),
            ...mapActions('userSettings', {
                fetchIsShowHashIds: 'fetchIsShowHashIds'
            }),
            moveToBound: async function(item) {
                let idx = this.itemsAvailable.indexOf(item);
                if (idx >= 0) {
                    // send request to the server and wait to finish
                    let newBound = {
                        "assetTypeId": this.assetType.id
                    };
                    if (this.threat != null) {
                        newBound.threatId = this.threat.id;
                        newBound.vulnerabilityId = item.id;
                    } else if (this.vulnerability != null) {
                        newBound.vulnerabilityId = this.vulnerability.id;
                        newBound.threatId = item.id;
                    } else {
                        return;
                    }

                    await this.$api.post(`threat-vuln`, newBound).then(({data}) => {
                        if (data.status === 'ok') {
                            if (data.data != null && data.data.id != null) {
                                // only fetch items when paginator is active
                                if (this.itemsBoundPaginator.itemCount >= this.itemsBoundPaginator.perPage) {
                                    this.fetchFilteredItemsBound();
                                } else {
                                    item.bindId = data.data.id;
                                    this.itemsBound.push(item);
                                    this.itemsBound.sort((a, b) => (a.name).localeCompare(b.name));
                                    this.itemsBoundPaginator.itemCount++;
                                }

                                if (this.itemsAvailablePaginator.itemCount > this.itemsAvailablePaginator.perPage) {
                                    this.fetchFilteredItemsAvailable();
                                } else {
                                    this.itemsAvailable.splice(idx, 1);
                                    this.itemsAvailable.sort((a, b) => (a.name).localeCompare(b.name));
                                    this.itemsAvailablePaginator.itemCount--;
                                }
                            } else {
                                this.fetchFilteredItemsBound();
                                this.fetchFilteredItemsAvailable();
                            }

                            this.showSaved();
                        }
                    });
                }

                this.$forceUpdate();
            },
            moveToUnbound: async function(item) {
                let idx = this.itemsBound.indexOf(item);
                if (idx >= 0) {
                    await this.$api.delete(`threat-vuln/${item.bindId}`).then(({data}) => {
                        if (data.status === 'ok') {
                            // only fetch items when paginator is active
                            if (this.itemsBoundPaginator.itemCount > this.itemsBoundPaginator.perPage) {
                                this.fetchFilteredItemsBound();
                            } else {
                                this.itemsBound.splice(idx, 1);
                                this.itemsBound.sort((a, b) => (a.name).localeCompare(b.name));
                                this.itemsBoundPaginator.itemCount--;
                            }

                            if (this.itemsAvailablePaginator.itemCount >= this.itemsAvailablePaginator.perPage) {
                                this.fetchFilteredItemsAvailable();
                            } else {
                                this.itemsAvailable.push(item);
                                this.itemsAvailable.sort((a, b) => (a.name).localeCompare(b.name));
                                this.itemsAvailablePaginator.itemCount++;
                            }

                            this.showDeleted();
                        }
                    });
                }

                this.$forceUpdate();
            },
            cancelFilter: async function(filterType) {
                this.watchFilter = false;

                if (filterType == 'threat') {
                    this.filterThreat.name = null;
                    this.filterThreat.tags = [];
                    await this.fetchFilteredThreats();
                } else if (filterType == 'vuln') {
                    this.filterVuln.name = null;
                    this.filterVuln.tags = [];
                    await this.fetchFilteredVulnerabilities();
                } else if (filterType == 'bound') {
                    this.itemsBoundFilter.name = null;
                    this.itemsBoundFilter.tags = [];
                    await this.fetchFilteredItemsBound();
                } else if (filterType == 'available') {
                    this.itemsAvailableFilter.name = null;
                    this.itemsAvailableFilter.tags = [];
                    await this.fetchFilteredItemsAvailable();
                } else if (filterType == 'assetTypes') {
                    this.assetTypesFilter.name = null;
                    await this.fetchFilteredAssetTypes();
                }

                this.$nextTick(() => {
                    this.watchFilter = true;
                });
            },
            getNameWithHashId: function(item) {
                if(this.isShowHashIds){
                    return `[${item.hashId}] ${item.name}`;
                }
                return item.name;
            },
        },
        notifications: {
            showSaved: {
                message: 'comp.manageThreatVulnRegister.bindingModal.newBindingSaved',
                type: 'success'
            },
            showDeleted: {
                message: 'comp.manageThreatVulnRegister.bindingModal.bindingDeleted',
                type: 'success'
            }
        }
    }
</script>

<style scoped>

div.modal-dialog {
    width: 85%;
}

ul.bind-dialog {
    display: block;
    list-style-type: none;
    padding: 0;
}
hr.clear {
    visibility: hidden;
    clear: both;
    margin: 0;
    padding: 0;
}
ul.bind-dialog .btn {
    white-space: normal;
}
ul.bind-dialog li+li {
    margin-top: 4px;
}
ul.bind-dialog li.btn {
    display: block;
    position: relative;
}

ul.bind-dialog-left.phase-2 li, ul.bind-dialog-right.phase-2 li, ul.bind-dialog-left.phase-1 li {
    padding-right: 10px;
    padding-left: 50px;
    text-align: left;
}
ul.bind-dialog-left.phase-2 li:hover::before, ul.bind-dialog-right.phase-2 li:hover::before, ul.bind-dialog-left.phase-1 li:hover::before {
    position: absolute;
    top: 30%;
    left: 3px;
    content: "vybrat";
    color: black;
    font-size: 10px;
    font-weight: bold;
}

ul.bind-dialog-left li {
    text-align: right;
}
ul.bind-dialog-right li {
    text-align: left;
}

ul.bind-dialog span {
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
}

ul.bind-dialog li span.totals {
    float: right;
    padding: 0;
    font-weight: normal;
    color: grey;
}

ul.bind-dialog-left.phase-3 li:hover::after {
    content: '';
	display: block;
	position: absolute;
	left: 100%;
	top: 20%;
	width: 0;
	height: 0;
	border-left: 10px solid #e08e0b;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid transparent;
}

ul.bind-dialog-right.phase-3 li:hover::before {
    content: '';
	display: block;
	position: absolute;
	right: 100%;
	top: 20%;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid #008d4c;
}

table.table {
    margin-top: 15px;
}
table.table.phase-1 {
    width: 50%;
    float: left;
}


div.modal-body span.active {
    color: black;
    font-weight: bold;
}
div.modal-body span.finished {
    color: grey;
    font-weight: normal;
}
div.modal-body span.delimiter {
    padding: 0 25px;
}
</style>

<style scoped lang="less">
    .multiselect {
        font-size: 12px;
        /deep/ .multiselect__tags {
            min-height: 30px;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }
        /deep/ .multiselect__select {
            height: 30px;
        }
        /deep/ .multiselect__placeholder {
            font-size: 12px;
        }
        /deep/ .multiselect__input {
            font-size: 12px;
        }
        /deep/ .multiselect__tag {
            font-size: 12px;
        }
    }
</style>
