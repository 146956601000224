<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="box box-warning" v-if="getClassification">
          <div class="box-header text-bold">
            {{ $t("comp.assetsDiagram.index.classificatonTitle") }}
          </div>
          <div class="box-body legend">
            <table class="table">
              <tbody>
                <tr
                  v-for="c in getClassification"
                  @click="classificationItemClicked(c)"
                  style="cursor: pointer"
                >
                  <td>
                    <i :class="c.icon" class="fa-fw"></i>
                  </td>
                  <td>
                    {{ c.name }}
                  </td>
                  <td style="width: 20px">
                    <input
                      type="checkbox"
                      v-model="c.hide"
                      v-if="!printMode"
                      :true-value="false"
                      :false-value="true"
                    />
                  </td>
                </tr>
                <tr
                  @click="classificationItemClicked(null)"
                  style="cursor: pointer"
                >
                  <td>
                    <i class="fas fa-times fa-fw"></i>
                  </td>
                  <td>
                    {{ $t("comp.assetsDiagram.index.unclassified") }}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      v-model="showAssetsWithoutClassification"
                      v-if="!printMode"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div v-if="countryLaw == 'CZ'">
                      <a
                        @click.stop="
                          assetSubClassificationFilterChanged('is_kii')
                        "
                        :style="{
                          'text-decoration': assetSubClassificationFilter.is_kii
                            ? null
                            : 'line-through',
                        }"
                        class="btn btn-link btn-xs text-strike no-padding"
                        >{{ $t("enums.assetSubClassification.is_kii") }}</a
                      >
                      /
                      <a
                        @click.stop="
                          assetSubClassificationFilterChanged('ks_kii')
                        "
                        :style="{
                          'text-decoration': assetSubClassificationFilter.ks_kii
                            ? null
                            : 'line-through',
                        }"
                        class="btn btn-link btn-xs no-padding"
                        >{{ $t("enums.assetSubClassification.ks_kii") }}</a
                      >
                      /
                      <a
                        @click.stop="assetSubClassificationFilterChanged('vis')"
                        :style="{
                          'text-decoration': assetSubClassificationFilter.vis
                            ? null
                            : 'line-through',
                        }"
                        class="btn btn-link btn-xs no-padding"
                        >{{ $t("enums.assetSubClassification.vis") }}</a
                      >
                      /
                      <a
                        @click.stop="
                          assetSubClassificationFilterChanged('iszs')
                        "
                        :style="{
                          'text-decoration': assetSubClassificationFilter.iszs
                            ? null
                            : 'line-through',
                        }"
                        class="btn btn-link btn-xs no-padding"
                        >{{ $t("enums.assetSubClassification.iszs") }}</a
                      >
                    </div>

                    <div v-if="countryLaw == 'SK'">
                      <a
                        @click.stop="
                          assetSubClassificationFilterChanged('category1')
                        "
                        :style="{
                          'text-decoration':
                            assetSubClassificationFilter.category1
                              ? null
                              : 'line-through',
                        }"
                        class="btn btn-link btn-xs no-padding"
                        >{{ $t("enums.assetSubClassification.category1") }}</a
                      >
                      /
                      <a
                        @click.stop="
                          assetSubClassificationFilterChanged('category2')
                        "
                        :style="{
                          'text-decoration':
                            assetSubClassificationFilter.category2
                              ? null
                              : 'line-through',
                        }"
                        class="btn btn-link btn-xs no-padding"
                        >{{ $t("enums.assetSubClassification.category2") }}</a
                      >
                      /
                      <a
                        @click.stop="
                          assetSubClassificationFilterChanged('category3')
                        "
                        :style="{
                          'text-decoration':
                            assetSubClassificationFilter.category3
                              ? null
                              : 'line-through',
                        }"
                        class="btn btn-link btn-xs no-padding"
                        >{{ $t("enums.assetSubClassification.category3") }}</a
                      >
                      /
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="box box-warning">
          <div class="box-header text-bold">
            {{ $t("comp.assetsDiagram.index.tagsTitle") }}
          </div>
          <div class="box-body legend" style="overflow: initial">
            <multiselect
              v-model="assetTagsFilter"
              :options="getAllTags.map((tag) => tag.id)"
              :custom-label="(opt) => getAllTags.find((x) => x.id == opt).name"
              :multiple="true"
              :taggable="false"
              :closeOnSelect="false"
              @input="changeTagIdsSelected(assetTagsFilter)"
            >
            </multiselect>
          </div>
        </div>

        <div class="box box-warning" v-if="riskClassification">
          <div class="box-header text-bold">
            {{ $t("comp.assetsDiagram.index.riskClassificationTitle") }}
          </div>
          <div class="box-body legend">
            <table class="table">
              <tbody>
                <tr v-for="item in riskClassification.items">
                  <td>
                    <i
                      class="fas fa-circle fa-fw"
                      :style="{ color: item.color }"
                    ></i>
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i class="fas fa-circle fa-fw"></i>
                  </td>
                  <td>
                    {{ $t("comp.assetsDiagram.index.notRated") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box box-warning" v-if="!printMode && aloneNodes.length">
          <div class="box-header text-bold">
            {{ $t("comp.assetsDiagram.index.unboundAssetsTitle") }}
          </div>
          <div class="box-body legend">
            <table class="table">
              <tbody>
                <tr v-for="node in aloneNodes">
                  <td>
                    <i
                      v-if="getIconByClassification(node.classificationId)"
                      :class="
                        getIconByClassification(node.classificationId).icon
                      "
                    ></i>
                  </td>
                  <td>
                    <a :href="node.link" target="_blank">
                      <span v-if="isShowHashIds">[{{ node.hashId }}]</span>
                      {{ node.name }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="box box-default">
          <div class="box-body" id="chart">
            <svg
              width="100%"
              :height="this.printMode ? '100%' : '650px'"
              ref="diagram"
            ></svg>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
  </div>
</template>

<script>
import "diagram-vue/dist/diagram.css";
import Multiselect from "../../Controls/CsaMultiSelect.vue";
import { DiagramEditor } from "diagram-vue";
import { mapActions, mapGetters } from "vuex";
import { fa400, fa900 } from "./fa.js";

export default {
  components: {
    DiagramEditor,
    Multiselect,
  },
  props: {
    assetId: {
      type: String,
    },
    width: {
      type: Number,
      default: 1200,
    },
    height: {
      type: Number,
      default: 800,
    },
    legend: {
      type: Boolean,
      default: true,
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
    printMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      loaded: false,
      nodes: [],
      aloneNodes: [],
      linkedNodes: [],
      links: [],
      tagIds: [],

      diagram: null,
      svg: null,
      riskClassification: null,
      classificationLegend: null,
      riskLegend: null,
      zoom: null,
      simulation: null,
      highlighted: false,
      showAssetsWithoutClassification: true,
      forceProperties: {
        center: {
          x: 0.5,
          y: 0.5,
        },
        charge: {
          enabled: true,
          strength: 1,
          distanceMin: 0,
          distanceMax: 100,
        },
        collide: {
          enabled: true,
          strength: 0.4,
          iterations: 3,
          radius: 120,
        },
        forceX: {
          enabled: true,
          strength: 0.1,
          x: 0.5,
        },
        forceY: {
          enabled: true,
          strength: 0.35,
          y: 0.5,
        },
        link: {
          enabled: true,
          distance: 50,
          iterations: 1,
        },
      },
      assetSubClassificationFilter: {
        is_kii: true,
        ks_kii: true,
        vis: true,
        iszs: true,
        category1: true,
        category2: true,
        category3: true,
      },
      assetTagsFilter: [],
    };
  },
  computed: {
    ...mapGetters("assetClassification", {
      getClassification: "getClassification",
      getClassificationById: "getItemById",
    }),
    ...mapGetters("assetType", {
      getTypes: "getTypes",
      getTypeById: "getTypeById",
    }),
    ...mapGetters("assetTags", {
      getAllTags: "getAll",
    }),
    ...mapGetters("userSettings", {
      isShowHashIds: "isShowHashIds",
    }),
    ...mapGetters("moduleConfig", {
      countryLaw: "countryLaw",
    }),
    assetClassification() {
      return this.getClassification.concat([
        {
          id: null,
          name: this.$t("comp.assetsDiagram.index.unclassified"),
        },
      ]);
    },
  },
  async mounted() {
    await this.fetchIsShowHashIds();
    await this.fetchCountryLaw();
    await this.fetchAssetTags();

    this.svg = d3.select(this.$refs["diagram"]);
    this.diagram = this.svg.append("g");

    await this.fetchClassification().then((items) => {
      this.getClassification.forEach((i) => {
        this.$set(i, "hide", false);
      });
    });

    await this.fetchTypes();

    // fetch risk classification
    await this.$api("config/default-risk-classification").then(
      async ({ data }) => {
        let id = data.data.id;
        await this.$api("risk-classification/" + id).then(({ data }) => {
          this.riskClassification = data.data;
        });
      }
    );

    await this.$api("asset/assets-for-dependency-diagram", {
      params: {
        assetId: this.assetId,
      },
    }).then(({ data }) => {
      this.setData(data.data);

      this.renderDiagram();
    });
  },
  methods: {
    ...mapActions("assetClassification", ["fetchClassification"]),
    ...mapActions("assetType", ["fetchTypes"]),
    ...mapActions("assetTags", { fetchAssetTags: "fetchAssetTags" }),
    ...mapActions("userSettings", {
      fetchIsShowHashIds: "fetchIsShowHashIds",
    }),
    ...mapActions("moduleConfig", { fetchCountryLaw: "fetchCountryLaw" }),
    renderDiagram(simulation = true) {
      let svg = this.svg;
      let diagram = this.diagram;

      //Add zoom and panning triggers
      this.zoom = d3
        .zoom()
        .scaleExtent([1 / 4, 4])
        .on("zoom", this.zoomed);

      svg.call(this.zoom);

      // Define the arrow marker
      let def = svg
        .append("svg:defs")
        .selectAll("marker")
        .data(["end"]) // Different link/path types can be defined here
        .enter();

      def
        .append("svg:marker") // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 38) // Prevents arrowhead from being covered by circle
        .attr("refY", 0)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5");

      //font awesome
      def
        .append("svg:style") // This section adds in the arrows
        .text(
          " @font-face {\n" +
            "\n" +
            "  font-family: 'FA_DIAGRAM';\n" +
            "  font-style: normal;\n" +
            "  font-weight: 400;\n" +
            "  font-display: auto;\n" +
            "          src: url(data:font/truetype;base64," +
            fa400 +
            ") format('truetype');\n" +
            "        }" +
            "@font-face {\n" +
            "  font-family: 'FA_DIAGRAM';\n" +
            "  font-display: auto;\n" +
            "          src: url(data:font/truetype;base64," +
            fa900 +
            ") format('truetype');\n" +
            "        }" +
            "  font-style: normal;\n" +
            "  font-weight: 900;\n" +
            "  font-display: auto;}"
        );

      //links
      diagram
        .selectAll(".link")
        .data(this.links)
        .enter()
        .append("path")
        .attr("class", "link")
        .attr("stroke", "#666")
        .attr("stroke-width", "1.5px")
        .attr("visibility", (d) => {
          return window.visible;
        });

      let nodes = diagram
        .selectAll(".asset-node")
        .data(this.nodes)
        .enter()
        .append("g")
        .attr("id", function (d, i) {
          return "asset_" + i;
        })
        .attr("class", "asset-node")
        .append("a")
        .attr("href", (d) => d.link)
        .on("mouseover", this.assetNodeMouseOver)
        .on("mouseout", this.assetNodeMouseOut)
        .on("contextmenu", this.keepHighlightedRelation);

      nodes
        .append("circle")
        .attr("r", 25)
        .attr("fill", (d) => d.risk.color)
        .attr("stroke-width", (d) => {
          if (this.assetId === d.id) {
            return "5px";
          }

          return "2.5px";
        })
        .attr("stroke", "#191900");

      nodes
        .append("circle")
        .attr("r", 5)
        .attr("cx", 0)
        .attr("cy", -25)
        .attr("fill", (d) => (d.filledPercentage === 100 ? "green" : "red"))
        .attr("stroke", "#191900")
        .attr("stroke-width", "1px");

      nodes
        .append("text")
        .attr("x", 0)
        .attr("y", 6)
        .attr("fill", "white")
        .attr("text-anchor", "middle")
        .attr("font-family", "FA_DIAGRAM")
        .attr("font-size", "14pt")
        .attr("font-weight", "400")
        .text((d) => {
          if (d.classificationId) {
            let type = this.getClassificationById(d.classificationId);
            return this.getIcon(type);
          }

          return this.getIcon(null);
        });

      nodes
        .append("text")
        .attr("x", 20)
        .attr("y", -25)
        .attr("fill", "black")
        .attr("text-anchor", "left")
        .attr("font-size", "8pt")
        .text((d) => {
          if (d.assetSubClassification) {
            switch (d.assetSubClassification) {
              case "is_kii":
                return this.$t("enums.assetSubClassification.is_kii");
              case "ks_kii":
                return this.$t("enums.assetSubClassification.ks_kii");
              case "vis":
                return this.$t("enums.assetSubClassification.vis");
              case "iszs":
                return this.$t("enums.assetSubClassification.iszs");
              case "category1":
                return this.$t("enums.assetSubClassification.category1");
              case "category2":
                return this.$t("enums.assetSubClassification.category2");
              case "category3":
                return this.$t("enums.assetSubClassification.category3");
            }
          } else if (d.typeId) {
            let assetType = this.getTypeById(d.typeId);

            if (assetType != null) {
              return assetType.name;
            } else return;
          } else if (d.tagId) return;
        });

      if (this.isShowHashIds) {
        nodes
          .append("text")
          .text((d) => `[${d.hashId}] ${d.name}`)
          .attr("text-anchor", "middle")
          .attr("x", "0")
          .attr("y", 40);
      } else {
        nodes
          .append("text")
          .text((d) => d.name)
          .attr("text-anchor", "middle")
          .attr("x", "0")
          .attr("y", 40);
      }

      if (!simulation) {
        return;
      }

      this.startSimulation();

      /*if(this.legend) {
                    this.renderClassificationLegend()
                    this.renderRiskLegend()
                }*/
    },
    redraw() {
      this.svg.selectAll(".link").attr("visibility", (d) => {
        let source_hidden = !this.isAssetNodeVisible(d.source);
        let target_hidden = !this.isAssetNodeVisible(d.target);

        return source_hidden || target_hidden ? "hidden" : "visible";
      });

      this.svg.selectAll(".asset-node").attr("visibility", (d) => {
        return this.isAssetNodeVisible(d) ? "visible" : "hidden";
      });

      this.svg.selectAll(".classificationItem").attr("class", (d) => {
        return d.hide === true
          ? "inactive classificationItem"
          : "classificationItem";
      });
    },
    startSimulation() {
      let simulation = d3
        .forceSimulation()
        .force("link", d3.forceLink())
        .force("charge", d3.forceManyBody())
        .force("collide", d3.forceCollide())
        .force("center", d3.forceCenter())
        .force("forceX", d3.forceX())
        .force("forceY", d3.forceY())
        .alphaDecay(0.5)
        .on("tick", this.tick)
        .on("end", () => {
          this.loaded = true;

          if (this.printMode) {
            this.printView();
          }
        });

      simulation.nodes(this.nodes);
      simulation.force("link").links(this.links);

      this.diagram
        .selectAll(".link")
        .attr("marker-start", function (d, i) {
          return "url(#start)";
        })
        .attr("marker-end", function (d, i) {
          return "url(#end)";
        });
      //.attr('marker-end', function(d,i){ return 'url(#asset_' + d.sub  + ')' })
      /*.attr("marker-end", d => {
                        // Caption items doesn't have source and target
                        if (d.source && d.target &&
                            d.source.index === d.target.index) return "url(#end-self)";
                        else return "url(#end)";
                    });*/

      const { forceProperties, width, height } = this;

      simulation
        .force("center")
        .x(width * forceProperties.center.x)
        .y(height * forceProperties.center.y);
      simulation
        .force("charge")
        .strength(
          forceProperties.charge.strength * forceProperties.charge.enabled
        )
        .distanceMin(forceProperties.charge.distanceMin)
        .distanceMax(forceProperties.charge.distanceMax);
      simulation
        .force("collide")
        .strength(
          forceProperties.collide.strength * forceProperties.collide.enabled
        )
        .radius(forceProperties.collide.radius)
        .iterations(forceProperties.collide.iterations);
      simulation
        .force("forceX")
        .strength(
          forceProperties.forceX.strength * forceProperties.forceX.enabled
        )
        .x(width * forceProperties.forceX.x);
      simulation
        .force("forceY")
        .strength(
          forceProperties.forceY.strength * forceProperties.forceY.enabled
        )
        .y(height * forceProperties.forceY.y);
      simulation
        .force("link")
        .distance(forceProperties.link.distance)
        .iterations(forceProperties.link.iterations);

      this.simulation = simulation;
    },
    tick() {
      let svg = this.diagram;

      const transform = (d) => {
        return "translate(" + d.x + "," + d.y + ")";
      };

      const link = (d) => {
        return (
          "M" +
          d.source.x +
          "," +
          d.source.y +
          " L" +
          d.target.x +
          "," +
          d.target.y
        );
      };

      svg.selectAll(".asset-node").attr("transform", transform);
      svg.selectAll(".link").attr("d", link);
    },
    zoomed() {
      const transform = d3.event.transform;

      this.diagram.attr("transform", transform);

      // Define some world boundaries based on the graph total size
      // so we don't scroll indefinitely
      const graphBox = this.diagram.node().getBBox();
      const margin = 1000;
      const worldTopLeft = [graphBox.x - margin, graphBox.y - margin];
      const worldBottomRight = [
        graphBox.x + graphBox.width + margin,
        graphBox.y + graphBox.height + margin,
      ];
      this.zoom.translateExtent([worldTopLeft, worldBottomRight]);
    },
    renderClassificationLegend() {
      // WARNING: Some gross math will happen here!
      const lineHeight = 40;
      const lineMiddle = lineHeight / 2;
      const captionXPadding = 15;
      const captionYPadding = 40;

      this.classificationLegend = this.svg
        .append("g")
        .attr("class", "classificationLegend");

      this.classificationLegend
        .append("rect")
        .attr("fill", "#CCCCCCAC")
        .attr("stroke", "#666")
        .attr("stroke-width", "1px")
        .attr("width", "250")
        .attr("height", lineHeight * this.assetClassification.length + 20)
        .attr("y", 30)
        .attr("rx", "10")
        .attr("ry", "10");

      this.classificationLegend
        .append("text")
        .attr("x", 0)
        .attr("y", 20)
        .attr("text-anchor", "start")
        .text("Druhy aktiv");

      let items = this.classificationLegend
        .selectAll(".classificationItem")
        .data(this.assetClassification)
        .enter()
        .append("g")
        .attr("class", "classificationItem")
        .on("click", this.classificationItemClicked);

      items
        .append("text")
        .attr("x", captionXPadding + 40)
        .attr(
          "y",
          (c) =>
            captionYPadding +
            (lineMiddle + 5) +
            lineHeight * this.assetClassification.indexOf(c)
        )
        .text((c) => c.name);

      items
        .append("text")
        .attr("font-family", "FA_DIAGRAM")
        .attr("font-size", "14pt")
        .attr("font-weight", "400")
        .text((type) => {
          return this.getIcon(type);
        })
        .attr("x", captionXPadding - 2)
        .attr(
          "y",
          (d) =>
            5 +
            captionYPadding +
            lineMiddle +
            lineHeight * this.assetClassification.indexOf(d)
        );
    },
    renderRiskLegend() {
      // WARNING: Some gross math will happen here!
      const lineHeight = 40;
      const lineMiddle = lineHeight / 2;
      const captionXPadding = 15;
      const captionYPadding = 30;
      const heightOfClassificationLegend = parseInt(
        this.classificationLegend.node().getBBox().height
      );

      this.riskLegend = this.svg
        .append("g")
        .attr("class", "riskLegend")
        .attr(
          "transform",
          "translate(0, " + (heightOfClassificationLegend + 40) + ")"
        )
        .attr("width", "250")
        .attr(
          "height",
          captionYPadding + lineHeight * this.riskClassification.items.length
        )
        .attr(
          "transform",
          "translate(0, " + (heightOfClassificationLegend + 40) + ")"
        );

      this.riskLegend
        .append("rect")
        .attr("fill", "#CCCCCCAC")
        .attr("stroke", "#666")
        .attr("stroke-width", "1px")
        .attr("width", "250")
        .attr(
          "height",
          captionYPadding + lineHeight * this.riskClassification.items.length
        )
        .attr("transform", "translate(0, 20)")
        .attr("rx", "10")
        .attr("ry", "10");

      this.riskLegend
        .append("text")
        .attr("x", 0)
        .attr("transform", "translate(0, 10)")
        .attr("text-anchor", "start")
        .text("Klasifikace rizik");

      let items = this.riskLegend
        .selectAll(".riskClassificationItem")
        .data(this.riskClassification.items)
        .enter()
        .append("g")
        .attr("transform", (c) => {
          let y =
            captionYPadding +
            (lineMiddle + 5) +
            lineHeight * this.riskClassification.items.indexOf(c);

          return "translate(0, " + y + ")";
        })
        .attr("class", "riskClassificationItem");
      //.on('click', this.classificationItemClicked)

      items
        .append("text")
        .attr("x", captionXPadding + 40)
        .text((c) => c.name);

      items
        .append("circle")
        .attr("r", 10)
        .attr("fill", (t) => t.color)
        .attr("stroke-width", "1pt")
        .attr("stroke", "black")
        .attr("cx", 20)
        .attr("cy", -5);
    },
    getIcon(type) {
      if (type == null) {
        return "\uf00d";
      } else if (type.icon === "fas fa-crown") {
        return "\uf521";
      } else if (type.icon === "fas fa-hands-helping") {
        return "\uf4c4";
      } else if (type.icon === "fas fa-cogs") {
        return "\uf085";
      } else if (type.icon === "fas fa-user") {
        return "\uf007";
      } else {
        return "\uf00d";
      }
    },
    getIconByClassification(id) {
      let type = this.getClassificationById(id);

      return type;
    },
    classificationItemClicked(item) {
      if (!item) {
        this.showAssetsWithoutClassification =
          !this.showAssetsWithoutClassification;
        this.redraw();
        return;
      }

      if (item.hide === undefined || item.hide === false) {
        this.$set(item, "hide", true);
      } else {
        this.$set(item, "hide", false);
      }

      this.redraw();
    },
    changeTagIdsSelected(value) {
      this.tagIds = value;
      this.redraw();
    },
    printView() {
      // let saveSvgAsPng = require('save-svg-as-png');
      //
      // saveSvgAsPng.saveSvgAsPng(this.svg.node(), "Mapa aktiv.png", {
      //     scale: 1,
      //     backgroundColor: '#ffffff'
      // });

      const svg = this.svg.node().cloneNode(true); // clone your original svg
      document.getElementById("chart").innerHTML = "";
      document.getElementById("chart").appendChild(svg); // append element to document
      const g = svg.querySelector("g"); // select the parent g

      svg.setAttribute("style", "");
      svg.setAttribute("transform", ""); // clean transform
      g.setAttribute("transform", ""); // clean transform
      svg.setAttribute("width", g.getBBox().width); // set svg to be the g dimensions
      svg.setAttribute("height", g.getBBox().height);
      svg.setAttribute(
        "viewBox",
        g.getBBox().x +
          "  " +
          g.getBBox().y +
          " " +
          g.getBBox().width +
          "  " +
          g.getBBox().height
      );
      svg.setAttribute("preserveAspectRatio", "none");
    },
    download() {
      this.isLoading = true;

      this.$api
        .get("asset/export-dependency-map", {
          params: {
            filter: this.filterAnswers,
          },
          responseType: "blob", // important
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.$t("comp.assetsDiagram.index.downloadFileName")
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    assetNodeMouseOver(d) {
      if (this.highlighted) {
        return;
      }

      const related = [];
      const relatedLinks = [];
      const assetNodes = this.diagram.selectAll(".asset-node");
      const links = this.diagram.selectAll(".link");

      related.push(d);

      this.simulation
        .force("link")
        .links()
        .forEach((link) => {
          if (link.source === d || link.target === d) {
            relatedLinks.push(link);
            if (related.indexOf(link.source) === -1) {
              related.push(link.source);
            }
            if (related.indexOf(link.target) === -1) {
              related.push(link.target);
            }
          }
        });

      assetNodes.classed("faded", true);
      assetNodes
        .filter((df) => related.indexOf(df) > -1)
        .classed("highlight", true);

      links.classed("faded", true);
      links
        .filter((df) => df.source === d || df.target === d)
        .classed("highlight", true);
    },
    assetNodeMouseOut(d) {
      if (this.highlighted) {
        return;
      }

      const assetNodes = this.diagram.selectAll(".asset-node");
      assetNodes.classed("faded", false);
      assetNodes.classed("highlight", false);

      const links = this.diagram.selectAll(".link");
      links.classed("faded", false);
      links.classed("highlight", false);
    },
    keepHighlightedRelation(d) {
      d3.event.preventDefault();

      const assetNodes = this.diagram.selectAll(".asset-node");
      const links = this.diagram.selectAll(".link");

      if (this.highlighted) {
        assetNodes.classed("faded", false);
        assetNodes.classed("highlight", false);
        links.classed("faded", false);
        links.classed("highlight", false);

        if (this.highlighted === d) {
          this.highlighted = false;
        } else {
          this.highlighted = false;
          this.assetNodeMouseOver(d);
          this.highlighted = d;
        }
      } else {
        this.assetNodeMouseOver(d);
        this.highlighted = d;
      }
    },
    assetSubClassificationFilterChanged(t) {
      this.$set(
        this.assetSubClassificationFilter,
        t,
        !this.assetSubClassificationFilter[t]
      );
      this.redraw();
    },
    isAssetNodeVisible(node) {
      let hide;
      if (node.classificationId) {
        let type = this.getClassificationById(node.classificationId);

        if (!type) {
          return true;
        }

        hide = type.hide;
      } else {
        hide = !this.showAssetsWithoutClassification;
      }

      if (node.assetSubClassification && !hide) {
        if (
          !this.assetSubClassificationFilter.ks_kii &&
          node.assetSubClassification === "ks_kii"
        ) {
          hide = true;
        } else if (
          !this.assetSubClassificationFilter.is_kii &&
          node.assetSubClassification === "is_kii"
        ) {
          hide = true;
        } else if (
          !this.assetSubClassificationFilter.vis &&
          node.assetSubClassification === "vis"
        ) {
          hide = true;
        } else if (
          !this.assetSubClassificationFilter.iszs &&
          node.assetSubClassification === "iszs"
        ) {
          hide = true;
        } else if (
          !this.assetSubClassificationFilter.category1 &&
          node.assetSubClassification === "category1"
        ) {
          hide = true;
        } else if (
          !this.assetSubClassificationFilter.category2 &&
          node.assetSubClassification === "category2"
        ) {
          hide = true;
        } else if (
          !this.assetSubClassificationFilter.category3 &&
          node.assetSubClassification === "category3"
        ) {
          hide = true;
        }
      }

      if (this.tagIds.length) {
        if (!this.tagIds.some((r) => node.assetTagIds.indexOf(r) >= 0)) {
          hide = true;
        }
      }

      return hide === false;
    },
    setData(data) {
      let nodes = data.nodes;
      let links = data.links;

      this.nodes = nodes.filter((item, index) => {
        return links.find((link) => {
          return (
            (link.source && link.source.id === item.id) ||
            (link.target && link.target.id === item.id) ||
            link.source === index ||
            link.target === index
          );
        });
      });

      this.aloneNodes = nodes.filter((n) => !this.nodes.includes(n));

      this.links = links.map((l) => {
        let s = this.nodes.indexOf(nodes[l.source]);
        let t = this.nodes.indexOf(nodes[l.target]);

        return {
          source: s,
          target: t,
        };
      });
    },
  },
  watch: {},
};
</script>

<style lang="less">
.faded {
  opacity: 0.3;
  transition: 0.3s opacity;
}

.highlight {
  opacity: 1;
}

path.link {
  fill: none;
  stroke: #666;
  stroke-width: 1.5px;
}

circle.asset {
  stroke: #191900;
  stroke-width: 2.5px;
}

text.assetName {
  font: 10px sans-serif;
  pointer-events: none;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;
}

.assetTypeIcon {
  color: #ffffff;
  font-size: 14pt;
  font-weight: 600;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;
}

.classificationLegend,
.riskLegend {
  overflow: visible !important;

  rect {
    overflow: visible;
  }

  .type {
    font-size: 14pt;
    font-weight: 600;
    fill: none;
    stroke: #000;
    stroke-width: 1.5px;
  }
}

.classificationItem {
  cursor: pointer;

  &.inactive {
    fill: #fff;
  }
}

.legend {
  max-height: 250px;
  overflow: auto;

  table {
    tr td:first-child {
      width: 20%;
    }
  }
}
</style>
