<template src="./AssetModal.html"></template>


<script>
import VueRouter from "vue-router";
import { mapGetters, mapActions } from "vuex";

const router = new VueRouter({
    linkExactActiveClass: "active",
    linkActiveClass: "active",
    routes: [
        {
            name: "general",
            path: "/add-edit-asset/:id?/general",
            component: () =>
                import(/* webpackPrefetch: true */ "./tabs/GeneralTab.vue"),
            props: true,
            meta: {
                showModal: true,
            },
        },
        {
            name: "relations",
            path: "/add-edit-asset/:id?/relations",
            component: () =>
                import(/* webpackPrefetch: true */ "./tabs/RelationsTab.vue"),
            props: true,
            meta: {
                showModal: true,
            },
        },
        {
            name: "evaluation",
            path: "/add-edit-asset/:id?/evaluation",
            component: () =>
                import(/* webpackPrefetch: true */ "./tabs/EvaluationTab.vue"),
            props: true,
            meta: {
                showModal: true,
            },
        },
        {
            name: "importanceAssessment",
            path: "/add-edit-asset/:id?/importance-assessment",
            component: () =>
                import(
          /* webpackPrefetch: true */ "./ImportanceAssessmentTab/ImportanceAssessmentTab.vue"
                ),
            props: true,
            meta: {
                showModal: true,
            },
        },
        {
            name: "threats",
            path: "/add-edit-asset/:id?/threats",
            component: () =>
                import(/* webpackPrefetch: true */ "./ThreatsTab/ThreatsTab.vue"),
            props: true,
            meta: {
                showModal: true,
            },
        },
        {
            name: "ways",
            path: "/add-edit-asset/:id?/ways",
            component: () => import(/* webpackPrefetch: true */ "./tabs/WaysTab.vue"),
            props: true,
            meta: {
                showModal: true,
            },
        },
        {
            name: "attributes",
            path: "/add-edit-asset/:id?/own-attributes",
            component: () =>
                import(/* webpackPrefetch: true */ "./AttributesTab/AttributesTab.vue"),
            props: true,
            meta: {
                showModal: true,
            },
        },
    ],
});

export default {
    router,
    data() {
        return {
            asset: null,
            isLoading: false,
            showModal: this.$route.meta.showModal,
            modal: null,
            editMode: false,
            isDuplicated: false,
            translations: {
                titleCreate: "n/a",
                titleEdit: "n/a",
                titleDuplicate: "n/a",
                saveButton: "n/a",
                duplicateButton: "n/a",
                createButton: "n/a",
            },
        };
    },
    async created() {
        this.initAsset();

        this.translations.titleCreate = this.$t(
            "comp.assetModal.threatsTab.assetModal.assetCreation"
        );
        this.translations.titleEdit = this.$t(
            "comp.assetModal.threatsTab.assetModal.assetEdit"
        );
        this.translations.titleDuplicate = this.$t(
            "comp.assetModal.threatsTab.assetModal.assetDuplicate"
        );
        this.translations.saveButton = this.$t("common.save");
        this.translations.duplicateButton = this.$t("common.duplicate");
        this.translations.createButton = this.$t("common.create");

        this.$watch(
            "$route.params.id",
            (id) => {
                this.editMode = id !== undefined && id != null;
                if (this.editMode && this.asset.id == null) {
                    this.fetchDataAndLoadAsset(id);
                }
            },
            { immediate: true }
        );
    },
    async mounted() {
        this.modal = this.$refs.modal;
        if (this.showModal) {
            this.show();
        }
    },
    watch: {
        "$route.meta"({ showModal }) {
            this.showModal = showModal;
        },
        showModal: function (showModal) {
            if (showModal) {
                this.show();
            } else {
                this.cancel();
            }
        },
    },
    computed: {
        ...mapGetters("suppliers", {
            getSupplierById: "getSupplierById",
        }),
        ...mapGetters("guarantor", {
            getGuarantorById: "getById",
        }),
        ...mapGetters("administrator", {
            getAdministratorById: "getById",
        }),
        ...mapGetters("assetClassification", {
            getClassificationById: "getItemById",
        }),
        ...mapGetters("assetScaleItems", {
            getScaleItemById: "getScaleItemById",
        }),
        ...mapGetters("assetTemplate", {
            getTemplateById: "getTemplateById",
        }),
        ...mapGetters("assetType", {
            getTypeById: "getTypeById",
        }),
        ...mapGetters("assetWayOfDispose", {
            getWayOfDisposeById: "getWayOfDisposeById",
        }),
        ...mapGetters("location", {
            getLocationById: "getLocationById",
        }),
        ...mapGetters("asset", {
            getAssetById: "getAssetById",
        }),
        ...mapGetters("assetTags", {
            getAssetTagById: "getById",
        }),
        ...mapGetters("threats", {
            getThreatById: "getById",
        }),
        ...mapGetters("vulnerabilities", {
            getVulnerabilityById: "getById",
        }),
        ...mapGetters("assetOwnAttributes", {
            getAttributeById: "getAttributeById",
        }),
        ...mapGetters("userSettings", {
            isShowHashIds: "isShowHashIds",
        }),
        ...mapGetters("moduleConfig", {
            countryLaw: "countryLaw",
        }),
    },
    methods: {
        ...mapActions("assetTemplate", ["fetchTemplates"]),
        ...mapActions("assetClassification", ["fetchClassification"]),
        ...mapActions("assetType", ["fetchTypes"]),
        ...mapActions("suppliers", ["fetchSuppliers"]),
        ...mapActions("guarantor", ["fetchGuarantors"]),
        ...mapActions("administrator", ["fetchAdministrators"]),
        ...mapActions("assetScaleItems", [
            "fetchIntegrity",
            "fetchAvailability",
            "fetchConfidence",
        ]),
        ...mapActions("assetType", ["fetchTypes"]),
        ...mapActions("assetWayOfDispose", ["fetchWayOfDisposes"]),
        ...mapActions("location", ["fetchLocations"]),
        ...mapActions("asset", ["fetchAssetsOnly"]),
        ...mapActions("assetTags", ["fetchAssetTags"]),
        ...mapActions("threats", ["fetchThreatsWithAssetTypes"]),
        ...mapActions("vulnerabilities", ["fetchVulnerabilities"]),
        ...mapActions("assetOwnAttributes", ["fetchAttributes"]),
        ...mapActions("assetImportanceAssessmentQuestions", ["fetchQuestions"]),
        ...mapActions("userSettings", { fetchIsShowHashIds: "fetchIsShowHashIds" }),
        ...mapActions("moduleConfig", { fetchCountryLaw: "fetchCountryLaw" }),

        async fetchData() {
            this.translations.titleCreate = this.$t(
                "comp.assetModal.threatsTab.assetModal.assetCreation"
            );
            this.translations.titleEdit = this.$t(
                "comp.assetModal.threatsTab.assetModal.assetEdit"
            );
            this.translations.saveButton = this.$t("common.save");
            this.translations.createButton = this.$t("common.create");

            const p1 = this.fetchTemplates();
            const p2 = this.fetchClassification();
            const p3 = this.fetchTypes();
            const p4 = this.fetchSuppliers();
            const p5 = this.fetchGuarantors();
            const p6 = this.fetchAdministrators();
            const p7 = this.fetchIntegrity();
            const p8 = this.fetchAvailability();
            const p9 = this.fetchConfidence();
            const p10 = this.fetchTypes();
            const p11 = this.fetchLocations();
            const p12 = this.fetchWayOfDisposes();
            const p13 = this.fetchAssetsOnly();
            const p14 = this.fetchThreatsWithAssetTypes();
            const p15 = this.fetchVulnerabilities();
            const p16 = this.fetchAttributes();
            const p17 = this.fetchQuestions();
            const p18 = this.fetchIsShowHashIds();
            const p19 = this.fetchCountryLaw();
            const p20 = this.fetchAssetTags();

            await Promise.all([
                p1,
                p2,
                p3,
                p4,
                p5,
                p6,
                p7,
                p8,
                p9,
                p10,
                p11,
                p12,
                p13,
                p14,
                p15,
                p16,
                p17,
                p18,
                p19,
                p20,
            ]);
        },

        open: async function (assetId = null, isDuplicate = false) {
            this.isDuplicated = isDuplicate;
            if (assetId != null) {
                this.editMode = true;
                this.$router.push({
                    name: "general",
                    params: { id: assetId, isDuplicate: this.isDuplicated },
                });
            } else {
                this.editMode = false;
                this.isLoading = true;
                this.$router.push({ name: "general" });

                await this.fetchData();
                this.initAsset();

                this.isLoading = false;
            }
        },
        cancel: function () {
            $(this.modal).modal("hide");
            this.$emit("reload");
            this.$router.push("/").catch((err) => { });
            this.initAsset();
        },
        show: function () {
            $(this.modal).modal({
                backdrop: false,
                keyboard: false,
            });
        },
        create() {
            if (!this.asset.name) {
                this.$router.push({ name: "general" });
                alert(this.$t("comp.createAsset.createAsset.enterAssetNameAlert"));
                return;
            }

            this.isLoading = true;

            let asset = this.packAsset();

            this.$api
                .post("asset", asset)
                .then(() => {
                    this.cancel();
                    this.showCreated();
                    this.isLoading = false;
                })
                .catch(() => {
                    this.showNotCreated();
                    this.isLoading = false;
                });
        },
        duplicate() {
            if (!this.asset.name) {
                this.$router.push({ name: "general" });
                alert(this.$t("comp.createAsset.createAsset.enterAssetNameAlert"));
                return;
            }

            this.isLoading = true;

            let asset = this.packAsset();
            let isDuplicate = this.isDuplicated;

            this.$api
                .post("asset/", asset)
                .then(() => {
                    this.cancel();
                    this.showDuplicated();
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.showSaveError();
                    this.isLoading = false;
                });
        },
        update: async function () {
            this.isLoading = true;

            let asset = this.packAsset();

            await this.$api
                .put("asset/" + this.asset.id, asset)
                .then((response) => {
                    if (response.data.status == "ok") {
                        this.showSaved();
                    } else {
                        this.showSaveError();
                    }

                    this.isLoading = false;
                    this.cancel();
                })
                .catch((err) => {
                    console.error(err);
                    this.showSaveError();
                    this.isLoading = false;
                });
        },
        initAsset() {
            this.asset = {
                id: null,
                hashId: null,
                name: null,
                templateId: null,
                classification: null,
                assetSubClassification: null,
                type: null,
                supplier: null,
                practitioner: null,
                practitionerPercentage: 0,
                location: null,
                wayOfDispose: null,
                description: null,
                guarantor: null,
                administrator: null,
                evaluation: {
                    integrity: null,
                    availability: null,
                    confidence: null,
                },
                rulesOfProtection: null,
                threats: [],
                rulesForManipulation: null,
                methodsOfUsage: null,
                ownAttributes: [],
                importanceAssessmentAnswers: [],
                dependingAssetIdsUp: [],
                dependingAssetIdsDown: [],
                assetTags: [],
                accepted: false,
            };
        },
        fetchDataAndLoadAsset: async function (assetId) {
            this.isLoading = true;

            await this.fetchData();
            this.initAsset();

            await this.$api
                .get("asset/fetch-asset/" + assetId)
                .then((data) => {
                    let fetchedAsset = data.data.data;

                    if (typeof assetId == "string") {
                        this.asset.id = parseInt(assetId, 10);
                    } else {
                        this.asset.id = assetId;
                    }

                    this.asset.hashId = fetchedAsset.hashId;
                    this.asset.name =
                        this.isDuplicated == false
                            ? fetchedAsset.name
                            : fetchedAsset.name +
                            this.$t(
                                "comp.assetModal.threatsTab.assetModal.endOfDuplicateName"
                            );
                    this.asset.description = fetchedAsset.description;
                    this.asset.practitionerPercentage =
                        fetchedAsset.practitionerPercentage;

                    this.asset.assetSubClassification =
                        fetchedAsset.assetSubClassification;

                    if (fetchedAsset.classificationId) {
                        this.asset.classification = this.getClassificationById(
                            fetchedAsset.classificationId
                        );
                    }
                    if (fetchedAsset.templateId) {
                        this.asset.template = this.getTemplateById(fetchedAsset.templateId);
                    }
                    if (fetchedAsset.typeId) {
                        this.asset.type = this.getTypeById(fetchedAsset.typeId);
                    }
                    if (fetchedAsset.supplierId) {
                        this.asset.supplier = this.getSupplierById(fetchedAsset.supplierId);
                    }
                    if (fetchedAsset.guarantorId) {
                        this.asset.guarantor = this.getGuarantorById(
                            fetchedAsset.guarantorId
                        );
                    }
                    if (fetchedAsset.administratorId) {
                        this.asset.administrator = this.getAdministratorById(
                            fetchedAsset.administratorId
                        );
                    }
                    if (fetchedAsset.practitionerId) {
                        this.asset.practitioner = this.getSupplierById(
                            fetchedAsset.practitionerId
                        );
                    }
                    if (fetchedAsset.locationId) {
                        this.asset.location = this.getLocationById(fetchedAsset.locationId);
                    }
                    if (fetchedAsset.wayOfDisposeId) {
                        this.asset.wayOfDispose = this.getWayOfDisposeById(
                            fetchedAsset.wayOfDisposeId
                        );
                    }

                    this.asset.rulesOfProtection = fetchedAsset.rulesOfProtection;
                    this.asset.rulesForManipulation = fetchedAsset.rulesForManipulation;
                    this.asset.methodsOfUsage = fetchedAsset.methodsOfUsage;
                    this.asset.accepted = fetchedAsset.accepted;

                    this.asset.isEvaluationDisabled = fetchedAsset.isEvaluationDisabled;
                    if (fetchedAsset.confidenceId) {
                        this.asset.evaluation.confidence = this.getScaleItemById(
                            fetchedAsset.confidenceId
                        );
                    }
                    if (fetchedAsset.integrityId) {
                        this.asset.evaluation.integrity = this.getScaleItemById(
                            fetchedAsset.integrityId
                        );
                    }
                    if (fetchedAsset.availabilityId) {
                        this.asset.evaluation.availability = this.getScaleItemById(
                            fetchedAsset.availabilityId
                        );
                    }

                    if (fetchedAsset.answers) {
                        for (var i = 0; i < fetchedAsset.answers.length; i++) {
                            let a = JSON.parse(fetchedAsset.answers[i]);
                            this.asset.importanceAssessmentAnswers.push({
                                questionId: a.id,
                                value: a.value,
                            });
                        }
                    }

                    if (fetchedAsset.assetsUp) {
                        this.asset.dependingAssetIdsUp = fetchedAsset.assetsUp;
                    }

                    if (fetchedAsset.assetsDown) {
                        this.asset.dependingAssetIdsDown = fetchedAsset.assetsDown;
                    }

                    if (fetchedAsset.assetTags) {
                        for (var i = 0; i < fetchedAsset.assetTags.length; i++) {
                            let a = this.getAssetTagById(fetchedAsset.assetTags[i]);
                            if (a !== undefined && a != null) {
                                this.asset.assetTags.push(a);
                            }
                        }
                    }

                    if (fetchedAsset.threats) {
                        for (var i = 0; i < fetchedAsset.threats.length; i++) {
                            let t = JSON.parse(fetchedAsset.threats[i]);
                            let currThreat = this.asset.threats.find(
                                (x) => x.threat.id == t.threat
                            ); // Get already processed threat or null

                            // Create new if not exists
                            if (!currThreat) {
                                let threat = this.getThreatById(t.threat);
                                if (!threat) {
                                    continue;
                                }

                                currThreat = { threat: threat, vulnerabilities: [] };
                                this.asset.threats.push(currThreat);
                            }

                            if (!currThreat.vulnerabilities.some((x) => x.id == t.vuln)) {
                                let vuln = this.getVulnerabilityById(t.vuln);
                                if (vuln) {
                                    currThreat.vulnerabilities.push(vuln);
                                }
                            }
                        }
                    }

                    if (fetchedAsset.ownAttr) {
                        for (var i = 0; i < fetchedAsset.ownAttr.length; i++) {
                            let oa = JSON.parse(fetchedAsset.ownAttr[i]);

                            this.asset.ownAttributes.push({
                                attributeId: oa.id,
                                value: oa.value,
                            });
                        }
                    }

                    // now load threats per asset type
                    if (this.asset.type) {
                        this.fetchThreatsWithAssetTypes(this.asset.type);
                    }

                    this.isLoading = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.showLoadError();
                    this.isLoading = false;
                    this.cancel();
                });
        },
        packAsset: function () {
            let asset = Object.assign({}, this.asset);

            delete asset.id;

            asset.templateId = asset.template ? asset.template.id : null;
            delete asset.template;

            asset.classificationId = asset.classification
                ? asset.classification.id
                : null;
            delete asset.classification;

            asset.typeId = asset.type ? asset.type.id : null;
            delete asset.type;

            asset.locationId = asset.location ? asset.location.id : null;
            delete asset.location;

            asset.supplierId = asset.supplier ? asset.supplier.id : null;
            delete asset.supplier;

            asset.practitionerId = asset.practitioner ? asset.practitioner.id : null;
            delete asset.practitioner;

            asset.wayOfDisposeId = asset.wayOfDispose ? asset.wayOfDispose.id : null;
            delete asset.wayOfDispose;

            asset.evaluation.integrityId = asset.evaluation.integrity
                ? asset.evaluation.integrity.id
                : null;
            delete asset.evaluation.integrity;

            asset.evaluation.availabilityId = asset.evaluation.availability
                ? asset.evaluation.availability.id
                : null;
            delete asset.evaluation.availability;

            asset.evaluation.confidenceId = asset.evaluation.confidence
                ? asset.evaluation.confidence.id
                : null;
            delete asset.evaluation.confidence;

            asset.guarantorId = asset.guarantor ? asset.guarantor.id : null;
            delete asset.guarantor;

            asset.administratorId = asset.administrator
                ? asset.administrator.id
                : null;
            delete asset.administrator;

            let transformed = [];
            asset.threats.forEach((selectedThreat) => {
                transformed.push({
                    threatId: selectedThreat.threat.id,
                    vulnerabilityIds: selectedThreat.vulnerabilities.map((v) => {
                        return v.id;
                    }),
                });
            });
            asset.threats = transformed;

            asset.dependingAssetIdsDown = this.asset.dependingAssetIdsDown;

            asset.dependingAssetIdsUp = this.asset.dependingAssetIdsUp;

            asset.assetTagIds = this.asset.assetTags.map((a) => {
                return a.id;
            });
            delete asset.assetTags;

            return asset;
        },
    },
    notifications: {
        showCreated: {
            message: "comp.createAsset.createAsset.assetCreated",
            type: "success",
        },
        showDuplicated: {
            message: "comp.createAsset.createAsset.assetDuplicated",
            type: "success",
        },
        showSaved: {
            message: "comp.assetModal.notifications.assetSaved",
            type: "success",
        },
        showSaveError: {
            message: "comp.assetModal.notifications.assetNotSaved",
            type: "error",
        },
        showLoadError: {
            message: "comp.assetModal.notifications.loadError",
            type: "error",
        },
        showNotCreated: {
            message: "comp.createAsset.createAsset.assetNotCreated",
            type: "error",
        },
    },
};
</script>

<style>
.control {
    margin-top: 5px;
}
</style>
