<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-btn input-group-lg">
                            <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle btn-lg"
                                    data-toggle="dropdown">
                                <template v-if="asset.template">
                                    {{ asset.template.name }}
                                </template>
                                <template v-else>
                                    {{
                                        $t("comp.assetModal.tabs.generalTab.selectAssetTemplate")
                                    }}
                                </template>
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <a @click="selectTemplate(null)">-</a>
                                </li>
                                <li v-for="template in templates" :key="template.id">
                                    <a @click="selectTemplate(template)">{{ template.name }}</a>
                                </li>
                            </ul>
                        </div>
                        <input
                               type="text"
                               class="form-control name input-lg"
                               :placeholder="$t('comp.assetModal.tabs.generalTab.namePlaceholder')
                                   "
                               v-model="asset.name" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt2">
            <div class="col-md-12">
                <asset-tags-control
                                    v-model="asset.assetTags"
                                    :value="asset.assetTags"></asset-tags-control>
            </div>
        </div>
        <div class="row mt2">
            <div class="col-md-6">
                <classification-control
                                        v-model="asset.classification"
                                        :value="asset.classification"
                                        :isCreating="asset.id == null" />
                <asset-sub-classification
                                          v-model="asset.assetSubClassification"
                                          :value="asset.assetSubClassification"
                                          :countryLaw="countryLaw" />
            </div>
            <div class="col-md-6">
                <type-control v-model="asset.type" :value="asset.type" />
            </div>
        </div>
        <div class="row mt2">
            <div class="col-md-6">
                <way-of-dispose-control
                                        v-model="asset.wayOfDispose"
                                        :value="asset.wayOfDispose" />
            </div>
            <div class="col-md-6">
                <location-control v-model="asset.location" :value="asset.location" />
            </div>
        </div>
        <div class="row mt2">
            <div class="col-md-6">
                <guarantor-control v-model="asset.guarantor" :value="asset.guarantor" />
            </div>
            <div class="col-md-6">
                <administrator-control
                                       v-model="asset.administrator"
                                       :value="asset.administrator" />
            </div>
        </div>
        <div class="row mt3">
            <div class="col-md-6">
                <supplier-control v-model="asset.supplier" :value="asset.supplier" />
            </div>
            <div class="col-md-4">
                <practitioner-control
                                      v-model="asset.practitioner"
                                      :value="asset.practitioner" />
            </div>
            <div class="col-md-2">
                <practitioner-percentage-control
                                                 v-model="asset.practitionerPercentage"
                                                 :disabled="asset.practitioner === null && asset.practitionerId == null
                                                     " />
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
            <div class="col-md-12 control">
                <label for="description">{{
                    $t("comp.assetModal.tabs.generalTab.description")
                }}</label>
                <ckeditor
                          :editor="editor"
                          v-model="asset.description"
                          :config="editorConfig"
                          id="description"></ckeditor>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>


<script>
import ClassificationControl from "../Controls/Classification.vue";
import TypeControl from "../Controls/Type.vue";
import WayOfDisposeControl from "../Controls/WayOfDispose.vue";
import LocationControl from "../Controls/Location.vue";
import GuarantorControl from "../Controls/Guarantor.vue";
import AdministratorControl from "../Controls/Administrator.vue";
import AssetSubClassification from "../Controls/AssetSubClassification.vue";
import AssetTagsControl from "../Controls/AssetTags.vue";
import SupplierControl from "../Controls/Supplier.vue";
import PractitionerControl from "../Controls/Practitioner.vue";
import PractitionerPercentageControl from "../Controls/PractitionerPercentage.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/cs";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        AssetTagsControl,
        ClassificationControl,
        TypeControl,
        WayOfDisposeControl,
        LocationControl,
        GuarantorControl,
        AdministratorControl,
        AssetSubClassification,
        SupplierControl,
        PractitionerControl,
        PractitionerPercentageControl,
        ckeditor: CKEditor.component,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                language: "cs",
                toolbar: ["bold", "italic", "bulletedList", "numberedList"],
            },
            isLoading: false,
        };
    },
    watch: {
        "asset.practitioner": function (practitioner) {
            if (!this.asset.practitioner) {
                this.asset.practitionerPercentage = 0;
            }
        },
    },
    computed: {
        ...mapGetters("assetTemplate", {
            templates: "getTemplates",
            templateById: "getTemplateById",
        }),
        ...mapGetters("assetClassification", {
            getClassificationItemById: "getItemById",
        }),
        ...mapGetters("assetType", ["getTypeById"]),
        ...mapGetters("moduleConfig", {
            countryLaw: "countryLaw",
        }),
    },
    methods: {
        ...mapActions("assetTemplate", ["fetchTemplates"]),
        ...mapActions("assetClassification", ["fetchClassification"]),
        ...mapActions("assetType", ["fetchTypes"]),
        ...mapActions("suppliers", ["fetchSuppliers"]),

        selectTemplate: function (template) {
            if (!template) {
                this.$set(this.asset, "template", null);
                this.$forceUpdate();
                return;
            }

            this.$set(this.asset, "template", template);

            if (template.typeId) {
                let type = this.getTypeById(template.typeId);
                this.$set(this.asset, "type", type);
            }

            if (template.classificationId) {
                let classification = this.getClassificationItemById(
                    template.classificationId
                );
                this.$set(this.asset, "classification", classification);
            }

            if (template.ownAttributeIds) {
                this.asset.ownAttributes = [];

                template.ownAttributeIds.forEach((id) => {
                    if (id != null && id > 0) {
                        this.asset.ownAttributes.unshift({
                            attributeId: id,
                            value: null,
                        });
                    }
                });
            }

            this.$forceUpdate();
        },
    },
};
</script>

