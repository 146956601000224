<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
    <textarea v-model="question.correct" class="form-control"
              :placeholder="$t('answer')" style="resize: vertical;"></textarea>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";
    import _ from 'lodash';

    export default {
        extends: BaseQuestion,
        components: {
            BaseQuestion
        },
        watch: {
            ['question.correct']: {
                handler: _.debounce(function () {
                    this.$emit("change", this.question);
                }, 400),
                deep: true
            }
        }
    }
</script>