<template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog vld-parent" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{$t('comp.kbAudit.modalExport.modalTitle')}}
                    </h4>
                </div>

                <div class="modal-body">
                    <p v-html="$t('comp.kbAudit.modalExport.label')"></p>
                    <div style="margin-left: 15px">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="filterAnswers.implemented">
                                {{$t('comp.kbAudit.answerType.implemented')}}
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="filterAnswers.in_implementation">
                                {{$t('comp.kbAudit.answerType.inImplementation')}}
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="filterAnswers.not_applicable">
                                {{$t('comp.kbAudit.answerType.notApplicable')}}
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="filterAnswers.not_implemented">
                                {{$t('comp.kbAudit.answerType.notImplemented')}}
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="filterAnswers.not_answered">
                                {{$t('comp.kbAudit.answerType.notAnswered')}}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-default pull-left" @click="closeModal" :disabled="isLoading">{{$t('comp.kbAudit.modalExport.close')}}</button>
                    <button class="btn btn-success pull-right" @click="startExport" :disabled="isLoading">{{$t('comp.kbAudit.modalExport.export')}}</button>
                </div>
            </div>

            <loading :active="isLoading" :is-full-page="false"></loading>
        </div>
    </div>
</template>

<script>
    import { EventBus } from './event-bus.js';

    export default {
        data() {
            return {
                modal: null,
                isLoading: false,
                filterAnswers: {
                    implemented: true,
                    in_implementation: true,
                    not_implemented: true,
                    not_applicable: true,
                    not_answered: true,
                }
            }
        },
        props: {
            linkExport: String,
            paramPrefix: String,
        },
        mounted: function () {
            this.modal = this.$refs.modal;

            EventBus.$on('show-export-modal', (v) => {
                if(v) {
                    this.showModal();
                } else {
                    this.closeModal();
                }
            });
        },
        methods: {
            closeModal: function () {
                $(this.modal).modal("hide");
            },
            showModal: function () {
                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });
            },
            startExport() {
                this.isLoading = true;

                this.$api.get('audit-kb/export?type=zokb', {
                    params: {
                        filter: this.filterAnswers
                    },
                    responseType: 'blob', // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.$t('comp.kbAudit.modalExport.fileName')); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(()=>{
                        this.isLoading = false;
                    });
            }
        }
    }
</script>
