<template>
    <div>
        <div class="row">
            <div class="col-md-12 control">
                <label for="waysOfUsage">{{$t('comp.assetModal.tabs.waysTab.waysOfUsage')}}</label>
                <ckeditor :editor="editor" v-model="asset.methodsOfUsage" :config="editorConfig" id="waysOfUsage"></ckeditor>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 control">
                <label for="waysOfManipulation">{{$t('comp.assetModal.tabs.waysTab.waysOfManipulation')}}</label>
                <ckeditor :editor="editor" v-model="asset.rulesForManipulation" :config="editorConfig" id="waysOfManipulation"></ckeditor>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>



<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/cs';

    export default {
        components: {
            ckeditor: CKEditor.component,
        },
        props: {
            asset: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'cs',
                    toolbar: ['bold', 'italic', "bulletedList", "numberedList"]
                },
                isLoading: false
            };
        },
    }
</script>