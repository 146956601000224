<template>
    <div class="vld-parent" style="width: 100%">
        <div class="pull-right">
            <button class="btn btn-success" @click="add">
                <i class="fa fa-plus"></i> {{ $t("common.add") }}
            </button>
        </div>
        <div class="pull-left" style="padding-top: 12px">
            <button
                    class="btn btn-xs btn-danger"
                    @click="removeSelected"
                    :disabled="currentSelections.length > 0 ? disabled : ''">
                <i class="fa fa-trash"></i> {{ $t("common.delete") }}
            </button>
        </div>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th></th>
                    <th style="width: 5%" v-if="isShowHashIds">
                        {{ $t("comp.manageMeasures.measureListing.id") }}
                    </th>
                    <th style="width: 30%">
                        {{ $t("comp.manageMeasures.measureListing.name") }}
                    </th>
                    <th style="width: 30%">
                        {{ $t("comp.manageMeasures.measureListing.tags") }}
                    </th>
                    <th style="width: 10%">
                        {{ $t("comp.manageMeasures.measureListing.applicable") }}
                    </th>
                    <th style="width: 10%">
                        {{ $t("comp.manageMeasures.measureListing.implemented") }}
                    </th>
                    <th style="width: 15%"></th>
                </tr>
            </thead>
            <tbody>
                <tr style="border-bottom: 2px solid #e8e8e8">
                    <td style="width: 2%">
                        <label class="form-checkbox" style="margin-bottom: 0px">
                            <input
                                   type="checkbox"
                                   :checked="isAllSelected"
                                   @click="selectAll" />
                            <i class="form-icon"></i>
                        </label>
                    </td>
                    <td v-if="isShowHashIds"></td>
                    <td>
                        <input
                               class="form-control input-sm"
                               v-model="filter.name"
                               :disabled="isEditing"
                               :placeholder="$t('comp.manageMeasures.measureListing.searchPlaceholder')" />
                    </td>
                    <td>
                        <multiselect
                                     v-model="filter.tags"
                                     :disabled="isEditing"
                                     :options="getVisibleTags.map((tag) => tag.id)"
                                     :custom-label="(opt) => getVisibleTags.find((x) => x.id == opt).name"
                                     :multiple="true"
                                     :taggable="false"
                                     :closeOnSelect="false">
                        </multiselect>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <button
                                class="btn btn-danger btn-xs"
                                @click="cancelFilter"
                                v-if="isFiltered">
                            {{ $t("common.cancelFilter") }}
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <template v-for="measure in measures">
                    <measure
                             v-if="measure.id"
                             :item="measure"
                             :key="measure.id"
                             :people="people"
                             :isShowHashIds="isShowHashIds"
                             @removed="remove"
                             @reload="reload"></measure>
                    <measure
                             v-else
                             :item="measure"
                             :people="people"
                             :key="measure.key"
                             :isShowHashIds="isShowHashIds"
                             @removed="remove"
                             @reload="reload"></measure>
                </template>
            </tbody>
        </table>

        <div class="pull-right" v-if="paginator.itemCount > 1">
            {{ paginator.offset + 1 }}-{{ paginator.offset + paginator.length }}/{{
                paginator.itemCount
            }}
            <div class="btn-group" style="padding-right: 20px">
                <button
                        class="btn btn-default btn-sm"
                        @click="paginator.page--"
                        :disabled="paginator.page === 1">
                    <i class="fa fa-chevron-left"></i>
                </button>
                <button
                        class="btn btn-default btn-sm"
                        @click="paginator.page++"
                        :disabled="paginator.page === paginator.pageCount">
                    <i class="fa fa-chevron-right"></i>
                </button>
            </div>
            <div id="measuresPerPage_LocalStorage" class="btn-group">
                <select class="form-control" v-model="paginator.perPage">
                    <option
                            v-for="perPage in perPageOptions"
                            :value="perPage"
                            :key="perPage">
                        {{ perPage }}
                    </option>
                </select>
            </div>
        </div>
        <loading :active="isLoading" :is-full-page="false"></loading>

        <delete-confirmation
                             :ref="'massDelConfMeasure'"
                             v-on:confirmed="massDeleteConfirmed($event)"></delete-confirmation>

        <!-- <import-modal></import-modal> -->
    </div>
</template>

<script>
import Measure from "./Measure.vue";
import Multiselect from "../../Controls/CsaMultiSelect.vue";
import { mapActions, mapGetters } from "vuex";
import store from "../../store";
import _ from "lodash";
import EventBus from "./EventBus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation.vue";

export default {
    store,
    components: {
        Measure,
        Multiselect,
        DeleteConfirmation,
    },
    data() {
        return {
            items: [],
            measures: [],
            people: [],
            key: 0,
            filter: {
                name: null,
                tags: [],
            },
            paginator: {
                perPage: 25,
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100],
            watchFilter: true,
            currentSelections: [],
        };
    },
    watch: {
        filter: {
            deep: true,
            handler: function () {
                if (this.watchFilter) {
                    this.debouncedFetchFilteredMeasures();
                }
            },
        },
        "paginator.page": function () {
            this.fetchFilteredMeasures();
        },
        ["paginator.perPage"]: function () {
            this.fetchFilteredMeasures();
            localStorage.setItem(
                "measuresPerPage_LocalStorage",
                this.paginator.perPage
            );
        },
        measures: {
            handler() {
                this.currentSelections = this.measures
                    .filter((item) => item.selected)
                    .map((item) => item.id);
            },
            deep: true,
        },
    },
    mounted: function () {
        if (localStorage.getItem("measuresPerPage_LocalStorage")) {
            try {
                this.paginator.perPage = localStorage.getItem(
                    "measuresPerPage_LocalStorage"
                );
            } catch (e) {
                localStorage.removeItem("measuresPerPage_LocalStorage");
            }
        }
    },
    computed: {
        ...mapGetters("measures", {
            fetchMeasures: "fetchMeasures",
        }),
        ...mapGetters("measureTags", {
            getVisibleTags: "getVisible",
        }),
        ...mapGetters("userSettings", {
            isShowHashIds: "isShowHashIds",
        }),
        ...mapGetters("riskMeasure", {
            implemented: "implemented",
        }),
        isFiltered: function () {
            return this.filter.name || this.filter.tags.length;
        },
        isEditing() {
            return (
                this.measures.filter((m) => {
                    return m.editMode === true;
                }).length > 0
            );
        },
        isAllSelected() {
            //checked/(unchecked) checkBox selectAll if every/(more then one) item on items is checked/(unchecked)
            if (this.currentSelections.length <= 0) {
                return false;
            }
            return this.measures.every((item) => item.selected);
        },
    },
    async created() {
        await this.$api.get("org-people-grouped").then(({ data }) => {
            this.people = data.data;
        });
        await this.fetchIsShowHashIds();
        await this.fetchFilteredMeasures();
        await this.fetchTags();

        EventBus.$on("imported", () => {
            this.fetchFilteredMeasures(false);
            this.fetchTags();
        });
    },
    methods: {
        add: function () {
            this.measures.unshift({
                id: null,
                name: null,
                tags: [],
                countRisksWithMeasure: "n",
                countApplicableRisksWithMeasure: "n",
                countApplicableRisksWithMeasureImplemented: "n",
                key: this.key++,
                riskPlan: {
                    id: null,
                },
            });
        },
        remove: function (item) {
            this.measures.splice(this.measures.indexOf(item), 1);
        },
        removeSelected: function () {
            let modalWindow = this.$refs.massDelConfMeasure;
            modalWindow.show();
        },
        massDeleteConfirmed: function () {
            this.isLoading = true;

            this.$api
                .post("measures/mass-delete", this.currentSelections)
                .then((res) => {
                    if (res.data.status == "ok") {
                        this.showDeleted();
                        this.reload(true);
                    } else if (res.data.status == "error") {
                        if (res.data.code == "PartiallyDeleted") {
                            this.showCanNotDeleteInUse();
                            this.reload(true, true);
                        } else {
                            this.showDeleteError();
                        }
                    }
                    this.isLoading = false;
                });
        },

        debouncedFetchFilteredMeasures: _.debounce(function (
            showLoading = true,
            keepSelection = false
        ) {
            this.fetchFilteredMeasures(showLoading, keepSelection);
        },
            700),

        fetchFilteredMeasures: function (
            showLoading = true,
            keepSelection = false
        ) {
            this.isLoading = showLoading;

            this.filterMeasures({
                filter: this.filter,
                paginator: this.paginator,
            }).then((measures) => {
                measures.items.forEach((measure) => {
                    if (keepSelection) {
                        measure.selected = this.currentSelections.indexOf(measure.id) >= 0;
                    } else {
                        measure.selected = false;
                    }
                });
                this.isLoading = false;
                this.measures = measures.items;
                this.paginator = measures.paginator;
            });
        },
        selectAll() {
            let selected = !this.isAllSelected;
            //check/uncheck all item checkBox on items if selectAll is checked/(unchecked)
            this.measures.forEach((item) => (item.selected = selected));
        },
        ...mapActions("measures", {
            filterMeasures: "filterMeasures",
        }),
        ...mapActions("measureTags", {
            fetchTags: "fetchTags",
        }),
        ...mapActions("userSettings", {
            fetchIsShowHashIds: "fetchIsShowHashIds",
        }),
        cancelFilter: function () {
            this.watchFilter = false;

            this.filter = {
                name: null,
                tags: [],
            };

            this.fetchFilteredMeasures();

            this.$nextTick(() => {
                this.watchFilter = true;
            });
        },
        reload: function (showLoading = false, keepSelection = false) {
            this.fetchFilteredMeasures(showLoading, keepSelection);
        },
    },
    notifications: {
        showDeleted: {
            message: "common.deletedItems",
            type: "success",
        },
        showDeleteError: {
            message: "common.deleteError",
            type: "error",
        },
        showCanNotDeleteInUse: {
            message: "common.oneOrMoreCanNotDeleteInUse",
            type: "error",
        },
    },
};
</script>

<style scoped lang="less">
.multiselect {
    font-size: 12px;

    /deep/ .multiselect__tags {
        min-height: 30px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    /deep/ .multiselect__select {
        height: 30px;
    }

    /deep/ .multiselect__placeholder {
        font-size: 12px;
    }

    /deep/ .multiselect__input {
        font-size: 12px;
    }

    /deep/ .multiselect__tag {
        font-size: 12px;
    }
}
</style>
