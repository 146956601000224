<template>
    <div class="row margin-bottom" v-if="value !== undefined">
        <label class="control-label col-md-3">
            {{$t('comp.settings.tabAssets.assetEvaluationPropagation.label')}}
        </label>
        <div class="col-lg-5 col-md-7" v-cloak>
            <div class="form-group">
                <label style="font-weight: normal">
                    <input type="checkbox" v-model="value" @change="save" ref="input" />
                    <span v-html="$t('comp.settings.tabAssets.assetEvaluationPropagation.option')"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AssetEvaluationPropagation',
        data() {
            return {
                value: false,
                previousValue: false
            };
        },
        created() {
            this.load();
        },
        methods: {
            load: function () {
                this.$api.get("config/asset-evaluation-propagation").then(({data}) => {
                    this.value = data.data;
                    this.previousValue = data.data;
                });
            },
            save: function (e) {

                if(this.value) {
                    if (!confirm(this.$t('comp.settings.tabAssets.assetEvaluationPropagation.confirm'))) {
                        this.value = this.previousValue;
                        e.target.checked = this.previousValue;
                        return;
                    }
                }

                this.$api.post("config/asset-evaluation-propagation", null, {
                    params: {
                        value: this.value
                    }
                }).then(({data}) => {
                    if(data.status === 'ok') {
                        this.previousValue = this.value;
                    }
                    else {
                        this.value = this.previousValue;
                        e.target.checked = this.previousValue;
                    }
                });
            }
        }
    }
</script>
