<template>
    <div class="control">
        <label>{{$t('comp.assetModal.controls.classification.label')}}</label>

        <csa-select
                v-model="selected"
                :options="filteredOptions"
                trackBy="id"
                label="name"
        >
        </csa-select>
    </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "asset-classification-control",
        components: {
            CsaSelect
        },
        data() {
            return {
                selected: null,
                showPrimaryClassification: true
            };
        },
        props: {
            value: null,
            isCreating: false
        },
        watch: {
            selected: function (v) {
                this.$emit('input', v);

                if(v && v.isPrimary && this.isCreating) {
                    this.importanceAssessment();
                }

                if(this.value && !this.value.isPrimary && v && v.isPrimary) {                    
                    this.checkAssetDependencies();
                }             
            },
            value: function (v) {
                this.selected = v;
            }
        },
        async created() {
            this.fetchClassification();
            this.selected = this.value;

            if(this.$app.user.role !== 'mcs') {
                await this.$api.get("config/guarantor-can-create-primary-assets").then(({data}) => {
                    this.showPrimaryClassification = data.data;
                });
            }
        },
        computed: {
            ...mapGetters("assetClassification", {
                options: "getClassification"
            }),
            filteredOptions() {
                if(this.showPrimaryClassification) {
                    return this.options;
                }

                return this.options.filter((i) => {
                    return !i.isPrimary;
                })
            }
        },
        methods: {
            ...mapActions("assetClassification", ["fetchClassification"]),
            getOption: function (id) {
                if(!id) {
                    return null;
                }

                return this.options.find((i)=>{return i.id === id;});
            }
        },
        notifications: {
            importanceAssessment: {
                title: 'comp.assetModal.controls.classification.importanceAssessment.title',
                message: 'comp.assetModal.controls.classification.importanceAssessment.message',
                type: 'info'
            },
            checkAssetDependencies: {
                message: 'comp.assetModal.notifications.checkAssetDependencies',
                type: 'error'
            }        
        }
    }
</script>