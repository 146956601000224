<template>
    <div class="control mb2">
        <label v-if="type === 'assetConfidence'"><strong>C</strong> - {{$t('comp.assetModal.controls.evaluation.assetConfidence')}}</label>
        <label v-if="type === 'assetIntegrity'"><strong>I</strong> - {{$t('comp.assetModal.controls.evaluation.assetIntegrity')}}</label>
        <label v-if="type === 'assetAvailability'"><strong>A</strong> - {{$t('comp.assetModal.controls.evaluation.assetAvailability')}}</label>

        <multiselect
                v-model="selected"
                :options="options"
                :custom-label="opt => opt.name"
                :disabled="disabled">
            <div slot="option" slot-scope="props">
                <strong>{{options.find(x => x.id == props.option.id).name}}</strong> <br />

                <div class="text-sm" style="white-space: pre-wrap;">{{options.find(x => x.id == props.option.id).description}}</div>
            </div>
        </multiselect>
        <div class="text-primary info text-sm" v-if="selected" style="white-space: pre-wrap;">{{selected.description}}</div>
    </div>
</template>

<script>
    import Multiselect from 'Controls/CsaMultiSelect.vue'

    export default {
        name: "asset-evaluation-control",
        components: {
            Multiselect
        },
        data() {
            return {
                selected: null
            };
        },
        watch: {
            selected: function (v) {
                this.$emit('input', v);
            },
            value: function (v) {
                this.selected = v;
            }
        },
        props: {
            type: {
                type: String,
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            value: {
                type: Object
            },
            disabled: false,
        },
        created() {
            this.selected = this.value;
        },
        methods: {

        }
    }
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
