<template>
    <div>
        <div v-if="!isUpRelationSelectionMode">
            <div>
                <div class="pull-right">
                    <button class="btn btn-danger btn-xs" v-if="isDownRelationSelectionMode"
                            @click="toggleDownRelationSelectionMode"
                            :disabled="isLoading">
                        {{ $t('comp.assetModal.tabs.relationsTab.endSelectAssets') }}
                    </button>
                    <button class="btn btn-warning btn-xs" v-else
                            @click="toggleDownRelationSelectionMode">
                        {{ $t('comp.assetModal.tabs.relationsTab.selectAssets') }}
                    </button>
                </div>
                <h4>{{ $t('comp.assetModal.tabs.relationsTab.addUpRelations') }}</h4>
            </div>
            <div class="assets">
                <div class="filter">
                    <input class="form-control input-sm" v-model="filterDownAssetsText"
                           :placeholder="$t('comp.assetModal.tabs.relationsTab.search')" />
                </div>
                <div class="filter">
                    <multiselect v-model="filterDownAssetTagIds"
                                 :options="allAssetTags.map(tag => tag.id)"
                                 :custom-label="opt => getAssetTagById(opt).name"
                                 :multiple="true"
                                 :taggable="false"
                                 :closeOnSelect="false">
                    </multiselect>
                </div>
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <td width="20px" v-if="isDownRelationSelectionMode"></td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.asset") }}
                            </td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.type") }}
                            </td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.guarantor") }}
                            </td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.location") }}
                            </td>
                            <td width="300px">
                                {{ $t("comp.assetModal.tabs.relationsTab.tags") }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in filteredDownRelationAssets">
                            <td width="20px" v-if="isDownRelationSelectionMode">
                                <input
                                       type="checkbox"
                                       class="checkbox"
                                       v-model="row.isDown"
                                       @change="setIdsToAsset" />
                            </td>
                            <td class="text-sm">
                                <i :class="row.classificationCssIcon"
                                   class="classification-icon"></i>
                                <a :href="row.link" target="_blank">{{ row.name }}</a>
                            </td>
                            <td class="text-sm">
                                {{ row.type }}
                            </td>
                            <td class="text-sm">
                                {{ row.guarantor }}
                            </td>
                            <td class="text-sm">
                                {{ row.location }}
                            </td>
                            <td class="text-sm" style="word-break: break-all;">
                                <span class="label label-default mr2" v-for="tag in row.assetTags" :key="tag.id">
                                    <span v-if="tag.name && tag.name.length > 30" :title="tag.name">{{ tag.name.slice(0, 27)
                                    }}...</span>
                                    <span v-else>{{ tag.name }}</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div v-if="!isDownRelationSelectionMode">
            <div>
                <div class="pull-right">
                    <button class="btn btn-danger btn-xs" v-if="isUpRelationSelectionMode"
                            @click="toggleUpRelationSelectionMode"
                            :disabled="isLoading">
                        {{ $t('comp.assetModal.tabs.relationsTab.endSelectAssets') }}
                    </button>
                    <button class="btn btn-warning btn-xs" v-else
                            @click="toggleUpRelationSelectionMode">
                        {{ $t('comp.assetModal.tabs.relationsTab.selectAssets') }}
                    </button>
                </div>
                <h4>{{ $t('comp.assetModal.tabs.relationsTab.addDownRelations') }}</h4>
            </div>
            <div class="assets">
                <div class="filter">
                    <input class="form-control input-sm" v-model="filterUpAssetsText"
                           :placeholder="$t('comp.assetModal.tabs.relationsTab.search')" />
                </div>
                <div class="filter">
                    <multiselect v-model="filterUpAssetTagIds"
                                 :options="allAssetTags.map(tag => tag.id)"
                                 :custom-label="opt => getAssetTagById(opt).name"
                                 :multiple="true"
                                 :taggable="false"
                                 :closeOnSelect="false">
                    </multiselect>
                </div>
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <td width="20px" v-if="isUpRelationSelectionMode"></td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.asset") }}
                            </td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.type") }}
                            </td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.guarantor") }}
                            </td>
                            <td>
                                {{ $t("comp.assetModal.tabs.relationsTab.location") }}
                            </td>
                            <td width="300px">
                                {{ $t("comp.assetModal.tabs.relationsTab.tags") }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in filteredUpRelationAssets">
                            <td width="20px" v-if="isUpRelationSelectionMode">
                                <input
                                       type="checkbox"
                                       class="checkbox"
                                       v-model="row.isUp"
                                       @change="setIdsToAsset" />
                            </td>
                            <td class="text-sm">
                                <i :class="row.classificationCssIcon"
                                   class="classification-icon"></i>
                                <a :href="row.link" target="_blank">{{ row.name }}</a>
                            </td>
                            <td class="text-sm">
                                {{ row.type }}
                            </td>
                            <td class="text-sm">
                                {{ row.guarantor }}
                            </td>
                            <td class="text-sm">
                                {{ row.location }}
                            </td>
                            <td class="text-sm" style="word-break: break-all;">
                                <span class="label label-default mr2" v-for="tag in row.assetTags" :key="tag.id">
                                    <span v-if="tag.name && tag.name.length > 30" :title="tag.name">{{ tag.name.slice(0, 27)
                                    }}...</span>
                                    <span v-else>{{ tag.name }}</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../../../store";
import Multiselect from "../../../Controls/CsaMultiSelect.vue";
import { mapGetters } from "vuex";

export default {
    store,
    components: {
        Multiselect,
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        asset: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isLoading: false,
            relationAssets: [],
            assetTags: [],
            isUpRelationSelectionMode: false,
            isDownRelationSelectionMode: false,
            filterDownAssetsText: null,
            filterUpAssetsText: null,
            filterDownAssetTagIds: [],
            filterUpAssetTagIds: [],
            filteredDownRelationAssets: [],
            filteredUpRelationAssets: [],
        }
    },
    watch: {
        ['filterDownAssetTagIds']: function () {
            this.filterDownAssets();
        },
        ['filterDownAssetsText']: function () {
            this.filterDownAssets();
        },
        ['filterUpAssetTagIds']: function () {
            this.filterUpAssets();
        },
        ['filterUpAssetsText']: function () {
            this.filterUpAssets();
        },
    },
    computed: {
        ...mapGetters("assetTags", {
            allAssetTags: "getAll",
            getAssetTagById: "getById",
        }),
        ...mapGetters("asset", {
            allAssets: "getAssets",
        }),
    },
    async mounted() {
        this.show();
    },
    methods: {
        show: function (row) {
            this.isLoading = true;
            this.loadData().then(() => {
                this.isLoading = false;
            });
        },

        async loadData() {
            // if (this.id != null) {
            await this.$api
                .get(
                    `asset/fetch-relation-assets-by-asset-id?assetId=${this.id}`
                )
                .then((res) => {
                    if (res.data.status == "ok") {
                        this.relationAssets = res.data.data;
                        this.filterDownAssets();
                        this.filterUpAssets();
                    }
                });
            // }
        },

        setIdsToAsset() {
            this.asset.dependingAssetIdsDown = this.relationAssets.filter(x => x.isDown).map(x => x.id);
            this.asset.dependingAssetIdsUp = this.relationAssets.filter(x => x.isUp).map(x => x.id);
        },

        toggleDownRelationSelectionMode() {
            this.isDownRelationSelectionMode = !this.isDownRelationSelectionMode;
            this.filterDownAssets();
        },

        toggleUpRelationSelectionMode() {
            this.isUpRelationSelectionMode = !this.isUpRelationSelectionMode;
            this.filterUpAssets();
        },

        filterDownAssets: function () {
            let filteredAssets = this.relationAssets.filter(x => !x.isUp);

            if (!this.isDownRelationSelectionMode) {
                filteredAssets = filteredAssets.filter(x => x.isDown);
            }

            if (this.filterDownAssetsText !== null && this.filterDownAssetsText.trim().length > 0) {
                let filterText = this.filterDownAssetsText.toLowerCase();
                filteredAssets = filteredAssets.filter((row) => {
                    return (
                        row.name?.toLowerCase().includes(filterText) ||
                        row.type?.toLowerCase().includes(filterText) ||
                        row.guarantor?.toLowerCase().includes(filterText) ||
                        row.location?.toLowerCase().includes(filterText) ||
                        row.assetTags?.map(x => x.name).join(' ').toLowerCase().includes(filterText)
                    );
                });
            }

            if (this.filterDownAssetTagIds.length > 0) {
                filteredAssets = filteredAssets.filter((row) => {
                    return row.assetTags.some((tag) => {
                        return this.filterDownAssetTagIds.includes(tag.id);
                    });
                });
            }

            this.filteredDownRelationAssets = filteredAssets;
        },

        filterUpAssets: function () {
            let filteredAssets = this.relationAssets.filter(x => !x.isDown);

            if (!this.isUpRelationSelectionMode) {
                filteredAssets = filteredAssets.filter(x => x.isUp);
            }

            if (this.filterUpAssetsText != null && this.filterUpAssetsText.trim().length > 0) {
                let filterText = this.filterUpAssetsText.toLowerCase();
                filteredAssets = filteredAssets.filter((row) => {
                    return (
                        row.name?.toLowerCase().includes(filterText) ||
                        row.type?.toLowerCase().includes(filterText) ||
                        row.guarantor?.toLowerCase().includes(filterText) ||
                        row.location?.toLowerCase().includes(filterText) ||
                        row.assetTags?.map(x => x.name).join(' ').toLowerCase().includes(filterText)
                    );
                });
            }
            if (this.filterUpAssetTagIds.length > 0) {
                filteredAssets = filteredAssets.filter((row) => {
                    return row.assetTags.some((tag) => {
                        return this.filterUpAssetTagIds.includes(tag.id);
                    });
                });
            }

            this.filteredUpRelationAssets = filteredAssets;
        },
    },
}
</script>
<style scoped lang="less">
.label {
    white-space: normal;
}

.filter {
    margin: 10px 0;
}

.multiselect {
    font-size: 12px;

    /deep/ .multiselect__tags {
        min-height: 30px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    /deep/ .multiselect__select {
        height: 30px;
    }

    /deep/ .multiselect__placeholder {
        font-size: 12px;
    }

    /deep/ .multiselect__input {
        font-size: 12px;
    }

    /deep/ .multiselect__tag {
        font-size: 12px;
    }
}
</style>
