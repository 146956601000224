<template>
    <div>
        <table class="table" v-if="answer">
            <tbody>
                <tr>
                    <td :title="$t('comp.kbAudit.answerType.implemented')" class="choice">
                        <div class="pretty p-icon p-curve">
                            <input type="radio" v-bind:name="question.id" value="implemented"
                                   v-model="answer.choice"
                                   v-bind:disabled="readonly"
                                   @click="radioClicked($event)" />

                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td :title="$t('comp.kbAudit.answerType.inImplementation')" class="choice">
                        <div class="pretty p-icon p-curve">
                            <input type="radio" v-bind:name="question.id" value="in_implementation"
                                   v-model="answer.choice"
                                   v-bind:disabled="readonly"
                                   @click="radioClicked($event)" />

                            <div class="state p-primary">
                                <i class="icon fa fa-minus"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td :title="$t('comp.kbAudit.answerType.notApplicable')" class="choice">
                        <div class="pretty p-icon p-curve">
                            <input type="radio" v-bind:name="question.id" value="not_applicable"
                                   v-model="answer.choice"
                                   v-bind:disabled="readonly"
                                   @click="radioClicked($event)" />

                            <div class="state p-warning">
                                <i class="icon fa fa-times"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td :title="$t('comp.kbAudit.answerType.notImplemented')" class="choice">
                        <div class="pretty p-icon p-curve">
                            <input type="radio" v-bind:name="question.id" value="not_implemented"
                                   v-model="answer.choice"
                                   v-bind:disabled="readonly"
                                   @click="radioClicked($event)" />

                            <div class="state p-danger">
                                <i class="icon fa fa-exclamation"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                    <td class="question">
                        {{question.value}}

                        <div class="text-sm text-gray" v-if="question.reference">
                            {{question.reference}}
                        </div>
                    </td>
                    <td class="text-right" style="width: 150px">
                        <button class="btn btn-xs mt1" @click="showModal = true" :class="{
                                'btn-success': answer.measures.length,
                                'btn-warning': !answer.measures.length && answer.comment,
                                'btn-default': !answer.measures.length && !answer.comment
                            }">
                            {{$t('comp.kbAudit.kbQuestion.measureBtn')}} <i class="fas fa-pencil-alt"></i>
                        </button>
                    </td>
                    <td class="text-right" style="width: 20px; text-align: right;">
                            <input type="checkbox" style="margin-top: 9px;" @change="handleCheckboxChange"
                            v-model="answer.selected"/>
                    </td>
                </tr>
            </tbody>
        </table>

        <edit-question :showModal="showModal" v-if="showModal" @hide="showModal = false" :answer="answer"></edit-question>
    </div>
</template>

<script>
    import EditQuestion from "./EditQuestion.vue";
    import {EventBus} from './event-bus.js';
    import {mapGetters} from "vuex";

    export default {
        name: "PoaQuestion",
        components: {
            EditQuestion
        },
        data() {
            return {
                previouslySelected: null,
                showModal: false,
                readonly: false,
                answer: null,
            }
        },
        props: {
            question: Object,
            readonly: {
                type: Boolean,
                required: true
            }
        },
        watch: {

        },
        computed: {
            ...mapGetters('auditKB', {
                getAnswerByQuestion: 'getAnswerByQuestionIso'
            })
        },
        mounted() {
            if(this.getAnswerByQuestion(this.question.id)) {
                this.answer = this.getAnswerByQuestion(this.question.id);
                this.previouslySelected = this.answer.choice;
            } else {
                this.answer = {
                    questionId: this.question.id,
                    choice: null,
                    comment: null,
                    measures: []
                }
            }

            this.$watch('answer.choice', (v) => {
                this.$emit('answerChanged');
                this.$api.post("audit-kb/answer?type=iso", this.answer);
            });
        },
        methods: {
            radioClicked: function (e) {
                if(e.target.value === this.previouslySelected) {
                    e.target.checked = null;
                    this.previouslySelected = null;
                    this.answer.choice = null;
                } else {
                    this.previouslySelected = e.target.value;
                }
            },
            handleCheckboxChange() {
                EventBus.$emit('checkboxChanged', this.answer);
            }
        },
    }
</script>

<style lang="less" scoped>
    .pretty {
        font-size: 25px;
        display: block;
        margin-right: 0;
    }

    .choice {
        vertical-align: middle;
        width: 55px;
        text-align: center;
        margin-top: auto;
    }
</style>
