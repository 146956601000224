<template>
    <tbody>
        <template v-if="!riskMeasure.measure.id">
            <tr>
                <td v-if="isSelectionMode"></td>
               <td v-if="isShowHashIds"></td>
                <td>
                    <input v-model="riskMeasure.measure.name"
                        type="text"
                        name="name"
                        id="name"
                        ref="name"
                        class="form-control"
                        :placeholder="$t('comp.manageMeasures.measure.namePlaceholder')"
                        @keypress.enter="save"
                        v-validate="'required|max:256'"
                        :data-vv-as="$t('comp.manageMeasures.measure.name-vv')"/>

                    <span class="help-block text-red">{{errors.first('name')}}</span>
                </td>
                <td style="height: 60px;">
                    <multiselect
                            v-model="riskMeasure.measure.tags"
                            :options="tags.map(tag => tag.id)"
                            :custom-label="opt => tags.find(x => x.id == opt).name"
                            :multiple="true"
                            :taggable="$app.user.role === 'mcs'"
                            :closeOnSelect="false"
                            @tag="createTag">
                    </multiselect>
                </td>
                <td>
                    <span class="label label-primary">
                        {{$t('common.undecided')}}
                    </span>
                </td>
                <td>
                    <div class="input-group" :class="{'has-error': errors.has('threatReductionValue')}">
                    <input
                        v-model.number="riskMeasure.threatReductionValue"
                        name="threatReductionValue"
                        type="number"
                        class="form-control"
                        min="0"
                        :max="maxThreat - 1"
                        step="1"
                        data-vv-validate-on="blur|focus|input"
                        @keypress="isNumber($event)" 
                        :disabled="!riskMeasure.isSelected" />
                    </div>
                    <span class="help-block text-red">{{ errors.first('threatReductionValue') }}</span>
                </td>
                <td>
                    <div class="input-group" :class="{'has-error': errors.has('vulnerabilityReductionValue')}">
                    <input
                        v-model.number="riskMeasure.vulnerabilityReductionValue"
                        name="vulnerabilityReductionValue"
                        type="number"
                        class="form-control"
                        min="0"
                        :max="maxVulnerability - 1"
                        step="1"
                        data-vv-validate-on="blur|focus|input"
                        @keypress="isNumber($event)"
                        :disabled="!riskMeasure.isSelected" />
                    </div>
                    <span class="help-block text-red">{{ errors.first('vulnerabilityReductionValue') }}</span>
                </td>
                <td class="text-center">
                    <span class="label label-danger">
                        {{$t('common.no')}}
                    </span>
                </td>
                <td class="text-right" id="save">
                    <button class="btn btn-success btn-xs" @click="save">
                        <i class="fas fa-save"></i>
                    </button>
                    <button class="btn btn-default btn-xs" @click="cancel">
                        <i class="fas fa-times"></i>
                    </button>
                </td>
            </tr>
        </template>
        <template v-else>
            <tr>
                <td v-if="isSelectionMode"><input type="checkbox" class="checkbox" v-model="riskMeasure.isSelected"></td>
                <td v-if="isShowHashIds">
                    {{riskMeasure.measure.hashId}}
                </td>
                <td>
                    {{riskMeasure.measure.name}}
                </td>
                <td>
                    <template v-for="tagId in riskMeasure.measure.tags" v-if="getTagById(tagId)">
                        <span class="label label-default mr2" v-if="!getTagById(tagId).link">
                            {{getTagById(tagId).name}}
                        </span>
                        <a class="label label-default mr2" v-else :href="getTagById(tagId).link" target="_blank">
                            <i class="fas fa-link"></i>{{getTagById(tagId).name}}
                        </a>
                    </template>
                </td>
                <td>
                    <span class="label label-success" v-if="riskMeasure.applicable">
                        {{$t('common.yes')}}
                    </span>
                    <span class="label label-danger" v-else-if="riskMeasure.applicable === false" >
                        {{$t('common.no')}}
                    </span>
                    <span class="label label-primary" v-else >
                        {{$t('common.undecided')}}
                    </span>
                </td>
                <td>
                    <div class="input-group" :class="{'has-error': errors.has('threatReductionValue')}">
                    <input
                        v-model.number="riskMeasure.threatReductionValue"
                        name="threatReductionValue"
                        type="number"
                        class="form-control"
                        min="0"
                        :max="maxThreat - 1"
                        step="1"
                        data-vv-validate-on="blur|focus|input"
                        @keypress="isNumber($event)"
                        :disabled="!riskMeasure.isSelected" />
                    </div>
                    <span class="help-block text-red">{{ errors.first('threatReductionValue') }}</span>
                </td>
                <td>
                    <div class="input-group" :class="{'has-error': errors.has('vulnerabilityReductionValue')}">
                    <input
                        v-model.number="riskMeasure.vulnerabilityReductionValue"
                        name="vulnerabilityReductionValue"
                        type="number"
                        class="form-control"
                        min="0"
                        :max="maxVulnerability - 1"
                        step="1"
                        data-vv-validate-on="blur|focus|input"
                        @keypress="isNumber($event)"
                        :disabled="!riskMeasure.isSelected" />
                    </div>
                    <span class="help-block text-red">{{ errors.first('vulnerabilityReductionValue') }}</span>
                </td>
                <td class="text-center">
                    <span class="label label-success" v-if="riskMeasure.implemented">
                        {{$t('common.yes')}}
                    </span>
                    <span class="label label-danger" v-else>
                        {{$t('common.no')}}
                    </span>
                </td>
                <td>
                    <button class="btn btn-danger btn-xs" @click="deselect" v-if ="!isSelectionMode" :title="$t('comp.editRisk.measuresTab.measure.removeTitle')">
                        <i class="fas fa-trash"></i>
                    </button>
                    <button class="btn btn-default btn-xs" @click="showDescription = !showDescription" :title="$t('comp.editRisk.measuresTab.measure.addDescriptionTitle')">
                        <i class="fas fa-pencil-alt"></i>
                    </button>
                </td>
            </tr>
        </template>
        <transition name="fade" >
            <tr v-if="showDescription">
                <td class="text-right">
                    <strong>{{$t('comp.editRisk.measuresTab.measure.description')}}: </strong>
                </td>
                <td colspan="8">
                    <textarea v-model="riskMeasure.description" class="form-control" style="resize: vertical" rows="2" 
                :disabled="!riskMeasure.isSelected"></textarea>
                </td>
            </tr>
        </transition>
    </tbody>
</template>

<script>
    import Multiselect from 'Controls/CsaMultiSelect.vue'
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'riskMeasure',
        components: {
            Multiselect
        },
        props: {
            riskMeasure: {
                type: Object,
                require: true
            },
            riskValue: {
                type: Number
            },
            risk: {
                type: Object,
                required: true
            },
            maxThreat:{
                type: Number,
                require: true,
                default: 0
            },
            maxVulnerability:{
                type: Number,
                require: true,
                default: 0
            },
            isSelectionMode:{
                type: Boolean,
                require: true,
                default: false
            },
        },
        mounted(){
            if (!this.riskMeasure.measure.id) {
                this.$refs.name.focus();
            }
        },
        watch: {
            ['riskMeasure.threatReductionValue']: _.debounce(function(v) {
                if(typeof v == 'number') {  
                    if(v < 0) {
                        this.riskMeasure.threatReductionValue = 0;
                    }    
                    else if(v > this.maxThreat - 1) {
                        this.riskMeasure.threatReductionValue = this.maxThreat - 1;
                    }                      
                    else if(v >= 0 || v <= this.maxThreat - 1) {
                        this.riskMeasure.threatReductionValue = parseFloat(parseFloat(v).toFixed(2));
                    }
                }

            }, 250),
            ['riskMeasure.vulnerabilityReductionValue']: _.debounce(function(v) {
                if(typeof v == 'number') {
                    if(v < 0) {
                        this.riskMeasure.vulnerabilityReductionValue = 0;
                    }
                    else if(v > this.maxVulnerability - 1) {
                        this.riskMeasure.vulnerabilityReductionValue = this.maxVulnerability - 1;
                    }                 
                    else if(v >= 0 || v <= this.maxVulnerability - 1) {
                        this.riskMeasure.vulnerabilityReductionValue = parseFloat(parseFloat(v).toFixed(2));
                    }
                }
            }, 250)
        },
        created: function () {
            this.showDescription = this.riskMeasure.description !== null && this.riskMeasure.description.trim() != '';
            this.fetchIsShowHashIds();
        },
        data() {
            return {
                isLoading: false,
                showDescription: false
            };
        },
        methods: {
            deselect: function () {
                if(!confirm(this.$t('comp.editRisk.measuresTab.measure.deleteConfirm'))) {
                    return;
                }

                this.$emit("deselect", this.riskMeasure);
            },
            
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode >= 48 && charCode <= 57) || charCode === 44 || charCode === 46) {     // [0-9,.]
                    return true;
                }
                evt.preventDefault();
            },
            ...mapActions('userSettings', {
                fetchIsShowHashIds: 'fetchIsShowHashIds'
            }),
            createTag: function (name) {
                let newTag = {
                    name: name
                };

                this.isLoading = true;

                this.addTag(newTag).then((tag) => {
                    this.isLoading = false;

                    this.riskMeasure.measure.tags.push(tag.id);
                });
            },
            save: function () {
                this.$validator.validate().then(result => {
                    if (!result) {
                        return;
                    }

                    this.addMeasure(this.riskMeasure.measure).then((measure) => {
                        Object.assign(this.riskMeasure.measure, measure)
                    });

                    this.showSaved();
                });
                this.$emit("toggleAddMeasureMode");
            },
            cancel: function () {
                this.$emit("cancelAddMeasure", this.riskMeasure);
            },
            ...mapActions('measures', ['addMeasure']),
            ...mapActions('measureTags', {
                addTag: 'addTag',
            })
        },
        computed: {
            ...mapGetters('measureTags', {
                visibleTags: 'getVisible',
                tags: 'getAll',
                allTags: 'getAll',
                getTagById: 'getById'
            }),
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            }),
            tags() {
                return this.allTags.filter((t) => {
                    if(this.riskMeasure.measure.tags.indexOf(t.id) !== -1) {
                        return true;
                    }

                    return this.visibleTags.indexOf(t) !== -1;
                });
            }
        },
        notifications: {
            showSaved: {
                message: 'common.saved',
                type: 'success'
            }
        }
    }
</script>

<style scoped lang="less">
    #name {
        height: 40px;
    }

    #save {
        vertical-align: middle;
    }

    .table > tbody > tr > td {
        vertical-align: middle;

        .input-group {
            margin-top: 5px;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s
    }
    .fade-enter, .fade-leave-to  {
        opacity: 0
    }

    .label {
        white-space: normal;
    }
</style>