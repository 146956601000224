<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="description">{{$t('comp.continuityPlanModal.tabs.process.description')}}</label>
                    <ckeditor :editor="editor" v-model="plan.process_description" :config="editorConfig" id="process_description"></ckeditor>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="description">{{$t('comp.continuityPlanModal.tabs.process.attachments')}}</label>

                    <div class="pull-right">
                        <label class="btn btn-default btn-sm" for="files">
                            <input type="file" id="files" style="display: none" multiple @change="selected" />
                            <i class="fas fa-paperclip"></i> {{$t('comp.continuityPlanModal.tabs.process.selectFiles')}}
                        </label>
                    </div>

                    <div class="clearfix"></div>

                    <table class="table" style="margin-top: 20px" v-if="plan.process_attachments.length">
                        <tbody>
                        <tr v-for="file in plan.process_attachments" :key="file.name">
                            <td v-if="isUploaded(file)">
                                {{file.name + '.' + file.extension}}
                            </td>
                            <td v-else>
                                {{file.name}}
                            </td>
                            <td class="text-right">
                                <button v-if="isUploaded(file)" class="btn btn-primary btn-xs" @click.prevent="download(file)">
                                    <i class="fas fa-download"></i>
                                </button>
                                <button class="btn btn-danger btn-xs" type="button" @click="remove(file)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-else class="text-center">
                        <em>{{$t('comp.continuityPlanModal.tabs.process.noAttachmentsSelected')}}</em>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/cs';

    export default {
        components: {
            ckeditor: CKEditor.component
        },
        props: ['plan'],
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'cs',
                    toolbar: ['bold', 'italic', "bulletedList", "numberedList"]
                }
            }
        },
        methods: {
            selected(e) {
                this.plan.process_attachments.push(...e.target.files)
            },
            remove(file) {
                if(file instanceof File === false) {
                    this.$emit('deleteFile', file)
                }

                this.plan.process_attachments.splice(this.plan.process_attachments.indexOf(file), 1)
            },
            isUploaded(file) {
                return !(file instanceof File);
            },
            download(file) {
                this.$api.get('continuity-plan/attachments', {
                    responseType: 'blob', // important
                    params: {
                      attachment_id: file.id
                    }
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', file.name + '.' + file.extension); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }
</script>