<template>
    <div class="modal fade" ref="modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{test.name}} <span class="label label-default">{{ $t("result") }}</span>

                        <button class="btn btn-default pull-right"
                                @click="close">
                            <i class="fas fa-times"></i>
                            {{ $t("close") }}
                        </button>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="pull-left">
                        <strong>{{ $t("questions") }}:</strong>
                        <ul class="numbering">
                            <li v-for="(question, index) in questions">
                                <button :key="index"
                                        type="button"
                                        @click="actualQuestionIndex = index"
                                        :class="{
                                            active: index === actualQuestionIndex,
                                            correct: isCorrect(question),
                                            bad: !isCorrect(question),
                                        }"
                                        :title="question.value"
                                >
                                    {{index+1}}
                                </button>
                            </li>
                        </ul>
                    </div>

                    <template v-if="questions.length">
                        <div class="clearfix mb3"></div>

                        <question-choices
                                v-if="questions[actualQuestionIndex].type === 'choices'"
                                :question="questions[actualQuestionIndex]"
                                :questionIndex = "actualQuestionIndex"
                                :key= "index"
                        ></question-choices>
                        <question-fulltext
                                v-if="questions[actualQuestionIndex].type === 'fulltext'"
                                :question="questions[actualQuestionIndex]"
                                :questionIndex = "actualQuestionIndex"
                                :key= "index"
                        ></question-fulltext>
                        <question-yes-no
                                v-if="questions[actualQuestionIndex].type === 'yesNo'"
                                :question="questions[actualQuestionIndex]"
                                :questionIndex = "actualQuestionIndex"
                                :key= "index"
                        ></question-yes-no>
                    </template>
                </div>
                <div class="modal-footer text-center">
                    <div class="text-center">
                        <button class="btn btn-primary pull-left"
                                @click="actualQuestionIndex-=1"
                                v-if="actualQuestionIndex > 0">
                            <i class="fas fa-long-arrow-alt-left"></i>
                        </button>

                        <span class="text-sm">{{actualQuestionIndex+1}} / {{questions.length}}</span>

                        <button class="btn btn-primary pull-right"
                                @click="actualQuestionIndex+=1"
                                v-if="actualQuestionIndex < questions.length-1">
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </button>
                    </div>
                </div>
                <loading :active.sync="isLoading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import QuestionChoices from './inputs/QuestionChoices.vue';
    import QuestionFulltext from './inputs/QuestionFulltext.vue';
    import QuestionYesNo from './inputs/QuestionYesNo.vue';


    export default {
        components: {
            Loading,
            QuestionChoices,
            QuestionFulltext,
            QuestionYesNo,
        },
        data() {
            return {
                test: {},
                questions: [],
                isLoading: false,
                actualQuestionIndex: 0
            }
        },
        mounted: function () {
            this.modal = this.$refs.modal;

            $(this.modal)
                .modal({
                    keyboard: false,
                    show: false,
                    backdrop: false,
                })
                .on("hidden.bs.modal", this.close);
        },
        watch: {
            testId: function (v) {
                if(v) {
                    this.load();
                    $(this.modal).modal('show');
                } else {
                    $(this.modal).modal('hide');
                }
            }
        },
        props: {
            testId: Number,
            onlineCourseHash: String,
        },
        methods: {
            load: function () {
                this.isLoading = true;

                this.$api.get("assigned-tests/"+ this.testId, {
                    params: {
                        onlineCourseHash: this.onlineCourseHash
                    }
                })
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.test = data.data;
                            this.isLoading = false;

                            this.loadResult();
                        } else {
                            alert(this.$t('comp.testResultDetail.testResultDetail.loadError'));
                        }
                    });
            },
            loadResult: function () {
                this.isLoading = true;

                this.$api.get("assigned-tests/"+ this.testId +"/result", {
                    params: {
                        onlineCourseHash: this.onlineCourseHash,
                    }
                })
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.questions = data.data.questions;
                            this.isLoading = false;
                        } else {
                            alert(this.$t('comp.testResultDetail.testResultDetail.loadResultError'));
                        }
                    });
            },
            close: function () {
                this.actualQuestionIndex = 0;
                this.view = 'welcome';
                this.$emit("close");
            },
            isCorrect: function (question) {
                if(question.type === 'fulltext') {
                    return true;
                }
                else if(question.type === 'yesNo') {
                    return question.correctByParticipant === question.correct;
                }
                else if(question.type === 'choices') {
                    var correct = true;

                    question.answers.forEach((a) => {
                        if(a.correct !== a.correctByParticipant) {
                            correct = false;
                        }
                    });

                    return correct;
                }

                return false;
            },
        }
    }
</script>

<style lang="less">
    .numbering {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            margin: 2px;

            button {
                border: 0;
                padding: 0 8px 0 8px;
                background: #8c8c8c;
                font-size: 18px;
                width: 40px;
            }

            button.correct {
                background: #00CC00;
            }

            button.bad {
                background: #e30742;
            }

            button.active {
                color: white;
            }
        }
    }

    .modal-footer {
        line-height: 30px;
    }
</style>