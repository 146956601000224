<template>
  <div class="nav-tabs-custom mt1">
    <ul class="nav nav-tabs">
      <router-link tag="li" :to="{ name: 'listing' }">
        <a>{{ $t("comp.manageMeasures.manageMeasures.measureList") }}</a>
      </router-link>
      <router-link tag="li" :to="{ name: 'tags' }">
        <a>{{ $t("comp.manageMeasures.manageMeasures.tags") }}</a>
      </router-link>
    </ul>
    <div class="tab-content vld-parent">
      <router-view></router-view>

      <div class="pull-left">
        <div class="btn-group btn-group-sm">
          <button class="btn btn-warning btn-sm" @click="importModal">
            <i class="fas fa-file-import"></i>
            {{ $t("common.importExport.import") }}
          </button>
          <button class="btn btn-warning btn-sm" @click="exportAll">
            <i class="fas fa-file-export"></i>
            {{ $t("common.importExport.export") }}
          </button>
        </div>
      </div>

      <div class="clearfix"></div>
    </div>

    <import-modal></import-modal>
  </div>
</template>

<script>
import MeasureListing from "./MeasureListing.vue";
import TagListing from "./TagListing.vue";
import VueRouter from "vue-router";
import { mapActions } from "vuex";
import store from "store";
import EventBus from "./EventBus";
import ImportModal from "./ImportModal.vue";

let router = new VueRouter({
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "/listing",
    },
    {
      name: "listing",
      path: "/listing",
      component: MeasureListing,
    },
    {
      name: "tags",
      path: "/tags",
      component: TagListing,
    },
  ],
});

export default {
  router,
  store,
  components: {
    ImportModal,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  created: function () {},
  methods: {
    ...mapActions("measures", ["fetchMeasures"]),
    ...mapActions("measureTags", ["fetchTags"]),
    exportAll: function () {
      this.isLoading = true;

      this.$api
        .get("measures/export", {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.$t("comp.manageMeasures.manageMeasures.fileName")
          ); // set filename
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    importModal() {
      EventBus.$emit("import-modal", true);
    },
  },
};
</script>

<style>
</style>
