<template>
    <div>
        <div class="panel-group">
            <template v-for="(question, index) in getQuestions">
                <question :question="question" :key="index" :index="index" @input="valueChanged(question, $event)" :value="getAnswer(question.id)"></question>
            </template>
        </div>
        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Question from "./Question.vue";

    export default {
        components: {
            Question
        },
        props: {
            asset: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
            };
        },
        created(){
        },
        computed: {
            ...mapGetters('assetImportanceAssessmentQuestions', ['getQuestions'])
        },
        methods: {
            valueChanged(question, value) {
                let answer = this.asset.importanceAssessmentAnswers.find((a)=>{
                    return a.questionId === question.id;
                });

                if (value == null) {
                    value = '';
                }

                if (!answer) {
                    answer = {
                        questionId: question.id,
                        value: value
                    };

                    this.asset.importanceAssessmentAnswers.push(answer);
                } else {
                    answer.value = value;
                }
            },
            getAnswer(questionId) {
                let v = this.asset.importanceAssessmentAnswers.find((q) => {
                    return q.questionId == questionId;
                })
                if (v === undefined) {
                    return null;
                } else {
                    return v.value;
                }
            }
        }
    }
</script>