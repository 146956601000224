<template id="main">
    <div>
        <div class="modal fade" role="dialog" ref="modal">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            {{$t('comp.kbAudit.editQuestion.modalTitle')}}
                        </h4>
                    </div>

                    <div class="modal-body" v-if="showModal">
                        <div class="form-group">
                            <div>
                                <label for="measure">{{$t('comp.kbAudit.editQuestion.measureListLabel')}}</label>

                                <div class="pull-right">
                                    <button class="btn btn-danger btn-xs" v-if="isSelectionMode" @click="toggleSelectionMode">
                                        {{$t('comp.kbAudit.editQuestion.cancelSelectMeasures')}}
                                    </button>
                                    <button class="btn btn-warning btn-xs" v-else @click="toggleSelectionMode">
                                        {{$t('comp.kbAudit.editQuestion.selectMeasures')}}
                                    </button>
                                </div>
                            </div>

                            <div v-if="isSelectionMode">
                                <div class="filter">
                                    <input class="form-control input-sm" v-model="searchFilter"
                                        :placeholder="$t('comp.kbAudit.editQuestion.searchPlaceholder')" />
                                </div>
                                <div class="filter">
                                    <multiselect v-model="tagIdsFilter"
                                                :options="tags.map(tag => tag.id)"
                                                :custom-label="opt => getTagById(opt).name"
                                                :multiple="true"
                                                :taggable="false"
                                                :closeOnSelect="false">
                                    </multiselect>
                                </div>
                                <table class="table table-striped table-hover" >
                                    <thead>
                                        <tr>
                                            <td width="20px"></td>
                                            <td width="50%">
                                                {{ $t("comp.kbAudit.editQuestion.name") }}
                                            </td>
                                            <td width="50%">
                                                {{ $t("comp.kbAudit.editQuestion.tags") }}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in filteredMeasureRows" :key="row.measure.id">
                                            <td width="20px">
                                                <input type="checkbox" class="checkbox" v-model="row.isSelected"/>
                                            </td>
                                            <td class="text-sm">
                                                {{ row.measure.name }}
                                            </td>
                                            <td class="text-sm">
                                                <span class="label label-default mr2" v-for="tag in row.tags" :key="tag.id">
                                                    {{tag.name}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <table class="table table-striped" style="margin-top: 10px;" v-else>
                                <tbody>
                                    <tr v-for="row in selectedMeasureRows" :key="row.measure.id">
                                        <td>
                                            <div>
                                                <strong style="margin-right: 10px;">{{row.measure.name}}</strong>
                                                <template v-for="tag in row.tags">
                                                    <span class="label label-default mr2" v-if="!tag.link">
                                                        {{tag.name}}
                                                    </span>
                                                    <a class="label label-default mr2 mt2" v-else :href="tag.link" target="_blank" style="word-wrap: break-word">
                                                        <i class="fas fa-link"></i>
                                                        {{tag.name}}
                                                    </a>
                                                </template>
                                            </div>

                                            <div v-if="getAssetsByMeasureId(row.measure.id).length" style="margin-top: 10px; font-size: 12px;">
                                                <span style="margin-left: 10px;">{{$t('comp.kbAudit.editQuestion.measureAssetsLabel')}}</span>
                                                <ul>
                                                    <li v-for="asset in getAssetsByMeasureId(row.measure.id)" :key="asset.id">
                                                        {{asset.name}}

                                                        <ul>
                                                            <li v-for="risk in asset.risks" :key="risk.id">
                                                                {{risk.threat}} : {{risk.vulnerability}} <template v-if="risk.isImplemented">{{$t('comp.kbAudit.editQuestion.isImplemented')}}</template>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            <button class="btn btn-danger btn-xs" @click="deselectMeasure(row)">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group" v-if="!isMassAdd">
                            <label>{{$t('comp.kbAudit.editQuestion.commentLabel')}}</label>
                            <ckeditor :editor="editor" v-model="comment" :config="editorConfig"></ckeditor>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default pull-left" @click="showModal = false">{{$t('comp.kbAudit.editQuestion.cancel')}}</button>
                        <button class="btn btn-success pull-right" @click="save">{{$t('comp.kbAudit.editQuestion.save')}}</button>
                    </div>

                    <loading :active="isLoading" :is-full-page="false"></loading>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import {mapGetters} from 'vuex';
    import Multiselect from '../../Controls/CsaMultiSelect.vue';
	import { EventBus } from "./event-bus.js";
	import PoaZokb from "./PoaZokb.vue";

    export default {
        components: {
            ckeditor: CKEditor.component,
            Multiselect,
            PoaZokb
        },
        data() {
            return {
                measureRows: [],
                filteredMeasureRows: [],
                comment: '',
                isSelectionMode: false,
                tagIdsFilter: [],
                searchFilter: null,
                isLoading: false,
                isMassAdd: false,
                modal: null,
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'bulletedList',
                            'numberedList',
                            'link'
                        ]
                    }
                },
            }
        },
        props: {
            showModal: Boolean,
            answer: Object,
            answers: Array
        },
        beforeMount() {
            if(this.answers?.length > 0) {
                this.isMassAdd = true;
                this.isSelectionMode = true;
                this.answer = {
                    measures: []
                };
            }
                this.createMeasureRows(this.answer.measures);
                this.comment = this.answer.comment;
        },
        mounted: function () {
            this.modal = this.$refs.modal;

            if(this.showModal) {
                this.show();
            }
        },
        watch: {
            showModal: function(showModal) {
                if(showModal) {
                    this.show();
                } else {
                    this.cancel();
                }
            },
            selectedMeasure: function (measureId) {
                if(measureId !== null) {
                    this.selectedMeasures.push(this.getMeasureById(measureId));
                    this.selectedMeasure = null;
                }
            },
            ['tagIdsFilter']: function () {
                this.filterMeasureRows();
            },
            ['searchFilter']: function () {
                this.filterMeasureRows();
            },
        },
        computed: {
            ...mapGetters('measures', {
                measures: 'getAll',
                getMeasureById: 'getById',
                getAssetsByMeasureId: 'getAssetsByMeasureId',
            }),
            ...mapGetters('measureTags', {
                tags: 'getAll',
                getTagById: 'getById'
            }),
            selectedMeasureRows: function () {
                return  this.measureRows.filter(m => m.isSelected);
            },
        },
        methods: {
            cancel() {
                $(this.modal).modal("hide");
                $(this.modal).on("hidden.bs.modal", () => {
                    this.$emit('hide');
                });
            },
            show() {
                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });
            },
            deselectMeasure(measureRow) {
                if(!confirm(this.$t('comp.kbAudit.editQuestion.removeMeasureConfirm'))) {
                    return;
                }

                measureRow.isSelected = false;
            },
            save() {
                let selectedMeasures = this.selectedMeasureRows.filter(m => m.isSelected).map(x => x.measure.id);

                if(this.isMassAdd){
                    this.answers.forEach(answer => {
                        const mergedMeasures = [...new Set([...selectedMeasures, ...answer.measures])];

                        answer.measures = mergedMeasures;
                    });

                    this.$api.post("audit-kb/mass-add-measures?type=zokb", this.answers).then(() => {
                        this.cancel();
                    });

                    //clear selected measures
                    this.answers.forEach(answer => {
                        answer.selected = false;
                    });
                    EventBus.$emit('clearSelectedAnswers');
                }
                else
                {
                    this.answer.measures = selectedMeasures;
                    this.answer.comment = this.comment;

                    this.$api.post("audit-kb/answer?type=zokb", this.answer).then(() => {
                        this.cancel();
                    });
                }
            },
            compareStrAsc(a, b) {
                // equal items sort equally
                if (a === b) {
                    return 0;
                }
                // nulls sort after anything else
                else if (a == null || typeof a === 'undefined') {
                    return 1;
                }
                else if (b == null || typeof b === 'undefined') {
                    return -1;
                }

                return a.localeCompare(b);
            },
            createMeasureRows: function (selectedMeasureIds) {
                this.measureRows = this.measures.map((m) => {
                    let tags = m.tags.map((t) => {
                        return this.getTagById(t);
                    });

                    return {
                        measure: m,
                        tags: tags,
                        isSelected: selectedMeasureIds ? selectedMeasureIds.findIndex(id => m.id === id) !== -1 : false,
                        searchText: m.name + ' ' + tags.map(t => t.name).join(' ')
                    }
                });

                this.filterMeasureRows();
            },
            toggleSelectionMode: function () {
                this.isSelectionMode = !this.isSelectionMode;
            },
            filterMeasureRows: function () {
                let rows = this.measureRows;

                if(this.searchFilter !== null && this.searchFilter.trim().length > 0) {
                    rows = rows.filter((row) => {
                        return row.searchText.toLowerCase().includes(this.searchFilter.toLowerCase());
                    });
                }

                if(this.tagIdsFilter.length > 0) {
                    rows = rows.filter((row) => {
                        return row.tags.some(t => this.tagIdsFilter.includes(t.id));
                    });
                }

                this.filteredMeasureRows = rows;
            }
        }
    }
</script>

<style scoped lang="less">
    .label {
        white-space: normal;
    }

    .filter {
        margin: 10px 0;
    }

    .multiselect {
        font-size: 12px;

        /deep/ .multiselect__tags {
            min-height: 30px;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }

        /deep/ .multiselect__select {
            height: 30px;
        }

        /deep/ .multiselect__placeholder {
            font-size: 12px;
        }

        /deep/ .multiselect__input {
            font-size: 12px;
        }

        /deep/ .multiselect__tag {
            font-size: 12px;
        }
    }
</style>

