<template>
    <div class="row margin-bottom" v-if="value !== undefined">
        <label class="control-label col-md-3">
            {{$t('comp.settings.tabAssets.guarantorCanModifyDeclarationOfApplicability.label')}}
        </label>
        <div class="col-lg-5 col-md-7" v-cloak>
            <div class="form-group">
                <label style="font-weight: normal">
                    <input type="checkbox" v-model="value" @change="save" ref="input" />
                    <span v-html="$t('comp.settings.tabAssets.guarantorCanModifyDeclarationOfApplicability.option')"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImpactCalculationMethod',
        data() {
            return {
                value: null
            };
        },
        created() {
            this.load();
        },
        methods: {
            load: function () {
                this.$api.get("config/guarantor-can-modify-declaration-of-applicability").then(({data}) => {
                    this.value = data.data;
                });
            },
            save: function () {
                this.$api.post("config/guarantor-can-modify-declaration-of-applicability", null, {
                    params: {
                        value: this.value
                    }
                }).then(({data}) => {
                    if(data.status === 'ok') {

                    }
                });
            }
        }
    }
</script>