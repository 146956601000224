<template id="main">
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{title}}</h4>
                </div>
                <div class="modal-body" n:snippet="body">
                    {{message}}
                </div>

                <div class="modal-footer">
                    <a class="btn btn-danger pull-right" @click="yep">{{continueBtn}}</a>
                    <a class="btn btn-success pull-left" @click="nope">{{abortBtn}}</a>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
</template>

<script>
export default {
    name: 'DeleteConfirmation',
    data() {
        return {
            title: null,
            message: null,
            continueBtn: null,
            abortBtn: null,
            modal: null,
            dataItem: null
        }
    },
    mounted() {
        this.modal = this.$refs.modal;
    },
    created(){
        this.initConfirm();
    },
    methods: {
        yep: function() {
            this.$emit("confirmed", this.dataItem);
            $(this.modal).modal("hide");
        },
        nope: function() {
            $(this.modal).modal("hide");
        },
        cancel: function () {
            this.initConfirm();
        },
        show: function (item = null) {
            this.dataItem = item;
            $(this.modal).modal();
        },
        initConfirm() {
            this.title = this.$t('comp.deleteConfirmation.deleteConfirmation.title');
            this.message = this.$t('comp.deleteConfirmation.deleteConfirmation.message');
            this.abortBtn = this.$t('common.no');
            this.continueBtn = this.$t('common.yes');
        }
    },

}
</script>