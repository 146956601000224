
<template>
  <div
    id="popover"
    :style="{
      top: top + 'px',
      left: left + 'px',
      display: display ? 'block' : 'none',
    }"
  >
    <strong class="text-danger">{{
      $t("comp.measuresRiskPopover.index.title")
    }}</strong>
    <template v-if="riskMeasures">
      <table class="table text-sm text-left">
        <tr>
          <th v-if="isShowHashIds">
            {{ $t("comp.riskPopover.index.id") }}
          </th>
          <th>{{ $t("comp.riskPopover.index.name") }}</th>
          <th>{{ $t("comp.measuresRiskPopover.index.applicable") }}</th>
          <th>{{ $t("comp.measuresRiskPopover.index.implemented") }}</th>
        </tr>
        <tr v-for="riskMeasure in riskMeasures" :key="riskMeasure.id">
          <td v-if="isShowHashIds">
            {{ riskMeasure.measure.hashId }}
          </td>
          <td>{{ riskMeasure.measure.name }}</td>
          <td>
            <span class="label label-success" v-if="riskMeasure.applicable">
              {{ $t("common.yes") }}
            </span>
            <span
              class="label label-danger"
              v-else-if="riskMeasure.applicable === false"
            >
              {{ $t("common.no") }}
            </span>
            <span class="label label-primary" v-else>
              {{ $t("common.undecided") }}
            </span>
          </td>
          <td class="text-center">
            <span class="label label-success" v-if="riskMeasure.implemented">
              {{ $t("common.yes") }}
            </span>
            <span class="label label-danger" v-else>
              {{ $t("common.no") }}
            </span>
          </td>
        </tr>
      </table>
    </template>

    <template v-else>
      <i class="fas fa-spinner fa-spin"></i>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      top: 0,
      left: 0,
      display: false,
      riskId: null,
      riskMeasures: null,
    };
  },
  computed: {
    ...mapGetters("userSettings", {
      isShowHashIds: "isShowHashIds",
    }),
  },
  created: function () {
    this.fetchIsShowHashIds();
  },
  methods: {
    ...mapActions("userSettings", {
      fetchIsShowHashIds: "fetchIsShowHashIds",
    }),
    show(riskId) {
      this.riskId = riskId;
      this.display = true;
      this.$api.get("risk/" + this.riskId + "/risk-measures/").then(
        ({ data }) => {
          if (data.status === "ok") {
            this.riskMeasures = data.data;
          } else {
            this.riskMeasures = [];
          }
        },
        (error) => {
          this.riskMeasures = [];
        }
      );
    },
    hide() {
      this.display = false;
      this.riskId = null;
      this.riskMeasures = null;
    },
    position(x, y) {
      this.top = y;
      this.left = x - 520;
    },
    mouseMoving(e) {
      this.top = e.clientY;
      this.left = e.clientX - 320;
    },
  },
};
</script>

<style scoped lang="less">
#popover {
  width: 500px;
  position: fixed;
  background: white;
  box-shadow: 0 0 5px #dddddd;
  border: 1px solid #dddddd;
  padding: 5px;
  text-align: center;
}

button {
  display: block;
  width: 100%;
  padding: 0;
}

table {
  margin-top: 12px;
  td {
    margin-left: 2px;
    padding: 2px 3px 2px 3px;
  }
}
th {
  margin-left: 2px;
  padding: 2px 3px 2px 3px;
}
</style>
