<template>
    <div class="box box-warning" v-if="person">
        <div class="box-header">
            <h2 class="box-title">{{$t('comp.orgStructure.personSystemUser.title')}}</h2>

            <div class="box-tools" v-if="user">
                <button class="btn btn-danger btn-sm" @click="removeSystemUser">
                    <i class="fas fa-times"></i>
                    {{$t('comp.orgStructure.personSystemUser.cancelConnection')}}
                </button>
            </div>
        </div>
        <div class="box-body" v-if="user">
            <div class="row">
                <div class="col-md-3 text-bold text-right">
                    {{$t('comp.orgStructure.personSystemUser.name')}}
                </div>
                <div class="col-md-6">
                    {{user.name}}
                </div>
            </div>
            <div class="row mt2">
                <div class="col-md-3 text-bold text-right">
                    {{$t('comp.orgStructure.personSystemUser.role')}}
                </div>
                <div class="col-md-6" v-if="user.role">
                    {{$t('enums.assignedUserRole.' + user.role)}}
                </div>
            </div>
            <div class="row mt2">
                <div class="col-md-3 text-bold text-right">
                    {{$t('comp.orgStructure.personSystemUser.email')}}
                </div>
                <div class="col-md-6">
                    {{user.email}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        props: ['person', 'users'],
        computed: {
            ...mapGetters('users', {
                getUser: 'getById',
                users: 'getAll',
            }),
            ...mapGetters("userRoles", {
                getRole: 'getByKey'
            })
        },
        watch: {
            'person.systemUserId': {
                handler() {
                    this.user = this.getUser(this.person.systemUserId);
                },
                deep: true
            },
            'users': {
                handler() {
                    this.user = this.getUser(this.person.systemUserId);
                },
                deep: true
            }
        },
        data(){
            return {
                user: null
            };
        },
        created() {
            this.user = this.getUser(this.person.systemUserId);
        },
        methods: {
            removeSystemUser() {
                this.person.systemUserId = null;

                this.$api.put("org-person/" + this.$route.params.personId + '/removeSystemUser', null).then(({data}) => {
                    if (data.status === 'ok') {
                        this.person = data.data;
                    }
                });
            }
        }
    }
</script>