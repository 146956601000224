<template>
    <div class="vld-parent">
        <div class="box box-primary vld-parent">
            <div class="box-header">
                <div class="pull-left">
                    <h2 class="box-title" v-if="query.searchedTerm">
                        {{ $t('comp.orgStructure.userListing.userListing.searching') }}
                        <strong>{{ query.searchedTerm }}</strong>
                    </h2>

                    <h2 class="box-title" v-else-if="unitId">
                        <template v-if="unit">
                            <i class="fas fa-address-book"></i>
                            {{ unit.name }} <small>| {{ $t('comp.orgStructure.userListing.userListing.personList')
                            }}</small>
                        </template>
                    </h2>

                    <h2 class="box-title" v-else>
                        <i class="fas fa-building"></i> {{ $t('comp.orgStructure.userListing.userListing.personListFull') }}
                    </h2>

                    <ol class="breadcrumb no-padding text-sm" style="background: none; margin: 10px 0 0 0;">
                        <li v-if="unit">
                            <router-link :to="{ name: 'listing' }" tag="a">
                                <i class="fas fa-building"></i>
                            </router-link>
                        </li>
                        <li v-for="(unit, index) in hierarchy" v-if="index + 1 !== hierarchy.length">
                            <router-link :to="{ name: 'listing', params: { unitId: unit.id } }" tag="a">
                                {{ unit.name }}
                            </router-link>
                        </li>
                    </ol>
                </div>

                <div class="pull-right">
                    <router-link :to="{ name: 'createPerson', params: { unitId: unitId } }" class="btn btn-success btn-sm">
                        <i class="fas fa-user-plus"></i>
                        {{ $t('comp.orgStructure.userListing.userListing.addNewPerson') }}
                    </router-link>
                    <router-link v-if="unitId" :to="{ name: 'assignPerson', params: { unitId: unitId } }"
                                 class="btn btn-success btn-sm">
                        <i class="fas fa-user-check"></i>
                        {{ $t('comp.orgStructure.userListing.userListing.addPersonToUnit') }}
                    </router-link>
                </div>
            </div>
            <div class="box-body">
                <datatable v-bind="$data">
                </datatable>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>

        <delete-confirmation :ref="'delConfUser'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import TdSystemUser from './TdSystemUser.vue';
import NavigationBus from "../NavigationBus.js";
import TdActions from "./TdActions.vue";
import ImportModalBus from '../ImportModal/ImportModalBus';
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation.vue';

export default {
    name: 'UserListing',
    components: {
        TdSystemUser,
        TdActions,
        DeleteConfirmation
    },
    watch: {
        'query': {
            handler() {
                if (this.watchQuery) {
                    this.handleQueryChange();
                }
            },
            deep: true
        },
        'unitId': {
            handler(v) {
                this.load();
                NavigationBus.$emit('select-node', this.unitId);
            }
        },
        '$route.query.q': {
            handler(v) {
                this.$set(this.query, 'searchedTerm', this.$route.query.q);
            }
        }
    },
    data() {
        return {
            HeaderSettings: false,
            tblClass: 'table',
            pageSizeOptions: [10, 25, 50, 100, 1000],
            columns: (() => {
                return [
                    { title: this.$t('comp.orgStructure.userListing.userListing.name'), field: 'name' },
                    { title: this.$t('comp.orgStructure.userListing.userListing.email'), field: 'email' },
                    { title: this.$t('comp.orgStructure.userListing.userListing.contact'), field: 'contact' },
                    {
                        title: this.$t('comp.orgStructure.userListing.userListing.systemUser'),
                        field: 'systemUser',
                        tdComp: 'TdSystemUser'
                    },
                    {
                        tdComp: 'TdActions',
                        field: 'systemUser',
                    },
                ];
            })(),
            xprops: {
                eventbus: new Vue()
            },
            data: [],
            total: 0,
            selection: [],
            summary: {},
            query: {},
            isLoading: false,
            unit: null,
            unitType: null,
            hierarchy: [],
            watchQuery: false,
            unitId: null
        }
    },
    computed: {
    },
    methods: {
        async getUsers() {
            let query = JSON.parse(JSON.stringify(this.query));

            if (this.unitId) {
                query.unitId = this.unitId;
            }

            this.isLoading = true;
            await this.$api.get("/org-person", { params: { query: query } })
                .then(({ data }) => {
                    if (data.status === 'ok') {
                        this.data = data.data.rows;
                        this.total = data.data.total;
                        this.isLoading = false;

                        this.data.forEach(row => {
                            row.unitId = this.unitId;
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        handleQueryChange() {
            this.getUsers();
        },
        async fetchHierarchy() {
            this.hierarchy = [];

            if (!this.unit) {
                return;
            }

            await this.$api.get("org-unit/" + this.unit.id + '/hierarchy').then(({ data }) => {
                if (data.status === 'ok') {
                    this.hierarchy = data.data;
                }
            });
        },
        async load() {
            if (this.unitId) {
                await this.$api.get("org-unit/" + this.unitId).then(({ data }) => {
                    if (data.status === 'ok') {
                        this.unit = data.data;
                    }
                });

                await this.getUsers();
                await this.fetchHierarchy();
            } else {
                await this.getUsers();
                await this.fetchHierarchy();
            }
        },
        deleteConfirmed(personId) {
            this.$api.delete("org-person/" + personId).then(({ data }) => {
                if (data.status === 'ok') {
                    this.load();
                    NavigationBus.$emit('remove-person', personId);
                }
            });
        },
        removePerson(row) {
            let modalWindow = this.$refs.delConfUser;
            modalWindow.show(row.id);
        }
    },
    async mounted() {
        this.unitId = this.$route.params.unitId
        this.$set(this.query, 'searchedTerm', this.$route.query.q);

        await this.load();

        this.$nextTick(() => {
            this.watchQuery = true;
        });

        this.xprops.eventbus
            .$on('REMOVE_PERSON', this.removePerson);

        ImportModalBus.$on('import-finished', () => {
            this.load();
        });

        NavigationBus.$on('reload-listing', (id) => {
            this.load();
        });
    }
}
</script>
<style>
.w-240 {
    width: 240px;
}

.breadcrumb>li+li:before {
    padding: 0 0 0 5px !important;
}
</style>
