<template>
    <div class="box box-primary question">
        <div class="box-header">
            <h3 class="box-title">{{questionIndex+1}}. {{ $t("question") }}</h3>

            <div class="pull-right" v-if="editable">
                <div class="btn-group">
                    <button class="btn btn-default btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="question.type === 'choices'">{{ $t("questionType.choices") }}</span>
                        <span v-if="question.type === 'yesNo'">{{ $t("questionType.yesNo") }}</span>
                        <span v-if="question.type === 'fulltext'">{{ $t("questionType.fulltext") }}</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li><a @click="changeType('choices')" style="cursor: pointer;">{{ $t("questionType.choices") }}</a></li>
                        <li><a @click="changeType('yesNo')" style="cursor: pointer;">{{ $t("questionType.yesNo") }}</a></li>
                        <li><a @click="changeType('fulltext')" style="cursor: pointer;">{{ $t("questionType.fulltext") }}</a></li>
                    </ul>
                </div>
                <div class="btn-group btn-group-xs">
                    <button class="btn btn-default btn-xs" @click="moveUp" v-bind:class="{disabled: questionIndex === 0}"><i class="fas fa-angle-up"></i></button>
                    <button class="btn btn-default btn-xs" @click="moveDown" v-bind:class="{disabled: questionIndex === total-1}"><i class="fas fa-angle-down"></i></button>
                </div>

                <button class="btn btn-danger btn-xs" @click="remove" v-bind:disabled="total === 1">{{ $t("remove") }}</button>
            </div>
        </div>
        <div class="box-body">
            <div class="form-group">
                <input  :name="questionIndex + '_question'"
                        :data-vv-as="$t('questionField')"
                        type="text"
                        class="form-control input-lg"
                        v-model="question.value"
                        :placeholder="$t('question')"
                        v-validate="'required'"
                        :disabled="!editable" />

                <span class="text-red">{{ errors.first(questionIndex + '_question') }}</span>
            </div>

            <slot/>
        </div>

        <div class="box-footer text-right" v-if="editable">
            <button class="btn btn-primary btn-xs" @click="addQuestion"><i class="fas fa-angle-down"></i> {{ $t("addQuestion") }}</button>
        </div>
    </div>
</template>

<script>

    export default {
        inject: [ 'parentValidator' ],
        components: {

        },
        data() {
            return {

            }
        },
        props: {
            question: Object,
            questionIndex: Number,
            total: Number,
            editable: Boolean
        },
        created () {
            this.$validator = this.parentValidator
        },
        methods: {
            addQuestion: function () {
                this.$emit("appendQuestion", this.question);
            },
            remove: function () {
                this.$emit("removeQuestion", this.question);
            },
            moveUp: function () {
                this.$emit("moveUp", this.question);
            },
            moveDown: function () {
                this.$emit("moveDown", this.question);
            },
            changeType: function ($newType) {
                this.question.type = $newType;
                this.question.correct = null;
            }
        }
    }
</script>