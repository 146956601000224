<template>
    <div>
        <risk-classification></risk-classification>
        <risk-auto-accept-threshold></risk-auto-accept-threshold>
    </div>
</template>

<script>
    import RiskClassification from "./TabRisk/RiskClassification.vue";
    import RiskAutoAcceptThreshold from "./TabRisk/RiskAutoAcceptThreshold.vue";

    export default {
        components: {
            RiskClassification,
            RiskAutoAcceptThreshold
        },
        data() {
            return {

            };
        }
    }
</script>