<template id="main">
    <div>
        <div class="modal fade" role="dialog" ref="modal">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            {{$t('comp.assetsImportWizard.editAsset.assetEdit')}}<template v-if="asset.name">: <strong>{{asset.name}}</strong></template>
                        </h4>
                    </div>
                    <div class="modal-body no-padding">
                        <div class="nav-tabs-custom no-margin">
                            <ul class="nav nav-tabs">
                                <router-link :to="{name: 'general'}" tag="li"><a>{{$t('comp.assetsImportWizard.editAsset.general')}}</a></router-link>
                                <router-link :to="{name: 'evaluation'}" tag="li"><a>{{$t('comp.assetsImportWizard.editAsset.evaluation')}}</a></router-link>
                                <router-link :to="{name: 'importanceAssessment'}" tag="li" v-if="asset.classification && asset.classification.isPrimary">
                                    <a>{{$t('comp.assetsImportWizard.editAsset.importanceAssessment')}}</a>
                                </router-link>
                                <router-link :to="{name: 'threats'}" tag="li"><a>{{$t('comp.assetsImportWizard.editAsset.threats')}}</a></router-link>
                                <router-link :to="{name: 'ways'}" tag="li"><a>{{$t('comp.assetsImportWizard.editAsset.ways')}}</a></router-link>
                                <router-link :to="{name: 'attributes'}" tag="li"><a>{{$t('comp.assetsImportWizard.editAsset.atributes')}}</a></router-link>
                            </ul>
                            <div class="tab-content" style="min-height: 400px">
                                <router-view :asset="asset"></router-view>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer text-right">
                        <button class="btn btn-link" @click="cancel">{{$t('common.cancel')}}</button>
                        <button class="btn btn-success pull-right" @click="save" :disabled="isLoading">{{$t('common.save')}}</button>
                    </div>

                    <loading :active="isLoading" :is-full-page="false"></loading>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import VueRouter from 'vue-router';
    import GlobalBus from "GlobalBus";

    const router = new VueRouter({
        linkExactActiveClass: 'active',
        linkActiveClass: 'active',
        routes: [
            {
                name: "general",
                path: '/general',
                component: () => import('../AssetModal/tabs/GeneralTab.vue'),
                props: true,
                meta: {
                    showModal: true
                }
            },
            {
                name: "evaluation",
                path: '/evaluation',
                component: () => import( '../AssetModal/tabs/EvaluationTab.vue'),
                props: true,
                meta: {
                    showModal: true
                }
            },
            {
                name: "importanceAssessment",
                path: '/importance-assessment',
                component: () => import('../AssetModal/ImportanceAssessmentTab/ImportanceAssessmentTab.vue'),
                props: true,
                meta: {
                    showModal: true
                }
            },
            {
                name: "threats",
                path: '/threats',
                component: () => import('../AssetModal/ThreatsTab/ThreatsTab.vue'),
                props: true,
                meta: {
                    showModal: true
                }
            },
            {
                name: "ways",
                path: '/ways',
                component: () => import('../AssetModal/tabs/WaysTab.vue'),
                props: true,
                meta: {
                    showModal: true
                }
            },
            {
                name: "attributes",
                path: '/own-attributes',
                component: () => import('../AssetModal/AttributesTab/AttributesTab.vue'),
                props: true,
                meta: {
                    showModal: true
                }
            }
        ],
    });

    export default {
        router,
        data() {
            return {
                asset: null,
                isLoading: false,
                showModal: this.$route.meta.showModal,
                modal: null,
                editMode: false
            }
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        created() {
            this.asset = JSON.parse(JSON.stringify(this.value));
        },
        mounted: function () {
            this.modal = this.$refs.modal;
            this.show();
        },
        methods: {
            cancel: function () {
                $(this.modal).modal("hide");
                this.$router.push("/");

                this.$emit("close");
            },
            show: function () {
                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });

                this.$router.push({ name: 'general' });
            },
            save: function () {
                this.value = Object.assign(this.value, this.asset);
                this.cancel();
            }
        }
    }
</script>

<style>
    .control {
        margin-top: 5px;
    }
</style>