<template>
    <div class="box box-primary vld-parent">
        <div class="box-header">
            <h2 class="box-title" v-if="$route.params.unitId && this.$route.name !== 'createUnit'">
                <template v-if="unit">
                    <i class="fas fa-address-book"></i>
                    {{unit.name}} <small>| {{$t('comp.orgStructure.editUnit.subTitle')}}</small>
                </template>
            </h2>
            <h2 class="box-title" v-else>
                <i class="fas fa-address-book"></i>
                {{$t('comp.orgStructure.editUnit.newUnit')}}
            </h2>

            <div class="box-tools">
                <button class="btn btn-primary btn-sm" @click="edit" v-if="!editMode">
                    <i class="fa fa-pencil-alt"></i> {{$t('common.edit')}}
                </button>
            </div>
        </div>
        <div class="box-body">
            <form class="form-horizontal" action="#" v-if="editMode" @submit.prevent="$route.name === 'createUnit' ? create() : save()">
                <input type="submit"
                       style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                       tabindex="-1" />

                <div class="form-group">
                    <label for="name" class="col-md-3 control-label" >{{$t('comp.orgStructure.editUnit.classification')}}</label>
                    <div class="col-md-6">
                        <ol class="breadcrumb pt2" >
                            <li><i class="fas fa-building"></i></li>
                            <li v-for="unit in hierarchy">
                                <em>{{unit.name}}</em>
                            </li>
                        </ol>
                    </div>
                </div>

                <div class="form-group">
                    <label for="name" class="col-md-3 control-label">{{$t('comp.orgStructure.editUnit.name')}}</label>
                    <div class="col-md-6">
                        <input class="form-control"
                               name="name"
                               id="name"
                               :placeholder="$t('comp.orgStructure.editUnit.namePlaceholder')"
                               v-validate="'required'"
                               :data-vv-as="$t('comp.orgStructure.editUnit.name-data-vv')"
                               v-model="editedData.name" />

                        <span class="text-red">{{ errors.first('name') }}</span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6 col-md-push-3 text-center">
                        <button class="btn btn-link" @click.prevent="cancelEdit">{{$t('common.cancel')}}</button>
                        <button class="btn btn-success" v-if="$route.name === 'createUnit'">
                            <i class="fa fa-plus"></i> {{$t('common.add')}}
                        </button>
                        <button class="btn btn-success" v-else>
                            <i class="fa fa-save"></i> {{$t('common.save')}}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import NavigationBus from './NavigationBus.js';

    export default {
        name: 'edit-unit',
        watch: {

        },
        data() {
            return {
                editMode: true,         // Currently unit has no info
                unit: null,
                isLoading: false,
                editedData: {
                    name: null
                },
                hierarchy: [],
            };
        },
        computed: {

        },
        methods: {
            cancelEdit() {
                this.$router.push({name: 'listing', params: {unitId: this.$route.params.unitId}});
            },
            async loadUnit() {
                await this.$api.get("org-unit/" + this.$route.params.unitId).then(({data})=>{
                    if(data.status === 'ok') {
                        this.unit = data.data;
                    }
                });
            },
            async fetchHierarchyByUnit(id) {
                await this.$api.get("org-unit/" + id + '/hierarchy').then(({data}) => {
                    if (data.status === 'ok') {
                        this.hierarchy = data.data;                       
                    }
                });
            },
            edit() {
                if(this.$route.name !== 'editUnit') {
                    this.$router.push({name: 'editUnit'});
                }

                this.editMode = true;
                this.$set(this.editedData, 'name', this.unit.name);
            },
            save() {
                this.$validator.validate().then(result => {
                    if(result) {
                        this.unit.name = this.editedData.name;

                        this.$api.post("org-unit/" + this.$route.params.unitId, this.unit).then(({data})=>{
                            if(data.status === 'ok') {        
                                NavigationBus.$emit("redraw-node", {id: this.unit.id, isUnit: true});
                                this.cancelEdit();
                            }
                        });
                    }
                });
            },
            create() {
                this.$validator.validate().then(result => {
                    if(result) {
                        let unit = {
                            name: this.editedData.name,
                            parentUnitId: this.$route.params.unitId ? parseInt(this.$route.params.unitId) : null,
                        };

                        this.$api.put("org-unit", unit).then(({data})=>{
                            if(data.status === 'ok') {
                                NavigationBus.$emit("create-unit", data.data);
                                this.cancelEdit();
                            }
                        });
                    }
                });
            },
        },
        beforeMount() {
            if(this.$route.name === 'createUnit') {
                this.editMode = true;
            } else {
                this.isLoading = true;

                this.loadUnit().then(()=>{
                    this.isLoading = false;

                    /*
                    // Use this if first should be visible detail, not edit
                    if(this.$route.meta.editMode) {
                        this.edit();
                    }
                    */

                   this.edit();
                });
            }
        },
        async created() {
            await this.fetchHierarchyByUnit(this.$route.params.unitId);
        }
    }
</script>

<style scoped lang="less">
    .breadcrumb {
        padding: 0;
        margin: 0;
        background: none;
    }
</style>