<template>
  <div class="modal fade" role="dialog" ref="modal">
    <div class="modal-dialog vld-parent" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ $t("comp.manageThreatVulnRegister.assetsModal.title") }}
          </h4>
        </div>
        <div class="modal-body" v-if="item">
          <div class="item-header-box">
            <div>
              <span class="item-label">{{
                $t("comp.manageThreatVulnRegister.assetsModal.assetType")
              }}</span>
              {{ item.assetType.name }}
            </div>
            <div>
              <span class="item-label">{{
                $t("comp.manageThreatVulnRegister.assetsModal.threat")
              }}</span>
              {{ item.threat.name }}
            </div>
            <div>
              <span class="item-label">{{
                $t("comp.manageThreatVulnRegister.assetsModal.vulnerability")
              }}</span>
              {{ item.vulnerability.name }}
            </div>
          </div>
            <div class="row">
                <div class="pull-right">
                    <button class="btn btn-danger btn-xs" v-if="isSelectionMode" @click="toggleSelectionMode">
                        {{$t('comp.manageThreatVulnRegister.assetsModal.endToggleSelectionMode')}}
                    </button>
                    <button class="btn btn-warning btn-xs" v-else @click="toggleSelectionMode">
                        {{$t('comp.manageThreatVulnRegister.assetsModal.toggleSelectionMode')}}
                    </button>
                </div>
            </div>

          <div class="assets">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <td width="20px" v-if="isSelectionMode"></td>
                  <td>
                    {{ $t("comp.manageThreatVulnRegister.assetsModal.asset") }}
                  </td>
                  <td>
                    {{ $t("comp.manageThreatVulnRegister.assetsModal.type") }}
                  </td>
                  <td>
                    {{ $t("comp.manageThreatVulnRegister.assetsModal.guarantor") }}
                  </td>
                  <td>
                    {{
                      $t("comp.manageThreatVulnRegister.assetsModal.location")
                    }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in filteredAssets">
                  <td width="20px" v-if="isSelectionMode">
                    <input
                      type="checkbox"
                      class="checkbox"
                      v-model="row.isAssignedUpdate"
                    />
                  </td>
                  <td class="text-sm">
                    <i :class="row.classificationCssIcon"
                      class="classification-icon"></i>
                      <a :href="row.link" target="_blank">{{ row.name }}</a>
                  </td>
                  <td class="text-sm">
                    {{ row.type }}
                  </td>
                  <td class="text-sm">
                    {{ row.guarantor }}
                  </td>
                  <td class="text-sm">
                    {{ row.location }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-default pull-left" @click="close()">
            {{ $t("common.close") }}
          </button>
          <button
            class="btn btn-success pull-right"
            @click="save()"
            :disabled="!isUpdated"
          >
            {{ $t("common.save") }}
          </button>
        </div>
      </div>

      <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import Multiselect from "../../Controls/CsaMultiSelect.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  store,
  components: {
    Multiselect,
  },

  data() {
    return {
      modal: null,
      item: null,
      assets: [],
      filteredAssets: [],
      isLoading: false,
      isSelectionMode: false,
      isAddMeasureMode: false,
    };
  },
  watch: {},
  created: function () {
  },
  computed: {
    isUpdated: function () {
      return this.assets.some((x) => x.isAssigned != x.isAssignedUpdate);
    },
  },
  async mounted() {
    this.modal = this.$refs.modal;
  },
  methods: {
    show: function (row) {
      this.isLoading = true;
      this.item = row;

      this.loadData().then(() => {
        $(this.modal).modal({
          backdrop: false,
          keyboard: false,
        });

        this.isLoading = false;
      });
    },
    close: function () {
      $(this.modal).modal("hide");
    },
    save: function () {
      this.isLoading = true;

      let data = {
        assetIdsToDelete: this.assets
          .filter((x) => x.isAssigned && !x.isAssignedUpdate)
          .map((x) => x.id),
        assetIdsToAdd: this.assets
          .filter((x) => !x.isAssigned && x.isAssignedUpdate)
          .map((x) => x.id),
      };

      this.$api
        .put(
          `asset/update-assets-for-risk-register-item?threatId=${this.item.threat.id}&vulnerabilityId=${this.item.vulnerability.id}`,
          data
        )
        .then((res) => {
          if (res.data.status == "ok") {
            this.showSaved();
            $(this.modal).modal("hide");
          } else {
            this.showUpdateError();
          }

          this.isLoading = true;
        });
    },
    async loadData() {
      await this.$api
        .get(
          `asset/fetch-assets-for-risk-register-item?threatId=${this.item.threat.id}&vulnerabilityId=${this.item.vulnerability.id}`
        )
        .then((res) => {
          if (res.data.status == "ok") {
            let assets = res.data.data;
            assets.forEach((item) => {
              item.isAssignedUpdate = item.isAssigned;
            });

            this.assets = assets;

            if(this.assets.some((x) => x.isAssigned == true)) {
              this.isSelectionMode = false;
            }
            else{
                this.isSelectionMode = true;

            }
              this.debouncedFetchFilteredRiskMeasures();
          }
        });
    },
    toggleSelectionMode: function () {
        this.isSelectionMode = !this.isSelectionMode;
        this.filterAssetsDataSet();
    },
      debouncedFetchFilteredRiskMeasures:  _.debounce(function(showLoading = true) {
          this.filterAssetsDataSet(showLoading);
      }, 700),
      filterAssetsDataSet:  function(showLoading = true) {
          this.isLoading = showLoading;

          let filteredAssets = [];
          if(this.isSelectionMode){
              filteredAssets = this.assets;
          }
          else{
              filteredAssets = this.assets.filter(x => x.isAssignedUpdate);
          };

          this.filteredAssets = filteredAssets;
          this.isLoading = false;
      },
  },
  notifications: {
    showSaved: {
      message: "comp.manageThreatVulnRegister.assetsModal.savedMsg",
      type: "success",
    },
    showUpdateError: {
      message: "comp.manageThreatVulnRegister.assetsModal.updateErrorMsg",
      type: "danger",
    },
  },
};
</script>

<style scoped lang="less">
div.modal-dialog {
  width: 50%;
  min-width: 500px;
}

.item-header-box {
  .item-label {
    font-weight: bold;
  }
}
.pull-right{
    padding-right: 20px;
}

.assets {
  margin-top: 20px;
  max-height: 60vh;
  overflow-y: auto;

  .classification-icon {
    margin-right: 10px;
  }
}
</style>
