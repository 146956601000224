<template>
    <spinner :active="$props.active" v-on="$listeners" color="red" :is-full-page="$props.isFullPage" z-index="1500" :width="70" :height="70" loader="dots">
        <span slot="before" class="logo">
        </span>
    </spinner>
</template>

<script>
    import Spinner from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        extends: Spinner,
        components: {
            Spinner
        }
    }
</script>

<style scoped>
    .logo {
        background: url("../../img/CSA-logo.svg") no-repeat center center;
        background-size: contain;
        width: 55px;
        height: 21px;
        position: absolute;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
    }
</style>