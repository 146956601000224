<template>
    <div class="row">

        <div class="col-md-7">
            <evaluation-control type="assetConfidence" v-model="asset.evaluation.confidence" :options="confidenceOptions" :value="asset.evaluation.confidence" :disabled="asset.isEvaluationDisabled" />
            <evaluation-control type="assetIntegrity" v-model="asset.evaluation.integrity" :options="integrityOptions" :value="asset.evaluation.integrity" :disabled="asset.isEvaluationDisabled" />
            <evaluation-control type="assetAvailability" v-model="asset.evaluation.availability" :options="availabilityOptions" :value="asset.evaluation.availability" :disabled="asset.isEvaluationDisabled" />
        </div>

        <div class="col-md-5">
            <div class="text-center" style="margin-top: 50px;">
                <p><strong style="font-size: 20px;">{{$t('comp.assetModal.tabs.evaluationTab.computedImpact')}}</strong></p>

                <div class="margin-bottom"></div>

                <template v-if="computedImpact && computedImpact.value">
                    <p>
                        <span class="label" style="font-size: 25px; color: white;" :style="{backgroundColor: computedImpact.color}">
                            {{computedImpact.value}}
                        </span>
                    </p>
                    <div class="margin-bottom"></div>
                    <div style="font-size: 20px; text-transform: uppercase" :style="{color: computedImpact.color}">
                        <strong>{{computedImpact.classification.name}}</strong>
                    </div>
                    <div class="text-primary info text-sm" v-show="selectedVulnerability">
                        {{computedImpact.classification.description}}
                    </div>
                </template>

                <em v-else>{{$t('comp.assetModal.tabs.evaluationTab.incompleteEvaluation')}}</em>
            </div>
        </div>

        <div class="col-md-12 mt2">
            <div class="form-group">
                <label>{{$t('comp.assetModal.tabs.evaluationTab.rulesOfProtection')}}</label>

                <ckeditor :editor="editor" v-model="asset.rulesOfProtection" :config="editorConfig"></ckeditor>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>

<script>
    import EvaluationControl from '../Controls/Evaluation.vue';
    import {mapGetters, mapActions} from 'vuex';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import '@ckeditor/ckeditor5-build-classic/build/translations/cs';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import _ from 'lodash';

    export default {
        name: "evaluation-tab",
        components: {
            EvaluationControl,
            ckeditor: CKEditor.component
        },
        props: {
            asset: {
                type: Object,
                required: true
            }
        },
        watch: {
            'asset.evaluation': {
                deep: true,
                handler: function () {
                    _.debounce(this.computeImpact, 100).call();
                },
                immediate: true
            },
        },
        data() {
            return {
                computedImpact: null,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'cs',
                    toolbar: ['bold', 'italic', "bulletedList","numberedList"]
                },
                isLoading: false
            };
        },
        computed: {
            ...mapGetters("assetScaleItems", {
                integrityOptions: "getIntegrityScale",
                availabilityOptions: "getAvailabilityScale",
                confidenceOptions: "getConfidenceScale",
                getById: "getScaleItemById"
            })
        },
        methods: {
            ...mapActions('assetScaleItems', ['fetchIntegrity', 'fetchAvailability', 'fetchConfidence']),
            computeImpact: async function () {
                if(!this.asset.evaluation.integrity ||
                    !this.asset.evaluation.availability ||
                    !this.asset.evaluation.confidence) {
                    this.computedImpact = null;
                    return;
                }

                await this.$api.get("asset/compute-impact-value", {
                    params: {
                        integrityId: this.asset.evaluation.integrity.id,
                        availabilityId: this.asset.evaluation.availability.id,
                        confidenceId: this.asset.evaluation.confidence.id,
                    }
                }).then(({data}) => {
                    if(data.status === 'ok') {
                        this.computedImpact = data.data;
                    }
                });
            },
        }
    };
</script>
