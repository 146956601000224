<template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog vld-parent" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{$t('Nový systémový uživatel')}}
                    </h4>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <label for="CreateSystemUserModal_name">{{$t('comp.orgStructure.createSystemUserModal.name')}}</label>
                        <input class="form-control" id="CreateSystemUserModal_name" type="text"
                               name="CreateSystemUserModal_name"
                               v-model="user.name"
                               v-validate="'required'"
                               :data-vv-as="$t('comp.orgStructure.createSystemUserModal.name-data-vv')" />

                        <span class="text-red">{{ errors.first('CreateSystemUserModal_name') }}</span>
                    </div>
                    <div class="form-group">
                        <label for="CreateSystemUserModal_surname">{{$t('comp.orgStructure.createSystemUserModal.lastName')}}</label>
                        <input class="form-control" id="CreateSystemUserModal_surname" name="CreateSystemUserModal_surname" type="text"
                               v-model="user.surname"
                               v-validate="'required'"
                               :data-vv-as="$t('comp.orgStructure.createSystemUserModal.lastName-data-vv')" />

                        <span class="text-red">{{ errors.first('CreateSystemUserModal_surname') }}</span>
                    </div>
                    <div class="form-group">
                        <label for="CreateSystemUserModal_email">{{$t('comp.orgStructure.createSystemUserModal.email')}}</label>
                        <input
                                class="form-control"
                                id="CreateSystemUserModal_email"
                                name="CreateSystemUserModal_email"
                                v-model="user.email"
                                type="text"
                                v-validate="'required|email'"
                                :data-vv-as="$t('comp.orgStructure.createSystemUserModal.email-data-vv')" />

                        <span class="text-red">{{ errors.first('CreateSystemUserModal_email') }}</span>
                    </div>
                    <div class="form-group">
                        <label for="CreateSystemUserModal_phone">{{$t('comp.orgStructure.createSystemUserModal.phone')}}</label>
                        <input class="form-control" id="CreateSystemUserModal_phone" type="text"
                               v-model="user.phone" />
                    </div>
                    <div class="form-group">
                        <label for="CreateSystemUserModal_role">{{$t('comp.orgStructure.createSystemUserModal.role')}}</label>
                        <select class="form-control" id="CreateSystemUserModal_role" type="text"
                                name="CreateSystemUserModal_role"
                                v-model="user.role"
                                v-validate="'required'"
                                data-vv-as="role">

                            <option v-for="role in roles" :value="role.key" :key="role.key">
                                {{$t('enums.assignedUserRole.' + role.key)}}
                            </option>
                        </select>

                        <span class="text-red">{{ errors.first('CreateSystemUserModal_role') }}</span>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-link pull-left" @click="close">{{$t('common.close')}}</button>
                    <button class="btn btn-success pull-right" @click="create">
                        {{$t('common.create')}}
                    </button>
                </div>
            </div>

            <loading :active="isLoading" :is-full-page="false"></loading>
        </div>
    </div>
</template>

<script>
    import EventBus from './Bus';
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'CreateSystemUserModal',
        data() {
            return {
                modal: null,
                isLoading: false,
                user: {
                    name: null,
                    surname: null,
                    email: null,
                    phone: null,
                    role: null,
                }
            }
        },
        mounted: function () {
            this.modal = this.$refs.modal;


            EventBus.$on("show", ()=>{
                this.show();
            });
            EventBus.$on("hide", ()=>{
                this.close();
            });
        },
        computed: {
            ...mapGetters('userRoles', {
                roles: 'getAll'
            }),
        },
        methods: {
            ...mapActions('userRoles', {
                fetchRoles: 'fetchRoles'
            }),
            close: function () {
                $(this.modal).modal("hide");
            },
            show: function () {
                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });

                this.fetchRoles();
                this.$validator.reset();
            },
            create() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.isLoading = true;

                        this.$api.put("users", this.user).then(({data}) => {
                            if(data.status === 'ok') {
                                this.close();
                                EventBus.$emit('user-created', data.data);
                                this.isLoading = false;
                            } else {
                                this.isLoading = false;
                                if(data.code === 'not_unique_email') {
                                    alert(this.$t('comp.orgStructure.createSystemUserModal.notUniqueEmail'));
                                }
                            }
                        });
                    }
                });
            }
        }
    }
</script>