<template>
    <div class="nav-tabs-custom">
        <div class="tab-content vld-parent" style="width: 100%">
            <div class="vld-parent">
                <div class="pull-right">
                    <button class="btn btn-success" @click="add">
                        {{ $t('comp.manageThreatVulnRegister.threatVulnListing.manageRegister') }}
                    </button>
                </div>
                <div class="pull-left" style='padding-top:12px'>
                    <button class="btn btn-xs btn-danger" @click="removeSelected"
                            :disabled="currentSelections.length > 0 ? disabled : ''">
                        <i class="fa fa-trash"></i> {{ $t('common.delete') }}
                    </button>
                </div>

                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th style="width: 16%">{{ $t('comp.manageThreatVulnRegister.threatVulnListing.assetType') }}
                            </th>
                            <th style="width: 40%" colspan="3">
                                {{ $t('comp.manageThreatVulnRegister.threatVulnListing.threat') }}</th>
                            <th style="width: 40%" colspan="3">
                                {{ $t('comp.manageThreatVulnRegister.threatVulnListing.vuln') }}</th>
                            <th style="width: 4%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="width: 2%">
                                <label class="form-checkbox" style="margin-bottom: 0px">
                                    <input type="checkbox" :checked="isAllSelected" @click="selectAll" />
                                    <i class="form-icon"></i>
                                </label>
                            </td>
                            <td width="10%">
                                <input class="form-control input-sm" v-model="filter.assetTypeName"
                                       :placeholder="$t('comp.manageThreatVulnRegister.threatVulnListing.searchPlaceholder')" />
                            </td>
                            <td width="21%" colspan="2">
                                <input class="form-control input-sm" v-model="filter.threatName"
                                       :placeholder="$t('comp.manageThreatVulnRegister.threatVulnListing.searchPlaceholder')" />
                            </td>
                            <td width="21%">
                                <multiselect
                                             style='height:20px !important'
                                             v-model="filter.threatTags"
                                             :options="getAllThreatTags.map(tag => tag.id)"
                                             :custom-label="opt => getAllThreatTags.find(x => x.id == opt).name"
                                             :multiple="true"
                                             :taggable="false"
                                             :closeOnSelect="false">
                                </multiselect>
                            </td>
                            <td width="21%" colspan="2">
                                <input class="form-control input-sm" v-model="filter.vulnerabilityName"
                                       :placeholder="$t('comp.manageThreatVulnRegister.threatVulnListing.searchPlaceholder')" />
                            </td>
                            <td width="21%">
                                <multiselect
                                             v-model="filter.vulnerabilityTags"
                                             :options="getAllVulnTags.map(tag => tag.id)"
                                             :custom-label="opt => getAllVulnTags.find(x => x.id == opt).name"
                                             :multiple="true"
                                             :taggable="false"
                                             :closeOnSelect="false">
                                </multiselect>
                            </td>
                            <td>
                                <button class="btn btn-danger btn-xs" @click="cancelFilter" v-if="isFiltered">
                                    {{ $t('common.cancelFilter') }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="threatVuln in threatVulnerabilities">
                            <threat-vuln :item="threatVuln" :isShowHashIds="isShowHashIds" :key="threatVuln.id"
                                         @removed="remove"
                                         @reload="reload"></threat-vuln>
                        </template>
                    </tbody>
                </table>

                <div class="pull-right" v-if="paginator.itemCount > 1">
                    {{ paginator.offset + 1 }}-{{ paginator.offset + paginator.length }}/{{ paginator.itemCount }}
                    <div class="btn-group" style="padding-right: 20px">
                        <button class="btn btn-default btn-sm" @click="paginator.page--" :disabled="paginator.page === 1">
                            <i class="fa fa-chevron-left"></i>
                        </button>
                        <button class="btn btn-default btn-sm" @click="paginator.page++"
                                :disabled="paginator.page === paginator.pageCount">
                            <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>
                    <div id="threatVulnPerPage_LocalStorage" class="btn-group">
                        <select class="form-control" v-model="paginator.perPage">
                            <option v-for="perPage in perPageOptions" :value="perPage" :key="perPage"> {{ perPage }}
                            </option>
                        </select>
                    </div>
                </div>
                <loading :active="isLoading"
                         :is-full-page="false"></loading>

                <delete-confirmation :ref="'massDelConfThreatVulnerabilities'"
                                     v-on:confirmed="massDeleteConfirmed($event)"></delete-confirmation>
            </div>
            <div class="clearfix"></div>
        </div>

        <binding-modal ref="bm" @hidden="onModalHidden"></binding-modal>
    </div>
</template>

<script>
import ThreatVuln from './ThreatVuln.vue';
import Multiselect from '../../Controls/CsaMultiSelect.vue';
import BindingModal from './BindingModal.vue';
import { mapActions, mapGetters } from 'vuex';
import store from '../../store';
import _ from 'lodash';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

export default {
    store,
    components: {
        ThreatVuln,
        Multiselect,
        BindingModal,
        DeleteConfirmation
    },
    data() {
        return {
            threatVulnerabilities: [],
            filter: {
                threatName: null,
                vulnerabilityName: null,
                assetTypeName: null,
                threatTags: [],
                vulnerabilityTags: []
            },
            paginator: {
                perPage: 25,
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100],
            isLoading: false,
            watchFilter: true,
            currentSelections: []
        }
    },
    watch: {
        ['filter.threatName']: function () {
            if (this.watchFilter) {
                this.debouncedFilterName();
            }
        },
        ['filter.vulnerabilityName']: function () {
            if (this.watchFilter) {
                this.debouncedFilterName();
            }
        },
        ['filter.assetTypeName']: function () {
            if (this.watchFilter) {
                this.debouncedFilterName();
            }
        },
        ['filter.threatTags']: function () {
            if (this.watchFilter) {
                this.fetchFilteredThreatVulnerabilities();
            }
        },
        ['filter.vulnerabilityTags']: function () {
            if (this.watchFilter) {
                this.fetchFilteredThreatVulnerabilities();
            }
        },
        ['paginator.page']: function () {
            this.fetchFilteredThreatVulnerabilities();
        },
        ['paginator.perPage']: function () {
            this.fetchFilteredThreatVulnerabilities();
            localStorage.setItem('threatVulnPerPage_LocalStorage', this.paginator.perPage);
        },
        threatVulnerabilities: {
            handler() {
                this.currentSelections = this.threatVulnerabilities.filter(item => item.selected).map(item => item.id);
            },
            deep: true
        }
    },
    mounted: function () {
        if (localStorage.getItem('threatVulnPerPage_LocalStorage')) {
            try {
                this.paginator.perPage = localStorage.getItem('threatVulnPerPage_LocalStorage');
            } catch (e) {
                localStorage.removeItem('threatVulnPerPage_LocalStorage');
            }
        }
    },
    computed: {
        ...mapGetters('threatVulnerabilities', {
            fetchThreatVulnerabilities: 'fetchThreatVulnerabilities',
        }),
        ...mapGetters('vulnerabilityTags', {
            getAllVulnTags: 'getAll'
        }),
        ...mapGetters('threatTags', {
            getAllThreatTags: 'getAll'
        }),
        ...mapGetters('userSettings', {
            isShowHashIds: 'isShowHashIds',
        }),
        isFiltered: function () {
            return this.filter.assetTypeName || this.filter.threatName || this.filter.vulnerabilityName
                || this.filter.threatTags.length || this.filter.vulnerabilityTags.length;
        },
        isAllSelected() {
            //checked/(unchecked) checkBox selectAll if every/(more then one) item on items is checked/(unchecked)
            if (this.currentSelections.length <= 0) {
                return false;
            }
            return this.threatVulnerabilities.every(item => item.selected);
        }
    },
    created: function () {
        this.fetchIsShowHashIds();
        this.fetchFilteredThreatVulnerabilities();
        this.fetchThreatTags();
        this.fetchVulnerabilityTags();

        this.debouncedFilterName = _.debounce(this.fetchFilteredThreatVulnerabilities, 700);
    },
    methods: {
        add: function () {
            // show modal window
            this.$refs.bm.show();
        },
        remove: function () {
            this.showDeleted();
            this.fetchFilteredThreatVulnerabilities();
        },
        onModalHidden: function () {
            this.$refs.bm.close();
            this.fetchFilteredThreatVulnerabilities();
        },
        removeSelected: function () {
            let modalWindow = this.$refs.massDelConfThreatVulnerabilities;
            modalWindow.show();
        },
        massDeleteConfirmed: function () {
            this.isLoading = true;

            this.$api.post("threat-vuln/mass-delete", this.currentSelections).then(res => {
                if (res.data.status == "ok") {
                    this.showDeletedSelected();
                    this.reload(true);
                }
                else {
                    this.showDeleteError();
                }
                this.isLoading = false;
            });
        },
        fetchFilteredThreatVulnerabilities: async function (showLoading = true) {
            this.isLoading = showLoading;

            await this.filterThreatVulnerabilities({ filter: this.filter, paginator: this.paginator }).then((threatVulnerabilities) => {
                threatVulnerabilities.items.forEach(threatVulnerability => {
                    threatVulnerability.selected = false;
                });
                this.threatVulnerabilities = threatVulnerabilities.items;
                this.paginator = threatVulnerabilities.paginator;
                this.isLoading = false;

                this.$forceUpdate();
            });
        },
        selectAll() {
            let selected = !this.isAllSelected;
            //check/uncheck all item checkBox on items if selectAll is checked/(unchecked)
            this.threatVulnerabilities.forEach(item => item.selected = selected);
        },
        ...mapActions('threatVulnerabilities', {
            filterThreatVulnerabilities: 'filterThreatVulnerabilities',
        }),
        ...mapActions('threatTags', {
            fetchThreatTags: 'fetchTags',
        }),
        ...mapActions('vulnerabilityTags', {
            fetchVulnerabilityTags: 'fetchTags',
        }),
        ...mapActions('userSettings', {
            fetchIsShowHashIds: 'fetchIsShowHashIds'
        }),
        cancelFilter: function () {
            this.watchFilter = false;

            this.filter = {
                threatName: null,
                vulnerabilityName: null,
                assetTypeName: null,
                threatTags: [],
                vulnerabilityTags: []
            };

            this.fetchFilteredThreatVulnerabilities();

            this.$nextTick(() => {
                this.watchFilter = true;
            });
        },
        reload: function (showLoading = false) {
            this.fetchFilteredThreatVulnerabilities(showLoading);
        }
    },
    notifications: {
        showDeleted: {
            message: 'comp.manageThreatVulnRegister.threatVulnListing.bindingDeleted',
            type: 'success'
        },
        showDeletedSelected: {
            message: 'comp.manageThreatVulnRegister.threatVulnListing.bindingsDeleted',
            type: 'success'
        },
        showDeleteError: {
            message: 'comp.manageThreatVulnRegister.threatVulnListing.bindingsDeletedError',
            type: 'error'
        }
    }
}
</script>


<style scoped lang="less">
.multiselect {
    font-size: 12px;

    /deep/ .multiselect__tags {
        min-height: 30px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    /deep/ .multiselect__select {
        height: 30px;
    }

    /deep/ .multiselect__placeholder {
        font-size: 12px;
    }

    /deep/ .multiselect__input {
        font-size: 12px;
    }

    /deep/ .multiselect__tag {
        font-size: 12px;
    }
}
</style>
