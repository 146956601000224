<template>
    <tr>
        <td>
            <input type="checkbox" class="checkbox" v-model="item.selected" />
        </td>
        <td class="text-sm">
            {{ item.assetType.name }}
        </td>

        <td class="text-sm" width="4%" v-if="isShowHashIds">
            {{ item.threat.hashId }}
        </td>
        <td class="text-sm" :colspan="isShowHashIds ? 2 : 3">
            {{ item.threat.name }}
            <i class="fas fa-info-circle description-icon" v-if="item.threat.description" :title="item.threat.description"></i>
        </td>

        <td class="text-sm" width="4%" v-if="isShowHashIds">
            <span v-if="isShowHashIds">{{ item.vulnerability.hashId }}</span>
        </td>
        <td class="text-sm" :colspan="isShowHashIds ? 2 : 3">
            {{ item.vulnerability.name }}
            <i class="fas fa-info-circle description-icon" v-if="item.vulnerability.description" :title="item.vulnerability.description"></i>
        </td>

        <td style="white-space: nowrap;">
            <button
                class="btn btn-danger btn-xs"
                @click="remove"
                :disabled="isLoading"
            >
                <i class="fas fa-trash fa-fw"></i>
            </button>
            <button
                class="btn btn-warning btn-xs"
                @click="showAssetsModal"
                :disabled="isLoading"
                :title="$t('comp.manageThreatVulnRegister.threatVuln.assetsBtnTooltip')"
            >
                <i class="fas fa-cubes fa-fw"></i>
            </button>
        </td>

        <delete-confirmation
            :ref="'delconfVulnerability'"
            v-on:confirmed="deleteConfirmed($event)"
        ></delete-confirmation>
        <assets-modal ref="assetsModalRef"></assets-modal>
    </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation.vue";
import AssetsModal from "./AssetsModal.vue";

export default {
    components: {
        DeleteConfirmation,
        AssetsModal
    },
    props: {
        item: {
            type: Object,
            require: true
        },
        isShowHashIds: {
            type: Boolean,
            require: true
        }
    },
    methods: {
        remove: function() {
            let modalWindow = this.$refs.delconfVulnerability;
            modalWindow.show();
        },
        showAssetsModal: function() {
            let assetsModal = this.$refs.assetsModalRef;
            assetsModal.show(this.item);
        },
        deleteConfirmed: function() {
            this.isLoading = true;

            if (this.item.id) {
                this.removeThreatVulnerability(this.item).then(() => {
                    this.isLoading = false;
                    this.$emit("removed", this.item);
                });
            } else {
                this.$emit("removed", this.item);
                this.isLoading = false;
            }
        },
        ...mapActions("threatVulnerabilities", {
            removeThreatVulnerability: "removeThreatVulnerability"
        })
    }
};
</script>

<style scoped lang="less">
#name {
    height: 40px;
}

#save {
    vertical-align: middle;
}

.label {
    white-space: normal;
}
</style>
