<template>
    <div class="row margin-bottom">
        <label class="control-label col-md-3">
            {{$t('comp.settings.tabRisk.riskClassification.label')}}
        </label>
        <div class="col-lg-5 col-md-7" v-cloak  v-if="value">
            <em v-if="!isEdit">{{ value.name }}</em>

            <template v-else>
                <div class="form-group">
                    <select class="form-control input-sm" v-model="tempValue">
                        <option v-for="item in items" :value="item.id">
                            {{item.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <button class="btn btn-sm btn-success ajax" @click="save">
                        {{$t('common.save')}}
                    </button>
                    <button class="btn btn-sm btn-warning" @click="cancel">
                        {{$t('common.cancel')}}
                    </button>
                </div>
            </template>

            <template v-if="!isEdit">
                (<button class="btn-link no-padding" @click="editMode">{{$t('common.change')}}</button>)
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RiskClassification',
        data() {
            return {
                value: null,
                tempValue: null,
                isEdit: false,
                items: []
            };
        },
        created() {
            this.load();
        },
        methods: {
            load: function () {
                this.$api.get("risk-classification").then(({data}) => {
                    if(data.status === 'ok') {
                        this.items = data.data;
                    }
                });

                this.$api.get("config/default-risk-classification").then(({data}) => {
                    this.value = data.data;
                });
            },
            save: function () {
                this.$api.post("config/default-risk-classification/" + this.tempValue).then(({data}) => {
                    if(data.status === 'ok') {
                        this.value = this.items.find((c)=>{
                           if(c.id === this.tempValue) {
                               return c;
                           }
                        });
                        this.cancel();
                    }
                });
            },
            cancel: function () {
                this.isEdit = false;
                this.tempValue = null;
            },
            editMode: function () {
                this.isEdit = true;
                this.tempValue = this.value.id;
            }
        }
    }
</script>