<template>
    <div class="vld-parent" :key="$route.params.personId">
        <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
                <li :class="{active: view === 'general'}">
                    <a @click="view = 'general'" style="cursor: pointer">
                        <template v-if="!editMode || person">
                            <i class="fas fa-user"></i>
                            <strong v-if="person">{{person.name}}</strong> <small>| {{$t('comp.orgStructure.editPerson.subTitle')}}</small>
                        </template>
                        <template v-else>{{$t('comp.orgStructure.editPerson.newPerson')}}</template>
                    </a>
                </li>
                <li :class="{active: view === 'attachments'}" v-if="person">
                    <a @click="view = 'attachments'" style="cursor: pointer">
                        <i class="fas fa-paperclip"></i> {{$t('comp.orgStructure.editPerson.attachment')}}
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane" v-if="view === 'general'" :class="{active: view === 'general'}">
                    <form class="form-horizontal" action="#" v-if="editMode"
                          @submit.prevent="$route.name === 'createPerson' ? create() : update()">
                        <input type="submit"
                               style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                               tabindex="-1"/>

                        <div class="form-group">
                            <label for="name" class="col-md-3 control-label" >{{$t('comp.orgStructure.editPerson.classification')}}</label>
                            <div class="col-md-6">
                                <ol class="breadcrumb pt2" >
                                    <li><i class="fas fa-building"></i></li>
                                    <li v-for="unit in hierarchy">
                                        <em>{{unit.name}}</em>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="name" class="col-md-3 control-label">{{$t('comp.orgStructure.editPerson.name')}}</label>
                            <div class="col-md-6">
                                <input class="form-control"
                                       name="name"
                                       id="name"
                                       :placeholder="$t('comp.orgStructure.editPerson.namePlaceholder')"
                                       v-model="editedData.name"
                                       v-validate="'required'"
                                       :data-vv-as="$t('comp.orgStructure.editPerson.name-data-vv')" />

                                <span class="text-red">{{ errors.first('name') }}</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="email" class="col-md-3 control-label">{{$t('comp.orgStructure.editPerson.email')}}</label>
                            <div class="col-md-6">
                                <input class="form-control"
                                       name="email"
                                       id="email"
                                       :placeholder="$t('comp.orgStructure.editPerson.emailPlaceholder')"
                                       :readonly="editedData.systemUser"
                                       v-validate="'email'"
                                       v-model="editedData.email"/>

                                <span class="text-red">{{ errors.first('email') }}</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="email" class="col-md-3 control-label">{{$t('comp.orgStructure.editPerson.contact')}}</label>
                            <div class="col-md-6">
                                <input class="form-control"
                                       name="contact"
                                       id="contact"
                                       :placeholder="$t('comp.orgStructure.editPerson.contactPlaceholder')"
                                       v-model="editedData.contact"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="systemUser" class="col-md-3 control-label">{{$t('comp.orgStructure.editPerson.connectWithSysUser')}} </label>
                            <div class="col-md-6">
                                <csa-select :options="availableSystemUsers" v-model="editedData.systemUser" @select="systemUserSelected" id="systemUser" label="fullName">
                                </csa-select>

                                <button class="btn btn-link pull-right" style="padding: 0;" @click.prevent="newSystemUser">
                                    {{$t('comp.orgStructure.editPerson.newUser')}}
                                </button>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="col-md-3 control-label">{{$t('comp.orgStructure.editPerson.groups')}}</label>
                            <div class="col-md-6 groups_select">
                                <multiselect v-model="personPersonGroupsId"
                                            :options="personGroups.map(x => x.id)"
                                            :custom-label="opt => personGroups.find(x => x.id == opt).name"
                                            :multiple="true"
                                            :taggable="false"
                                            :closeOnSelect="false">
                                </multiselect>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="notDeleteOnSync" class="col-md-3 control-label" style="padding-top: 0;">{{$t('comp.orgStructure.editPerson.notDeleteOnSync')}}</label>
                            <div class="col-md-6">
                                <input name="name"
                                       id="notDeleteOnSync"
                                       type="checkbox"
                                       v-model="editedData.notDeleteOnSync" />
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-md-6 col-md-push-3 text-center">
                                <button class="btn btn-link" @click.prevent="cancelEdit">{{$t('common.cancel')}}</button>
                                <button class="btn btn-success" v-if="$route.name === 'createPerson'">
                                    <i class="fa fa-plus"></i> {{$t('common.add')}}
                                </button>
                                <button class="btn btn-success" type="submit" v-else>
                                    <i class="fa fa-save"></i> {{$t('common.save')}}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div v-else>
                        <div class="row">
                            <div class="col-md-3 text-bold text-right">
                                {{$t('comp.orgStructure.editPerson.classification')}}:
                            </div>
                            <div class="col-md-6" v-if="person">
                                <ol class="breadcrumb">
                                    <li><i class="fas fa-building"></i></li>
                                    <li v-for="unit in hierarchy">
                                        <em>{{unit.name}}</em>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-3 text-bold text-right">
                                {{$t('comp.orgStructure.editPerson.name')}}:
                            </div>
                            <div class="col-md-6" v-if="person">
                                {{person.name}}
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-3 text-bold text-right">
                                {{$t('comp.orgStructure.editPerson.email')}}:
                            </div>
                            <div class="col-md-6" v-if="person">
                                <a :href="'mailto:' + person.email">{{person.email}}</a>
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-3 text-bold text-right">
                                {{$t('comp.orgStructure.editPerson.contact')}}:
                            </div>
                            <div class="col-md-6" v-if="person">
                                {{person.contact}}
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-3 text-bold text-right">
                                {{$t('comp.orgStructure.editPerson.groups')}}:
                            </div>
                            <div class="col-md-6">
                                <template v-for="personGroup in personPersonGroups" >
                                    <span class="label mr1 group-label" :style="{'background': personGroup.color}" >
                                        {{personGroup.name}}
                                    </span>
                                </template>
                            </div>
                        </div>


                        <div class="row mt2">
                            <div class="col-md-3 text-bold text-right">
                                {{$t('comp.orgStructure.editPerson.notDeleteOnSync')}}:
                            </div>
                            <div class="col-md-6" v-if="person">
                                {{person.notDeleteOnSync ? $t('common.yes') : $t('common.no')}}
                            </div>
                        </div>
                        <div class="row mt2">
                            <div class="col-md-6 col-md-push-3">
                                <button class="btn btn-danger btn-sm" @click="removePerson" v-if="!editMode">
                                    <i class="fa fa-trash"></i> {{$t('common.delete')}}
                                </button>
                                <button class="btn btn-warning btn-sm" @click="edit" v-if="!editMode">
                                    <i class="fa fa-pencil-alt"></i> {{$t('common.edit')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" v-if="view === 'attachments' && person" :class="{active: view === 'attachments'}">
                    <person-attachments-tab :person-id="person.id" :key="person.id"></person-attachments-tab>
                </div>
            </div>
            <!-- /.tab-content -->
        </div>

        <person-system-user :person="person" :users="users" v-if="!editMode && person && users && person.systemUserId"></person-system-user>
        <create-system-user-modal></create-system-user-modal>
        <delete-confirmation :ref="'delconfUser'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>

        <loading :active="isLoading" :is-full-page="false"></loading>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import PersonSystemUser from './PersonSystemUser.vue';
    import NavigationBus from './NavigationBus.js';
    import CsaSelect from 'Controls/CsaMultiSelect.vue';
    import SystemUserModalBus from './CreateSystemUserModal/Bus';
    import CreateSystemUserModal from './CreateSystemUserModal/index.vue';
    import PersonAttachmentsTab from './PersonAttachmentsTab.vue';
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';
    import Multiselect from '../../Controls/CsaMultiSelect.vue';


    export default {
        name: 'edit-person',
        components: {
            PersonSystemUser,
            CsaSelect,
            CreateSystemUserModal,
            PersonAttachmentsTab,
            DeleteConfirmation,
            Multiselect
        },
        data() {
            return {
                view: 'general',
                editMode: false,
                editedData: {
                    name: null,
                    email: null,
                    contact: null,
                    systemUser: null,
                    notDeleteOnSync: false,
                },
                person: null,
                isLoading: false,
                hierarchy: [],
                availableSystemUsers: [],
                personGroups: [],
                personPersonGroups: [],
                personPersonGroupsId: [],
            };
        },
        computed: {
            ...mapGetters('users', {
                getUser: 'getById',
                users: 'getAll',
            })
        },
        watch: {
            '$route.params.personId': function (id) {
                this.load();
            },
            '$route.params.unitId': function (id) {
                this.load();
            }
        },
        methods: {
            ...mapActions('users', {
                fetchUsers: 'fetchUsers'
            }),
            cancelEdit() {
                if (this.$route.name === 'createPerson') {
                    this.$router.push({name: 'listing', params: {unitId: this.$route.params.unitId}});
                } else {
                    this.editMode = false;
                }
            },
            setUserFullName: function(user){
                user.fullName = `${user.name} (${user.email}, ${this.$t('enums.assignedUserRole.' + user.role)})`;
            },
            async fetchPerson() {
                await this.$api.get("org-person/" + this.$route.params.personId).then(({data}) => {
                    if (data.status === 'ok') {
                        this.person = data.data;
                    }
                });
            },
            async fetchAvailableSystemUsers() {
                await this.$api.get("org-person/unassigned-system-users").then(({data}) => {
                    if (data.status === 'ok') {
                        let availableSystemUserIds = data.data;                                                
                        this.availableSystemUsers = [];

                        this.users.forEach((user)=>{
                            if(availableSystemUserIds.indexOf(user.id) !== -1) {
                                this.setUserFullName(user);
                                this.availableSystemUsers.push(user);
                            }
                        });
                    }
                });
            },
            async fetchHierarchyByUnit(id) {
                if(id){
                    await this.$api.get("org-unit/" + id + '/hierarchy').then(({data}) => {
                        if (data.status === 'ok') {
                            this.hierarchy = data.data;                       
                        }
                    });
                }
            },
            async fetchPersonGroups() {
                await this.$api.get("org-person-group").then(({data})=>{
                    if(data.status === 'ok') {
                        this.personGroups = data.data;
                    }
                });
            },
            async fetchPersonPersonGroups() {
                if(this.$route.params.personId){
                    await this.$api.get("org-person/" + this.$route.params.personId + '/fetchPersonGroups').then(({data}) => {
                        if (data.status === 'ok') {
                            this.personPersonGroups = data.data;   
                            this.personPersonGroupsId = this.personPersonGroups.map(x => x.id);
                        }
                    });
                }
                else {
                    this.personPersonGroups = [];
                }
            },
            edit() {
                this.editMode = true;
                this.$set(this.editedData, 'name', this.person.name);
                this.$set(this.editedData, 'email', this.person.email);
                this.$set(this.editedData, 'contact', this.person.contact);
                this.$set(this.editedData, 'notDeleteOnSync', this.person.notDeleteOnSync);

                if(this.person.systemUserId) {
                    let user = this.getUser(this.person.systemUserId);

                    this.$set(this.editedData, 'systemUser', user);

                    if(this.availableSystemUsers.indexOf(user) === -1) {
                        this.setUserFullName(user);
                        this.availableSystemUsers.push(user);
                    }
                }
                else{
                    this.$set(this.editedData, 'systemUser', null);
                }
            },
            create() {
                this.$validator.validate().then(result => {
                    if (result) {
                        let unitId = this.$route.params.unitId ?? null

                        let person = {
                            name: this.editedData.name,
                            email: this.editedData.email,
                            contact: this.editedData.contact,
                            notDeleteOnSync: this.editedData.notDeleteOnSync,
                            systemUserId: this.editedData.systemUser ? this.editedData.systemUser.id : null,
                            unitId: unitId,
                            personGroupIds: this.personPersonGroupsId
                        };

                        this.$api.post("org-person", person).then(({data}) => {
                            if (data.status === 'ok') {
                                NavigationBus.$emit("create-person", {person: data.data, unitId: unitId});
                                this.$router.push({name: 'listing', params: {unitId: person.unitId}});
                            }
                        });
                    }
                });
            },
            update() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.person.name = this.editedData.name;
                        this.person.email = this.editedData.email;
                        this.person.contact = this.editedData.contact;
                        this.person.notDeleteOnSync = this.editedData.notDeleteOnSync;
                        this.person.systemUserId = this.editedData.systemUser ? this.editedData.systemUser.id : null;
                        this.person.personGroupIds = this.personPersonGroupsId;

                        this.$api.put("org-person/" + this.$route.params.personId, this.person).then(({data}) => {
                            if (data.status === 'ok') {
                                NavigationBus.$emit('redraw-node', {id: this.person.id, isUnit: false});
                                this.fetchPersonPersonGroups();
                                this.cancelEdit();
                            }
                        });
                    }
                });
            },
            systemUserSelected(user) {
                this.$set(this.editedData, 'email', user.email);
            },
            newSystemUser() {
                SystemUserModalBus.$emit('show');
            },
            deleteConfirmed(personId) {
                this.$api.delete("org-person/" + personId).then(({data}) => {
                    if (data.status === 'ok') {
                        NavigationBus.$emit('remove-person', personId);
                    }
                });
            },
            removePerson() {
                let modalWindow = this.$refs.delconfUser;
                modalWindow.show(this.person.id);
            },
            async load(){
                if (this.$route.name === 'createPerson') {
                    this.editMode = true;
                } else {
                    this.editMode = false;
                    this.isLoading = true;

                    await this.fetchPerson().then(() => {  
                        if (this.$route.meta.editMode) {
                            this.edit();
                        }
                    });
                }

                await this.fetchHierarchyByUnit(this.$route.params.unitId);
                await this.fetchUsers();
                await this.fetchAvailableSystemUsers();
                await this.fetchPersonGroups();
                await this.fetchPersonPersonGroups();


                SystemUserModalBus.$on('user-created', async (user)=>{
                    await this.fetchUsers();
                    await this.fetchAvailableSystemUsers();

                    this.editedData.email = user.email;
                    this.editedData.systemUser = user;
                });

                this.isLoading = false;
            }
        },
        
        async created() {
           await this.load();

            this.$nextTick(()=>{
                $(".groups-select select").selectpicker();
            });
        }
    }
</script>

<style scoped lang="less">
    .breadcrumb {
        padding: 0;
        margin: 0;
        background: none;
    }
</style>