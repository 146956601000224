<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>
        <div class="box box-primary">
            <div class="box-header">
                <h3 class="box-title">
                    <i class="fas fa-superscript"></i>
                    {{test.name}} <span class="label label-default">{{ $t("result") }}</span>
                </h3>

                <div class="pull-right">
                    <router-link to="/" class="btn btn-default btn-xs ajax">
                        <i class="fas fa-angle-left"></i>
                        {{ $t("backToTestListing") }}
                    </router-link>
                </div>
            </div>
            <div class="box-body">
                <div class="table-responsive">
                    <table class="table table-striped table-hover fixed-header">
                        <thead>
                            <tr class="bg-gray-light">
                                <th>
                                    <div>
                                        {{ $t("solver") }}
                                    </div>
                                </th>
                                <th class="text-center">
                                    <div>
                                        {{ $t('evaluation')}}
                                    </div>
                                </th>
                                <th class="question-number" v-for="(question, index) in questions">
                                    <div>
                                        <span class="label label-primary" :title="question.value">
                                            {{index+1}}
                                        </span>
                                        <div class="sm-text">
                                            {{question.value}}
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(result, index) in results">
                                <td class="nowrap">
                                    <div>
                                        {{result.solver}}
                                    </div>
                                </td>

                                <td class="evaluation">
                                    <div>
                                        <span class="label label-success mr3" v-if="test.evaluationType === evaluationType_MinForSuccess && test.minForSuccess <= result.correctQuestionsCount">
                                            {{ $t("state.successful") }}
                                        </span>
                                        <span class="label label-danger mr3" v-else-if="test.evaluationType === evaluationType_MinForSuccess && test.minForSuccess > result.correctQuestionsCount">
                                            {{ $t("state.unsuccessful") }}
                                        </span>
                                        <span class="evaluation-scale" v-else-if="test.evaluationType === evaluationType_ScaleTextAndEmoji">
                                            <i :class="stepIcons[result.resultScaleEvaluationStep.step-1]" ></i>
                                            {{result.resultScaleEvaluationStep.evaluationText}}
                                        </span>
                                        <span class="evaluation-scale" v-else-if="test.evaluationType === evaluationType_ScaleText">
                                            {{result.resultScaleEvaluationStep.evaluationText}}
                                        </span>
                                        <span class="evaluation-scale" v-else-if="test.evaluationType === evaluationType_ScaleEmoji">
                                            <i :class="stepIcons[result.resultScaleEvaluationStep.step-1]" ></i>
                                        </span>

                                        {{result.correctQuestionsCount}} / {{evaluatedQuestions.length}}
                                    </div>
                                </td>

                                <td class="success text-center" v-for="(question, index) in questions" :class="{
                                    success: question.type !== 'fulltext' && isCorrect(question, result),
                                    danger: question.type !== 'fulltext' && !isCorrect(question, result)}">

                                    <div>
                                        <div v-if="question.type === 'fulltext'" class="sm-text">
                                            {{getAnswerValue(question, result)}}
                                        </div>
                                        <template v-else>
                                            <i class="fas fa-check" v-if="isCorrect(question, result)"></i>
                                            <i class="fas fa-times" v-else></i>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "ManageElearningTests",
        components: {

        },
        data() {
            return {
                isLoading: false,
                test: {},
                questions: [],
                results: [],
                evaluationType_None: 0,
                evaluationType_MinForSuccess: 1,
                evaluationType_ScaleTextAndEmoji: 2,
                evaluationType_ScaleText: 3,
                evaluationType_ScaleEmoji: 4,
                stepIcons: [
                    "far fa-frown-open",
                    "far fa-frown",
                    "far fa-meh",
                    "far fa-smile",
                    "far fa-grin"
                ]
            }
        },
        beforeMount: function () {
            this.loadTest();
            this.loadResult();
            this.loadTestQuestions();
        },
        props: {
            testId: String,
        },
        computed: {
            evaluatedQuestions: function () {
                return this.questions.filter((q)=>{
                    return q.type !== 'fulltext';
                });
            }
        },
        methods: {
            loadTest: function () {
                this.isLoading = true;

                this.$api.get("elearning-tests/" + this.testId)
                    .then(({data}) => {
                        this.test = data.data
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            loadResult: function () {
                this.isLoading = true;

                this.$api.get("elearning-tests/result/" + this.testId)
                    .then(({data}) => {
                        this.results = data.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            loadTestQuestions: function () {
                this.isLoading = true;

                this.$api.get("elearning-tests/test-questions/" + this.testId)
                    .then(({data}) => {
                        this.questions = data.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            isCorrect: function (question, result) {
                let answer = this.getAnswer(question, result);

                if(!answer) {
                    return false;
                }

                if(question.type === 'fulltext') {
                    return true;
                }
                else if(question.type === 'yesNo') {
                    return question.correct === answer.value;
                }
                else if(question.type === 'choices') {
                    let correctAnswers = [];

                    question.answers.forEach((a) => {
                        if(a.correct) {
                            correctAnswers.push(a.id);
                        }
                    });

                    let difference = correctAnswers.filter(x => !answer.value.includes(x));

                    return difference.length === 0;
                }

                return false;
            },
            correctCount: function (result) {
                return this.evaluatedQuestions.filter((q) => {
                    return this.isCorrect(q, result);
                }).length;
            },
            getAnswer: function (question, result) {
                let answer = result.answers.find((a) => {
                    if (a.questionId === question.id) {
                        return true;
                    }
                });

                return answer;
            },
            getAnswerValue: function (question, result) {
                let answer = this.getAnswer(question, result);

                if(answer){
                    return answer.value;
                }

                return answer;
            }
        }
    }
</script>

<style lang="less">
    .question-number {
        font-size: 14pt;
        text-align: center;
    }

    tbody td{
        border: 2px solid #fff;
    }
    td.result {
        &.successed {
            border-left: 7px solid green;
        }
        &.failed {
            border-left: 7px solid red;
        }
    }

    td.evaluation {
        white-space: nowrap;
    }

    table.fixed-header{
        width: auto;
        max-width: none;
    }

    table.fixed-header tbody{
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        height: 70vh;
    }

    table.fixed-header thead tr {
        display:block;
    }

    table.fixed-header thead tr > th > div, .fixed-header tbody tr > td > div {
        display: block;
        width: 200px;
    }

    table.fixed-header .sm-text {
        font-size: 10px;
    }
</style>
