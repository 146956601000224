<template>
  <tr class="vld-parent">
    <template v-if="item.editMode">
      <td></td>
      <td>
        <input
          v-model="editedItem.name"
          type="text"
          id="name"
          name="name"
          ref="name"
          class="form-control"
          :placeholder="$t('comp.manageVulnerabilities.tag.namePlaceholder')"
          @keypress.enter="save"
          v-validate="'required|max:256'"
          :data-vv-as="$t('comp.manageVulnerabilities.tag.name-data-vv')"
        />

        <span class="help-block text-red">{{ errors.first("name") }}</span>
      </td>
      <td class="text-right" id="save">
        <button class="btn btn-success btn-xs" @click="save">
          <i class="fas fa-save"></i>
        </button>
        <button class="btn btn-default btn-xs" @click="cancel">
          <i class="fas fa-times"></i>
        </button>
      </td>
    </template>
    <template v-else>
      <td>
        <input type="checkbox" class="checkbox" v-model="item.selected" />
      </td>
      <td class="text-sm">
        {{ item.name }}
      </td>
      <td class="text-right">
        <div class="label text-black pull-left" v-if="item.methodology">
          {{ $t("common.methodology") }} <img src="/favicon.ico" />
        </div>
        <button
          class="btn btn-danger btn-xs"
          @click="remove(item)"
          :disabled="isLoading"
        >
          <i class="fas fa-trash"></i>
        </button>
        <button
          class="btn btn-warning btn-xs"
          @click="toggleEdit"
          :disabled="isLoading"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
      </td>
    </template>
    <delete-confirmation
      :ref="'delconfTag'"
      v-on:confirmed="deleteConfirmed($event)"
    ></delete-confirmation>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation.vue";

export default {
  name: "create-vulnerability",
  props: {
    readonly: {
      type: Boolean,
    },
    item: {
      type: Object,
      require: true,
    },
  },
  components: {
    DeleteConfirmation,
  },
  beforeMount: function () {
    if (!this.item.id) {
      this.$set(this.item, "editMode", true);
    } else {
      this.$set(this.item, "editMode", false);
    }

    this.editedItem = Object.assign({}, this.item);
  },
  mounted() {
    if (this.item.editMode) {
      this.$refs.name.focus();
    }
  },
  data() {
    return {
      edit: false,
      editedItem: null,
    };
  },
  methods: {
    toggleEdit: function () {
      if (this.item.editMode) {
        this.$set(this.item, "editMode", false);
      } else {
        this.$set(this.item, "editMode", true);
      }

      if (this.editMode) {
        this.editedItem = Object.assign({}, this.item);
      }
    },
    save: function () {
      this.$validator.validate().then((result) => {
        if (!result) {
          return;
        }

        this.item.name = this.editedItem.name;

        if (this.item.id) {
          this.updateTag(this.item);
        } else {
          //create
          this.addTag(this.item).then((tag) => {
            this.item.id = tag.id;
          });
        }

        this.showSaved();
        this.toggleEdit();
      });
    },
    cancel: function () {
      if (this.item.id) {
        this.toggleEdit();
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.$emit("removed", this.item);
      }
    },
    remove: function (item) {
      let modalWindow = this.$refs.delconfTag;
      modalWindow.show(item);
    },
    deleteConfirmed: function (item) {
      this.isLoading = true;

      if (item.id) {
        this.removeTag(item).then(() => {
          this.$emit("removed", item);
          this.isLoading = false;
        });
      } else {
        this.$emit("removed", item);
        this.isLoading = false;
      }
    },
    ...mapActions("vulnerabilityTags", ["removeTag", "updateTag", "addTag"]),
  },
  notifications: {
    showSaved: {
      message: "common.saved",
      type: "success",
    },
  },
};
</script>

<style scoped lang="less">
#name {
  height: 40px;
}

#save {
  vertical-align: middle;
}
</style>
