<template>
    <div class="vld-parent">
        <div
            class="panel panel-default"
            v-if="deep < 2"
            v-show="!deep || visibleQuestionsCount"
        >
            <div
                class="panel-heading"
                style="cursor: pointer;"
                @click="collapsedToggle()"
            >
                <h4 class="panel-title">
                    <strong>{{ getSectionNumber() }}</strong>
                    <span style="padding-left: 20px;">
                        {{ item.value }}
                        <span
                            v-if="item.reference"
                            style="padding-left: 10px; color: #adadad"
                        >
                            ({{ item.reference }})
                        </span>
                    </span>

                    <span class="pull-right info">
                        <span
                            class="label"
                            v-bind:class="labelCountClass"
                            style="width: 70px; display: block;"
                        >
                            {{ answeredCount }} / {{ visibleQuestionsCount }}
                        </span>
                    </span>
                </h4>
            </div>
            <div class="panel-body" v-show="!collapsed">
                <div class="text-center" v-if="!visibleQuestionsCount">
                    <em>{{ $t("comp.kbAudit.kbSection.empty") }}</em>
                </div>

                <table class="table" v-if="deep > 0">
                    <tr id="legend">
                        <td
                            class="text-success answer"
                            :title="$t('comp.kbAudit.answerType.implemented')"
                        >
                            <i
                                class="fa fa-check-circle fa-2x fa-fw"
                                aria-hidden="true"
                            ></i>
                        </td>
                        <td
                            class="text-primary answer"
                            :title="
                                $t('comp.kbAudit.answerType.inImplementation')
                            "
                        >
                            <i
                                class="fa fa-minus-circle fa-2x fa-fw"
                                aria-hidden="true"
                            ></i>
                        </td>
                        <td
                            class="text-warning answer"
                            :title="$t('comp.kbAudit.answerType.notApplicable')"
                        >
                            <i
                                class="fa fa-times-circle fa-2x fa-fw"
                                aria-hidden="true"
                            ></i>
                        </td>
                        <td
                            class="text-danger answer"
                            :title="
                                $t('comp.kbAudit.answerType.notImplemented')
                            "
                        >
                            <i
                                class="fa fa-exclamation-triangle fa-2x fa-fw"
                                aria-hidden="true"
                            ></i>
                        </td>
                        <td></td>
                        <td class="no-padding">
                            <div style="width: 130px; float: right;">
                                <canvas
                                    ref="chart"
                                    style="width: 130px;"
                                ></canvas>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="clearfix"></div>

                <template v-for="(item, index) in item.items">
                    <kb-section
                        :item="item"
                        v-if="item.type === 'section'"
                        :number="index + 1"
                        :deep="deep + 1"
                        :countryLaw="countryLaw"
                        ref="sections"
                        @visibilityChanged="questionVisibilityChanged"
                        @answerChanged="answerChanged"
                    ></kb-section>
                    <kb-question
                        :item="item"
                        v-else-if="item.type === 'question'"
                        :question="item"
                        :countryLaw="countryLaw"
                        ref="questions"
                        @visibilityChanged="questionVisibilityChanged"
                        @answerChanged="answerChanged"
                    ></kb-question>
                </template>
            </div>
        </div>
        <div
            v-else-if="deep === 2"
            class="questions"
            v-show="visibleQuestionsCount"
        >
            <div style="padding-bottom: 15px">
                <strong>{{ item.value }}</strong>
                <div class="text-sm text-gray" v-if="item.reference">
                    {{ item.reference }}
                </div>
            </div>

            <div>
                <template v-for="(item, index) in item.items">
                    <kb-section
                        :item="item"
                        v-if="item.type === 'section'"
                        :number="index + 1"
                        :deep="deep + 1"
                        :countryLaw="countryLaw"
                        ref="sections"
                        @visibilityChanged="questionVisibilityChanged"
                        @answerChanged="answerChanged"
                    ></kb-section>
                    <kb-question
                        :item="item"
                        v-else-if="item.type === 'question'"
                        :question="item"
                        :countryLaw="countryLaw"
                        ref="questions"
                        @visibilityChanged="questionVisibilityChanged"
                        @answerChanged="answerChanged"
                    ></kb-question>
                </template>
            </div>
        </div>
        <div
            v-else
            id="questions"
            style="padding-left: 30px; margin-left: 10px; border-left: 4px solid #adadad"
            v-show="visibleQuestionsCount"
        >
            <div style="padding-bottom: 15px">
                <strong>{{ item.value }}</strong>
                <div class="text-sm text-gray" v-if="item.reference">
                    {{ item.reference }}
                </div>
            </div>

            <div>
                <template v-for="(item, index) in item.items">
                    <kb-section
                        :item="item"
                        v-if="item.type === 'section'"
                        :number="index + 1"
                        :deep="deep + 1"
                        :countryLaw="countryLaw"
                        ref="sections"
                        @visibilityChanged="questionVisibilityChanged"
                        @answerChanged="answerChanged"
                    ></kb-section>
                    <kb-question
                        :item="item"
                        v-else-if="item.type === 'question'"
                        :question="item"
                        :countryLaw="countryLaw"
                        ref="questions"
                        @visibilityChanged="questionVisibilityChanged"
                        @answerChanged="answerChanged"
                    ></kb-question>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import KbQuestion from "./KbQuestion.vue";
import { EventBus } from "./event-bus.js";
import KbSection from "./KbSection.vue";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
    name: "KbSection",
    components: {
        KbQuestion,
        KbSection
    },
    data() {
        return {
            isLoading: false,
            collapsed: true,
            chart: null,
            visibleQuestionsInChildSections: [],
            answeredCount: 0
        };
    },
    props: {
        item: Object,
        number: Number,
        deep: Number,
        readonly: {
            type: Boolean,
            required: true
        },
        countryLaw: {
            // 'CZ', 'SK'
            type: String,
            required: true
        }
    },
    mounted() {
        this.showChart();
        this.$watch(
            "visibleQuestionsCount",
            count => {
                this.$emit("visibilityChanged", this.item.id, count);
                this.answeredCount =
                    this.visibleQuestionsCount - this.getChoicesCount(null);
                this.updateChart();
            },
            { immediate: true }
        );

        this.answeredCount =
            this.visibleQuestionsCount - this.getChoicesCount(null);
    },
    computed: {
        ...mapGetters("auditKB", {
            settings: "getSettings"
        }),
        labelCountClass: function() {
            return this.answeredCount === this.visibleQuestionsCount
                ? "label-success"
                : "label-default";
        },
        visibleQuestionsCount() {
            return (
                this.visibleQuestions.length +
                this.visibleQuestionsInChildSections.reduce(function(total, s) {
                    return total + s.total;
                }, 0)
            );
        },
        visibleQuestions() {
            return this.item.items.filter(i => {
                if (i.type === "question") {
                    if (this.countryLaw == "CZ") {
                        if (
                            this.settings.relevantFor.kii &&
                            (i.relevantFor.kii === "yes" ||
                                i.relevantFor.kii === "optional")
                        ) {
                            return true;
                        }
                        if (
                            this.settings.relevantFor.vis &&
                            (i.relevantFor.vis === "yes" ||
                                i.relevantFor.vis === "optional")
                        ) {
                            return true;
                        }
                        if (
                            this.settings.relevantFor.dsp &&
                            (i.relevantFor.dsp === "yes" ||
                                i.relevantFor.dsp === "optional")
                        ) {
                            return true;
                        }
                    } else if (this.countryLaw == "SK") {
                        if (
                            this.settings.relevantFor.zokb &&
                            i.relevantFor.zokb &&
                            i.relevantFor.categories.indexOf(
                                this.settings.relevantFor.categoryZokb
                            ) >= 0
                        ) {
                            return true;
                        }
                        if (
                            this.settings.relevantFor.itvs &&
                            i.relevantFor.itvs &&
                            i.relevantFor.categories.indexOf(
                                this.settings.relevantFor.categoryItvs
                            ) >= 0
                        ) {
                            return true;
                        }
                    }

                    return false;
                }
            });
        }
    },
    methods: {
        collapsedToggle: function() {
            this.collapsed = !this.collapsed;
        },
        getSectionNumber() {
            let parentNumber = this.$parent.getSectionNumber();

            if (parentNumber) {
                return this.$parent.getSectionNumber() + "." + this.number;
            } else {
                return this.number;
            }
        },
        showChart: function() {
            if (!this.$refs.chart) {
                return;
            }

            this.chart = chart(this.$refs.chart);

            this.updateChart();
        },
        updateChart: function() {
            if (!this.chart) {
                return;
            }

            let count = this.getQuestionsCount();
            let implemented = this.getChoicesCount("implemented");
            let not_applicable = this.getChoicesCount("not_applicable");
            let in_implementation = this.getChoicesCount("in_implementation");
            let not_implemented = this.getChoicesCount("not_implemented");
            let not_answered = this.getChoicesCount(null);

            this.chart.data.datasets[0].data = [
                Math.round((implemented * 100) / count),
                Math.round((not_applicable * 100) / count),
                Math.round((in_implementation * 100) / count),
                Math.round((not_implemented * 100) / count),
                Math.round((not_answered * 100) / count)
            ];

            this.chart.options.elements.center.text =
                Math.round(((implemented + not_applicable) * 100) / count) +
                "%";
            this.chart.update();
        },
        getChoicesCount(choice) {
            let count = 0;

            if (this.$refs["questions"]) {
                this.$refs["questions"].forEach(q => {
                    if (q.answer && q.answer.choice === choice && q.isVisible) {
                        count++;
                    }
                });
            }

            if (this.$refs["sections"]) {
                this.$refs["sections"].forEach(s => {
                    count += s.getChoicesCount(choice);
                });
            }

            return count;
        },
        getQuestionsCount() {
            let count = 0;

            if (this.$refs["questions"]) {
                this.$refs["questions"].forEach(q => {
                    if (q.isVisible) {
                        count++;
                    }
                });
            }

            if (this.$refs["sections"]) {
                this.$refs["sections"].forEach(s => {
                    count += s.getQuestionsCount();
                });
            }

            return count;
        },
        answerChanged() {
            this.updateChart();
            this.$emit("answerChanged");

            this.answeredCount =
                this.visibleQuestionsCount - this.getChoicesCount(null);
        },
        questionVisibilityChanged(sectionId, total) {
            let i = this.visibleQuestionsInChildSections.find(i => {
                return i.id === sectionId;
            });

            if (i) {
                i.total = total;
            } else {
                this.visibleQuestionsInChildSections.push({
                    id: sectionId,
                    total: total
                });
            }
        }
    }
};

function chart(chartDiv) {
    var config = {
        type: "doughnut",
        data: {
            datasets: [
                {
                    data: [],
                    backgroundColor: [
                        "#5cb85c",
                        "#f0ad4e",
                        "#337ab7",
                        "#d9534f",
                        "#dddddd"
                    ],
                    hoverBackgroundColor: [
                        "#5cb85c",
                        "#f0ad4e",
                        "#337ab7",
                        "#d9534f",
                        "#dddddd"
                    ],
                    hoverBorderColor: [
                        "#5cb85c",
                        "#f0ad4e",
                        "#337ab7",
                        "#d9534f",
                        "#dddddd"
                    ]
                }
            ]
        },
        options: {
            responsive: true,
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            hover: { mode: null },
            elements: {
                center: {
                    text: "",
                    color: "#000000", // Default is #000000
                    fontStyle: "Arial", // Default is Arial
                    sidePadding: 0 // Defualt is 20 (as a percentage)
                }
            }
        }
    };

    var ctx = chartDiv.getContext("2d");
    return new Chart(ctx, config);
}
</script>

<style lang="less" scoped type="text/less">
#legend td.answer {
    width: 55px;
    text-align: left;
    padding-left: 5px;
}

.questions {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 1px solid #adadad;
    border-bottom: 1px solid #adadad;

    td:not(:last-child) {
        width: 60px;
        text-align: left;
    }

    .group td {
        padding-left: 0;
        padding-top: 20px;
        border: 0;
        border-bottom: 1px solid black;
    }

    tr.sep td {
        border-top: 1px solid black;
    }

    tbody > tr.group:hover,
    tbody > tr.sep:hover {
        background-color: inherit !important;
    }
}

</style>
