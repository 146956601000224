<template>
    <div class="box box-primary vld-parent">
        <div class="box-header">
            <h2 class="box-title">
                <i class="fas fa-tags"></i>
                {{$t('comp.orgStructure.personGroups.groups')}} 
            </h2>

            <div class="pull-right">
                <a class="btn btn-success btn-sm" @click="showManagePersonGroupModal(null)">
                    <i class="fas fa-tag"></i>
                    {{$t('comp.orgStructure.personGroups.addNewGroup')}}
                </a>
            </div>
        </div>

        <div class="box-body">
            <div v-if="personGroups.length > 0">
                <template v-for="personGroup in personGroups" >
                    <span class="group">
                        <span class="label mr1 group-label" :style="{'background': personGroup.color}" @click="showManagePersonGroupModal(personGroup)">
                            {{personGroup.name}}
                        </span>
                        <span class="group-delete text-danger" @click="remove(personGroup.id)">
                            <i class="fas fa-times"></i>
                        </span>
                    </span>
                </template>
            </div>
            <div v-else style="text-align: center;">
                <em>
                    {{$t('comp.orgStructure.personGroups.noGroups')}}
                </em>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>
        <manage-person-group></manage-person-group>
        <delete-confirmation :ref="'delConfUser'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import ManagePersonGroup from './ManagePersonGroup/index.vue';
    import ManagePersonGroupBus from './ManagePersonGroup/Bus.js';
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

    export default {
        name: 'person-groups',
        components: {       
            ManagePersonGroup,
            DeleteConfirmation
        },
        watch: {
        },
        data() {
            return {
                isLoading: false,
                personGroups: []
            };
        },
        mounted() {
            ManagePersonGroupBus.$on('reload-groups', () => {
                this.reload();
            });
        },
        methods: {
            showManagePersonGroupModal(personGroup) {
                ManagePersonGroupBus.$emit('show', personGroup);
            },
            async fetchPersonGroups() {
                await this.$api.get("org-person-group").then(({data})=>{
                    if(data.status === 'ok') {
                        this.personGroups = data.data;
                    }
                });
            },
            async reload() {
                this.isLoading = true;
                await this.fetchPersonGroups().then(()=>{
                    this.isLoading = false;
                });
            },
            remove(id) {
                let modalWindow = this.$refs.delConfUser;
                modalWindow.show(id);
            },    
            async deleteConfirmed(id) {
                this.isLoading = true;
                await this.$api.delete("org-person-group/" + id).then(({data}) => {
                    if (data.status === 'ok') {
                        ManagePersonGroupBus.$emit('reload-groups');
                    }
                });
                this.isLoading = false;  
            }
        },
        beforeMount() {
            this.reload();
        }
    }
</script>

<style lang="less" scoped>

    .group {
        .group-label {
            cursor: pointer;
        }

        .group-delete {
            cursor: pointer;
            margin-right: 10px;
            visibility: hidden;
        }
    }

    .group:hover .group-delete {
        visibility: visible;
    }
</style>