<template>
    <div>
        <div>
            <div class="pull-right">
                <router-link :to="{name: 'personGroups'}" tag="a" class="btn btn-primary">
                    <i class="fas fa-tags"></i>
                    {{$t('comp.orgStructure.index.groups')}}
                </router-link>
                <div class="dropdown pull-right ml2">
                    <button class="btn btn-default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-cog"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dLabel">
                        <li><a href="javascript:;" @click="importModal">{{$t('comp.orgStructure.index.importCsv')}}</a></li>
                        <li><a href="javascript:;" @click="exportAll">{{$t('comp.orgStructure.index.exportCsv')}}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="clearfix mb2"></div>

        <div class="row" v-if="!isLoading">
            <div class="col-lg-4">
                <navigation></navigation>
            </div>
            <div class="col-lg-8">
                <router-view v-if="$route.name === 'listing'" :unit-sub-id="$route.params.unitId" :key="$route.params.unitId"></router-view>

                <router-view :key="$route.params.unitId" v-else-if="$route.params.unitId"></router-view>
                <router-view v-else></router-view>
            </div>
        </div>

        <import-modal></import-modal>
    </div>
</template>

<script>
    import Navigation from './Navigation.vue';
    import UserListing from './UserListing/UserListing.vue';
    import VueRouter from 'vue-router';
    import {mapGetters, mapActions} from 'vuex';
    import ImportModalBus from './ImportModal/ImportModalBus';
    import ImportModal from "./ImportModal/ImportModal.vue";
    import RSVP from 'rsvp'

    const router = new VueRouter({
        routes: [
            {
                name: "createUnit",
                path: '/unit/new/:unitId?',
                component: () => import('./EditUnit.vue'),
            },
            {
                name: "unit",
                path: '/unit/:unitId',
                component: () => import('./EditUnit.vue'),
                children: [
                    {
                        name: "editUnit",
                        path: 'edit',
                        meta: {
                            editMode: true
                        }
                    }
                ]
            },
            {
                name: "createPerson",
                path: '/person/new/:unitId?',
                component: () => import('./EditPerson.vue'),
            },
            {
                name: "assignPerson",
                path: '/assignPerson/:unitId',
                component: () => import('./AssignPerson.vue'),
            },
            {
                name: "person",
                path: '/person/:personId/:unitId?',
                component: () => import('./EditPerson.vue'),
                children: [
                    {
                        name: "editPerson",
                        path: 'edit',
                        meta: {
                            editMode: true
                        }
                    }
                ]
            },
            {
                name: "personGroups",
                path: '/personGroups',
                component: () => import('./PersonGroups.vue'),
            },
            {
                name: "listing",
                path: '/:unitId?',
                component: () => import('./UserListing/UserListing.vue')
            },
        ]
    });

    export default {
        name: 'orgStructure',
        router,
        components: {
            Navigation,
            UserListing,
            ImportModal
        },
        data() {
            return {
                showManageTypesModal: false,
                isLoading: true
            };
        },
        methods: {
            ...mapActions('userRoles', {
                fetchUserRoles: 'fetchRoles'
            }),
            exportAll: function () {
                this.$api.get("org-person/export", {
                    responseType: 'blob'
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.$t('comp.orgStructure.index.fileName')); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
            },
            importModal() {
                ImportModalBus.$emit('show');
            }
        },
        computed: {

        },
        async created() {
            await RSVP.all([
                this.fetchUserRoles()
            ]).then(() => {
                this.isLoading = false;
            });
        }
    }
</script>