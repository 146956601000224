<template>
  <div class="control">
    <div class="form-group" style="margin-bottom: 0px;">
      <label>{{$t('comp.assetModal.controls.practitionerPercentage.label')}}</label>

      <div class="input-group mt2">
        <input type="range" min="1" max="100" v-model.number="selectedValue" :disabled="disabled" />
        <div class="input-group-addon no-border"><strong>{{selectedValue}} %</strong></div>
      </div>
    </div>
  </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "asset-supplier-control",
        components: {
            CsaSelect
        },
        data() {
            return {
                selectedValue: null
            };
        },
        props: {
            value: null,
            disabled: false,
        },
        watch: {
            selectedValue: function (v) {
                this.$emit('input', v);
            },
            value: function (v) {
                this.selectedValue = v;
            }
        },
        created() {
            this.selectedValue = this.value;
        },
        computed: {
            ...mapGetters("suppliers", {
                options: "getSuppliers"
            }),
        },
        methods: {
            getOption: function (id) {
                if(!id) {
                    return null;
                }

                return this.options.find((i)=>{return i.id === id;});
            }
        }
    }
</script>