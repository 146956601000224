<template>
    <div class="item" style="clear:both;">
        <div class="row">
            <div class="col-md-6 no-padding">
                <div class="text-center text-bold text-gray">{{ $t('comp.assetModal.threatsTab.selectedThreat.threat') }}
                </div>
                <div class="threat">
                    <div class="row" style="display: flex; align-items: center;">
                        <div class="col-md-1">
                            <button class="btn btn-success" @click="toggleEdit()" v-if="editMode && !fixed">
                                <i class="fas fa-save"></i>
                            </button>
                            <button class="btn btn-success"
                                    :disabled="!data.threat || !data.vulnerabilities.length" @click="save"
                                    v-if="editMode && fixed">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                        <div class="col-md-11">
                            <csa-select
                                        v-if="editMode && asset.type != null"
                                        v-model="data.threat"
                                        :options="groupedThreats"
                                        group-values="threats"
                                        group-label="groupname"
                                        trackBy="id"
                                        :label="isShowHashIds ? 'hashIdName' : 'name'"
                                        @input="threatSelected"
                                        @tag="createThreat"
                                        :taggable="$app.user.role === 'mcs'"
                                        :tag-placeholder="$t('comp.assetModal.threatsTab.selectedThreat.addThreatPlaceholder')">
                                <span slot="placeholder">
                                    {{ $t('comp.assetModal.threatsTab.selectedThreat.selectThreatPlaceholder') }}
                                </span>
                            </csa-select>
                            <csa-select
                                        v-else-if="editMode && asset.type == null"
                                        v-model="data.threat"
                                        :options="unselectedThreats"
                                        trackBy="id"
                                        :label="isShowHashIds ? 'hashIdName' : 'name'"
                                        @input="threatSelected"
                                        @tag="createThreat"
                                        :taggable="$app.user.role === 'mcs'"
                                        :tag-placeholder="$t('comp.assetModal.threatsTab.selectedThreat.addThreatPlaceholder')">
                                <span slot="placeholder">
                                    {{ $t('comp.assetModal.threatsTab.selectedThreat.selectThreatPlaceholder') }}
                                </span>
                            </csa-select>
                        </div>
                    </div>

                    <template v-if="!editMode">
                        <div class="pull-left">
                            <button class="btn btn-danger btn-xs" @click="removeThreat()">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button class="btn btn-warning btn-xs" @click="toggleEdit()">
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                        </div>

                        <div class="pull-right text-sm" style="width: 90%">
                            <span v-if="isShowHashIds">{{ data.threat.hashIdName }}</span>
                            <span v-else>{{ data.threat.name }}</span>
                            <i class="fas fa-info-circle description-icon" v-if="data.threat.description"
                               :title="data.threat.description"></i>
                        </div>

                        <div class="clearfix"></div>
                    </template>
                </div>
            </div>
            <div class="col-md-6 no-padding">
                <div class="text-center text-bold text-gray">
                    {{ $t('comp.assetModal.threatsTab.selectedThreat.vulnerability') }}</div>

                <div class="vulnerabilities">
                    <table class="table table-striped no-padding no-margin">
                        <tbody>
                            <tr v-if="editMode">
                                <td colspan="2">
                                    <csa-select
                                                v-if="asset.type == null"
                                                :options="unselectedVulnerabilities"
                                                trackBy="id"
                                                :label="isShowHashIds ? 'hashIdName' : 'name'"
                                                :placeholder="$t('comp.assetModal.threatsTab.selectedThreat.selectVulnerabilityPlaceholder')"
                                                @input="vulnerabilitySelected"
                                                :disabled="!data.threat"
                                                @tag="createVulnerability"
                                                :taggable="$app.user.role === 'mcs'"
                                                :tag-placeholder="$t('comp.assetModal.threatsTab.selectedThreat.addVulnerabilityPlaceholder')">
                                        <span slot="placeholder">
                                            {{ $t('comp.assetModal.threatsTab.selectedThreat.selectVulnerabilityPlaceholder') }}
                                        </span>
                                    </csa-select>
                                    <csa-select
                                                v-else
                                                :options="groupedVulnerabilities"
                                                group-values="vulns"
                                                group-label="groupname"
                                                trackBy="id"
                                                :label="isShowHashIds ? 'hashIdName' : 'name'"
                                                :placeholder="$t('comp.assetModal.threatsTab.selectedThreat.selectVulnerabilityPlaceholder')"
                                                @input="vulnerabilitySelected"
                                                :disabled="!data.threat"
                                                @tag="createVulnerability"
                                                :taggable="$app.user.role === 'mcs'"
                                                :tag-placeholder="$t('comp.assetModal.threatsTab.selectedThreat.addVulnerabilityPlaceholder')">
                                        <span slot="placeholder">
                                            {{ $t('comp.assetModal.threatsTab.selectedThreat.selectVulnerabilityPlaceholder') }}
                                        </span>
                                    </csa-select>
                                </td>
                            </tr>
                            <tr v-for="vulnerability in data.vulnerabilities" :key="vulnerability.id">
                                <td class="text-sm">
                                    <span v-if="isShowHashIds">{{ vulnerability.hashIdName }}</span>
                                    <span v-else>{{ vulnerability.name }}</span>
                                    <i class="fas fa-info-circle description-icon" v-if="vulnerability.description"
                                       :title="vulnerability.description"></i>
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-link btn-xs text-red"
                                            @click="removeVulnerability(vulnerability)" v-if="editMode">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CsaSelect from 'Controls/CsaMultiSelect.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        CsaSelect
    },
    props: {
        isShowHashIds: {
            type: Boolean,
            default: false
        },
        fixed: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            required: true
        },
        asset: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('threats', {
            getVisibleThreats: 'getVisible',
        }),
        ...mapGetters('vulnerabilities', {
            getVisibleVulnerabilities: 'getVisible',
        }),
        ...mapGetters('vulnerabilities', {
            getVisibleVulnerabilitiesWithBindings: 'getVisibleWithBindings',
        }),
        groupedThreats: function () {
            return [
                {
                    'groupname': this.$t('comp.assetModal.threatsTab.selectedThreat.threatAssociated'),
                    'threats': this.unselectedThreats.filter((t) => {
                        return 'threatAssociated' in t && t.threatAssociated === true;
                    })
                },
                {
                    'groupname': this.$t('comp.assetModal.threatsTab.selectedThreat.others'),
                    'threats': this.unselectedThreats.filter((t) => {
                        return 'threatAssociated' in t && t.threatAssociated === false;
                    })
                }
            ];
        },
        groupedVulnerabilities: function () {
            return [
                {
                    'groupname': this.$t('comp.assetModal.threatsTab.selectedThreat.vulnerabilityAssociated'),
                    'vulns': this.unselectedVulnerabilitiesWithBindings.filter((v) => {
                        return 'associated' in v && v.associated === true;
                    })
                },
                {
                    'groupname': this.$t('comp.assetModal.threatsTab.selectedThreat.others'),
                    'vulns': this.unselectedVulnerabilitiesWithBindings.filter((v) => {
                        return 'associated' in v && v.associated === false;
                    })
                }
            ];
        },
        unselectedThreats: function () {
            return this.getVisibleThreats.filter((t) => {
                return !this.asset.threats.find((st) => {
                    return st.threat.id == t.id;
                });
            });
        },
        unselectedVulnerabilities: function () {
            return this.getVisibleVulnerabilities.filter((v) => {
                return !this.data.vulnerabilities.find((sv) => {
                    return sv.id == v.id;
                });
            });
        },
        unselectedVulnerabilitiesWithBindings: function () {
            return this.getVisibleVulnerabilitiesWithBindings.filter((v) => {
                return !this.data.vulnerabilities.find((sv) => {
                    return sv.id == v.id;
                });
            });
        }
    },
    data() {
        return {
            editMode: this.fixed
        };
    },
    methods: {
        ...mapActions('threats', ['addThreat', 'fetchThreatsWithAssetTypes', 'fetchThreats']),
        ...mapActions('vulnerabilities', ['addVulnerability', 'fetchVulnerabilitiesWithBindings']),
        vulnerabilitySelected: function (vulnerability) {
            this.data.vulnerabilities.unshift(vulnerability);
        },
        threatSelected: async function (threat) {
            this.$parent.setLoading(true);
            if (this.asset.type != null && threat) {
                let params = { 'assetType': this.asset.type.id, 'threat': threat.id, 'getAll': true };
                await this.fetchVulnerabilitiesWithBindings(params);

                this.data.vulnerabilities = this.unselectedVulnerabilitiesWithBindings.filter((v) => {
                    return 'associated' in v && v.associated === true;
                });
            }

            if (!threat) {
                this.data.vulnerabilities = [];
            }
            this.$parent.setLoading(false);
        },
        removeVulnerability(vulnerability) {
            let index = this.data.vulnerabilities.indexOf(vulnerability);

            this.data.vulnerabilities.splice(index, 1);
        },
        removeThreat() {
            this.$emit("remove", this.data);
        },
        save() {
            if (this.fixed) {
                this.$emit('add');
            }
        },
        toggleEdit: async function () {
            this.editMode = !this.editMode;

            if (!this.editMode && (!this.data.threat || !this.data.vulnerabilities.length)) { //remove
                this.$emit('remove', this.data);
            } else if (this.editMode) {
                let params = { 'assetType': this.asset.type.id, 'threat': this.data.threat.id, 'getAll': true };
                await this.fetchVulnerabilitiesWithBindings(params);
            }
        },
        createThreat(name) {
            this.addThreat({
                name: name
            }).then((threat) => {
                this.data.threat = threat;
            });
        },
        createVulnerability(name) {
            this.addVulnerability({
                name: name
            }).then((vulnerability) => {
                this.data.vulnerabilities.unshift(vulnerability);
            });
        }
    }
}
</script>

<style lang="less" scoped>
.item {
    padding: 0 20px 0 20px;
    margin-bottom: 10px;

    .threat {
        background: #ecf0f5;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 10px;

        .remove {
            vertical-align: middle;
        }
    }

    .vulnerabilities {
        border: 2px solid #ecf0f5;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}

/deep/ .multiselect__single,
/deep/ .multiselect__option {
    font-size: 10pt;
}
</style>
