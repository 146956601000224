<template>
    <div class="vld-parent" style="width: 100%">
        <div class="pull-right">
            <button class="btn btn-success" @click="add">
                <i class="fa fa-plus"></i> {{ $t("common.add") }}
            </button>
        </div>
        <div class="pull-left" style="padding-top: 12px">
            <button
                    class="btn btn-xs btn-danger"
                    @click="removeSelected"
                    :disabled="currentSelections.length > 0 ? disabled : ''">
                <i class="fa fa-trash"></i> {{ $t("common.delete") }}
            </button>
        </div>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th style="width: 20px"></th>
                    <th style="width: 60px" v-if="isShowHashIds">{{ $t("comp.manageThreats.threatListing.id") }}</th>
                    <th style="width: 40%">{{ $t("comp.manageThreats.threatListing.name") }}</th>
                    <th style="width: 30%">{{ $t("comp.manageThreats.threatListing.tags") }}</th>
                    <th style="width: 200px">{{ $t("comp.manageThreats.threatListing.evaluation") }}</th>
                    <th style="white-space: nowrap; width: 80px;">{{ $t("comp.manageThreats.threatListing.riskRegister") }}
                    </th>
                    <th style="white-space: nowrap; width: 80px;">{{ $t("comp.manageThreats.threatListing.risks") }}</th>
                    <th style="width: 80px"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="width: 2%">
                        <label class="form-checkbox" style="margin-bottom: 0px">
                            <input
                                   type="checkbox"
                                   :checked="isAllSelected"
                                   @click="selectAll" />
                            <i class="form-icon"></i>
                        </label>
                    </td>
                    <td v-if="isShowHashIds"></td>
                    <td>
                        <input
                               class="form-control input-sm"
                               v-model="filter.name"
                               :disabled="isEditing"
                               :placeholder="$t('comp.manageThreats.threatListing.searchPlaceholder')" />
                    </td>
                    <td>
                        <multiselect
                                     v-model="filter.tags"
                                     :disabled="isEditing"
                                     :options="getAllTags.map((tag) => tag.id)"
                                     :custom-label="(opt) => getAllTags.find((x) => x.id == opt).name"
                                     :multiple="true"
                                     :taggable="false"
                                     :closeOnSelect="false">
                        </multiselect>
                    </td>
                    <td>
                        <select
                                class="form-control input-sm"
                                v-model="filter.classification"
                                :disabled="isEditing">
                            <option
                                    v-for="classification in getAllScales"
                                    :value="classification.id"
                                    :key="classification.id">
                                {{ classification.name }}
                            </option>
                        </select>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <button
                                class="btn btn-danger btn-xs"
                                @click="cancelFilter"
                                v-if="isFiltered">
                            {{ $t("common.cancelFilter") }}
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <template v-for="threat in threats">
                    <threat
                            v-if="threat.id"
                            :item="threat"
                            :isShowHashIds="isShowHashIds"
                            :key="threat.id"
                            @removed="remove"
                            @reload="reload"></threat>

                    <threat
                            v-else
                            :item="threat"
                            :isShowHashIds="isShowHashIds"
                            :key="threat.key"
                            @removed="remove"
                            @reload="reload"></threat>
                </template>
            </tbody>
        </table>

        <div class="pull-right" v-if="paginator.itemCount > 1">
            {{ paginator.offset + 1 }}-{{ paginator.offset + paginator.length }}/{{
                paginator.itemCount
            }}
            <div class="btn-group" style="padding-right: 20px">
                <button
                        class="btn btn-default btn-sm"
                        @click="paginator.page--"
                        :disabled="paginator.page === 1">
                    <i class="fa fa-chevron-left"></i>
                </button>
                <button
                        class="btn btn-default btn-sm"
                        @click="paginator.page++"
                        :disabled="paginator.page === paginator.pageCount">
                    <i class="fa fa-chevron-right"></i>
                </button>
            </div>
            <div id="threatsPerPage_LocalStorage" class="btn-group">
                <select class="form-control" v-model="paginator.perPage">
                    <option
                            v-for="perPage in perPageOptions"
                            :value="perPage"
                            :key="perPage">
                        {{ perPage }}
                    </option>
                </select>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="false"></loading>

        <delete-confirmation
                             :ref="'massDelConfThreat'"
                             v-on:confirmed="massDeleteConfirmed($event)"></delete-confirmation>
    </div>
</template>

<script>
import Threat from "./Threat.vue";
import Multiselect from "../../Controls/CsaMultiSelect.vue";
import { mapActions, mapGetters } from "vuex";
import store from "../../store";
import _ from "lodash";
import EventBus from "./EventBus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation.vue";

export default {
    store,
    components: {
        Threat,
        Multiselect,
        DeleteConfirmation,
    },
    data() {
        return {
            threats: [],
            key: 0,
            filter: {
                name: null,
                tags: [],
                classification: null,
            },
            paginator: {
                perPage: 25,
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100],
            isLoading: false,
            watchFilter: true,
            classifications: [],
            currentSelections: [],
        };
    },
    watch: {
        ["filter.name"]: function () {
            if (this.watchFilter) {
                this.debouncedFilterName();
            }
        },
        ["filter.tags"]: function () {
            if (this.watchFilter) {
                this.fetchFilteredThreats();
            }
        },
        ["filter.classification"]: function () {
            if (this.watchFilter) {
                this.fetchFilteredThreats();
            }
        },
        ["paginator.page"]: function () {
            this.fetchFilteredThreats();
        },
        ["paginator.perPage"]: function () {
            this.fetchFilteredThreats();
            localStorage.setItem(
                "threatsPerPage_LocalStorage",
                this.paginator.perPage
            );
        },
        threats: {
            handler() {
                this.currentSelections = this.threats
                    .filter((item) => item.selected && !item.methodology)
                    .map((item) => item.id);
            },
            deep: true,
        },
    },
    mounted: function () {
        if (localStorage.getItem("threatsPerPage_LocalStorage")) {
            try {
                this.paginator.perPage = localStorage.getItem(
                    "threatsPerPage_LocalStorage"
                );
            } catch (e) {
                localStorage.removeItem("threatsPerPage_LocalStorage");
            }
        }
    },
    computed: {
        ...mapGetters("threats", {
            fetchThreats: "fetchThreats",
        }),
        ...mapGetters("userSettings", {
            isShowHashIds: "isShowHashIds",
        }),
        ...mapGetters("threatTags", {
            getAllTags: "getAll",
        }),
        ...mapGetters("threatClassification", {
            getAllScales: "getClassificationsWithZero",
        }),
        isFiltered: function () {
            return (
                this.filter.name ||
                this.filter.tags.length ||
                this.filter.classification
            );
        },
        isEditing() {
            return (
                this.threats.filter((t) => {
                    return t.editMode === true;
                }).length > 0
            );
        },
        isAllSelected() {
            //checked/(unchecked) checkBox selectAll if every/(more then one) item on items is checked/(unchecked)
            if (this.currentSelections.length <= 0) {
                return false;
            }
            return this.threats
                .filter((item) => !item.methodology)
                .every((item) => item.selected);
        },
    },
    created: function () {
        this.fetchIsShowHashIds();
        this.fetchFilteredThreats();

        this.debouncedFilterName = _.debounce(this.fetchFilteredThreats, 700);

        this.fetchTags();
        this.fetchClassification();

        EventBus.$on("imported", () => {
            this.fetchFilteredThreats(false);
            this.fetchTags();
            this.fetchClassification();
        });
    },
    methods: {
        add: function () {
            this.threats.unshift({
                id: null,
                name: null,
                description: null,
                hidden: false,
                tags: [],
                key: this.key++,
                classification: null,
            });
        },
        remove: function (item) {
            this.threats.splice(this.threats.indexOf(item), 1);
        },
        removeSelected: function () {
            let modalWindow = this.$refs.massDelConfThreat;
            modalWindow.show();
        },
        massDeleteConfirmed: function () {
            this.isLoading = true;

            this.$api
                .post("threats/mass-delete", this.currentSelections)
                .then((res) => {
                    if (res.data.status == "ok") {
                        this.showDeleted();
                        this.reload(true);
                    } else if (res.data.status == "error") {
                        if (res.data.code == "PartiallyDeleted") {
                            this.showCanNotDeleteInUse();
                            this.reload(true, true);
                        } else {
                            this.showDeleteError();
                        }
                    }
                    this.isLoading = false;
                });
        },
        fetchFilteredThreats: function (showLoading = true, keepSelection = false) {
            this.isLoading = showLoading;

            this.filterThreats({
                filter: this.filter,
                paginator: this.paginator,
            }).then((threats) => {
                threats.items.forEach((threat) => {
                    if (keepSelection) {
                        threat.selected = this.currentSelections.indexOf(threat.id) >= 0;
                    } else {
                        threat.selected = false;
                    }
                });
                this.isLoading = false;
                this.threats = threats.items;
                this.paginator = threats.paginator;
            });
        },
        selectAll() {
            let selected = !this.isAllSelected;
            //check/uncheck all item checkBox on items if selectAll is checked/(unchecked)
            this.threats.forEach((item) => (item.selected = selected));
        },
        ...mapActions("threats", {
            filterThreats: "filterThreats",
        }),
        ...mapActions("threatTags", {
            fetchTags: "fetchTags",
        }),
        ...mapActions("threatClassification", {
            fetchClassification: "fetchClassification",
        }),
        ...mapActions("userSettings", {
            fetchIsShowHashIds: "fetchIsShowHashIds",
        }),
        cancelFilter: function () {
            this.watchFilter = false;

            this.filter = {
                name: null,
                tags: [],
                classification: null,
            };

            this.fetchFilteredThreats();

            this.$nextTick(() => {
                this.watchFilter = true;
            });
        },
        reload: function (showLoading = false, keepSelection = false) {
            this.fetchFilteredThreats(showLoading, keepSelection);
        },
    },
    notifications: {
        showDeleted: {
            message: "common.deletedItems",
            type: "success",
        },
        showDeleteError: {
            message: "common.deleteError",
            type: "error",
        },
        showCanNotDeleteInUse: {
            message: "common.oneOrMoreCanNotDeleteInUse",
            type: "error",
        },
    },
};
</script>

<style scoped lang="less">
.multiselect {
    font-size: 12px;

    /deep/ .multiselect__tags {
        min-height: 30px;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    /deep/ .multiselect__select {
        height: 30px;
    }

    /deep/ .multiselect__placeholder {
        font-size: 12px;
    }

    /deep/ .multiselect__input {
        font-size: 12px;
    }

    /deep/ .multiselect__tag {
        font-size: 12px;
    }
}
</style>
