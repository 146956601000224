<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <input type="text" class="form-control name input-lg required" :placeholder="$t('comp.continuityPlanModal.tabs.general.namePlaceholder')" v-model="plan.name"/>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-addon text-bold">
              {{$t('comp.continuityPlanModal.tabs.general.id')}}
            </div>
            <input type="text" class="form-control input-lg id" v-model="plan.custom_id"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="description">{{$t('comp.continuityPlanModal.tabs.general.description')}}</label>
          <ckeditor :editor="editor" v-model="plan.description" :config="editorConfig" id="description"></ckeditor>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="tags">{{$t('comp.continuityPlanModal.tabs.general.tags')}}</label>
          <csa-select
              id="tags"
              v-model="plan.tags"
              :options="tags.map(x => x.id)"
              multiple
              :custom-label="customLabel"
          >
          </csa-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/cs';
import CsaSelect from 'Controls/CsaMultiSelect.vue'

export default {
  components: {
    ckeditor: CKEditor.component,
    CsaSelect,
  },
  props: {
    plan: Object,
    tags: Array,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: 'cs',
        toolbar: ['bold', 'italic', "bulletedList", "numberedList"]
      }
    }
  },
  methods: {
    getTagById(id) {
      let tag = this.tags.find(x => x.id === id)

      if (!tag) {
        return null
      }

      return tag
    },
    customLabel (id) {
      return this.getTagById(id).name
    }
  }
}
</script>