<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th class="question-type-info">* {{ $t("chooseAnswer") }}</th>
                <th style="width: 150px;"></th>
            </tr>
            </thead>
            <tbody>
                <tr v-bind:class="{success: question.correct === 'yes'}">
                    <td>
                        {{ $t("yes") }}
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon" style="font-size: 20px;">
                            <input
                                    @click="radioClicked($event)"
                                    :name="questionIndex + '_answerCorrect'"
                                    type="radio"
                                    v-model="question.correct"
                                    value="yes"
                                    v-validate="'required'" />
                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-bind:class="{success: question.correct === 'no'}">
                    <td>
                        {{ $t("no") }}
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon" style="font-size: 20px;">
                            <input
                                    @click="radioClicked($event)"
                                    :name="questionIndex + '_answerCorrect'"
                                    type="radio"
                                    v-model="question.correct"
                                    value="no"
                                    v-validate="'required'" />
                            <div class="state p-success">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        inheritAttrs: false,
        components: {
            BaseQuestion
        },
        data() {
            return {
                previouslySelected: null,
            }
        },
        beforeMount: function (){
            this.previouslySelected = this.question.correct;
        },
        methods: {
            radioClicked: function (e) {
                if(e.target.value === this.previouslySelected) {
                    e.target.checked = null;
                    this.previouslySelected = null;
                    this.question.correct = null;
                } else {
                    this.previouslySelected = e.target.value;
                }
            }
        },
        watch: {
            ['question.correct']: {
                handler: function (v) {
                    this.$emit("change", this.question);
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    .question-type-info {
        font-weight: 400;
        font-style: italic;
    }
</style>