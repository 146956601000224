<template>
    <tr>
        <template v-if="item.editMode">
            <td></td>
            <td class="text-sm" v-if="isShowHashIds">
                {{ item.hashId }}
            </td>
            <td>
                <input v-model="editedItem.name"
                       type="text"
                       name="name"
                       id="name"
                       ref="name"
                       class="form-control"
                       :placeholder="$t('comp.manageMeasures.measure.namePlaceholder')"
                       @keypress.enter="save"
                       v-validate="'required|max:256'"
                       :data-vv-as="$t('comp.manageMeasures.measure.name-vv')"/>

                <span class="help-block text-red">{{errors.first('name')}}</span>
            </td>
            <td style="height: 60px;">
                <multiselect
                        v-model="editedItem.tags"
                        :options="tags.map(tag => tag.id)"
                        :custom-label="opt => tags.find(x => x.id == opt).name"
                        :multiple="true"
                        :taggable="$app.user.role === 'mcs'"
                        :closeOnSelect="false"
                        @tag="createTag">
                </multiselect>
            </td>
            <td  class='center'>
               {{ item.countApplicableRisksWithMeasure }}/{{ item.countRisksWithMeasure }}
            </td>
            <td  class='center'>
               {{ item.countApplicableRisksWithMeasureImplemented }}/{{ item.countApplicableRisksWithMeasure }}
            </td>
            <td class="text-right" id="save">
                <button class="btn btn-success btn-xs" @click="save">
                    <i class="fas fa-save"></i>
                </button>
                <button class="btn btn-default btn-xs" @click="cancel">
                    <i class="fas fa-times"></i>
                </button>
            </td>
        </template>

        <template v-else>
            <td><input type="checkbox" class="checkbox" v-model="item.selected"/></td>
            <td class="text-sm" v-if="isShowHashIds">
                {{ item.hashId }}
            </td>
            <td class="text-sm">
                {{ item.name }}
            </td>
            <td>
                <template v-for="tagId in item.tags" v-if="getTagById(tagId)">
                    <a class="label label-default mr2" :href="getTagById(tagId).link" target="_blank" v-if="getTagById(tagId).link" :key="tagId.id">
                        <i class="fas fa-link"></i>
                        {{getTagById(tagId).name}}
                    </a>
                    <span class="label label-default mr2" v-else :key="tagId.id">{{getTagById(tagId).name}}</span>
                </template>
            </td>
            <td  class='center'>
               {{ item.countApplicableRisksWithMeasure }}/{{ item.countRisksWithMeasure }}
            </td>
            <td  class='center'>    
                {{ item.countApplicableRisksWithMeasureImplemented }}/{{ item.countApplicableRisksWithMeasure }}
            </td>
            <td class="text-right">
                <div class="label text-black pull-left" v-if="item.methodology">
                    {{$t('comp.manageMeasures.measure.methodology')}} <img src="/favicon.ico">
                </div>
                <button class="btn btn-danger btn-xs" @click="remove" :disabled="isLoading">
                    <i class="fas fa-trash"></i>
                </button>
                <button class="btn btn-warning btn-xs" @click="toggleEdit" :disabled="isLoading">
                    <i class="fas fa-pencil-alt"></i>
                </button>
            </td>
        </template>

        <delete-confirmation :ref="'delconfMeasure'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>
    </tr>
</template>

<script>
    import Multiselect from '../../Controls/CsaMultiSelect.vue'
    import {mapActions, mapGetters} from 'vuex';
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

    export default {
        name: 'create-measure',
        components: {
            Multiselect,
            DeleteConfirmation
        },
        props: {
            item: {
                type: Object,
                require: true
            },
            isShowHashIds: {
                type: Boolean,
                require: true
            },
            people: {
                require: true
            }
        },
        beforeMount: function () {
            if (!this.item.id) {
                this.$set(this.item, "editMode", true);
            } else {
                this.$set(this.item, "editMode", false);
            }

            this.editedItem = Object.assign({}, this.item);
        },
        mounted(){
            if (this.item.editMode) {
                this.$refs.name.focus();
            }
        },
        data() {
            return {
                editedItem: null,
                isLoading: false,
                confirmationDialog: null,
            };
        },
        mounted(){
            this.confirmationDialog = this.$refs['confirmationDialog']
        },
        methods: {
            createTag: function (name) {
                let newTag = {
                    name: name
                };

                this.isLoading = true;

                this.addTag(newTag).then((tag) => {
                    this.isLoading = false;

                    this.item.tags.push(tag.id);
                });
            },
            toggleEdit: function () {
                if(this.item.editMode) {
                    this.$set(this.item, "editMode", false);
                } else {
                    this.$set(this.item, "editMode", true);
                }

                if (this.item.editMode) {
                    this.editedItem = JSON.parse(JSON.stringify(this.item));
                }
            },
            save: function () {
                this.$validator.validate().then(result => {
                    if (!result) {
                        return;
                    }

                    Object.assign(this.item, this.editedItem);

                    if (this.item.id) {
                        this.updateMeasure(this.item);
                    } else {//create
                        this.addMeasure(this.item).then((measure) => {
                            Object.assign(this.item, measure)
                        });
                    }

                    this.showSaved();
                    this.toggleEdit();
                });
            },
            cancel: function () {
                if (this.item.id) {
                    this.toggleEdit();
                    this.editedItem = JSON.parse(JSON.stringify(this.item));
                } else {
                    this.$emit("removed", this.item);
                }
            },
            remove: function () {
                this.$refs.delconfMeasure.show();
            },
            deleteConfirmed: function(item) {
                this.isLoading = true;

                if (this.item.id) {
                    this.removeMeasure(this.item).then(res => {
                        if(res == "ok"){
                            this.$emit("removed", this.item);
                        }
                        else if (res == "in_use"){
                            this.showCanNotDeleteInUse();
                        }

                        this.isLoading = false;
                    });
                } else {
                    this.$emit("removed", this.item);
                    this.isLoading = false;
                }
            },
            ...mapActions('measures', ['removeMeasure', 'updateMeasure', 'addMeasure']),
            ...mapActions('measureTags', {
                addTag: 'addTag',
            })
        },
        computed: {
            ...mapGetters('measureTags', {
                visibleTags: 'getVisible',
                allTags: 'getAll',
                getTagById: 'getById'
            }),
            tags() {
                return this.allTags.filter((t) => {
                    if(this.editedItem.tags.indexOf(t.id) !== -1) {
                        return true;
                    }

                    return this.visibleTags.indexOf(t) !== -1;
                });
            }
        },
        notifications: {
            showSaved: {
                message: 'common.saved',
                type: 'success'
            },
            showCanNotDeleteInUse: {
                message: 'common.canNotDeleteInUse',
                type: 'error'
            }
        }
    }
</script>

<style scoped lang="less">
    #name {
        height: 40px;
    }

    #save {
        vertical-align: middle;
    }

    .label {
        white-space: normal;
    }
</style>