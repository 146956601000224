<style>
.pretty.p-icon .state .icon {
    top: 0px !important;
}
</style>

<template>
    <div>
        <div class="row">
            <div class="col-lg-3">
                <div class="box box-primary">
                    <div
                        class="box-header with-border"
                        @click="isLegendExpanded = !isLegendExpanded"
                        style="cursor: pointer;"
                    >
                        <h3 class="box-title" style="display: block;">
                            <span>
                                {{ $t("comp.kbAudit.kbAudit.legend") }}
                            </span>
                            <span class="pull-right info">
                                <i
                                    class="fa fa-caret-down"
                                    v-if="!isLegendExpanded"
                                ></i>
                                <i class="fa fa-caret-up" v-else></i>
                            </span>
                        </h3>
                    </div>

                    <div class="box-body" v-show="isLegendExpanded">
                        <table class="table" id="legend">
                            <tbody>
                                <tr class="text-success">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.implemented'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-check-circle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.implemented"
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="text-primary">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.inImplementation'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-minus-circle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.inImplementation"
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="text-warning">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.notApplicable'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-times-circle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.notApplicable"
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="text-danger">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.notImplemented'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.notImplemented"
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-lg-7"></div>

            <div class="col-lg-2 text-right">
                <button class="btn btn-primary" @click="exportModal" style="margin-bottom: 5px;">
                    <i class="far fa-file-excel"></i>
                    {{ $t("comp.kbAudit.kbAudit.export") }}
                </button>
                <button class="btn btn-primary"
                    :disabled="selectedAnswers.length === 0"
                    @click="showModal = true" style="margin-bottom: 5px;">
                    <i class="fas fa-plus"></i>
                    {{ $t("comp.kbAudit.kbAudit.addMassMeasures") }} ({{ selectedAnswers.length }})
                </button>
            </div>
        </div>

        <div v-for="(item, index) in audit" :key="item.uuid">
            <poa-section
                :item="item"
                :number="index + 1"
                :deep="0"
                :countryLaw="countryLaw"
                ref="sections"
                @onQuestionsChanged="buildOverallData"
                @answerChanged="buildOverallData"
            />
        </div>

        <overall-chart :data="overallData" v-if="!isLoading"></overall-chart>

        <loading :active.sync="isLoading" :is-full-page="false"></loading>

        <modal-export></modal-export>

        <edit-question
            :showModal="showModal"
            v-if="showModal"
            @hide="showModal = false"
            :answers="selectedAnswers"
        ></edit-question>

    </div>
</template>

<script>
import PoaSection from "./PoaSection.vue";
import ModalExport from "./ModalExport.vue";
import { EventBus } from "./event-bus.js";
import { mapActions, mapGetters } from "vuex";
import OverallChart from "./OverallChart.vue";
import EditQuestion from "./EditQuestion.vue";

export default {
    components: {
        PoaSection,
        ModalExport,
        OverallChart,
        EditQuestion
    },
    data() {
        return {
            isLoading: true,
            overallData: null,
            isLegendExpanded: false,
            selectedAnswers: [],
            showModal: false,
        };
    },
    watch: {},
    mounted() {
        EventBus.$on('checkboxChanged', this.handleCheckboxChange);
        EventBus.$on('clearSelectedAnswers', this.handleClearSelectedAnswers);
    },
    beforeMount: async function() {
        await this.fetchAnswersIso();
        await this.fetchMeasures();
        await this.fetchTags();
        await this.fetchAuditIso().then(() => {
            this.isLoading = false;
            this.buildOverallData();
        });

        this.fetchMeasuresAssetsWithRisks();    // Does not need to wait for result
    },
    computed: {
        ...mapGetters("auditKB", {
            audit: "getAuditIso",
            answers: "getAnswersIso",
            countryLaw: "getCountryLaw",
            settings: "getSettings"
        })
    },
    methods: {
        ...mapActions("measures", {
            fetchMeasures: "fetchMeasures",
            fetchMeasuresAssetsWithRisks: "fetchMeasuresAssetsWithRisks",
        }),
        ...mapActions("measureTags", {
            fetchTags: "fetchTags"
        }),
        ...mapActions("auditKB", ["fetchAuditIso", "fetchAnswersIso"]),
        getSectionNumber() {
            return null;
        },
        getChoicesCount(choice) {
            let count = 0;

            if (this.$refs["sections"]) {
                this.$refs["sections"].forEach(s => {
                    count += s.getChoicesCount(choice);
                });
            }

            return count;
        },
        getQuestionsCount() {
            let count = 0;

            if (this.$refs["sections"]) {
                this.$refs["sections"].forEach(s => {
                    count += s.getQuestionsCount();
                });
            }

            return count;
        },
        buildOverallData() {
            this.overallData = {
                count: this.getQuestionsCount(),
                choices: {
                    implemented: this.getChoicesCount("implemented"),
                    in_implementation: this.getChoicesCount(
                        "in_implementation"
                    ),
                    not_applicable: this.getChoicesCount("not_applicable"),
                    not_implemented: this.getChoicesCount("not_implemented"),
                    not_answered: this.getChoicesCount(null)
                }
            };
        },
        exportModal() {
            EventBus.$emit("show-export-modal", true);
        },
        handleCheckboxChange(answer) {
            if (answer.selected) {
                this.selectedAnswers.push(answer);
            } else {
                const index = this.selectedAnswers.findIndex(item => item.questionId === answer.questionId);
                if (index > -1) {
                    this.selectedAnswers.splice(index, 1);
                }
            }
        },
        handleClearSelectedAnswers() {
            this.selectedAnswers = [];
        },
    }
};
</script>

<style>
.pretty .state label:before {
    top: 0 !important;
}
.pretty .state label:after {
    top: 0 !important;
}
#legend td {
    padding: 2px 0 2px 0;
}
.table > tbody > tr > td {
    border-top: none !important;
}

</style>
