<template>
    <tr :style="{color: item.hidden ? '#adadad' : 'black'}">
        <template v-if="item.editMode">
            <td ></td>
            <td class="text-sm" v-if="isShowHashIds">
                {{item.hashId}}
            </td>
            <td>
                <input v-model="editedItem.name"
                       type="text"
                       name="name"
                       id="name"
                       ref="name"
                       class="form-control"
                       :placeholder="$t('comp.manageThreats.threat.namePlaceholder')"
                       :readonly="item.methodology"
                       @keypress.enter="save"
                       v-validate="'required|max:256'"
                       :data-vv-as="$t('comp.manageThreats.threat.name-data-vv')"/>

                <span class="help-block text-red">{{errors.first('name')}}</span>

                <textarea v-if="!item.methodology || item.description"
                          v-model="editedItem.description"
                          type="text"
                          name="description"
                          id="description"
                          ref="description"
                          class="form-control"
                          :placeholder="$t('comp.manageThreats.threat.descriptionPlaceholder')"
                          :readonly="item.methodology">
                </textarea>
            </td>
            <td style="height: 60px;">
                <multiselect
                        v-model="editedItem.tags"
                        :options="tags.map(tag => tag.id)"
                        :custom-label="opt => tags.find(x => x.id == opt).name"
                        :multiple="true"
                        :taggable="true"
                        :closeOnSelect="false"
                        @tag="createTag">
                </multiselect>
            </td>
            <td>
                <multiselect
                        v-model="editedItem.classification"
                        :options="classifications.map(threatClassification => threatClassification.id)"
                        :custom-label="opt => classifications.find(x => x.id == opt).name"
                        :multiple="false"
                        :taggable="false"
                        :closeOnSelect="true"
                        >
                </multiselect>
            </td>
            <td></td>
            <td></td>
            <td class="text-right" id="save">
                <button class="btn btn-success btn-xs" @click="save">
                    <i class="fas fa-save"></i>
                </button>
                <button class="btn btn-default btn-xs" @click="cancel">
                    <i class="fas fa-times"></i>
                </button>
            </td>
        </template>
        <template v-else>
            <template  v-if="!item.methodology">
                <td><input type="checkbox" class="checkbox" v-model="item.selected"/></td>
            </template>
            <template v-else>
                <td></td>
            </template>
            <td class="text-sm" v-if="isShowHashIds">
                {{item.hashId}}
            </td>
            <td class="text-sm">
                {{item.name}}
                <i class="fas fa-info-circle description-icon" v-if="item.description" :title="item.description"></i>
            </td>
            <td>
                <label class="label label-default mr2" v-for="tagId in item.tags" v-if="getTagById(tagId)" :key="tagId">
                    {{getTagById(tagId).name}}
                </label>
            </td>
            <td class="text-sm"><span v-if="item.classification != null">{{(classifications.find(x => x.id == item.classification)).name}}</span></td>
            <td>{{item.threatVulnCount}}</td>
            <td>{{item.riskCount}}</td>
            <td class="text-right">
                <template  v-if="!item.methodology">
                    <button class="btn btn-danger btn-xs" @click="remove" :disabled="isLoading">
                        <i class="fas fa-trash fa-fw"></i>
                    </button>
                </template>

                <template v-else>
                    <div class="label text-black pull-left">
                        {{$t('common.methodology')}} <img src="/favicon.ico" />
                    </div>
                    <button title="nezobrazeno" @click="hide" class="btn btn-primary btn-xs" v-if="item.hidden">
                        <i aria-hidden="true" class="far fa-eye-slash fa-fw"></i>
                    </button>
                    <button title="zobrazeno" @click="hide" class="btn btn-primary btn-xs" v-else>
                        <i aria-hidden="true" class="far fa-eye fa-fw"></i>
                    </button>
                </template>


                <button class="btn btn-warning btn-xs" @click="toggleEdit" :disabled="isLoading">
                    <i class="fas fa-pencil-alt fa-fw"></i>
                </button>
            </td>
        </template>
        <delete-confirmation :ref="'delconfThreat'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>
    </tr>
</template>

<script>
    import Multiselect from '../../Controls/CsaMultiSelect.vue'
    import {mapActions, mapGetters} from 'vuex';
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

    export default {
        name: 'create-threat',
        components: {
            Multiselect,
            DeleteConfirmation
        },
        props: {
            readonly: {
                type: Boolean
            },
            item: {
                type: Object,
                require: true
            },
            isShowHashIds: {
                type: Boolean
            },
        },
        beforeMount: function () {
            if (!this.item.id) {
                this.$set(this.item, "editMode", true);
            } else {
                this.$set(this.item, "editMode", false);
            }

            this.editedItem = Object.assign({}, this.item);
        },
        mounted(){
            if (this.item.editMode) {
                this.$refs.name.focus();
            }
        },
        data() {
            return {
                edit: false,
                editedItem: null,
                isLoading: false
            };
        },
        methods: {
            createTag: function (name) {
                let newTag = {
                    name: name
                };

                this.isLoading = true;

                this.addTag(newTag).then((tag) => {
                    this.isLoading = false;

                    this.item.tags.push(tag.id);
                });
            },
            toggleEdit: function () {
                if(this.item.editMode) {
                    this.$set(this.item, "editMode", false);
                } else {
                    this.$set(this.item, "editMode", true);
                }

                if (this.editMode) {
                    this.editedItem = Object.assign({}, this.item);
                }
            },
            save: function () {
                this.$validator.validate().then(result => {
                    if (!result) {
                        return;
                    }

                    this.item.name = this.editedItem.name;
                    this.item.description = this.editedItem.description;
                    this.item.tags = this.editedItem.tags;
                    this.item.classification = this.editedItem.classification;

                    if (this.item.id) {
                        this.updateThreat(this.item);
                    } else {//create
                        this.addThreat(this.item).then((threat) => {
                            this.item.id = threat.id;
                            this.item.hashId = threat.hashId;
                        });;
                    }

                    this.showSaved();
                    this.toggleEdit();
                });
            },
            cancel: function () {
                if (this.item.id) {
                    this.toggleEdit();
                    this.editedItem = Object.assign({}, this.item);
                } else {
                    this.$emit("removed", this.item);
                }
            },
            remove: function () {
                let modalWindow = this.$refs.delconfThreat;
                modalWindow.show();
            },
            deleteConfirmed: function() {
                this.isLoading = true;

                if (this.item.id) {
                    this.removeThreat(this.item).then(res => {
                        if(res == "ok"){
                            this.$emit("removed", this.item);
                        }
                        else if (res == "in_use"){
                            this.showCanNotDeleteInUse();
                        }

                        this.isLoading = false;
                    });
                } else {
                    this.$emit("removed", this.item);
                    this.isLoading = false;
                }
            },
            hide: function () {
                this.isLoading = true;

                this.item.hidden = !this.item.hidden;

                this.updateThreat(this.item).then(() => {
                    this.isLoading = false;
                });
            },
            ...mapActions('threats', ['removeThreat', 'updateThreat', 'addThreat']),
            ...mapActions('threatTags', {
                addTag: 'addTag',
            }),
            ...mapActions('threatClassification', {
                setClassification: 'setClassification'
            })
        },
        computed: {
            ...mapGetters('threatTags', {
                tags: 'getAll',
                getTagById: 'getById'
            }),
            ...mapGetters('threatClassification', {
                classifications: 'getClassification'
            })
        },
        notifications: {
            showSaved: {
                message: 'common.saved',
                type: 'success'
            },
            showCanNotDeleteInUse: {
                message: 'common.canNotDeleteInUse',
                type: 'error'
            }
        }
    }
</script>

<style scoped lang="less">
    #name {
        height: 40px;
    }

    #save {
        vertical-align: middle;
    }

    .label {
        white-space: normal;
    }
</style>
