<template>
    <div>
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th style="width: 50px;"></th>
                    <th style="width: 40%;">{{$t('comp.assetModal.attributesTab.attributesTab.attribute')}}</th>
                    <th>{{$t('comp.assetModal.attributesTab.attributesTab.value')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td>
                        <csa-select
                                @input="addAttribute"
                                :options="availableAttributes"
                                trackBy="id"
                                label="name"
                        />
                    </td>
                    <td class="text-blue text-bold">
                        <i class="fas fa-arrow-left"></i> {{$t('comp.assetModal.attributesTab.attributesTab.selectAttr')}}
                    </td>
                </tr>
            </tbody>
            <tbody class="attribute-controls" v-if="attributes.length">
                <tr v-for="attribute in asset.ownAttributes"  :key="attribute.attributeId">
                    <td>
                        <button type="button" class="btn btn-link text-red" @click="removeAttribute(attribute)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                    <td>{{getAttributeById(attribute.attributeId).name}}</td>
                    <td>
                        <template v-if="getAttributeById(attribute.attributeId).type === 'select'">
                            <select v-model="attribute.value" v-once>
                                <option :value="null"></option>
                                <option v-for="option in getAttributeById(attribute.attributeId).options"
                                        :value="option.id" v-bind:key="option.id">{{truncateOptions(option.value)}}</option>
                            </select>
                        </template>
                        <template v-if="getAttributeById(attribute.attributeId).type === 'multiSelect'">
                            <select multiple="multiple" v-model="attribute.value">
                                <option v-for="option in getAttributeById(attribute.attributeId).options"
                                        :value="option.id" v-bind:key="option.id">{{truncateOptions(option.value)}}</option>
                            </select>
                        </template>
                        <template v-if="getAttributeById(attribute.attributeId).type === 'boolean'">
                            <input type="checkbox" v-model="attribute.value" />
                        </template>
                        <template v-if="getAttributeById(attribute.attributeId).type === 'simpleText'">
                            <input type="text" class="form-control" v-model="attribute.value" maxlength="256" />
                        </template>
                        <template v-if="getAttributeById(attribute.attributeId).type === 'text'">
                            <limited-textarea v-model="attribute.value" :max=256 :value="attribute.value" :setFormControl="true" :showCounter="true"></limited-textarea>
                        </template>
                        <template v-if="getAttributeById(attribute.attributeId).type === 'link'">
                            <input type="text" class="form-control" v-model="attribute.value" maxlength="256" />
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex'
    import 'bootstrap-select';

    export default {
        components: {
            CsaSelect
        },
        props: {
            asset: {
                type: Object,
                required: true
            }
        },
        data(){
            return {
                isLoading: false
            };
        },
        created() {
            this.$watch('asset.ownAttributes', () => {
                this.$nextTick(()=>{
                    $(".attribute-controls select").selectpicker();
                });
            }, { immediate: true });
        },
        activated(){
            this.$nextTick(()=>{
                $(".attribute-controls select").selectpicker();
            });
        },
        computed: {
            ...mapGetters('assetOwnAttributes', {
                attributes: 'getAttributes',
                getAttributeById: 'getAttributeById'
            }),
            availableAttributes: function () {
                return this.attributes.filter(a => {
                    return !this.asset.ownAttributes.find((s)=>{
                        return s.attributeId === a.id;
                    });
                });
            }
        },
        methods: {
            ...mapActions('assetOwnAttributes', {
                fetchAttributes: 'fetchAttributes'
            }),
            addAttribute(attribute) {
                this.asset.ownAttributes.unshift({
                    attributeId: attribute.id,
                    value: null
                });
            },
            removeAttribute(attribute) {
                let index = this.asset.ownAttributes.indexOf(attribute);
                this.asset.ownAttributes.splice(index, 1);
            },
            truncateOptions(val) {
                if (!val)
                    return val;

                if (val.length > 50) {
                    let i = val.indexOf(" ", 45);
                    if (i > 0)
                        val = val.substring(0, i) + "…";
                    else if (val.length > 55)
                        val = val.substring(0, 50) + "…";
                }

                return val;
            }
        }
    }
</script>

<style lang="less" scoped>
    td {
        vertical-align: middle !important;
    }
</style>