<template>
    <div v-if="risk">
        <div class="row" v-if="!isLoading">
            <div class="col-md-7">
                <div class="form-group">
                    <label for="impact">{{$t('comp.editRisk.evaluation.evaluation.impact')}}</label>
                    <multiselect
                            id="impact"
                            v-model="risk.scaleIId"
                            :options="getImpactScale.map(impact => impact.id)"
                            :custom-label="opt => getImpactScale.find(x => x.id == opt).name"
                            :disabled="true">

                        <div slot="option" slot-scope="props">
                            <strong>{{getImpactScale.find(x => x.id == props.option).name}}</strong> <br />

                            <div class="text-sm" style="white-space: pre-wrap;">{{getImpactScale.find(x => x.id == props.option).description}}</div>
                        </div>
                    </multiselect>
                    <div class="text-primary info text-sm" style="white-space: pre-wrap;" v-if="selectedImpact">{{selectedImpact.description}}</div>

                    <div style="padding-top: 10px; padding-bottom: 5px;">
                        <label>{{$t('comp.editRisk.evaluation.evaluation.impactBy')}}</label>
                        <div class="flex">
                            <label class="checkbox-inline">
                                <input type="checkbox" v-model="risk.impactByConfidence">
                                <abbr><strong>C</strong> - {{$t('comp.editRisk.evaluation.evaluation.confidence')}}</abbr>
                            </label>
                            <label class="checkbox-inline">
                                <input type="checkbox" v-model="risk.impactByIntegrity">
                                <abbr><strong>I</strong> - {{$t('comp.editRisk.evaluation.evaluation.integrity')}}</abbr>
                            </label>
                            <label class="checkbox-inline">
                                <input type="checkbox" v-model="risk.impactByAvailability">
                                <abbr><strong>A</strong> - {{$t('comp.editRisk.evaluation.evaluation.availability')}}</abbr>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="threat">{{$t('comp.editRisk.evaluation.evaluation.threat')}}</label>
                    <multiselect
                            id="threat"
                            v-model="risk.scaleTId"
                            :options="getThreatScale.map(impact => impact.id)"
                            :custom-label="opt => getThreatScale.find(x => x.id == opt).name"
                           >

                        <div slot="option" slot-scope="props">
                            <strong>{{getThreatScale.find(x => x.id == props.option).name}}</strong> <br />

                            <div class="text-sm" style="white-space: pre-wrap;">{{getThreatScale.find(x => x.id == props.option).description}}</div>
                        </div>
                    </multiselect>
                    <div class="text-primary info text-sm" v-if="selectedThreat" style="white-space: pre-wrap;">{{selectedThreat.description}}</div>
                </div>
                <div class="form-group">
                    <label for="vulnerability">{{$t('comp.editRisk.evaluation.evaluation.vulnerability')}}</label>
                    <multiselect
                            id="vulnerability"
                            v-model="risk.scaleVId"
                            :options="getVulnerabilityScale.map(impact => impact.id)"
                            :custom-label="opt => getVulnerabilityScale.find(x => x.id == opt).name"
                           >

                        <div slot="option" slot-scope="props">
                            <strong>{{getVulnerabilityScale.find(x => x.id == props.option).name}}</strong> <br />

                            <div class="text-sm" style="white-space: pre-wrap;">{{getVulnerabilityScale.find(x => x.id == props.option).description}}</div>
                        </div>
                    </multiselect>
                    <div class="text-primary info text-sm" style="white-space: pre-wrap;" v-if="selectedVulnerability">{{selectedVulnerability.description}}</div>
                </div>
            </div>

            <div class="col-md-5">
                <div class="text-center" v-if="computedRisk">
                    <div class="text-left">
                        <p v-if="getThreat(risk.threatId)">
                            <strong>{{$t('comp.editRisk.evaluation.evaluation.threat')}}:</strong>
                            <span v-if = "isShowHashIds"> [{{getThreat(risk.threatId).hashId}}]</span>
                            {{getThreat(risk.threatId).name}}
                            <i class="fas fa-info-circle description-icon" v-if="getThreat(risk.threatId).description" :title="getThreat(risk.threatId).description"></i>
                        </p>
                        <p v-if="getVulnerability(risk.vulnerabilityId)">
                            <strong>{{$t('comp.editRisk.evaluation.evaluation.vulnerability')}}:</strong>
                            <span v-if = "isShowHashIds"> [{{getVulnerability(risk.vulnerabilityId).hashId}}]</span>
                            {{getVulnerability(risk.vulnerabilityId).name}}
                            <i class="fas fa-info-circle description-icon" v-if="getVulnerability(risk.vulnerabilityId).description" :title="getVulnerability(risk.vulnerabilityId).description"></i>
                        </p>
                    </div>

                    <div style="margin-top: 30px;"></div>

                    <p><strong style="font-size: 20px;">{{$t('comp.editRisk.evaluation.evaluation.risk')}}</strong></p>

                    <div class="margin-bottom"></div>

                    <template v-if="computedRisk.classification">
                        <p>
                            <span class="label" style="font-size: 25px; color: white;" :style="{backgroundColor: computedRisk.color}">
                                {{computedRisk.value}} ({{computedRisk.percentage}}%)
                            </span>
                        </p>
                        <div class="margin-bottom"></div>
                        <div style="font-size: 20px; text-transform: uppercase" :style="{color: computedRisk.color}">
                            <strong>{{computedRisk.classification.name}}</strong>
                        </div>
                        <div class="text-primary info text-sm" v-show="selectedVulnerability">
                            {{computedRisk.classification.description}}
                        </div>
                    </template>

                    <em v-else>{{$t('comp.editRisk.evaluation.evaluation.incompleteEvaluation')}}</em>
                </div>
            </div>
        </div>

        <loading :active="isLoading"></loading>
    </div>
</template>

<script>
    import Multiselect from 'Controls/CsaMultiSelect.vue'
    import {mapGetters, mapActions} from 'vuex';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    export default {
        name: "tab-Evaluation",
        components: {
            Multiselect,
            ckeditor: CKEditor.component
        },
        props: {
            risk: {
                type: Object,
                required: true
            },
            readonly: {
                type: Boolean,
                required: true
            }
        },
        watch: {
            'risk.scaleIId': function (v) {
                this.computeRisk();
            },
            'risk.scaleTId': function (v) {
                this.computeRisk();
            },
            'risk.scaleVId': function (v) {
                this.computeRisk();
            },
            'risk.impactByIntegrity': function (v) {
                this.computeImpactScaleId();
            },
            'risk.impactByAvailability': function (v) {
                this.computeImpactScaleId();
            },
            'risk.impactByConfidence': function (v) {
                this.computeImpactScaleId();
            }
        },
        async created() {
            await this.fetchScales();
            this.computeRisk(false).then(()=>{
                this.isLoading = false;
            });
            await this.fetchIsShowHashIds();
        },
        data() {
            return {
                isLoading: true,
                computedRisk: null,
                autoAcceptThreshold: null,
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'bulletedList',
                            'numberedList',
                        ]
                    }
                }
            };
        },
        methods: {
            ...mapActions("riskScaleItems", {
                fetchScaleImpacts: 'fetchImpacts',
                fetchScaleVulnerabilites: 'fetchVulnerabilites',
                fetchScaleThreats: 'fetchThreats',
            }),
            ...mapActions('userSettings', {
                    fetchIsShowHashIds: 'fetchIsShowHashIds'
                }),
            ...mapActions("threats", ["fetchThreats"]),
            ...mapActions("vulnerabilities", ["fetchVulnerabilities"]),
            fetchScales: async function () {
                await this.fetchScaleImpacts();
                await this.fetchScaleVulnerabilites();
                await this.fetchScaleThreats();
                await this.fetchVulnerabilities();
                await this.fetchThreats();
            },
            computeRisk: async function () {
                await this.$api.get('risk/compute-risk-value/' + this.risk.id, {
                    params: {
                        impactId: this.risk.scaleIId,
                        threatId: this.risk.scaleTId,
                        vulnerabilityId: this.risk.scaleVId,
                    }
                })
                .then(({data}) => {
                    this.computedRisk = data.data;
                });
            },
            computeImpactScaleId: async function () {
                await this.$api.get('risk/compute-impact-scale-id/' + this.risk.id, {
                    params: {
                        impactByIntegrity: this.risk.impactByIntegrity,
                        impactByAvailability: this.risk.impactByAvailability,
                        impactByConfidence: this.risk.impactByConfidence,
                    }
                })
                .then(({data}) => {
                    this.risk.scaleIId = data.data;
                });
            }
        },
        computed: {
            ...mapGetters("riskScaleItems", ["getImpactScale", "getThreatScale", "getVulnerabilityScale"]),
            ...mapGetters("threats", {
                getThreat: 'getById'
            }),
            ...mapGetters("vulnerabilities", {
                getVulnerability: 'getById'
            }),
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            }),
            selectedThreat: function () {
                if(!this.risk) {
                    return null;
                }

                return this.getThreatScale.find((i) => {
                    return i.id === this.risk.scaleTId;
                });
            },
            selectedVulnerability: function () {
                if(!this.risk) {
                    return null;
                }

                return this.getVulnerabilityScale.find((i) => {
                    return i.id === this.risk.scaleVId;
                });
            }
        }
    }
</script>
