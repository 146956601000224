<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th colspan=3 >
                    <span class="question-type-info">
                        *                    
                        <span v-if="question.isMultipleCorrectAnswers" v-html="$t('chooseMultipleAnswers')"></span>
                        <span v-if="!question.isMultipleCorrectAnswers" v-html="$t('chooseOneAnswer')"></span>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody id="answers">
                <tr
                        v-for="(answer, index) in question.answers"
                        v-bind:class="{
                            success: question.answers[index].correct
                        }">
                    <td class="text-bold" style="width: 50px;">{{index+1}}.</td>
                    <td>
                        {{question.answers[index].value}}

                        <div class="text-sm"  v-if="question.answers[index].correct !== question.answers[index].correctByParticipant &&
                                                    question.answers[index].substantiation">
                            <strong>{{ $t("substantiation") }}: </strong> <span class="text-warning">{{question.answers[index].substantiation}}</span>
                        </div>
                    </td>
                    <td class="text-center" style="width: 150px;">
                        <div class="pretty p-icon" style="font-size: 20px;">
                            <input
                                    disabled="disabled"
                                    type="checkbox"
                                    v-model="question.answers[index].correctByParticipant"
                                    v-validate="'required'" />
                            <div class="state p-success" v-if="question.answers[index].correct === question.answers[index].correctByParticipant">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                            <div class="state p-danger" v-else>
                                <i class="icon fa fa-times"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        inheritAttrs: false,
        components: {
            BaseQuestion
        }
    }
</script>

<style scoped>
    #answers td {
        vertical-align: middle;
    }

    .question-type-info {
        font-weight: 400;
        font-style: italic;
    }
</style>