<template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" v-if="!id">
                        {{$t('comp.riskClassification.editModal.classificationCreate')}}
                    </h4>
                    <h4 class="modal-title" v-else>
                        {{$t('comp.riskClassification.editModal.classificationEdit')}}: <strong>{{classification.name}}</strong>
                    </h4>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <label>{{$t('comp.riskClassification.editModal.name')}}</label>
                        <input type="text"
                               class="form-control"
                               v-model="classification.name"
                               :readonly="classification.isMethodology"
                               v-validate="'required'"
                               name="name"
                               :data-vv-as="$t('comp.riskClassification.editModal.name-data-vv')" />
                        <span class="text-red">{{ errors.first('name') }}</span>

                        <span class="help-block"></span>
                    </div>

                    <h4 class="text-center">{{$t('comp.riskClassification.editModal.classificationList')}}</h4>

                    <p class="text-right">
                        <button class="btn btn-default" @click="addItem" :disabled="classification.isMethodology">
                            <i class="fas fa-plus"></i>
                            {{$t('common.add')}}
                        </button>
                    </p>

                    <table class="table">
                        <thead>
                        <tr>
                            <th style="width: 150px;" class="bg-light-blue-gradient">{{$t('comp.riskClassification.editModal.interval')}}</th>
                            <th class="bg-light-blue-gradient">{{$t('comp.riskClassification.editModal.nameAndDescription')}}</th>
                            <th class="bg-light-blue-gradient" width="10%"></th>
                        </tr>
                        </thead>
                        <tbody v-for="(item, index) in sortedItems" :key="item.key">
                            <tr class="item">
                                <td class="bg-gray-light text-center" v-if="index === 0 && sortedItems.length === 1">
                                    <div class="input-group value">
                                        <div class="input-group-addon">1</div>
                                        <div class="input-group-addon">-</div>
                                        <input
                                                type="number"
                                                class="form-control value"
                                                readonly
                                                :value="item.valueMax" />
                                    </div>
                                </td>
                                <td class="bg-gray-light text-center" v-else-if="index === 0">
                                    <div class="input-group value">
                                        <div class="input-group-addon">1</div>
                                        <div class="input-group-addon">-</div>
                                        <input
                                                type="number"
                                                class="form-control"
                                                min="2"
                                                :max="sortedItems[index + 1].valueMax - 2"
                                                v-model.lazy.number="item.valueMax"
                                                ref="value"
                                                @change="fixValues"
                                                @keypress="validateInput" />
                                    </div>
                                </td>
                                <td class="bg-gray-light text-center" v-else-if="(index+1) === sortedItems.length">
                                    <div class="input-group value">
                                        <div class="input-group-addon">{{sortedItems[index - 1].valueMax + 1}}</div>
                                        <div class="input-group-addon">-</div>
                                        <input type="number"
                                               class="form-control"
                                               v-model="item.valueMax"
                                               ref="value"
                                               readonly />
                                    </div>
                                </td>
                                <td class="bg-gray-light text-center" v-else-if="index !== sortedItems.length">
                                    <div class="input-group value">
                                        <div class="input-group-addon">{{sortedItems[index - 1].valueMax + 1}}</div>
                                        <div class="input-group-addon">-</div>
                                        <input
                                                type="number"
                                                class="form-control"
                                                :min="sortedItems[index - 1].valueMax+2"
                                                :max="sortedItems[index + 1].valueMax-2"
                                                v-model.lazy.number="item.valueMax"
                                                ref="value"
                                                @change="fixValues"
                                                @keypress="validateInput" />
                                    </div>
                                </td>

                                <td class="bg-gray-light">
                                    <input type="text"
                                           :placeholder="$t('comp.riskClassification.editModal.namePlaceholder')"
                                           class="form-control"
                                           v-model="item.name"
                                           v-validate="'required'"
                                           :name="'name' + item.key"
                                           :readonly="classification.isMethodology"
                                           :data-vv-as="$t('comp.riskClassification.editModal.name-data-vv')" />

                                    <span class="text-red">{{ errors.first('name' + item.key) }}</span>
                                    <textarea :placeholder="$t('comp.riskClassification.editModal.descriptionPlaceholder')" class="form-control text-sm" style="resize: vertical; min-height: 50px; margin-top: 5px;" v-model="item.description" :readonly="classification.isMethodology" />
                                </td>
                                <td class="no-border actions">
                                    <button class="ajax btn btn-danger"
                                            style="height: 34px;"
                                            @click="remove(item)"
                                            :disabled="classification.items.length === 1 || classification.isMethodology">
                                        <i class="far fa-trash-alt"></i>
                                    </button>

                                    <div class="btn-group btn-group-xs moveButtons">
                                        <button class="btn btn-default btn-xs" :disabled="index === 0 || classification.isMethodology" @click="moveItemUp(item)"><i class="fas fa-angle-up"></i></button>
                                        <button class="btn btn-default btn-xs" :disabled="index === sortedItems.length-1 || classification.isMethodology"  @click="moveItemDown(item)"><i class="fas fa-angle-down"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="modal-footer">

                    <button class="btn btn-link pull-left" @click="cancel">{{$t('common.cancel')}}</button>
                    <div class="pull-right">
                        <span class="text-danger" style="margin-right: 10px;" v-if="isScalesChanged">
                            <i class="fas fa-exclamation-triangle"></i> {{$t('comp.riskClassification.editModal.isScalesChanged')}}
                        </span>
                        <button class="btn btn-success pull-right" @click="save">
                            {{$t('common.save')}}
                        </button>
                    </div>
                </div>

                <loading :active="isLoading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script>
    import Bus from './Bus';

    export default {
        name: 'riskClassification-editModal',
        data(){
            return {
                id: this.$route.params.id,
                classification: {
                    name: "",
                    items: [],
                    maxRiskValue: 0
                },
                key: 1,
                isScalesChanged: false
            };
        },
        computed: {
            sortedItems() {
                return this.classification.items.sort((a, b)=>{
                    return a.valueMax > b.valueMax;
                });
            }
        },
        mounted() {
            this.modal = this.$refs.modal;

            $(this.modal).modal();
            $(this.modal).on('hidden.bs.modal', ()=>{
                this.$router.push({name: 'listing'});
            });

            if(this.id) {
                this.load();
            } else {
                this.initNewClassification();
            }
        },
        methods: {
            cancel() {
                $(this.modal).modal('hide');
            },
            fixValues() {
                if(this.sortedItems.length) {
                    let items = [...this.sortedItems];

                    for(let i = 0; i < items.length; i++) {
                        let item = items[i];

                        if(i === 0 && items.length === 1) {
                            this.$set(item, 'valueMin', 1);
                            this.$set(item, 'valueMax', this.classification.maxRiskValue);
                        }
                        else if(i === 0) {
                            this.$set(item, 'valueMin', 1);
                        } else {
                            this.$set(item, 'valueMin', items[i - 1].valueMax + 1);
                        }
                    }
                }
            },
            recomputeBoundaries() {
                let items = this.sortedItems;
                let average = Math.round(this.classification.maxRiskValue / items.length);

                for(let i = 0; i < items.length; i++) {
                    let item = items[i];

                    if(i === 0 && items.length === 1) {
                        this.$set(item, 'valueMin', 1);
                        this.$set(item, 'valueMax', this.classification.maxRiskValue);
                    }
                    else if(i === 0) {
                        this.$set(item, 'valueMin', 1);
                        this.$set(item, 'valueMax', average);
                    } else {
                        this.$set(item, 'valueMin', i * average + 1);
                        this.$set(item, 'valueMax', i * average + average);
                    }

                    if((i+1) === items.length && item.valueMax !== this.classification.maxRiskValue) {
                        item.valueMax = this.classification.maxRiskValue;
                        this.$set(item, 'valueMax', this.classification.maxRiskValue);
                    }
                }
            },
            validateInput: function ($event) {
                $event.preventDefault();
            },
            addItem() {
                this.classification.items.push({
                    key: this.key++,
                    id: null,
                    name: '',
                    description: ''
                });

                this.recomputeBoundaries();
            },
            remove(item) {
                let items = this.classification.items;
                let index = items.indexOf(item);
                this.classification.items.splice(index, 1);

                this.recomputeBoundaries();
            },
            save() {
                this.$validator.validate().then(result => {
                    if (result) {
                        if(this.id) {
                            this.$api.post("risk-classification/" + this.id, this.classification).then(({data}) => {
                                if(data.status === 'ok') {
                                    this.cancel();
                                    this.$emit("reload");
                                    this.classificationSaved();
                                }
                            });
                        } else {
                            this.$api.post("risk-classification/", this.classification).then(({data}) => {
                                if(data.status === 'ok') {
                                    this.cancel();
                                    this.$emit("reload");
                                    this.classificationCreated();
                                }
                            });
                        }
                    }
                });
            },
            load() {
                this.$api.get("risk-classification/" + this.id).then(({data})=>{
                    if(data.status === 'ok') {
                        this.classification = data.data;

                        this.classification.items.forEach((i) => {
                            this.$set(i, 'key', this.key++);
                        });

                        // Check max risk value and if scales changed, then recount categories
                        if(this.sortedItems.length > 0 && this.sortedItems[this.sortedItems.length-1].valueMax !== this.classification.maxRiskValue){
                            this.recomputeBoundaries();
                            this.isScalesChanged = true;
                        }
                    }
                });
            },
            initNewClassification() {
                this.$api.get("risk-classification/max-risk-value").then(({data})=>{
                    if(data.status === 'ok') {
                        this.classification.maxRiskValue = data.data.maxRiskValue;
                        this.addItem();
                    }
                });
            },
            moveItemUp(item) {
                let index = this.classification.items.indexOf(item);
                let prevItem = this.classification.items[index - 1];

                let tmpName = prevItem.name;
                let tmpDescription = prevItem.description;

                this.$set(prevItem, 'name', item.name);
                this.$set(prevItem, 'description', item.description);
                this.$set(item, 'name', tmpName);
                this.$set(item, 'description', tmpDescription);
            },
            moveItemDown(item) {
                let index = this.classification.items.indexOf(item);
                let nextItem = this.classification.items[index + 1];

                let tmpName = nextItem.name;
                let tmpDescription = nextItem.description;

                this.$set(nextItem, 'name', item.name);
                this.$set(nextItem, 'description', item.description);
                this.$set(item, 'name', tmpName);
                this.$set(item, 'description', tmpDescription);
            }
        },
        notifications: {
            classificationCreated: {
                message: 'comp.riskClassification.editModal.classificationCreated',
                type: 'success'
            },
            classificationSaved: {
                message: 'comp.riskClassification.editModal.classificationSaved',
                type: 'success'
            }
        }
    }
</script>

<style scoped lang="less">
    .value {
        border: 1px solid #b0b0b0;
        padding-right: 5px;

        .input-group-addon{
            border: 0;
            font-weight: bolder;
            text-align: right;
        }

        input {
            border: 0;
            font-weight: bolder;
            padding-right: 0;
        }
    }

    .actions {
        position: relative;

        .moveButtons {
            position: absolute;
            bottom: 5px;
            left: 5px;
        }
    }
</style>
