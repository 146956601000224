<template>
    <div class="box box-warning">
        <div class="box-header">
            <h3 class="box-title">
                {{ $t("general") }}
            </h3>
        </div>
        <div class="box-body">
            <div class="form-group">
                <label>{{ $t("testName") }}</label>
                <input type="text" class="form-control" v-model="test.name" :disabled="!editable" name="testName"
                       v-validate="'required'" />

                <span class="text-red">{{ errors.first('testName') }}</span>
            </div>
            <div class="form-group">
                <label>{{ $t("testDescription") }}</label>
                <vue-ckeditor v-model="test.description" :config="config" :readOnlyMode="!editable"></vue-ckeditor>
            </div>
            <div class="form-group">
                <label>{{ $t("timeConsumption") }} [min]</label>
                <input type="number" min="0" class="form-control" v-model="test.time" :disabled="!editable" name="time"
                       v-validate="'integer'"
                       :data-vv-as="$t('timeConsumption')" />

                <span class="text-red">{{ errors.first('time') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import VueCkeditor from 'vue-ckeditor2';

    export default {
        inject: [ 'parentValidator' ],
        components: {
            VueCkeditor
        },
        data() {
            return {
                files: [],
                config: {
                    toolbar: [
                        ['Bold', 'Italic', 'Underline', 'Strike', 'BulletedList', 'NumberedList']
                    ],
                    height: 100
                },
            }
        },
        props: {
            test: Object,
            editable: Boolean
        },
        created () {
            this.$validator = this.parentValidator;
        },
        methods: {

        }
    }
</script>

<style>

</style>