<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog modal-xl" role="document" v-if="assetId">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">{{$t('comp.assetDependencyDiagram.index.title')}} <span v-if="isShowHashIds">[{{ asset.hashId }}] </span>{{ asset.name }}</h4>
                </div>
                <div class="modal-body">
                    <assets-diagram
                            :asset-id="assetId"
                            width="1150"
                            height="550"
                            :key="key"
                    ></assets-diagram>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">{{$t('common.close')}}</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>

<script>
    import AssetsDiagram from '../AssetsDiagram/index.vue';
    import {mapActions, mapGetters} from "vuex";

    export default {
        components: {
            AssetsDiagram
        },
        data() {
            return {
              assetId: null,
              asset: null,
              key: 0
            };
        },
        computed: {
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            }),
        },
        mounted() {
            this.$root.$on("open", (id) => {
                return this.open(id);
            });
        },
        created: function () {
            this.fetchIsShowHashIds()
        },
        methods: {
            open(id) {
                this.assetId = id;
                this.key++;     // To update assets-diagram on next load

                if(this.assetId){
                    this.$api.get("asset/fetch-asset/" + this.assetId).then((data)=>{
                        this.asset = data.data.data;
                        $(this.$refs.modal).modal('show');
                    });
                }
            },
            ...mapActions('userSettings', {
                fetchIsShowHashIds: 'fetchIsShowHashIds'
            }),
        }
    }
</script>