    <template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog modal-xl vld-parent" role="document" v-if="risk">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{$t('comp.editRisk.editRisk.risk')}} <strong><span v-if = "isShowHashIds">[{{risk.hashId}}]</span></strong> {{$t('comp.editRisk.editRisk.onAsset')}} <strong><span v-if = "isShowHashIds">[{{risk.asset.hashId}}]</span> {{risk.asset.name}}</strong>
                    </h4>
                </div>

                <div class="modal-body no-padding">
                    <div class="nav-tabs-custom no-margin">
                        <ul class="nav nav-tabs">
                            <router-link :to="{name: 'general'}" tag="li"><a>{{$t('comp.editRisk.editRisk.general')}}</a></router-link>
                            <router-link :to="{name: 'measures'}" tag="li"><a>{{$t('comp.editRisk.editRisk.measure')}}</a></router-link>
                        </ul>
                        <div class="tab-content">
                            <keep-alive v-if="showModal">
                                <router-view :risk="risk" :riskMeasures="riskMeasures"></router-view>
                            </keep-alive>
                            <router-view v-else></router-view>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-default pull-left" @click="close" :disabled="isLoading">{{$t('common.close')}}</button>

                    <span class="pull-right">
                        <label class="checkbox-inline" style="line-height: 20px; margin-right: 10px;" v-if="showAllowUpdateManualAcceptanceCheckbox">
                            <input type="checkbox" v-model="isUpdateManualAcceptance">
                            <abbr>{{$t('comp.editRisk.editRisk.autoAcceptCheckbox')}}</abbr>
                        </label>
                        <button class="btn btn-success" @click="save" :disabled="isLoading || isAddMeasureMode">{{$t('common.save')}}</button>
                    </span>
                </div>
            </div>

            <loading :active="isLoading" :is-full-page="false"></loading>
        </div>
    </div>
</template>


<script>
    import VueRouter from 'vue-router';
    import EvaluationTab from './Evaluation/Evaluation.vue';
    import Measures from './MeasuresTab/MeasuresTab.vue';
    import store from 'store';
    import {mapGetters, mapActions} from 'vuex';
    import CommunicationBus from "./CommunicationBus.js";

    const router = new VueRouter({
        linkExactActiveClass: 'active',
        linkActiveClass: 'active',
        routes: [
            {
                name: "general",
                component: EvaluationTab,
                path: '/edit-risk/:id/general',
                meta: {
                    showModal: true
                }
            },
            {
                name: "measures",
                path: '/edit-risk/:id/measures',
                component: Measures,
                meta: {
                    showModal: true
                }
            }
        ],
    });


    export default {
        store,
        router,
        data() {
            return {
                id: this.$route.params.id,
                risk: null,
                riskMeasures: [],
                isLoading: false,
                showModal: this.$route.meta.showModal,
                modal: null,
                showAllowUpdateManualAcceptanceCheckbox: false,
                isUpdateManualAcceptance: false,
                isAddMeasureMode: false
            }
        },
        mounted: function () {
            this.modal = this.$refs.modal;

            if(this.showModal) {
                this.show();
            }

            CommunicationBus.$on('toggleAddMeasureMode', () => {
                this.toggleAddMeasureMode();
            });
        },
        watch: {
            '$route.meta' ({showModal}) {
                this.showModal = showModal
            },
            showModal: function(showModal) {
                if(showModal) {
                    this.show();
                } else {
                    this.close();
                }
            }
        },
        created: function () {
            this.fetchIsShowHashIds();
        },
        computed:{
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            }),
        },
        methods: {
            ...mapActions('userSettings', {
                    fetchIsShowHashIds: 'fetchIsShowHashIds'
            }),
            close: function () {
                $(this.modal).modal("hide");
                this.$router.push({path: '/'}).catch(err => {}); //due to 'uncaught exception: Object'
            },
            show: function () {
                this.id = this.$route.params.id;

                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });
                this.fetchRisk();
            },
            async save() {
                this.isLoading = true;

                delete this.risk.accepted;

                if(this.riskMeasures.every(x => x.isSelected != undefined)) {
                    this.risk.riskMeasures = this.riskMeasures.filter(x => x.isSelected);

                    this.risk.riskMeasures.forEach((riskMeasure) => {
                        let threatValue = riskMeasure.threatReductionValue;
                        if(typeof threatValue === 'string'){
                            threatValue = threatValue.trim();
                        }
                        if(threatValue == null || threatValue == ''){
                            riskMeasure.threatReductionValue = 0;
                        }

                        let vulnerabilityValue = riskMeasure.vulnerabilityReductionValue;
                        if(typeof vulnerabilityValue === 'string'){
                            vulnerabilityValue = vulnerabilityValue.trim();
                        }
                        if(vulnerabilityValue == null || vulnerabilityValue == ''){
                            riskMeasure.vulnerabilityReductionValue = 0;
                        }
                    });
                }
                else {
                    // Prevent to update riskMeasures without loaded and processed measures tab
                    // Without this check current riskMeasures are deleted if user save risk without click on measures tab
                    this.risk.riskMeasures = null;
                }

                this.risk.isUpdateManualAcceptance = this.isUpdateManualAcceptance;

                await this.$api.put('risk/' + this.id, this.risk);

                this.$emit("reload");
                this.close();
            },
            async fetchRisk() {
                this.isLoading = true;

                await this.$api.get('risk/' + this.id)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.risk = data.data;
                            this.isLoading = false;
                        }
                    });

                await this.$api.get('risk/' + this.id + '/risk-measures', this.risk)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.riskMeasures = data.data;
                            this.isLoading = false;
                        }
                    });

                await this.$api.get('config/risk-auto-accept-threshold')
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            let threshold = data.data.threshold;
                            this.isUpdateManualAcceptance = false;
                            this.showAllowUpdateManualAcceptanceCheckbox = false;
                            if(threshold) {
                                // If is manually accepted and auto acceptace is allowed => show checkbox with ask to allow auto acceptance changes
                                if(this.risk.accepted && this.risk.autoAccepted === false){
                                    this.showAllowUpdateManualAcceptanceCheckbox = true;
                                }
                            }
                        }
                    });
            },
            open: function (id) {
                this.$router.push({ name: 'general', params: { id: id } })
            },
            toggleAddMeasureMode: function () {
                this.isAddMeasureMode = !this.isAddMeasureMode;
            },
        },
    }
</script>

<style scoped>
    .modal-body {
        min-height: 300px;
    }
</style>
