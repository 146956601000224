<template>
    <div>
        {{value ? 'ano' : 'ne'}}
    </div>
</template>

<script>
    export default {
        props: {
            value: Boolean
        }
    }
</script>