<template>
    <div>
        <router-view v-bind="$props" />
    </div>
</template>

<script>
    import VueRouter from 'vue-router';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import List from './List.vue';
    import Result from './Result.vue';

    const router = new VueRouter({
        routes: [
            { path: '/', component: List },
            {
                name: 'result',
                path: '/result/:testId',
                component: Result,
                props: true
            }
        ]
    });

    export default {
        name: "ManageElearningTests",
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false
            }
        },
        mounted: function () {

        },
        props: {
            courseId: String,
            notEditable: {
                type: Boolean,
                default: false
            },
        },
        methods: {

        },
        router
    }
</script>

<style lang="less">

</style>