<template>
    <div class="vld-parent">
        <template v-if="riskValuesAfterApplication && computedRisk">
            <div class="text-center mb2 text-bold text-red m-auto" style="width: 400px;">
                <div class="text-center mb2 text-uppercase">{{$t('comp.riskMeasureListing.index.decrease')}}</div>

                <div class="pull-left text-center">
                    <div class="label" style="font-size: 18px; color: white; display: block; width: 150px" :style="{backgroundColor: computedRisk.color}">
                        {{computedRisk.classification.name}} / {{computedRisk.percentageValue}}%
                    </div>
                    <span class="text-gray" style="line-height: 4rem">{{$t('comp.riskMeasureListing.index.beforeApplication')}}</span>
                </div>

                <i class="fas fa-4x fa-long-arrow-alt-right px3"></i>

                <div class="pull-right">
                    <div class="label" style="font-size: 18px; color: white; display: block; width: 150px" :style="{backgroundColor: riskValuesAfterApplication.classification.color}">
                        {{riskValuesAfterApplication.classification.name}} / {{riskValuesAfterApplication.overallValue}}%
                    </div>
                    <span class="text-gray" style="line-height: 4rem">{{$t('comp.riskMeasureListing.index.afterApplication')}}</span>
                </div>
            </div>
        </template>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th style="width: 40px;">
                        <span class="label label-success">
                            {{$t('comp.riskMeasureListing.index.applicated')}}
                        </span>
                    </th>
                    <th>{{$t('comp.riskMeasureListing.index.name')}}</th>
                    <th style="width: 25%">{{$t('comp.riskMeasureListing.index.tags')}}</th>
                    <th style="width: 20%">{{$t('comp.riskMeasureListing.index.decreaseOf')}}</th>
                    <th style="width: 10%"></th>
                </tr>
            </thead>

            <measure
                    v-for="measure in measures"
                    :key="measure.subId"
                    :measure="measure"

                    :riskSubId="riskSubId"
                    :riskValue="riskValueAfterMeasureApplication(measure)"
            ></measure>
        </table>

        <loading :active.sync="isLoading"
                 :is-full-page="false"></loading>
    </div>
</template>

<script>
    import Measure from './Measure.vue';
    import {mapActions, mapGetters} from 'vuex';
    import store from 'store';
    import Multiselect from 'Controls/CsaMultiSelect.vue'

    export default {
        store,
        components: {
            Measure,
            Multiselect
        },
        data() {
            return {
                measures: [],
                isLoading: false,
                riskValuesAfterApplication: null,
                computedRisk: null,
            }
        },
        props: {
            riskSubId: String,
        },
        computed: {
            ...mapGetters('measures', {
                measures: 'getAll',
            }),
            ...mapGetters('measureTags', {
                getAllTags: 'getAll'
            })
        },
        created: async function () {
            this.isLoading = true;
            await this.fetchRiskMeasures();
            await this.computeRisk();
            await this.computeRiskValueAfterMeasuresApplication();
            await this.fetchTags();
            this.isLoading = false;
        },
        methods: {
            ...mapActions('measureTags', {
                fetchTags: 'fetchTags',
                addTag: 'addTag',
            }),
            fetchRiskMeasures: async function () {
                await this.$api.get('risk/' + this.riskSubId + '/measures')
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.measures = data.data;
                        }
                    });
            },
            computeRisk: async function () {
                this.isLoading = true;

                await this.$api.get('risk/computeRiskValue/' + this.riskSubId)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.computedRisk = data.data;
                        }
                    });
            },
            computeRiskValueAfterMeasuresApplication: function () {
                this.$api.post('risk/computeRiskValueAfterMeasuresApplication/' + this.riskSubId, this.measures)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.riskValuesAfterApplication = data.data;
                        }
                    });
            },
            riskValueAfterMeasureApplication: function (measure) {
                if(this.riskValuesAfterApplication === null) {
                    return null;
                }

                let measureAfterApplication = this.riskValuesAfterApplication.measures.find((m) => {
                    return m.subId === measure.subId;
                });

                if(measureAfterApplication) {
                    return measureAfterApplication.value;
                }

                return null;
            },
        }
    }
</script>

<style scoped>
    input.measure_name {
        height: 40px;
    }
</style>