<template>
    <div class="panel panel-default">
        <div class="panel-body">
            <label class="file btn btn-primary pull-right" for="file" :class="{disabled: isUploading}">
                <i class="fas fa-spinner fa-spin" v-if="isUploading"></i>
                <input type="file"
                       name="file"
                       id="file"
                       ref="file"
                       style="display: none" :disabled="isUploading" @change="upload"/> {{$t('comp.orgStructure.personAttachmentsTab.addFile')}}
            </label>

            <table class="table">
                <thead>
                <tr>
                    <th width="70%">{{$t('comp.orgStructure.personAttachmentsTab.name')}}</th>
                    <th width="30%">{{$t('comp.orgStructure.personAttachmentsTab.action')}}</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="attachment in getAttachments">
                        <td>{{attachment.name}}</td>
                        <td>
                            <button class="btn btn-danger btn-xs" @click="remove(attachment)" :disabled="attachment.loading">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button class="btn btn-primary btn-xs" @click="download(attachment)" :disabled="attachment.loading">
                                <i class="fas fa-download"></i>
                            </button>
                        </td>
                    </tr>
                    <tr v-if="getAttachments.length === 0">
                        <td colspan="2" class="text-center">{{$t('comp.orgStructure.personAttachmentsTab.noAttachments')}}</td>
                    </tr>
                </tbody>
            </table>
            <delete-confirmation :ref="'delconfFile'" v-on:confirmed="deleteConfirmed($event)"></delete-confirmation>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.vue';

    export default {
        props: {
            personId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isUploading: false,
            }
        },
        components: {
            DeleteConfirmation
        },
        computed: {
            ...mapGetters('orgPersonAttachments', ['getAttachments']),
        },
        methods: {
            ...mapActions('orgPersonAttachments', ['fetchAttachments', 'addAttachment', 'removeAttachment']),
            upload() {
                let file = this.$refs.file.files[0];
                this.isUploading = true;

                this.addAttachment({personId: this.personId, file}).then(() => {
                    this.isUploading = false;
                });
            },
            download(item) {
                item.loading = true;

                this.$api.get('org-person-attachment/' + item.id + '/download', {
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.name + '.' + item.fileExtension);
                    document.body.appendChild(link);
                    link.click();
                    item.loading = false;
                });
            },
            remove(item) {
                this.$refs.delconfFile.show(item);
            },
            deleteConfirmed(attachment) {
                attachment.loading = true;
                this.removeAttachment(attachment).then(() => {
                    attachment.loading = false;
                });
            }
        },
        created() {
            this.fetchAttachments(this.personId);
        }
    }
</script>