<template>
  <div
    id="popover"
    :style="{
      top: top + 'px',
      left: left + 'px',
      display: display ? 'block' : 'none',
      width: '600px',
    }"
    :key="assetId"
  >
    <template v-if="loading">
      <i class="fas fa-spinner fa-spin"></i>
    </template>

    <template v-else-if="childAssets.length <= 0 && parentAssets.length <= 0">
      {{ $t("comp.assetDependencyPopover.index.noDependencies") }}
    </template>

    <template v-else>
      <div style="overflow: auto; max-height: 300px">
        <template v-if="childAssets.length > 0">
          <div>
            <span class="text-danger" style="font-weight: bold">
              <i class="fas fa-sitemap"></i>
              {{ $t("comp.assetDependencyPopover.index.childAssets") }}
            </span>

            <a
              class="btn btn-xs"
              style="float: right; margin-right: 15px"
              v-on:click="showDependencyDiagramModal(assetId)"
              v-if="allowDependencyDiagram"
            >
              <i class="fas fa-project-diagram" style="margin-right: 5px"></i>
              {{ $t("comp.assetDependencyPopover.index.showDiagram") }}
            </a>
          </div>
          <table class="table" style="width: 99%" :key="assetId">
            <thead>
              <tr>
                <th>
                  {{ $t("comp.assetDependencyPopover.index.classification") }}
                </th>
                <th v-if="isShowHashIds">
                  {{ $t("comp.assetDependencyPopover.index.id") }}
                </th>
                <th>{{ $t("comp.assetDependencyPopover.index.assetName") }}</th>
                <th>{{ $t("comp.assetDependencyPopover.index.type") }}</th>
                <th>{{ $t("comp.assetDependencyPopover.index.guarantor") }}</th>
                <th>{{ $t("comp.assetDependencyPopover.index.supplier") }}</th>
                <th>
                  {{ $t("comp.assetDependencyPopover.index.practitioner") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="asset in childAssets">
                <td style="width: 10%">
                  <i
                    v-if="asset.classificationName"
                    :class="asset.classificationIcon"
                    :title="asset.classificationName"
                  >
                  </i>
                  <i class="fas fa-times" v-else></i>
                </td>
                <td
                  style="width: 10%"
                  class="text-left text-sm"
                  v-if="isShowHashIds"
                >
                  {{ asset.hashId }}
                </td>
                <td
                  style="width: 20%"
                  class="text-left text-sm"
                  v-if="asset.name && asset.name.trim() != ''"
                >
                  <a :href="asset.link">{{ asset.name }}</a>
                </td>
                <td style="width: 20%" class="text-left text-sm" v-else>
                  <a :href="asset.link">{{
                    $t("comp.assetDependencyPopover.index.withoutName")
                  }}</a>
                </td>
                <td style="width: 20%" class="text-left text-sm">
                  {{ asset.typeName }}
                </td>
                <td style="width: 20%" class="text-left text-sm">
                  {{ asset.guarantorName }}
                </td>
                <td style="width: 20%" class="text-left text-sm">
                  {{ asset.supplierName }}
                </td>
                <td style="width: 30%" class="text-left text-sm">
                  <span v-if="asset.practitionerName">
                    {{ asset.practitionerName }} ({{
                      asset.practitionerPercentage
                    }}%)
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-if="parentAssets.length > 0">
          <div>
            <span class="text-danger" style="font-weight: bold">
              <i class="fas fa-sitemap fa-rotate-180"></i>
              {{ $t("comp.assetDependencyPopover.index.parentAssets") }}
            </span>

            <a
              class="btn btn-xs"
              style="float: right; margin-right: 15px"
              v-on:click="showDependencyDiagramModal(assetId)"
              v-if="allowDependencyDiagram"
            >
              <i class="fas fa-project-diagram"></i>
              {{ $t("comp.assetDependencyPopover.index.showDiagram") }}
            </a>
          </div>
          <table class="table" style="width: 99%" :key="assetId">
            <thead>
              <tr>
                <th>
                  {{ $t("comp.assetDependencyPopover.index.classification") }}
                </th>
                <th v-if="isShowHashIds">
                  {{ $t("comp.assetDependencyPopover.index.id") }}
                </th>
                <th>{{ $t("comp.assetDependencyPopover.index.assetName") }}</th>
                <th>{{ $t("comp.assetDependencyPopover.index.type") }}</th>
                <th>{{ $t("comp.assetDependencyPopover.index.guarantor") }}</th>
                <th>{{ $t("comp.assetDependencyPopover.index.supplier") }}</th>
                <th>
                  {{ $t("comp.assetDependencyPopover.index.practitioner") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="asset in parentAssets">
                <td style="width: 10%">
                  <i
                    v-if="asset.classificationName"
                    :class="asset.classificationIcon"
                    :title="asset.classificationName"
                  >
                  </i>
                  <i class="fas fa-times" v-else></i>
                </td>
                <td
                  style="width: 10%"
                  class="text-left text-sm"
                  v-if="isShowHashIds"
                >
                  {{ asset.hashId }}
                </td>
                <td
                  style="width: 20%"
                  class="text-left text-sm"
                  v-if="asset.name && asset.name.trim() != ''"
                >
                  <a v-if="asset.link" :href="asset.link">{{ asset.name }}</a>
                  <span v-else :href="asset.link">{{ asset.name }}</span>
                </td>
                <td style="width: 20%" class="text-left text-sm" v-else>
                  <a v-if="asset.link" :href="asset.link">{{
                    $t("comp.assetDependencyPopover.index.withoutName")
                  }}</a>
                  <span v-else :href="asset.link">{{
                    $t("comp.assetDependencyPopover.index.withoutName")
                  }}</span>
                </td>
                <td style="width: 20%" class="text-left text-sm">
                  {{ asset.typeName }}
                </td>
                <td style="width: 20%" class="text-left text-sm">
                  {{ asset.guarantorName }}
                </td>
                <td style="width: 20%" class="text-left text-sm">
                  {{ asset.supplierName }}
                </td>
                <td style="width: 30%" class="text-left text-sm">
                  <span v-if="asset.practitionerName">
                    {{ asset.practitionerName }} ({{
                      asset.practitionerPercentage
                    }}%)
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AssetsDiagram from "../AssetsDiagram/index.vue";
import AssetDependencyDiagram from "../AssetDependencyDiagram/index.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AssetsDiagram,
    AssetDependencyDiagram,
  },
  data() {
    return {
      top: 0,
      left: 0,
      display: false,
      assetId: null,
      childAssets: [],
      parentAssets: [],
      loading: false,
    };
  },
  props: {
        allowDependencyDiagram: {
            type: Boolean,
            default: true,
        }
    },
  computed: {
    ...mapGetters("userSettings", {
      isShowHashIds: "isShowHashIds",
    }),
  },
  mounted() {},
  created: function () {
    this.fetchIsShowHashIds();
  },
  methods: {
    ...mapActions("userSettings", {
      fetchIsShowHashIds: "fetchIsShowHashIds",
    }),
    showDependencyDiagramModal(id) {
      this.$root.$emit("open", id);
    },

    show(id) {
      this.loading = true;
      this.assetId = id;
      this.childAssets = [];
      this.parentAssets = [];
      this.display = true;

      this.$api("asset/dependency-list", {
        params: {
          assetId: id,
        },
      }).then(({ data }) => {
        this.childAssets = data.data.childAssets;
        this.parentAssets = data.data.parentAssets;
        this.loading = false;
      });
    },
    hide() {
      this.display = false;
      this.childAssets = [];
      this.parentAssets = [];
    },
    position(x, y) {
      this.top = y;
      this.left = x - 420;
    },
    mouseMoving(e) {
      this.top = e.pageY;
      this.left = e.pageX - 420;
    },
    detail() {
      this.$emit("detail", this.assetId);
    },
  },
};
</script>

<style scoped lang="less">
#popover {
  z-index: 100;
  width: 450px;
  position: absolute;
  background: white;
  box-shadow: 0 0 5px #dddddd;
  border: 1px solid #dddddd;
  padding: 5px;
  text-align: center;
}

.values {
  padding-top: 15px;
}

a {
  display: block;
  padding: 0;
}
</style>
