<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>
        <div class="box box-primary">
            <div class="box-header">
                <h3 class="box-title">
                    <i class="fas fa-superscript"></i>
                    {{ $t("tests") }}
                </h3>
            </div>
            <div class="box-body">
                <table class="table">
                    <thead>
                    <tr>
                        <th style="width: 40%;">{{ $t("testName") }}</th>
                        <th style="width: 10%;">{{ $t("timeLimit") }}</th>
                        <th>{{ $t("result") }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(test, index) in tests">
                            <td>{{test.name}}</td>
                            <td v-if="test.timeLimit">{{test.timeLimit}} {{$t('min')}}</td>
                            <td v-if="!test.timeLimit"><i class="fas fa-infinity"></i></td>
                            <td >
                                <template v-if="test.isTestFinished">
                                    <span class="label label-success mr3" v-if="test.evaluationType === evaluationType_None">
                                        {{ $t("state.pass") }}
                                    </span>
                                    <span class="label label-success mr3" v-else-if="test.evaluationType === evaluationType_MinForSuccess && test.minForSuccess <= test.correctQuestionsCount">
                                        {{ $t("state.successful") }}
                                    </span>
                                    <span class="label label-danger mr3" v-else-if="test.evaluationType === evaluationType_MinForSuccess && test.minForSuccess > test.correctQuestionsCount">
                                        {{ $t("state.unsuccessful") }}
                                    </span>
                                    <span class="evaluation-scale" v-else-if="test.evaluationType === evaluationType_ScaleTextAndEmoji">
                                        <i :class="stepIcons[test.resultScaleEvaluationStep.step-1]" ></i> 
                                        {{test.resultScaleEvaluationStep.evaluationText}}
                                    </span>
                                    <span class="evaluation-scale" v-else-if="test.evaluationType === evaluationType_ScaleText">
                                        {{test.resultScaleEvaluationStep.evaluationText}}
                                    </span>
                                    <span class="evaluation-scale" v-else-if="test.evaluationType === evaluationType_ScaleEmoji">
                                        <i :class="stepIcons[test.resultScaleEvaluationStep.step-1]" ></i>
                                    </span>

                                    ({{test.correctQuestionsCount}} / {{test.evaluatedQuestionsCount}})
                                </template>
                                <template v-else-if="test.isTestRunning"> 
                                    <span class="label label-primary">
                                        {{ $t("state.running") }}
                                    </span>
                                </template>
                                <template v-else>
                                    <span class="label label-warning">
                                        {{ $t("state.notStart") }}
                                    </span>
                                </template>    
                            </td>
                            <td class="text-right">
                                <button class="btn btn-primary btn-xs ajax" @click="testResult(test)" v-if="test.isTestFinished">
                                    <i class="fas fa-search"></i>
                                    {{ $t("detail") }}
                                </button>
                                <button class="btn btn-success btn-xs ajax" @click="doATest(test)" v-else>
                                    <i class="fas fa-user-edit"></i>
                                    {{ $t("fill") }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <do-a-test
                v-on:testResultChanged="load"
                v-on:close="doATestModalClose"
                :test-id="showDoATestModal"
                :onlineCourseHash="onlineCourseHash"></do-a-test>

        <test-result-detail
                v-on:close="testResultModalClose"
                :test-id="showTestResultModal"
                :onlineCourseHash="onlineCourseHash"></test-result-detail>
    </div>
</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import DoATest from './DoATest/DoATest.vue';
    import TestResultDetail from '../TestResultDetail/TestResultDetail.vue';

    export default {
        components: {
            DoATest,
            TestResultDetail
        },
        data() {
            return {
                isLoading: false,
                tests: [],
                showDoATestModal: false,
                showTestResultModal: false,
                evaluationType_None: 0,
                evaluationType_MinForSuccess: 1,
                evaluationType_ScaleTextAndEmoji: 2,
                evaluationType_ScaleText: 3,
                evaluationType_ScaleEmoji: 4,
                stepIcons: [
                    "far fa-frown-open",
                    "far fa-frown",
                    "far fa-meh",
                    "far fa-smile",
                    "far fa-grin"
                ]
            }
        },
        mounted: function () {
            this.load();
        },
        props: {
            courseId: String,
            onlineCourseHash: String
        },
        methods: {
            load: function () {
                this.isLoading = true;

                this.$api.get("assigned-tests", {
                    params: {
                        courseId: this.courseId,
                        onlineCourseHash: this.onlineCourseHash
                    }
                })
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.tests = data.data;
                            this.isLoading = false;
                        } else {
                            alert(this.$t('assignedTestsListPublic.loadError'));
                        }
                    });
            },
            doATest: function ($test) {
                this.showDoATestModal = $test.id;
            },
            doATestModalClose: function () {
                this.showDoATestModal = null;
            },
            testResult: function ($test) {
                this.showTestResultModal = $test.id;
            },
            testResultModalClose: function () {
                this.showTestResultModal = null;
            },
        }
    }
</script>

<style lang="less">
    .evaluation-scale{ 
        font-weight: bold;
        margin-right: 0.6em;

        i {
            font-size: 30px;
            margin-right: 5px;
        }

        span {
            line-height: 30px;
        }
    }
</style>