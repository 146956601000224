<template>
    <base-question
            v-bind="$props"
            v-on="$listeners">
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th class="question-type-info">* {{ $t("questionYesNo") }}</th>
                <th style="width: 150px;"></th>
            </tr>
            </thead>
            <tbody>
                <tr v-bind:class="{
                success: question.correct === 'yes',
                }">
                    <td>
                        {{ $t("yes") }}
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon" style="font-size: 20px;">
                            <input
                                    disabled="disabled"
                                    type="radio"
                                    v-model="question.correctByParticipant"
                                    value="yes" />
                            <div class="state p-success" v-if="question.correct === question.correctByParticipant && question.correct === 'yes'">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                            <div class="state p-danger" v-else>
                                <i class="icon fa fa-times"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-bind:class="{
                success: question.correct === 'no',
                }">
                    <td>
                        {{ $t("no") }}
                    </td>
                    <td class="text-center">
                        <div class="pretty p-icon" style="font-size: 20px;">
                            <input
                                    disabled="disabled"
                                    type="radio"
                                    v-model="question.correctByParticipant"
                                    value="no" />
                            <div class="state p-success" v-if="question.correct === question.correctByParticipant && question.correct === 'no'">
                                <i class="icon fa fa-check"></i>
                                <label></label>
                            </div>
                            <div class="state p-danger" v-else>
                                <i class="icon fa fa-times"></i>
                                <label></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="background: none;">
                        <div class="text-sm"  v-if="question.correct !== question.correctByParticipant && question.substantiation">
                            <strong>{{ $t("substantiation") }}: </strong> <span class="text-warning">{{question.substantiation}}</span>
                        </div>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </base-question>
</template>

<script>
    import BaseQuestion from "./BaseQuestion.vue";

    export default {
        extends: BaseQuestion,
        inheritAttrs: false,
        components: {
            BaseQuestion
        }
    }
</script>

<style scoped>
    .question-type-info {
        font-weight: 400;
        font-style: italic;
    }
</style>