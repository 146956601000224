<template>
    <div class="modal fade" ref="modal">
        <div class="modal-dialog modal-lg" v-if="testId">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{test.name}}
                    </h4>
                </div>
                <div class="modal-body">
                    <template v-if="view === 'welcome'">
                        <div class="callout callout-danger" v-if="test.ended">
                            {{ $t("testCanNoLongerAbsolved") }}
                        </div>

                        <div v-html="test.description" style="max-height: 300px; overflow: auto;"></div>

                        <p style="margin-top: 20px;">
                            <strong>{{ $t("questionsCount") }}: </strong> {{test.questionsCount}} <br />
                            <strong>{{ $t("timeLimit") }}: </strong>
                            <template v-if="test.timeLimit">{{test.timeLimit}} {{$t('min')}}</template>
                            <template v-else>{{ $t("none") }}</template> <br />

                            <strong>{{ $t("minimumCorrectQuestions") }}: </strong>
                            <template v-if="test.evaluationType == evaluationType_MinForSuccess">
                                {{test.minForSuccess}}
                            </template>
                            <template v-else-if="test.evaluationType == evaluationType_ScaleTextAndEmoji || test.evaluationType == evaluationType_ScaleText || test.evaluationType == evaluationType_ScaleEmoji">
                                <em>{{ $t("minScaleEvaluation") }}</em>
                            </template>
                            <template v-else>
                                <em>{{ $t("none") }}</em>
                            </template>
                        </p>

                        <div class="text-center">
                            <button class="btn btn-success" @click="startTest" v-if="!test.ended">
                                {{ $t("beginTest") }}
                            </button>
                            <button class="btn btn-default"
                                    @click="close">
                                <i class="fas fa-times"></i>
                                {{ $t("close") }}
                            </button>
                        </div>
                    </template>
                    <template v-if="view === 'test'">
                        <div class="pull-left">
                            <strong>{{ $t("questions") }}:</strong>
                            <ul class="numbering">
                                <li v-for="(question, index) in questions">
                                    <button :key="index"
                                            type="button"
                                            @click="actualQuestionIndex = index"
                                            :class="{
                                                answered: isAnswered(index),
                                                active: index === actualQuestionIndex,
                                            }"
                                            :title="question.value">
                                        {{index+1}}
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div class="pull-right" v-if="timing.end">
                            <span class="text-sm">{{ $t("remainTime") }}</span>
                            <span class="label label-danger ml2">
                                {{timing.remain}}
                            </span>
                        </div>

                        <div class="clearfix mb3"></div>

                        <question-choices
                                v-if="questions[actualQuestionIndex].type === 'choices'"
                                :question="questions[actualQuestionIndex]"
                                :questionIndex = "actualQuestionIndex"
                                :key= "index"
                                v-on:change="answerChanged"
                        ></question-choices>
                        <question-fulltext
                                v-if="questions[actualQuestionIndex].type === 'fulltext'"
                                :question="questions[actualQuestionIndex]"
                                :questionIndex = "actualQuestionIndex"
                                :key= "index"
                                v-on:change="answerChanged"
                        ></question-fulltext>
                        <question-yes-no
                                v-if="questions[actualQuestionIndex].type === 'yesNo'"
                                :question="questions[actualQuestionIndex]"
                                :questionIndex = "actualQuestionIndex"
                                :key= "index"
                                v-on:change="answerChanged"
                        ></question-yes-no>
                    </template>
                    <template v-if="view === 'finish'">
                        <div class="text-center text-success">
                            <i class="far fa-check-circle fa-5x"></i>
                            <p style="font-size: 30px;">{{ $t("testFinished") }}</p>

                            <button class="btn btn-default btn-lg mt2" @click="close">
                                {{ $t("close") }}
                            </button>
                        </div>
                    </template>
                </div>
                <div class="modal-footer text-center" v-if="view === 'test'">
                    <div class="text-center">
                        <button class="btn btn-primary pull-left"
                                @click="actualQuestionIndex-=1"
                                v-if="actualQuestionIndex > 0">
                            <i class="fas fa-long-arrow-alt-left"></i>
                        </button>

                        <span class="text-sm">{{actualQuestionIndex+1}} / {{questions.length}}</span>

                        <button class="btn btn-primary pull-right"
                                @click="actualQuestionIndex+=1"
                                v-if="actualQuestionIndex < questions.length-1">
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </button>
                        <button class="btn btn-success pull-right"
                                @click="finishTest"
                                v-if="actualQuestionIndex === questions.length-1">
                            <i class="fas fa-check-double"></i>
                            {{ $t("finishTest") }}
                        </button>
                    </div>
                </div>
                <loading :active.sync="isLoading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import QuestionChoices from './inputs/QuestionChoices.vue';
    import QuestionFulltext from './inputs/QuestionFulltext.vue';
    import QuestionYesNo from './inputs/QuestionYesNo.vue';

    export default {
        components: {
            Loading,
            QuestionChoices,
            QuestionFulltext,
            QuestionYesNo,
        },
        data() {
            return {
                isLoading: false,
                test: {},
                questions: {},
                view: 'welcome',
                actualQuestionIndex: 0,
                timing: {
                    start: null,
                    end: null,
                    remain: null,
                },
                timerJob: null,
                modal: null,
                evaluationType_None: 0,
                evaluationType_MinForSuccess: 1,
                evaluationType_ScaleTextAndEmoji: 2,
                evaluationType_ScaleText: 3,
                evaluationType_ScaleEmoji: 4,
            }
        },
        props: {
            testId: String,
            onlineCourseHash: String,
        },
        mounted() {
            this.modal = this.$refs.modal;

            $(this.modal)
                .modal({
                    keyboard: false,
                    show: false,
                    backdrop: false,
                })
                .on("hidden.bs.modal", this.close);
        },
        watch: {
            testId: function (v) {
                if(v) {
                    this.load();
                    $(this.modal).modal('show');
                } else {
                    $(this.modal).modal('hide');
                }
            }
        },
        methods: {
            load: function () {
                this.isLoading = true;

                this.$api.get("assigned-tests/" + this.testId, {
                    params: {
                        onlineCourseHash: this.onlineCourseHash
                    }
                })
                    .then(({data}) => {
                        if(data.status === "ok") {
                            this.test = data.data;
                            this.isLoading = false;
                        } else {
                            alert(this.$t('doATest.loadError'));
                        }
                    });
            },
            startTest: function () {
                this.isLoading = true;

                this.$api.get("assigned-tests/"+ this.testId +"/questions", {
                    params: {
                        onlineCourseHash: this.onlineCourseHash
                    }
                })
                    .then(({data}) => {
                        if(data.status !== 'ok') {
                            alert(this.$t('doATest.startTestError'));
                            return''
                        }


                        this.questions = data.data.questions;
                        this.timing.start = data.data.startTime;
                        this.timing.end = data.data.endTime;

                        if(this.timing.end) {
                            this.startCountDown();
                        }

                        this.isLoading = false;
                        this.view = 'test';

                        this.$emit("testResultChanged");
                    });
            },
            finishTest: function () {
                clearInterval(this.timerJob);

                this.isLoading = true;

                let answers = [];

                this.questions.forEach((question) => {
                    answers.push({
                        questionId: question.id,
                        value: this.getAnswer(question)
                    });
                });

                this.$api.post("assigned-tests/"+ this.testId +"/finish", {
                    answers: answers
                }, {
                    params: {
                        onlineCourseHash: this.onlineCourseHash
                    }
                })
                    .then(({data}) => {
                        if(data.status !== 'ok' && data.code !== 'test_ended') {
                            this.isLoading = false;
                            alert(data.message);
                        }

                        this.view = 'finish';
                        this.isLoading = false;

                        this.$emit("testResultChanged");
                    });
            },
            saveAnswer: function (question) {
                this.$api.post("assigned-tests/"+ this.testId +"/saveAnswer", {
                    questionId: question.id,
                    value: this.getAnswer(question)
                }, {
                    params: {
                        onlineCourseHash: this.onlineCourseHash,
                        testId: this.testId
                    }
                })
                    .then(({data}) => {
                        if(data.status !== 'ok') {
                            alert(data.message);
                        }
                    });
            },
            getAnswer: function(question) {
                var answer = null;

                if(question.type === 'fulltext' || question.type === 'yesNo') {
                    answer = question.correct;
                }
                else if(question.type === 'choices') {
                    answer = [];

                    question.answers.forEach((a) => {
                        if(a.correct) {
                            answer.push(a.id);
                        }
                    });
                }

                return answer;
            },
            answerChanged: function (question) {
                this.saveAnswer(question);
            },
            isAnswered: function (index) {
                let q = this.questions[index];
                let ret = false;

                if(q.type === 'choices') {
                    q.answers.forEach((a) => {
                        if(a.correct) {
                            ret = true;
                        }
                    });
                }
                else if(q.type === 'fulltext') {
                    ret = q.correct && q.correct.length;
                }
                else if(q.type === 'yesNo') {
                    ret = q.correct;
                }

                return ret;
            },
            startCountDown: function () {
                let self = this;

                this.updateTime();

                this.timerJob = setInterval(function() {
                    self.updateTime();
                }, 1000);
            },
            updateTime: function () {
                let seconds = moment(this.timing.end).diff(moment());

                if(seconds < 0) {
                    clearInterval(this.timerJob);
                    this.finishTest();
                    return;
                }

                let time = moment(seconds);
                this.timing.remain = time.minutes() + " min " + time.seconds() + " s";
            },
            close: function () {
                this.actualQuestionIndex = 0;
                this.view = 'welcome';
                this.$emit("close");
            }
        }
    }
</script>

<style lang="less">
    .numbering {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            margin: 2px;

            button {
                border: 0;
                padding: 0 8px 0 8px;
                background: #8c8c8c;
                font-size: 18px;
                width: 40px;
            }

            button.answered {
                background: #C6E746;
            }

            button.active {
                color: white;
            }
        }
    }

    .modal-footer {
        line-height: 30px;
    }
</style>