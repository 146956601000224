<template>
    <div class="control">
        <label>{{$t('comp.assetModal.controls.administrator.label')}}</label>
        <multiselect
            v-model="selected"
            :options="options"
            group-values="people"
            group-label="org_path"
            :group-select="false"
            label="name"
            track-by="id"
            :multiple="false">
        </multiselect>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Multiselect from '../../../Controls/CsaMultiSelect.vue'

    export default {
        name: "asset-administrator-control",
        components: {
          Multiselect
        },
        data() {
            return {
                selected: null
            };
        },
        props: {
            value: null
        },
        watch: {
            selected: function (v) {
                this.$emit('input', v);
            },
            value: function (v) {
                this.selected = v;
            }
        },
        created() {
            this.selected = this.value
        },
        computed: {
            ...mapGetters("administrator", {
                options: "getAdministrators",
                getById: 'getById'
            })
        },
        methods: {
            ...mapActions("administrator", {
                fetchAdministrators: 'fetchAdministrators'
            })
        }
    }
</script>