<style>
.pretty.p-icon .state .icon {
    top: 0px !important;
}
</style>

<template>
    <div>
        <div class="row">
            <div class="col-lg-3">
                <div class="box box-primary">
                    <div class="box-header with-border">
                        <h3 class="box-title">
                            {{ $t("comp.kbAudit.kbAudit.legend") }}
                        </h3>
                    </div>
                    <!-- /.box-header -->
                    <div class="box-body">
                        <table class="table" id="legend">
                            <tbody>
                                <tr class="text-success">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.implemented'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-check-circle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.implemented"
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="text-primary">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.inImplementation'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-minus-circle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.inImplementation"
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="text-warning">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.notApplicable'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-times-circle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.notApplicable"
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="text-danger">
                                    <td
                                        :title="
                                            $t(
                                                'comp.kbAudit.answerType.notImplemented'
                                            )
                                        "
                                    >
                                        <i
                                            class="fa fa-exclamation-triangle fa-2x"
                                            aria-hidden="true"
                                        ></i>
                                    </td>
                                    <td class="text-bold">
                                        {{
                                            $t(
                                                "comp.kbAudit.answerType.notImplemented"
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.box-body -->
                </div>
                <!-- /.box -->
            </div>
            <div class="col-lg-7">
                <div class="box box-primary">
                    <div class="box-header with-border">
                        <h3 class="box-title" style="display:block;">
                            {{ $t("comp.kbAudit.kbAudit.filter.label") }}

                            <span
                                style="font-size: 10pt; vertical-align: middle; float: right; text-align: right"
                                v-if="countryLaw == 'CZ'"
                            >
                                <div class="label label-warning">
                                    {{
                                        $t(
                                            "comp.kbAudit.kbAudit.filter.required"
                                        )
                                    }}
                                </div>
                                <div class="label label-info">
                                    {{
                                        $t(
                                            "comp.kbAudit.kbAudit.filter.recommendation"
                                        )
                                    }}
                                </div>
                                <div class="label label-default">
                                    {{
                                        $t(
                                            "comp.kbAudit.kbAudit.filter.optional"
                                        )
                                    }}
                                </div>
                            </span>

                            <span
                                style="font-size: 10pt; vertical-align: middle; float: right; text-align: right"
                                v-if="countryLaw == 'SK'"
                            >
                                <div class="label label-default">
                                    {{
                                        $t(
                                            "comp.kbAudit.kbAudit.filter.relevant"
                                        )
                                    }}
                                </div>
                                <div class="label label-warning">
                                    {{
                                        $t(
                                            "comp.kbAudit.kbAudit.filter.selected"
                                        )
                                    }}
                                </div>
                            </span>
                        </h3>
                    </div>
                    <!-- /.box-header -->
                    <div class="box-body" v-if="countryLaw == 'CZ'">
                        <div class="flex">
                            <div
                                class="pretty p-icon p-smooth"
                                style="white-space:normal"
                            >
                                <input
                                    type="checkbox"
                                    v-model="settings.relevantFor.kii"
                                />
                                <div class="state p-success">
                                    <i class="icon fa fa-check"></i>
                                    <label
                                        style="padding-left:10px;line-height: normal"
                                        v-html="
                                            $t(
                                                'comp.kbAudit.kbAudit.filter.kii'
                                            )
                                        "
                                    ></label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="flex">
                            <div>
                                <div
                                    class="pretty p-icon p-smooth"
                                    style="white-space:normal"
                                >
                                    <input
                                        type="checkbox"
                                        v-model="settings.relevantFor.vis"
                                    />
                                    <div class="state p-success">
                                        <i class="icon fa fa-check"></i>
                                        <label
                                            style="padding-left:10px;line-height: normal"
                                            v-html="
                                                $t(
                                                    'comp.kbAudit.kbAudit.filter.vis'
                                                )
                                            "
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="flex">
                            <div>
                                <div
                                    class="pretty p-icon p-smooth"
                                    style="white-space:normal"
                                >
                                    <input
                                        type="checkbox"
                                        v-model="settings.relevantFor.dsp"
                                    />
                                    <div class="state p-success">
                                        <i class="icon fa fa-check"></i>
                                        <label
                                            style="padding-left:10px;line-height: normal"
                                            v-html="
                                                $t(
                                                    'comp.kbAudit.kbAudit.filter.dsp'
                                                )
                                            "
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-body" v-if="countryLaw == 'SK'">
                        <div class="flex">
                            <div>
                                <div
                                    class="pretty p-icon p-smooth"
                                    style="white-space:normal"
                                >
                                    <input
                                        type="checkbox"
                                        v-model="settings.relevantFor.zokb"
                                    />
                                    <div class="state p-success">
                                        <i class="icon fa fa-check"></i>
                                        <label
                                            style="padding-left:10px;line-height: normal"
                                            v-html="
                                                $t(
                                                    'comp.kbAudit.kbAudit.filter.zokb'
                                                )
                                            "
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="flex">
                            <span style="margin-right: 10px;"
                                >{{
                                    $t("comp.kbAudit.kbAudit.filter.category")
                                }}
                            </span>
                            <label class="radio-inline">
                                <input
                                    type="radio"
                                    name="inlineRadioOptionsZokb"
                                    :value="1"
                                    v-model="settings.relevantFor.categoryZokb"
                                />
                                <abbr>1</abbr>
                            </label>
                            <label class="radio-inline">
                                <input
                                    type="radio"
                                    name="inlineRadioOptionsZokb"
                                    :value="2"
                                    v-model="settings.relevantFor.categoryZokb"
                                />
                                <abbr>2</abbr>
                            </label>
                            <label class="radio-inline">
                                <input
                                    type="radio"
                                    name="inlineRadioOptionsZokb"
                                    :value="3"
                                    v-model="settings.relevantFor.categoryZokb"
                                />
                                <abbr>3</abbr>
                            </label>
                        </div>
                        <br />
                        <div class="flex">
                            <div>
                                <div class="pretty p-icon p-smooth">
                                    <input
                                        type="checkbox"
                                        v-model="settings.relevantFor.itvs"
                                    />
                                    <div class="state p-success">
                                        <i class="icon fa fa-check"></i>
                                        <label
                                            v-html="
                                                $t(
                                                    'comp.kbAudit.kbAudit.filter.itvs'
                                                )
                                            "
                                        ></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="flex">
                            <span style="margin-right: 10px;"
                                >{{
                                    $t("comp.kbAudit.kbAudit.filter.category")
                                }}
                            </span>
                            <label class="radio-inline">
                                <input
                                    type="radio"
                                    name="inlineRadioOptionsItvs"
                                    :value="1"
                                    v-model="settings.relevantFor.categoryItvs"
                                />
                                <abbr>1</abbr>
                            </label>
                            <label class="radio-inline">
                                <input
                                    type="radio"
                                    name="inlineRadioOptionsItvs"
                                    :value="2"
                                    v-model="settings.relevantFor.categoryItvs"
                                />
                                <abbr>2</abbr>
                            </label>
                            <label class="radio-inline">
                                <input
                                    type="radio"
                                    name="inlineRadioOptionsItvs"
                                    :value="3"
                                    v-model="settings.relevantFor.categoryItvs"
                                />
                                <abbr>3</abbr>
                            </label>
                        </div>
                    </div>
                    <!-- /.box-body -->
                </div>
                <!-- /.box -->
            </div>

            <div class="col-lg-2 text-right">
                <button class="btn btn-primary" @click="exportModal" style="margin-bottom: 5px;">
                    <i class="far fa-file-excel"></i>
                    {{ $t("comp.kbAudit.kbAudit.export") }}
                </button>
                <button class="btn btn-primary"
                    :disabled="selectedAnswers.length === 0"
                    @click="showModal = true" style="margin-bottom: 5px;">
                    <i class="fas fa-plus"></i>
                    {{ $t("comp.kbAudit.kbAudit.addMassMeasures") }} ({{ selectedAnswers.length }})
                </button>
            </div>
        </div>

        <div v-for="(item, index) in audit" :key="item.uuid">
            <kb-section
                :item="item"
                :number="index + 1"
                :deep="0"
                :countryLaw="countryLaw"
                ref="sections"
                @visibilityChanged="visibilityChanged"
                @answerChanged="answerChanged"
            />
        </div>

        <overall-chart :data="overallData" v-if="!isLoading"></overall-chart>

        <loading :active.sync="isLoading" :is-full-page="false"></loading>

        <modal-export></modal-export>

        <edit-question
            :showModal="showModal"
            v-if="showModal"
            @hide="showModal = false"
            :answers="selectedAnswers"
        ></edit-question>
    </div>
</template>

<script>
import KbSection from "./KbSection.vue";
import ModalExport from "./ModalExport.vue";
import { EventBus } from "./event-bus.js";
import { mapActions, mapGetters } from "vuex";
import OverallChart from "./OverallChart.vue";
import EditQuestion from "./EditQuestion.vue";

export default {
    components: {
        KbSection,
        ModalExport,
        OverallChart,
        EditQuestion
    },
    data() {
        return {
            isLoading: true,
            overallData: null,
            selectedAnswers: [],
            showModal: false,
        };
    },
    watch: {},
    mounted() {
        EventBus.$on('checkboxChanged', this.handleCheckboxChange);
        EventBus.$on('clearSelectedAnswers', this.handleClearSelectedAnswers);
    },
    beforeMount: async function() {
        await this.fetchAnswersZoKB();
        await this.fetchMeasures();
        await this.fetchTags();
        await this.fetchSetup().then(() => {
            if (this.countryLaw == "CZ") {
                this.$watch("settings.relevantFor.kii", function(v) {
                    if (
                        !v &&
                        !this.settings.relevantFor.vis &&
                        !this.settings.relevantFor.dsp
                    ) {
                        this.settings.relevantFor.kii = true;
                        return;
                    }

                    this.saveSettings();
                });
                this.$watch("settings.relevantFor.vis", function(v) {
                    if (
                        !v &&
                        !this.settings.relevantFor.kii &&
                        !this.settings.relevantFor.dsp
                    ) {
                        this.settings.relevantFor.kii = true;
                        return;
                    }

                    this.saveSettings();
                });
                this.$watch("settings.relevantFor.dsp", function(v) {
                    if (
                        !v &&
                        !this.settings.relevantFor.vis &&
                        !this.settings.relevantFor.kii
                    ) {
                        this.settings.relevantFor.kii = true;
                        return;
                    }

                    this.saveSettings();
                });
            } else if (this.countryLaw == "SK") {
                this.$watch("settings.relevantFor.zokb", function(v) {
                    if (
                        !v &&
                        !this.settings.relevantFor.zokb &&
                        !this.settings.relevantFor.itvs
                    ) {
                        this.settings.relevantFor.zokb = true;
                        return;
                    }

                    this.saveSettings();
                });
                this.$watch("settings.relevantFor.itvs", function(v) {
                    if (
                        !v &&
                        !this.settings.relevantFor.zokb &&
                        !this.settings.relevantFor.itvs
                    ) {
                        this.settings.relevantFor.zokb = true;
                        return;
                    }

                    this.saveSettings();
                });
                this.$watch("settings.relevantFor.categoryZokb", function(v) {
                    this.saveSettings();
                });
                this.$watch("settings.relevantFor.categoryItvs", function(v) {
                    this.saveSettings();
                });
            }
        });
        await this.fetchAuditZoKB().then(() => {
            this.isLoading = false;
            this.buildOverallData();
        });

        this.fetchMeasuresAssetsWithRisks();    // Does not need to wait for result
    },
    computed: {
        ...mapGetters("auditKB", {
            audit: "getAuditZoKB",
            answers: "getAnswersZoKB",
            countryLaw: "getCountryLaw",
            settings: "getSettings"
        })
    },
    methods: {
        ...mapActions("measures", {
            fetchMeasures: "fetchMeasures",
            fetchMeasuresAssetsWithRisks: "fetchMeasuresAssetsWithRisks",
        }),
        ...mapActions("measureTags", {
            fetchTags: "fetchTags"
        }),
        ...mapActions("auditKB", [
            "fetchAuditZoKB",
            "fetchAnswersZoKB",
            "fetchSetup",
            "saveSettings"
        ]),
        getSectionNumber() {
            return null;
        },
        getChoicesCount(choice) {
            let count = 0;

            if (this.$refs["sections"]) {
                this.$refs["sections"].forEach(s => {
                    count += s.getChoicesCount(choice);
                });
            }

            return count;
        },
        getQuestionsCount() {
            let count = 0;

            if (this.$refs["sections"]) {
                this.$refs["sections"].forEach(s => {
                    count += s.getQuestionsCount();
                });
            }

            return count;
        },
        buildOverallData() {
            this.overallData = {
                count: this.getQuestionsCount(),
                choices: {
                    implemented: this.getChoicesCount("implemented"),
                    in_implementation: this.getChoicesCount(
                        "in_implementation"
                    ),
                    not_applicable: this.getChoicesCount("not_applicable"),
                    not_implemented: this.getChoicesCount("not_implemented"),
                    not_answered: this.getChoicesCount(null)
                }
            };
        },
        answerChanged() {
            this.buildOverallData();
        },
        visibilityChanged(count) {
            this.buildOverallData();
        },
        exportModal() {
            EventBus.$emit("show-export-modal", true);
        },
        handleCheckboxChange(answer) {
            if (answer.selected) {
                this.selectedAnswers.push(answer);
            } else {
                const index = this.selectedAnswers.findIndex(item => item.questionId === answer.questionId);
                if (index > -1) {
                    this.selectedAnswers.splice(index, 1);
                }
            }
        },
        handleClearSelectedAnswers() {
            this.selectedAnswers = [];
        },

    }
};
</script>

<style>
.pretty .state label:before {
    top: 0 !important;
}
.pretty .state label:after {
    top: 0 !important;
}
#legend td {
    padding: 2px 0 2px 0;
}

</style>
