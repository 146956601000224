<template>
    <keep-alive>
        <multiselect v-bind="$props" v-on="$listeners"
                     :placeholder="($props.placeholder && $props.placeholder !== 'Select option') ? $props.placeholder : $t('controls.csaMultiSelect.selectPlaceholder')"
                     :selectLabel="$t('controls.csaMultiSelect.selectLabel')"
                     :tagPlaceholder="$t('controls.csaMultiSelect.tagPlaceholder')"
                     :selectedLabel="$t('controls.csaMultiSelect.selectedLabel')"
                     :deselectLabel="$t('controls.csaMultiSelect.deselectPlaceholder')">


            <template slot="placeholder" slot-scope="props">
                <slot name="placeholder" v-bind="props"></slot>
            </template>

            <span slot="noOptions">
                {{$t('controls.csaMultiSelect.noOptions')}}
            </span>

            <span slot="noResult">
                {{$t('controls.csaMultiSelect.noResult')}}
            </span>

            <template slot="option" slot-scope="props">
                <slot name="option" v-bind="props"></slot>
            </template>

            <template slot="singleLabel" slot-scope="props">
                <slot name="singleLabel" v-bind="props"></slot>
            </template>
        </multiselect>
    </keep-alive>
</template>

<script>
    import Multiselect from 'vue-multiselect/src/Multiselect.vue'

    export default {
        extends: Multiselect,
        components: {
            Multiselect
        }
    }
</script>

<style scoped lang="less">
    .multiselect {
        /deep/ .multiselect__tags {
            border: 1px solid #ccc;
            border-radius: 0;
        }

        /deep/ .multiselect__option--highlight:after {
            background: none;
        }

        /deep/ .multiselect__option--highlight {
            background: #f5000b;
        }

        /deep/ .multiselect__tag {
            background: #f5000b;
            white-space: normal;
        }

        /deep/ .multiselect__tag-icon {
            background: #f5000b;
            color: #000;
        }

        /deep/ .multiselect__tag-icon:focus,
        /deep/ .multiselect__tag-icon:hover {
            background: #cb000b;
        }

        /deep/ .multiselect__tag-icon:after {
            color: #000;
        }

        /deep/ .multiselect--above {
            position: absolute;
        }

        width: 100%;
        text-wrap: normal;

        /deep/ .multiselect__option {
            white-space: normal;
        }

        /deep/ .multiselect__placeholder {
            display: inline-block;
            margin-bottom: 0;
            padding-top: 0;
        }
    }
</style>