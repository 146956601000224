<template>
    <div class="modal fade" role="dialog" ref="modal">
        <div class="modal-dialog modal-lg vld-parent" role="document" v-if="risk">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{$t('comp.riskAcceptance.riskAcceptance.risk')}} <strong><span v-if="isShowHashIds">[{{risk.hashId}}]</span></strong> {{$t('comp.editRisk.editRisk.onAsset')}} <strong><span v-if="isShowHashIds">[{{risk.asset.hashId}}]</span> {{risk.asset.name}}</strong>
                    </h4>
                </div>

                <div class="modal-body no-padding vld-parent">
                    <div class="text-left" style="padding: 10px 15px;">
                        <p v-if="getThreat(risk.threatId)">
                            <strong>{{$t('comp.riskAcceptance.riskAcceptance.threat')}}:</strong>
                            <span v-if="isShowHashIds"> [{{getThreat(risk.threatId).hashId}}]</span>
                            {{getThreat(risk.threatId).name}}
                            <i class="fas fa-info-circle description-icon" v-if="getThreat(risk.threatId).description" :title="getThreat(risk.threatId).description"></i>
                        </p>
                        <p v-if="getVulnerability(risk.vulnerabilityId)">
                            <strong>{{$t('comp.riskAcceptance.riskAcceptance.vulnerability')}}:</strong>
                            <span v-if="isShowHashIds"> [{{getVulnerability(risk.vulnerabilityId).hashId}}]</span>
                            {{getVulnerability(risk.vulnerabilityId).name}}
                            <i class="fas fa-info-circle description-icon" v-if="getVulnerability(risk.vulnerabilityId).description" :title="getVulnerability(risk.vulnerabilityId).description"></i>
                        </p>
                    </div>

                    <table class="table" style="margin: auto; width: 500px;">
                        <tbody>
                            <tr style="font-size: 13pt;">
                                <td style="border-top: 0px;">
                                    {{$t('comp.riskAcceptance.riskAcceptance.risk')}}
                                </td>
                                <td style="border-top: 0px;">
                                    <span v-if="risk.metadata.defaultRiskValue" class="label text-white" style="min-width: 120px; line-height: 20px; display: block;" :style="{background: risk.metadata.defaultRiskValue.color}">
                                        {{risk.metadata.defaultRiskValue.class}} / {{risk.metadata.defaultRiskValue.value}}
                                    </span>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                            </tr>
                            <tr style="font-size: 13pt;">
                                <td>
                                    {{$t('comp.riskAcceptance.riskAcceptance.requestedRisk')}}
                                </td>
                                <td>
                                    <span v-if="risk.metadata.fullReducedRiskValue" class="label text-white" style="min-width: 120px; line-height: 20px; display: block;" :style="{background: risk.metadata.fullReducedRiskValue.color}">
                                        {{risk.metadata.fullReducedRiskValue.class}} / {{risk.metadata.fullReducedRiskValue.value}}
                                    </span>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                            </tr>
                            <tr style="font-size: 17pt;">
                                <td style="border-top: 1px dotted grey" class="text-red nowrap">
                                    {{$t('comp.riskAcceptance.riskAcceptance.resultingRisk')}}
                                </td>
                                <td style="border-top: 1px dotted grey">
                                    <span v-if="risk.metadata.riskValue" class="label text-white" style="min-width: 120px; line-height: 25px; display: block;" :style="{background: risk.metadata.riskValue.color}">
                                        {{risk.metadata.riskValue.class}} / {{risk.metadata.riskValue.value}}
                                    </span>
                                    <template v-else>
                                        -
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <template v-if="risk">
                        <div class="col-md-12 mt3 text-center">
                            <button class="btn bg-olive"
                                    @click="risk.accepted = false"
                                    :disabled="isLoading || acceptationDisabled"
                                    v-if="risk.accepted"
                                    style="width: 200px">
                                <i class="far fa-check-square fa-2x pull-left"></i>
                                <strong style="line-height: 20pt">{{$t('comp.riskAcceptance.riskAcceptance.accepted')}}</strong>
                            </button>
                            <button class="btn btn-warning"
                                    @click="risk.accepted = true"
                                    :disabled="isLoading || acceptationDisabled"
                                    v-if="!risk.accepted"
                                    style="width: 200px">
                                <i class="far fa-square fa-2x pull-left"></i>
                                <strong style="line-height: 20pt">{{$t('comp.riskAcceptance.riskAcceptance.notAccepted')}}</strong>
                            </button>
                        </div>

                        <div class="col-md-12 mt3">
                            <label v-if="risk.accepted">{{$t('comp.riskAcceptance.riskAcceptance.justificationForAcceptance')}}</label>
                            <label v-else>{{$t('comp.riskAcceptance.riskAcceptance.justificationForNotAcceptance')}}</label>

                            <ckeditor :editor="editor" v-model="acceptanceDescription" :config="editorConfig"></ckeditor>
                        </div>
                    </template>

                    <div class="clearfix"></div>

                    <loading :active="isLoading" :is-full-page="false"></loading>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-default pull-left" @click="close" :disabled="isLoading">{{$t('common.cancel')}}</button>
                    <button class="btn btn-success pull-right" @click="save">
                        <i class="fas fa-save"></i>
                        {{$t('common.save')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import store from 'store';
    import {mapGetters, mapActions} from 'vuex';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    export default {
        name: "risk-acceptance",
        components: {
            ckeditor: CKEditor.component
        },
        store,
        data() {
            return {
                subId: null,
                risk: null,
                threshold: null,
                acceptanceDescription: null,
                isLoading: false,
                modal: null,
                showModal: false,
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'bulletedList',
                            'numberedList',
                        ]
                    }
                }
            }
        },
        async created () {
            await this.fetchIsShowHashIds();
            await this.fetchVulnerabilities();
            await this.fetchThreats();
        },
        computed: {
            acceptationDisabled: function () {
                if(this.risk.metadata.riskValue.value === null) {
                    return false;
                }

                return this.threshold >= this.risk.metadata.riskValue.value;
            },
            ...mapGetters('userSettings', {
                isShowHashIds: 'isShowHashIds',
            }),
            ...mapGetters("threats", {
                getThreat: 'getById'
            }),
            ...mapGetters("vulnerabilities", {
                getVulnerability: 'getById'
            })
        },
        mounted: function () {
            this.modal = this.$refs.modal;
        },
        watch: {
            showModal: function(showModal) {
                if(showModal) {
                    this.show();
                } else {
                    this.close();
                }
            }
        },
        methods: {
            ...mapActions('userSettings', {
                    fetchIsShowHashIds: 'fetchIsShowHashIds'
            }),
            ...mapActions("threats", ["fetchThreats"]),
            ...mapActions("vulnerabilities", ["fetchVulnerabilities"]),
            close: function () {
                $(this.modal).modal("hide");
                this.subId = null;
                this.showModal = false;
                this.acceptanceDescription = "";
            },
            show: function () {
                $(this.modal).modal({
                    backdrop: false,
                    keyboard: false,
                });

                this.isLoading = true;
                this.fetchRisk().then(()=>{
                    this.isLoading = false;
                });
            },
            save: function () {
                this.isLoading = true;

                let data = {
                    subId: this.risk.subId,
                    acceptanceDescription: this.acceptanceDescription,
                };

                if(!this.acceptationDisabled) {
                    data['accepted'] = this.risk.accepted;
                }

                this.$api.put('risk/' + this.subId + '/acceptance', data)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.$emit('reload');
                            this.close();
                        }
                    });

                this.$emit('reload');
                this.close();
            },
            fetchRisk: async function () {
                await this.$api.get('risk/' + this.subId)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.risk = data.data;
                            if(this.risk.acceptanceDescription){
                                this.acceptanceDescription = this.risk.acceptanceDescription;
                            }
                        }
                    });

                await this.$api.get('config/risk-auto-accept-threshold')
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.threshold = data.data.threshold;
                        }
                    });
            },
            open: function (subId) {
                this.subId = subId;
                this.showModal = true;
            }
        },
    }
</script>

<style scoped>
    .modal-body {
        min-height: 300px;
    }
</style>
