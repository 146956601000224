<template>
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th colspan="4">
                    <csa-select
                        v-model="selected_asset"
                        :options="notSelectedAssets"
                        trackBy="id"
                        label="name">
                    </csa-select>
                </th>
                <th style="width: 30px" class="text-right">
                    <button class="btn btn-success" type="button" @click="addAsset" :disabled="!selected_asset">
                        <i class="fas fa-plus"></i> {{$t('common.add')}}
                    </button>
                </th>
            </tr>
            <tr>
                <th>{{$t('comp.continuityPlanModal.tabs.assets.asset')}}</th>
                <th style="width: 15%">{{$t('comp.continuityPlanModal.tabs.assets.type')}}</th>
                <th style="width: 15%">{{$t('comp.continuityPlanModal.tabs.assets.guarantor')}}</th>
                <th style="width: 15%">{{$t('comp.continuityPlanModal.tabs.assets.location')}}</th>
                <th style="width: 30px"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="asset in selectedAssets" :key="asset">
                <td>
                    <span style="width: 25px; display: inline-block;">
                        <i v-if="asset.classification" :class="asset.classification.icon" :title="asset.classification.name"></i>
                    </span>
                    {{asset.name}}
                </td>
                <td><span v-if="asset.type">{{asset.type.name}}</span></td>
                <td><span v-if="asset.guarantor">{{asset.guarantor.name}}</span></td>
                <td><span v-if="asset.location">{{asset.location.name}}</span></td>
                <td class="text-right">
                    <button class="btn btn-default btn-xs"
                            type="button"
                            @click="toggleAssetDependencyPopover($event, asset.id)">
                        <i class="fas fa-project-diagram"></i>
                    </button>
                    <button class="btn btn-danger btn-xs" type="button" @click="removeAsset(asset)">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        </tbody>

        <asset-dependency-popover ref="dependencyPopover" :allowDependencyDiagram="false"></asset-dependency-popover>
    </table>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import '@ckeditor/ckeditor5-build-classic/build/translations/cs';
    import CsaSelect from 'Controls/CsaMultiSelect.vue'
    import AssetDependencyPopover from '../../AssetDependencyPopover/index.vue'

    export default {
        components: {
            ckeditor: CKEditor.component,
            CsaSelect,
            AssetDependencyPopover
        },
        props: ['plan', 'assets'],
        computed: {
            selectedAssets() {
                return this.assets.filter((asset) => {
                    return this.plan.assets.includes(asset.id);
                })
            },
            notSelectedAssets() {
                return this.assets.filter((asset) => {
                    return !this.plan.assets.includes(asset.id);
                })
            },
        },
        data() {
            return {
                selected_asset: null,
                isShownAssetDependencyPopover: false
            }
        },
        methods: {
            addAsset() {
                this.plan.assets.push(this.selected_asset.id)
                this.selected_asset = null
            },
            removeAsset(asset) {
                this.plan.assets.splice(this.plan.assets.indexOf(asset.id), 1)
            },
            toggleAssetDependencyPopover($event, assetId) {
                let dependencyPopover = this.$refs.dependencyPopover;

                if(this.isShownAssetDependencyPopover){
                    dependencyPopover.hide();
                }
                else {
                    dependencyPopover.show(assetId);
                    dependencyPopover.position($event.pageX - 200, $event.pageY);
                }
                this.isShownAssetDependencyPopover = !this.isShownAssetDependencyPopover;
            },
        }
    }
</script>
