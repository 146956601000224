<template>
    <div>
        <div class="box box-danger">
            <div class="box-header">
                <h3 class="box-title">{{ $t('basicTestEvaluation') }}</h3>

                <div class="box-tools">
                    <button class="btn btn-success btn-xs" :disabled="!editable" v-if="test.evaluationType != evaluationType_MinForSuccess" @click="switchOnMinForSuccessEvaluation">
                        {{ $t("switchOn") }}
                    </button>
                    <button class="btn btn-danger btn-xs" :disabled="!editable" v-else @click="switchOffMinForSuccessEvaluation">
                        {{ $t("switchOff") }}
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div class="form-group">
                    <label>{{ $t("minQuestionsCount") }}:</label>
                    <div class="input-group">
                        <input type="number"
                            min=1
                            :max="evaluatedQuestions.length"
                            class="form-control"
                            v-model.number="test.minForSuccess"
                            :disabled="!editable || test.evaluationType != evaluationType_MinForSuccess"
                            name="minForSuccess_input"
                            v-validate="{
                                min_value: 1,
                                max_value: evaluatedQuestions.length
                            }"
                            :data-vv-as="$t('comp.testEditor.evaluation.evaluatedQuestionsValidation')" />

                        <div class="input-group-addon">
                            {{$t('comp.testEditor.evaluation.max')}} <strong>{{evaluatedQuestions.length}}</strong>
                        </div>
                    </div>

                    <span class="help-block text-sm">*{{ $t("whichQuestionsAreEvaluated") }}</span>
                    <span class="text-red">{{ errors.first('minForSuccess_input') }}</span>
                </div>
            </div>
        </div>


        <div class="box box-danger">
            <div class="box-header">
                <h3 class="box-title">{{ $t('scaleTestEvaluation') }}</h3>

                <div class="box-tools">
                    <button class="btn btn-success btn-xs" :disabled="!editable" 
                            v-if="!isEvaluationType_Scale" 
                            @click="switchOnScaleEvaluation">
                        {{ $t("switchOn") }} 
                    </button>
                    <button class="btn btn-danger btn-xs" :disabled="!editable" v-else @click="switchOffScaleEvaluation">
                        {{ $t("switchOff") }} 
                    </button>
                </div>
            </div>
            <div class="box-body">
                <div class="radio-group">
                    <span class="radio-label">{{$t('comp.testEditor.evaluation.scaleEvaluationStepsTitle')}}</span>
                    <label class="radio-inline">
                        <input type="radio" name="scaleRadioOptions" value=2 v-model="test.scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!editable || !isEvaluationType_Scale || evaluatedQuestions.length < 1">
                        <abbr >2</abbr>
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="scaleRadioOptions" value=3 v-model="test.scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!editable || !isEvaluationType_Scale  || evaluatedQuestions.length < 2">
                        <abbr >3</abbr>
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="scaleRadioOptions" value=4 v-model="test.scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!editable || !isEvaluationType_Scale  || evaluatedQuestions.length < 3">
                        <abbr >4</abbr>
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="scaleRadioOptions" value=5 v-model="test.scaleEvaluationStepsCount" v-on:change="setupScale" :disabled="!editable || !isEvaluationType_Scale  || evaluatedQuestions.length < 4">
                        <abbr >5</abbr>
                    </label>
                </div>

                <div class="radio-group">
                    <span class="radio-label">{{$t('comp.testEditor.evaluation.scaleEvaluationTypeTitle')}}</span>
                    <label class="radio-inline">
                        <input type="radio" name="scaleEvaluationTypeRadioOptions" :value="evaluationType_ScaleTextAndEmoji" v-model="test.evaluationType" :disabled="!editable || !isEvaluationType_Scale">
                        <abbr >{{$t('comp.testEditor.evaluation.evaluationType_ScaleTextAndEmoji')}}</abbr>
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="scaleEvaluationTypeRadioOptions" :value="evaluationType_ScaleEmoji" v-model="test.evaluationType" :disabled="!editable || !isEvaluationType_Scale">
                        <abbr >{{$t('comp.testEditor.evaluation.evaluationType_ScaleEmoji')}}</abbr>
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="scaleEvaluationTypeRadioOptions" :value="evaluationType_ScaleText" v-model="test.evaluationType" :disabled="!editable || !isEvaluationType_Scale">
                        <abbr >{{$t('comp.testEditor.evaluation.evaluationType_ScaleText')}}</abbr>
                    </label>
                </div>


                <div v-if="isEvaluationType_Scale" > 
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th style="width: 50px;" v-if="test.evaluationType == evaluationType_ScaleTextAndEmoji || test.evaluationType == evaluationType_ScaleEmoji"></th>
                            <th>
                                <span v-if="test.evaluationType == evaluationType_ScaleTextAndEmoji || test.evaluationType == evaluationType_ScaleText">
                                    {{$t('comp.testEditor.evaluation.headerEvaluationType_ScaleText')}}
                                </span>
                            </th>
                            <th style="width: 110px;">{{$t('comp.testEditor.evaluation.minPoints')}}</th>
                        </tr>
                        </thead>
                        <tbody >
                            <tr v-for="scaleStep in test.scaleEvaluationSteps">
                                <td class="evaluation-icon" v-if="test.evaluationType == evaluationType_ScaleTextAndEmoji || test.evaluationType == evaluationType_ScaleEmoji">
                                    <i :class="scaleStep.icon" ></i>
                                </td>
                                <td class="text-bold" >
                                    <div class="form-group" v-if="test.evaluationType == evaluationType_ScaleTextAndEmoji || test.evaluationType == evaluationType_ScaleText">
                                        <input type="text"
                                            class="form-control"
                                            v-model="scaleStep.evaluationText"
                                            :placeholder="scaleStep.placeholder"
                                            :disabled="!editable || !isEvaluationType_Scale"
                                            :name="'scaleStepText_input' + scaleStep.step"
                                            :key="'scaleStepText_input' + scaleStep.step"
                                            v-validate.persistent="'required'"
                                            data-vv-validate-on="input"
                                            :data-vv-as="$t('comp.testEditor.evaluation.validationEvaluationType_ScaleText')" />
                                        <span class="text-red">{{ errors.first('scaleStepText_input' + scaleStep.step) }}</span>
                                    </div>
                                </td>
                                <td class="text-bold">
                                    <div class="form-group">
                                        <input type="number"
                                            :min="getScaleStepMin(scaleStep)"
                                            :max="getScaleStepMax(scaleStep)"
                                            class="form-control"
                                            v-model.number="scaleStep.minLimit"
                                            :disabled="!editable || !isEvaluationType_Scale || scaleStep.step == 1"
                                            :name="'scaleStepMax_input' + scaleStep.step"                                            
                                            :key="'scaleStepMax_input' + scaleStep.step"        
                                            v-validate.persistent="'required'"
                                            v-validate="{
                                                min_value: getScaleStepMin(scaleStep),
                                                max_value: getScaleStepMax(scaleStep)
                                            }"                                     
                                            data-vv-validate-on="input"                                            
                                            :data-vv-as="$t('comp.testEditor.evaluation.minPoints')"/>
                                        <span class="text-red">{{ errors.first('scaleStepMax_input' + scaleStep.step) }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <span class="help-block text-sm">*{{ $t("whichQuestionsAreEvaluated") }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        inject: [ 'parentValidator' ],
        components: { 

        },
        data() {
            return {
                evaluationType_None: 0,
                evaluationType_MinForSuccess: 1,
                evaluationType_ScaleTextAndEmoji: 2,
                evaluationType_ScaleText: 3,
                evaluationType_ScaleEmoji: 4,
                isEvaluationType_Scale: false,
                step1: {id: 0, step: 1, evaluationText: null, placeholder: this.$t("scaleStep1Placeholder"), minLimit: 0, icon: "far fa-frown-open"},
                step2: {id: 0, step: 2, evaluationText: null, placeholder: this.$t("scaleStep2Placeholder"), minLimit: null, icon: "far fa-frown"},
                step3: {id: 0, step: 3, evaluationText: null, placeholder: this.$t("scaleStep3Placeholder"), minLimit: null, icon: "far fa-meh"},
                step4: {id: 0, step: 4, evaluationText: null, placeholder: this.$t("scaleStep4Placeholder"), minLimit: null, icon: "far fa-smile"},
                step5: {id: 0, step: 5, evaluationText: null, placeholder: this.$t("scaleStep5Placeholder"), minLimit: null, icon: "far fa-grin"},

            }
        },
        props: {
            test: Object,
            editable: Boolean
        },
        watch: {
            ['test.evaluationType']: function (evaluationType) {
                if(evaluationType == this.evaluationType_ScaleTextAndEmoji ||
                   evaluationType == this.evaluationType_ScaleText ||
                   evaluationType == this.evaluationType_ScaleEmoji) {
                    this.isEvaluationType_Scale = true;

                    this.setupScale();
                } else {
                    this.isEvaluationType_Scale = false;
                }
            },
            evaluatedQuestions: function (val) {
                if(this.isEvaluationType_Scale) {
                    if(this.test.scaleEvaluationStepsCount > val.length){
                        this.test.scaleEvaluationStepsCount = val.length + 1;
                    }

                    this.setupScale();
                }
            }
        },
        created () {
            this.$validator = this.parentValidator;
            this.setupScale();
        },
        computed: {
            evaluatedQuestions: function () {
                return this.test.questions.filter(function (q) {
                    return (q.type === 'choices' || q.type === 'yesNo') && !q.removed;
                });
            }
        },
        methods: {
            switchOnMinForSuccessEvaluation: function () {
                this.switchOffScaleEvaluation();            // Disable othe evaluation option

                this.$set(this.test, 'evaluationType', this.evaluationType_MinForSuccess);
            },
            switchOffMinForSuccessEvaluation: function () {
                this.$set(this.test, 'evaluationType', this.evaluationType_None);
                this.$set(this.test, 'minForSuccess', null);
            },
            switchOnScaleEvaluation: function () {
                this.switchOffMinForSuccessEvaluation();     // Disable othe evaluation option

                // Default
                this.$set(this.test, 'evaluationType', this.evaluationType_ScaleTextAndEmoji);
                this.test.scaleEvaluationStepsCount = 2; 
            },
            switchOffScaleEvaluation: function () {
                this.$set(this.test, 'evaluationType', this.evaluationType_None);
                this.$set(this.test, 'scaleEvaluationSteps', []);
                this.test.scaleEvaluationStepsCount = null;     
            },
            getScaleStepMin: function(scaleStep){
                if(scaleStep){
                    if(scaleStep.step == 1) return 0;

                    let stepIndex = this.test.scaleEvaluationSteps.findIndex(x=>x.step == scaleStep.step);
                    if(stepIndex > 0)
                    {
                        return this.test.scaleEvaluationSteps[stepIndex - 1].minLimit + 1; 
                    }
                }
   
                return 0;
            },
            getScaleStepMax: function(scaleStep){
                if(scaleStep){
                    if(scaleStep.step == 1) return 0;
                    if(scaleStep.step == 5) return this.evaluatedQuestions.length;

                    let stepIndex = this.test.scaleEvaluationSteps.findIndex(x=>x.step == scaleStep.step);
                    if(stepIndex >= 0 && stepIndex < (this.evaluatedQuestions.length-1) && this.test.scaleEvaluationSteps[stepIndex + 1].minLimit)
                    {
                        return this.test.scaleEvaluationSteps[stepIndex + 1].minLimit - 1; 
                    }
                }
   
                return this.evaluatedQuestions.length;
            },
            setupScale(){                
                let scaleEvaluationSteps = [];
                let step = null;
                
                // Removes old inputs on scale changed
                this.test.scaleEvaluationSteps.forEach(item => this.$validator.detach(`scaleStepMax_input${item.step}`));


                // Step 1
                step = this.test.scaleEvaluationSteps.find(x => x.step == 1);
                if(step) {
                    this.step1.id = step.id;
                    this.step1.minLimit = step.minLimit;
                    this.step1.evaluationText = step.evaluationText;
                }                
                scaleEvaluationSteps.push(this.step1);

                // Step 2
                if(this.test.scaleEvaluationStepsCount == 5 ||
                   this.test.scaleEvaluationStepsCount == 4){ 
                    step = this.test.scaleEvaluationSteps.find(x => x.step == 2);
                    if(step) {
                        this.step2.id = step.id;
                        this.step2.minLimit = step.minLimit;
                        this.step2.evaluationText = step.evaluationText;
                    }                
                    scaleEvaluationSteps.push(this.step2);
                }

                // Step 3
                if(this.test.scaleEvaluationStepsCount == 5 ||
                   this.test.scaleEvaluationStepsCount == 4 ||
                   this.test.scaleEvaluationStepsCount == 3){ 
                    step = this.test.scaleEvaluationSteps.find(x => x.step == 3);
                    if(step) {
                        this.step3.id = step.id;
                        this.step3.minLimit = step.minLimit;
                        this.step3.evaluationText = step.evaluationText;
                    }                
                    scaleEvaluationSteps.push(this.step3);
                }
                
                // Step 4
                if(this.test.scaleEvaluationStepsCount == 5){ 
                    step = this.test.scaleEvaluationSteps.find(x => x.step == 4);
                    if(step) {
                        this.step4.id = step.id;
                        this.step4.minLimit = step.minLimit;
                        this.step4.evaluationText = step.evaluationText;
                    }                
                    scaleEvaluationSteps.push(this.step4);
                }

                // Step 5
                step = this.test.scaleEvaluationSteps.find(x => x.step == 5);
                if(step) {
                    this.step5.id = step.id;
                    this.step5.minLimit = step.minLimit;
                    this.step5.evaluationText = step.evaluationText;
                }                
                scaleEvaluationSteps.push(this.step5);

                this.test.scaleEvaluationSteps.splice(0, this.test.scaleEvaluationSteps.length);
                scaleEvaluationSteps.forEach(element => {
                    this.test.scaleEvaluationSteps.push(element);                    
                }); 
            }
        }
    }
</script>


<style>

.radio-group {
    margin-bottom: 10px;
}

.radio-label {
    font-weight: bold;
    margin-right: 10px;;
}

.evaluation-icon {
    font-size: 30px;
}
</style>