<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>
        <div class="box box-primary">
            <div class="box-header">
                <h3 class="box-title">
                    <i class="fas fa-superscript"></i>
                    {{ $t("tests") }}
                </h3>

                <div class="pull-right" v-if="!notEditable">
                    <a class="btn btn-success btn-xs ajax" @click="createTest">
                        <i class="fa fa-plus"></i>
                        {{ $t("addTest") }}
                    </a>
                </div>
            </div>
            <div class="box-body">
                <table class="table">
                    <thead>
                    <tr>
                        <th style="width: 40%;">{{ $t("testName") }}</th>
                        <th style="width: 10%;">{{ $t("timeLimit") }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(test, index) in tests">
                            <td>{{test.name}}</td>
                            <td v-if="test.timeLimit">{{test.timeLimit}} min</td>
                            <td v-if="!test.timeLimit"><i class="fas fa-infinity"></i></td>
                            <td class="text-right">
                                <router-link :to="{name: 'result', params: {testId: test.id}}" tag="button" class="btn btn-primary btn-xs">
                                    <i class="fas fa-poll"></i>
                                    {{ $t("results") }}
                                </router-link> |
                                <button class="btn btn-danger btn-xs ajax" @click="removeTest(test, $t('messages.areYouSureRemove'))" :disabled="notEditable">
                                    <i class="fas fa-trash"></i>
                                    {{ $t("remove") }}
                                </button>
                                <button class="btn btn-warning btn-xs ajax" @click="editTest(test)" :disabled="notEditable">
                                    <i class="fas fa-pencil-alt"></i>
                                    {{ $t("edit") }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <template v-if="showTestModal">
            <test-modal
                    :id="editTestId"
                    :courseId="courseId"
                    v-on:reload="load"
                    v-on:close="modalClose"></test-modal>
        </template>
    </div>
</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import TestModal from './TestModal.vue';

    export default {
        name: "ManageElearningTests",
        injects: ['courseId'],
        components: {
            TestModal
        },
        data() {
            return {
                isLoading: false,
                tests: [],
                showTestModal: false
            }
        },
        mounted: function () {
            this.load();
        },
        props: {
            courseId: String,
            notEditable: {
                required: true
            },
        },
        methods: {
            load: function () {
                this.isLoading = true;

                this.$api.get("elearning-tests/in-course", {
                    params: {
                        courseId: this.courseId,
                        onlineCourseHash: this.onlineCourseHash
                    }
                })
                    .then(({data}) => {
                        this.tests = data.data;
                        this.isLoading = false;
                    })
                    .catch(() => {
                        alert("error");
                    });
            },
            createTest: function () {
                this.showTestModal = true;
                this.editTestId = null;
            },
            editTest: function ($test) {
                this.showTestModal = true;
                this.editTestId = $test.id;
            },
            modalClose: function () {
                this.showTestModal = false;
                this.editTestId = null;
            },
            removeTest: function ($test, $message) {

                if (!confirm($message)) {
                    return;
                }   

                this.isLoading = true;

                this.$api.delete("elearning-tests/" + $test.id)
                    .then(({data}) => {
                        if(data.status === 'ok') {
                            this.load();
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }
</script>

<style lang="less">

</style>