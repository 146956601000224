<template>
    <div>
        <div class="flex items-center justify-center" style="min-height: 50vh" v-if="view === 'selectFile'">
            <div class="text-center">
                <p>
                    <label class="btn btn-success btn-lg" for="select_file">
                        {{$t('comp.assetsImportWizard.assetsImportWizard.selectFile')}}
                        <input type="file" id="select_file" class="hidden" ref="file" @change="fileChanged" />
                    </label>
                </p>
                <p><em>{{$t('comp.assetsImportWizard.assetsImportWizard.or')}}</em></p>
                <p>
                    <button class="btn btn-warning" @click="template">
                        {{$t('comp.assetsImportWizard.assetsImportWizard.selectTemplate')}}
                    </button>
                </p>
            </div>
        </div>

        <div class="flex items-center justify-center" style="min-height: 50vh" v-if="view === 'finished'">
            <div class="text-center">
                <p class="text-bold" style="font-size: 25px;">
                    <i class="far fa-check-circle fa-4x text-green"></i> <br/>
                    {{$t('comp.assetsImportWizard.assetsImportWizard.importDone')}}
                </p>
            </div>
        </div>

        <div class="nav-tabs-custom no-margin" v-if="view === 'assets'">
            <ul class="nav nav-tabs">
                <li :class="{active: tab === 1}"><a href="javascript:;" @click="tab = 1">{{$t('comp.assetsImportWizard.assetsImportWizard.tabGeneral')}}</a></li>
                <li :class="{active: tab === 2}"><a href="javascript:;" @click="tab = 2">{{$t('comp.assetsImportWizard.assetsImportWizard.tabEvaluation')}}</a></li>
                <li :class="{active: tab === 3}"><a href="javascript:;" @click="tab = 3">{{$t('comp.assetsImportWizard.assetsImportWizard.tabUsageAndManipulation')}}</a></li>
                <li :class="{active: tab === 4}"><a href="javascript:;" @click="tab = 4">{{$t('comp.assetsImportWizard.assetsImportWizard.tabOwnAttributes')}}</a></li>


                <li class="pull-right">
                    <button class="btn btn-success" @click="submit">
                        <i class="fas fa-check"></i>
                        {{$t('comp.assetsImportWizard.assetsImportWizard.submit')}}
                    </button>
                </li>
            </ul>
            <div class="tab-content">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.name')}}</th>

                        <template v-if="tab === 1">
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.template')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.classification')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.type')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.guarantor')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.administrator')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.location')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.supplier')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.practitioner')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.practitionerPercentage')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.wayOfDispose')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.accepted')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.description')}}</th>
                        </template>

                        <template v-if="tab === 2">
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.confidence')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.integrity')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.availability')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.rulesOfProtection')}}</th>
                        </template>

                        <template v-if="tab === 3">
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.methodsOfUsage')}}</th>
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.rulesForManipulation')}}</th>
                        </template>

                        <template v-if="tab === 4">
                            <th>{{$t('comp.assetsImportWizard.assetsImportWizard.th.ownAttributes')}}</th>
                        </template>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="asset in assets" :key="asset.id">
                            <td>{{asset.name}}</td>

                            <template v-if="tab === 1">
                                <td>{{asset.template ? asset.template.name : '-'}}</td>
                                <td>{{asset.classification ? asset.classification.name : '-'}}</td>
                                <td>{{asset.type ? asset.type.name : '-'}}</td>
                                <td>{{asset.guarantor ? asset.guarantor.name : '-'}}</td>
                                <td>{{asset.administrator ? asset.administrator.name : '-'}}</td>
                                <td>{{asset.location ? asset.location.name : '-'}}</td>
                                <td>{{asset.supplier ? asset.supplier.name : '-'}}</td>
                                <td>{{asset.practitioner ? asset.practitioner.name : '-'}}</td>
                                <td>{{asset.practitionerPercentage ? asset.practitionerPercentage : '0'}}</td>
                                <td>{{asset.wayOfDispose ? asset.wayOfDispose.name : '-'}}</td>
                                <td>{{asset.accepted ? $t('common.yes') : $t('common.no')}}</td>
                                <td>{{asset.description ? asset.description : '-'}}</td>
                            </template>

                            <template v-if="tab === 2">
                                <td>{{asset.evaluation.confidence ? asset.evaluation.confidence.name : '-'}}</td>
                                <td>{{asset.evaluation.integrity ? asset.evaluation.integrity.name : '-'}}</td>
                                <td>{{asset.evaluation.availability ? asset.evaluation.availability.name : '-'}}</td>
                                <td>{{asset.rulesOfProtection ? asset.rulesOfProtection : '-'}}</td>
                            </template>

                            <template v-if="tab === 3">
                                <td>{{asset.methodsOfUsage ? asset.methodsOfUsage : '-'}}</td>
                                <td>{{asset.rulesForManipulation ? asset.rulesForManipulation : '-'}}</td>
                            </template>

                            <template v-if="tab === 4">
                                <td>
                                    <table class="table no-padding no-margin no-border" style="background: none;">
                                        <tbody>
                                            <tr v-for="attribute in asset.ownAttributes">
                                                <td style="width: 50%" class="text-bold">{{getAttributeById(attribute.attributeId).name}}</td>
                                                <td style="width: 50%">
                                                    <template v-if="getAttributeById(attribute.attributeId).type === 'boolean'">
                                                        {{attribute.value ? $t('common.yes') : $t('common.no')}}
                                                    </template>
                                                    <template v-else-if="getAttributeById(attribute.attributeId).type === 'select'">
                                                        <template v-if="getAttributeOption(attribute.attributeId, attribute.value)">
                                                          {{getAttributeOption(attribute.attributeId, attribute.value).value}}
                                                        </template>
                                                    </template>
                                                    <template v-else-if="getAttributeById(attribute.attributeId).type === 'multiSelect'">
                                                        <template v-for="(optionId, index) in attribute.value">
                                                            <template v-if="getAttributeOption(attribute.attributeId, optionId)">
                                                              {{getAttributeOption(attribute.attributeId, optionId).value}}
                                                            </template>

                                                            <span v-if="index !== attribute.value.length">, </span>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{attribute.value}}
                                                    </template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </template>

                            <td class="text-right" style="width: 100px;">
                                <button class="btn btn-danger btn-xs" @click="remove(asset)"><i class="fas fa-trash"></i></button>
                                <button class="btn btn-warning btn-xs" @click="edit(asset)"><i class="fas fa-pencil-alt"></i></button>
                            </td>
                            <edit-asset :value="asset" v-if="editedAsset === asset" @close="editedAsset = null"></edit-asset>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <loading :active="isLoading" :is-full-page="true"></loading>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import EditAsset from './EditAsset.vue';

    export default {
        name: 'asset-import-wizard',
        components: {EditAsset},
        data(){
            return {
                tab: 1,
                fileSelected: false,
                isLoading: false,
                view: 'selectFile',
                assets: [],
                editedAsset: null
            };
        },
        computed: {
            ...mapGetters("assetClassification", {
                getClassificationItemById: "getItemById"
            }),
            ...mapGetters("assetType", ['getTypeById']),
            ...mapGetters("location", ['getLocationById']),
            ...mapGetters("assetWayOfDispose", ['getWayOfDisposeById']),
            ...mapGetters("assetTemplate", ['getTemplateById']),
            ...mapGetters("assetScaleItems", ['getScaleItemById']),
            ...mapGetters('assetOwnAttributes', ['getAttributeById']),
            ...mapGetters('suppliers', ['getSupplierById'])
        },
        mounted(){
            this.fetchTemplates();
            this.fetchClassification();
            this.fetchTypes();
            this.fetchLocations();
            this.fetchSuppliers();
            this.fetchWayOfDisposes();
            this.fetchAttributes();

            this.fetchIntegrity();
            this.fetchAvailability();
            this.fetchConfidence();
            this.fetchQuestions();
        },
        methods: {
            ...mapActions('assetImportanceAssessmentQuestions', ['fetchQuestions']),

            fileChanged: function (f) {
                this.fileSelected = f.target.files.length > 0;

                this.uploadFile();
            },
            uploadFile() {
                this.isLoading = true;

                let data = new FormData;

                data.append("file", this.$refs.file.files[0]);

                this.$api.post("assets-import", data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then(({data}) => {
                    if(data.status === 'ok') {
                        this.view = 'assets';
                        this.isLoading = false;

                        data.data.assets.forEach((asset) => {
                            this.initAsset(asset);
                        });
                    } else {
                        this.isLoading = false;
                        alert(this.$t('comp.assetsImportWizard.assetsImportWizard.fileIsNotValid'));
                    }
                });
            },
            initAsset(data){
                let a = {
                    name: null,
                    templateId: null,
                    classification: null,
                    assetSubClassification: null,
                    type: null,
                    location: null,
                    wayOfDispose: null,
                    description: null,
                    accepted: null,
                    guarantor: null,
                    administrator: null,
                    supplier: null,
                    practitioner: null,
                    practitionerPercentage: 0,
                    evaluation: {
                        integrity: null,
                        availability: null,
                        confidence: null,
                    },
                    rulesOfProtection: null,
                    threats: [],
                    rulesForManipulation: null,
                    methodsOfUsage: null,
                    ownAttributes: [],
                    importanceAssessmentAnswers: [],
                    assetDependenciesDown: [],
                    assetDependenciesUp: [],
                    assetTags: []
                };

                a.name = data.name;
                a.description = data.description;
                a.accepted = data.accepted;
                a.methodsOfUsage = data.methodsOfUsage;
                a.rulesForManipulation = data.rulesForManipulation;
                a.rulesOfProtection = data.rulesOfProtection;
                a.ownAttributes = data.ownAttributes;

                if(data.templateId) {
                    a.template = this.getTemplateById(data.templateId);
                }
                if(data.classificationId) {
                    a.classification = this.getClassificationItemById(data.classificationId);
                }
                if(data.typeId) {
                    a.type = this.getTypeById(data.typeId);
                }
                if(data.guarantorId) {
                    this.fetchPerson(data.guarantorId).then((person)=>{
                        a.guarantor = person;
                    });
                }
                if(data.administratorId) {
                    this.fetchPerson(data.administratorId).then((person)=>{
                        a.administrator = person;
                    });
                }
                if(data.locationId) {
                    a.location = this.getLocationById(data.locationId);
                }
                if(data.supplierId) {
                    a.supplier = this.getSupplierById(data.supplierId);
                }
                if(data.practitionerId) {
                    a.practitioner = this.getSupplierById(data.practitionerId);
                }
                if(data.practitionerPercentage) {
                    a.practitionerPercentage = data.practitionerPercentage;
                }
                if(data.wayOfDisposeId) {
                    a.wayOfDispose = this.getWayOfDisposeById(data.wayOfDisposeId);
                }
                if(data.evaluation.integrityId) {
                    a.evaluation.integrity = this.getScaleItemById(data.evaluation.integrityId);
                }
                if(data.evaluation.availabilityId) {
                    a.evaluation.availability = this.getScaleItemById(data.evaluation.availabilityId);
                }
                if(data.evaluation.confidenceId) {
                    a.evaluation.confidence = this.getScaleItemById(data.evaluation.confidenceId);
                }

                this.assets.push(a);
            },
            ...mapActions("assetTemplate", ["fetchTemplates"]),
            ...mapActions("assetClassification", ["fetchClassification"]),
            ...mapActions("assetType", ["fetchTypes"]),
            ...mapActions("location", ["fetchLocations"]),
            ...mapActions("assetWayOfDispose", ["fetchWayOfDisposes"]),
            ...mapActions('assetScaleItems', ['fetchIntegrity', 'fetchAvailability', 'fetchConfidence']),
            ...mapActions("assetOwnAttributes", ["fetchAttributes"]),
            ...mapActions("orgStructure", ["fetchPerson"]),
            ...mapActions("suppliers", ["fetchSuppliers"]),

            template: function (){
                this.$api.get("assets-import/template", {
                    responseType: 'blob'
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.$t('comp.assetsImportWizard.assetsImportWizard.templateFileName')); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(()=>{
                        this.isLoading = false;
                    });
            },
            edit(asset) {
                this.editedAsset = asset;
            },
            remove(asset) {
                this.assets.splice(this.assets.indexOf(asset), 1);
            },
            async submit(){
                this.isLoading = true;

                let assets = [...this.assets];

                for (let asset of assets) {
                    await this.createAsset(asset);
                    this.assets.splice(this.assets.indexOf(asset), 1);
                }

                this.isLoading = false;
                this.view = 'finished';
            },
            async createAsset(asset) {
                asset = JSON.parse(JSON.stringify(asset));

                asset.templateId = asset.template ? asset.template.id : null;
                delete asset.template;

                asset.classificationId = asset.classification ? asset.classification.id : null;
                delete asset.classification;

                asset.typeId = asset.type ? asset.type.id : null;
                delete asset.type;

                asset.locationId = asset.location ? asset.location.id : null;
                delete asset.location;

                asset.supplierId = asset.supplier ? asset.supplier.id : null;
                delete asset.supplier;

                asset.practitionerId = asset.practitioner ? asset.practitioner.id : null;
                delete asset.practitioner;

                asset.wayOfDisposeId = asset.wayOfDispose ? asset.wayOfDispose.id : null;
                delete asset.wayOfDispose;

                asset.evaluation.integrityId = asset.evaluation.integrity ? asset.evaluation.integrity.id : null;
                delete asset.evaluation.integrity;

                asset.evaluation.availabilityId = asset.evaluation.availability ? asset.evaluation.availability.id : null;
                delete asset.evaluation.availability;

                asset.evaluation.confidenceId = asset.evaluation.confidence ? asset.evaluation.confidence.id : null;
                delete asset.evaluation.confidence;

                asset.guarantorId = asset.guarantor ? asset.guarantor.id : null;
                delete asset.guarantor;

                asset.administratorId = asset.administrator ? asset.administrator.id : null;
                delete asset.administrator;

                let transformed = [];
                asset.threats.forEach((selectedThreat) =>{
                    transformed.push({
                        threatId: selectedThreat.threat.id,
                        vulnerabilityIds: selectedThreat.vulnerabilities.map((v) => {
                            return v.id;
                        })
                    });
                });
                asset.threats = transformed;

                asset.dependingAssetIdsDown = asset.assetDependenciesDown.map((a)=>{
                    return a.id;
                });
                delete asset.assetDependenciesDown;

                asset.dependingAssetIdsUp = asset.assetDependenciesUp.map((a)=>{
                    return a.id;
                });
                delete asset.assetDependenciesUp;

                asset.assetTagIds = asset.assetTags.map((a) => a.id);
                delete asset.assetTags;

                await this.$api.post("asset", asset);
            },
            getAttributeOption(attributeId, optionId) {
              let attribute = this.getAttributeById(attributeId)

              return attribute.options.find(o => o.id === optionId)
            }
        }
    }
</script>
