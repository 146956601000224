<template>
    <div class="text-right">
        <button class="btn btn-danger btn-xs" @click="remove">
            <i class="fas fa-trash"></i>
        </button>
        <button class="btn btn-warning btn-xs" @click="edit">
            <i class="fas fa-search"></i>
        </button>
    </div>
</template>

<script>
    import NavigationBus from '../NavigationBus.js';

    export default {
        props: ['row', 'xprops'],
        methods: {
            edit(unitId) {
                NavigationBus.$emit('select-node', this.row.id);
                NavigationBus.$emit('expand-to-node', this.row.id);
                this.$router.push({name: 'person', params: {personId: this.row.id, unitId: this.row.unitId}});
            },
            remove() {
                this.xprops.eventbus.$emit("REMOVE_PERSON", this.row);
            }
        }
    }
</script>