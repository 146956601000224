<template>
  <div class="vld-parent">
    <table style="margin-bottom: 30px">
      <tr>
        <th></th>
        <th style="text-align: center">
          {{ $t("comp.editRisk.measuresTab.measuresTab.impact") }}
        </th>
        <th>x</th>
        <th style="text-align: center">
          {{ $t("comp.editRisk.measuresTab.measuresTab.threat") }}
        </th>
        <th>x</th>
        <th style="text-align: center">
          {{ $t("comp.editRisk.measuresTab.measuresTab.vulnerability") }}
        </th>
        <th>=</th>
        <th style="text-align: center">
          {{ $t("comp.editRisk.measuresTab.measuresTab.risk") }}
        </th>
      </tr>
      <tr>
        <td></td>
        <td style="text-align: center">
          <span v-if="riskScaleI">
            {{ riskScaleI.name }} / {{ riskScaleI.rank }}
          </span>
          <span v-else>-</span>
        </td>
        <td></td>
        <td style="text-align: center">
          <span v-if="riskScaleT">
            {{ riskScaleT.name }} / {{ riskScaleT.rank }}
          </span>
          <span v-else>-</span>
        </td>
        <td></td>
        <td style="text-align: center">
          <span v-if="riskScaleV">
            {{ riskScaleV.name }} / {{ riskScaleV.rank }}
          </span>
          <span v-else>-</span>
        </td>
        <td></td>
        <td style="text-align: center">
          <span v-if="computedRisk && computedRisk.classification">
            {{ computedRisk.classification.name }} /
            {{ computedRisk.value }} ({{ computedRisk.percentage }}%)
          </span>
          <span v-else>-</span>
        </td>
      </tr>
      <tr>
        <td style="font-weight: bold; font-size: 16px; padding-top: 20px">
          {{ $t("comp.editRisk.measuresTab.measuresTab.targetedRisk") }}
        </td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          <span v-if="riskScaleI">{{ riskScaleI.rank }}</span>
        </td>
        <td></td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          {{ threatFullReducedValue }}
          <span
            v-if="threatFullReductionPercentage"
            class="text-success"
            style="margin-left: 5px; font-size: 11px"
            ><i class="fas fa-level-down-alt"></i>
            {{ threatFullReductionPercentage }}%</span
          >
        </td>
        <td></td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          {{ vulnerabilityFullReducedValue }}
          <span
            v-if="vulnerabilityFullReductionPercentage"
            class="text-success"
            style="margin-left: 5px; font-size: 11px"
            ><i class="fas fa-level-down-alt"></i>
            {{ vulnerabilityFullReductionPercentage }}%</span
          >
        </td>
        <td></td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          <span v-if="riskFullReducedValue">
            {{ riskFullReducedValue }} ({{ riskFullReducedValuePercentage }}%)
            <span
              v-if="riskFullReductionPercentage"
              class="text-success"
              style="margin-left: 5px; font-size: 11px"
              ><i class="fas fa-level-down-alt"></i>
              {{ riskFullReductionPercentage }}%</span
            >
          </span>
        </td>
      </tr>
      <tr>
        <td style="font-weight: bold; font-size: 16px; padding-top: 20px">
          {{ $t("comp.editRisk.measuresTab.measuresTab.resultingRisk") }}
        </td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          <span v-if="riskScaleI">{{ riskScaleI.rank }}</span>
        </td>
        <td></td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          {{ threatReducedValue }}
          <span
            v-if="threatReductionPercentage"
            class="text-success"
            style="margin-left: 5px; font-size: 11px"
            ><i class="fas fa-level-down-alt"></i>
            {{ threatReductionPercentage }}%</span
          >
        </td>
        <td></td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          {{ vulnerabilityReducedValue }}
          <span
            v-if="vulnerabilityReductionPercentage"
            class="text-success"
            style="margin-left: 5px; font-size: 11px"
            ><i class="fas fa-level-down-alt"></i>
            {{ vulnerabilityReductionPercentage }}%</span
          >
        </td>
        <td></td>
        <td
          style="
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            padding-top: 20px;
          "
        >
          <span v-if="riskReducedValue">
            {{ riskReducedValue }} ({{ riskReducedValuePercentage }}%)
            <span
              v-if="riskReductionPercentage"
              class="text-success"
              style="margin-left: 5px; font-size: 11px"
              ><i class="fas fa-level-down-alt"></i>
              {{ riskReductionPercentage }}%</span
            >
          </span>
        </td>
      </tr>
    </table>

    <div class="pull-right">
      <button
        class="btn btn-success btn-xs"
        v-if="isSelectionMode"
        @click="add"
        :disabled="isAddMeasureMode"
      >
        <i class="fa fa-plus"></i>
        {{ $t("comp.editRisk.measuresTab.measuresTab.newMeasure") }}
      </button>
      <button
        class="btn btn-danger btn-xs"
        v-if="isSelectionMode"
        @click="toggleSelectionMode"
        :disabled="isAddMeasureMode"
      >
        {{ $t("comp.editRisk.measuresTab.measuresTab.endToggleSelectionMode") }}
      </button>
      <button
        class="btn btn-warning btn-xs"
        v-else
        @click="toggleSelectionMode"
      >
        {{ $t("comp.editRisk.measuresTab.measuresTab.toggleSelectionMode") }}
      </button>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th v-if="isSelectionMode"></th>
          <th style="width: 20px" v-if="isShowHashIds">ID</th>
          <th>{{ $t("comp.editRisk.measuresTab.measuresTab.name") }}</th>
          <th style="width: 250px">
            {{ $t("comp.editRisk.measuresTab.measuresTab.tags") }}
          </th>
          <th style="width: 107px">
            {{ $t("comp.editRisk.measuresTab.measuresTab.applicable") }}
          </th>
          <th style="width: 107px">
            {{
              $t("comp.editRisk.measuresTab.measuresTab.threatReductionValue")
            }}
          </th>
          <th style="width: 107px">
            {{
              $t(
                "comp.editRisk.measuresTab.measuresTab.vulnerabilityReductionValue"
              )
            }}
          </th>
          <th style="width: 107px">
            {{ $t("comp.editRisk.measuresTab.measuresTab.implemented") }}
          </th>
          <th style="width: 80px"></th>
        </tr>
      </thead>
      <tbody>
        <tr style="border-bottom: 2px solid #e8e8e8">
          <td style="width: 2%" v-if="isSelectionMode">
            <label class="form-checkbox" style="margin-bottom: 0px">
              <input
                type="checkbox"
                :checked="isAllSelected"
                @click="toggleSelectAll"
              />
              <i class="form-icon"></i>
            </label>
          </td>
          <td v-if="isShowHashIds"></td>
          <td>
            <input
              class="form-control input-sm"
              v-model="filter.name"
              :placeholder="
                $t('comp.manageMeasures.measureListing.searchPlaceholder')
              "
              :disabled="isAddMeasureMode"
            />
          </td>
          <td>
            <multiselect
              v-model="filter.tags"
              :disabled="isAddMeasureMode"
              :options="getAllTags.map((tag) => tag.id)"
              :custom-label="(opt) => getAllTags.find((x) => x.id == opt).name"
              :multiple="true"
              :taggable="false"
              :closeOnSelect="false"
            >
            </multiselect>
          </td>
          <td>
            <select
              class="form-control input-sm"
              v-model="filter.applicable"
              :disabled="isAddMeasureMode"
            >
              <option :value="1">
                {{ $t("common.yes") }}
              </option>
              <option :value="0">
                {{ $t("common.no") }}
              </option>
              <option :value="2">
                {{ $t("common.undecided") }}
              </option>
            </select>
          </td>
          <td></td>
          <td></td>
          <td>
            <select
              class="form-control input-sm"
              v-model="filter.implemented"
              :disabled="isAddMeasureMode"
            >
              <option :value="1">
                {{ $t("common.yes") }}
              </option>
              <option :value="0">
                {{ $t("common.no") }}
              </option>
            </select>
          </td>
          <td>
            <button
              class="btn btn-danger btn-xs"
              @click="cancelFilter"
              v-if="isFiltered"
            >
              {{ $t("common.cancelFilter") }}
            </button>
          </td>
        </tr>
      </tbody>

      <measure
        v-for="riskMeasure in filteredRiskMeasures"
        :key="riskMeasure.id"
        :riskMeasure="riskMeasure"
        :risk="risk"
        @deselect="deselectRiskMeasure"
        @reload="reloadMeasure"
        :maxThreat="maxThreat"
        :maxVulnerability="maxVulnerability"
        :isSelectionMode="isSelectionMode"
        @cancelAddMeasure="cancelAddMeasure"
        @toggleAddMeasureMode="toggleAddMeasureMode"
      ></measure>
    </table>

    <loading :active.sync="isLoading" :is-full-page="false"></loading>
  </div>
</template>

<script>
import Measure from "./Measure.vue";
import { mapActions, mapGetters } from "vuex";
import store from "store";
import Multiselect from "Controls/CsaMultiSelect.vue";
import CommunicationBus from "../CommunicationBus.js";

export default {
  store,
  components: {
    Measure,
    Multiselect,
  },
  data() {
    return {
      isLoading: false,
      computedRisk: null,
      riskScaleI: null,
      riskScaleT: null,
      riskScaleV: null,
      maxRisk: null,
      maxThreat: null,
      maxVulnerability: null,
      threatReducedValue: null,
      threatFullReducedValue: null,
      threatReductionPercentage: null,
      threatFullReductionPercentage: null,
      vulnerabilityReducedValue: null,
      vulnerabilityFullReducedValue: null,
      vulnerabilityReductionPercentage: null,
      vulnerabilityFullReductionPercentage: null,
      riskReducedValue: null,
      riskFullReducedValue: null,
      riskReducedValuePercentage: null,
      riskFullReducedValuePercentage: null,
      riskReductionPercentage: null,
      riskFullReductionPercentage: null,
      filter: {
        name: null,
        tags: [],
        applicable: null,
        implemented: null,
      },
      isLoading: false,
      isSelectionMode: false,
      isAddMeasureMode: false,
      watchFilter: true,
      filteredRiskMeasures: [],
    };
  },
  props: {
    riskMeasures: {
      type: Array,
      required: true,
    },
    risk: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("measures", {
      measures: "getAll",
    }),
    ...mapGetters("measureTags", {
      getAllTags: "getAll",
    }),
    ...mapGetters("userSettings", {
      isShowHashIds: "isShowHashIds",
    }),
    ...mapGetters("riskScaleItems", [
      "getImpactScale",
      "getThreatScale",
      "getVulnerabilityScale",
    ]),
    availableMeasures: function () {
      let riskMeasureIds = this.riskMeasures.map((m) => m.measure.id);

      return this.measures.filter((m) => {
        let i = riskMeasureIds.indexOf(m.id);

        if (i === -1) {
          return true;
        } else {
          let m = this.riskMeasures[i];
          return m.deleted === true;
        }
      });
    },
    isFiltered: function () {
      return (
        this.filter.name ||
        this.filter.tags.length ||
        this.filter.applicable !== null ||
        this.filter.implemented !== null
      );
    },
    isAllSelected() {
      if (this.filteredRiskMeasures.length <= 0) {
        return false;
      }
      return this.filteredRiskMeasures.every((item) => item.isSelected);
    },
  },
  watch: {
    ["risk.scaleIId"]: function () {
      this.computeRisk();
      this.loadScaleImpact();
      this.computeRiskReduction();
    },
    ["risk.scaleTId"]: function () {
      this.computeRisk();
      this.loadScaleThreat();
      this.computeThreatReduction();
      this.computeRiskReduction();
    },
    ["risk.scaleVId"]: function () {
      this.computeRisk();
      this.loadScaleVulnerability();
      this.computeVulnerabilityReduction();
      this.computeRiskReduction();
    },
    ["computedRisk.value"]: function () {
      this.computeRiskReduction();
    },
    riskMeasures: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        this.computeThreatReduction();
        this.computeVulnerabilityReduction();
        this.computeRiskReduction();
      },
    },
    filter: {
      deep: true,
      handler: function () {
        if (this.watchFilter) {
          this.debouncedFetchFilteredRiskMeasures();
        }
      },
    },
  },
  created: async function () {
    this.isLoading = true;

    await this.fetchMeasures();
    await this.fetchTags();
    await this.fetchScales();
    await this.computeRisk();
    await this.fetchIsShowHashIds();

    this.prepareRiskMeasuresDataSet();
    this.filterRiskMeasuresDataSet();

    this.computeThreatReduction();
    this.computeVulnerabilityReduction();
    this.computeRiskReduction();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("measures", {
      fetchMeasures: "fetchMeasures",
    }),
    ...mapActions("measureTags", {
      fetchTags: "fetchTags",
      addTag: "addTag",
    }),
    ...mapActions("userSettings", {
      fetchIsShowHashIds: "fetchIsShowHashIds",
    }),
    ...mapActions("riskScaleItems", {
      fetchScaleImpacts: "fetchImpacts",
      fetchScaleVulnerabilites: "fetchVulnerabilites",
      fetchScaleThreats: "fetchThreats",
    }),
    toggleSelectionMode: function () {
      this.isSelectionMode = !this.isSelectionMode;
      this.filterRiskMeasuresDataSet();
      if (!this.isSelectionMode) {
        this.cancelFilter();
      }
    },
    toggleAddMeasureMode: function () {
      this.isAddMeasureMode = !this.isAddMeasureMode;
      // Emit signal to parent
      CommunicationBus.$emit("toggleAddMeasureMode");
    },
    debouncedFetchFilteredRiskMeasures: _.debounce(function (
      showLoading = true
    ) {
      this.filterRiskMeasuresDataSet(showLoading);
    },
    700),
    filterRiskMeasuresDataSet: function (showLoading = true) {
      this.isLoading = showLoading;

      let filteredRiskMeasures = [];
      if (this.isSelectionMode) {
        filteredRiskMeasures = this.riskMeasures;
      } else {
        filteredRiskMeasures = this.riskMeasures.filter((x) => x.isSelected);
      }

      if (this.filter.applicable != null) {
        let applicable =
          this.filter.applicable != 2 ? this.filter.applicable : null;
        filteredRiskMeasures = filteredRiskMeasures.filter(
          (x) => x.applicable == applicable
        );
      }

      if (this.filter.implemented != null) {
        filteredRiskMeasures = filteredRiskMeasures.filter(
          (x) => x.implemented == this.filter.implemented
        );
      }

      if (this.filter.name != null && this.filter.name.trim() != "") {
        let name = this.filter.name.toLowerCase();
        filteredRiskMeasures = filteredRiskMeasures.filter(
          (x) =>
            x.measure.name && x.measure.name.toLowerCase().indexOf(name) >= 0
        );
      }
      if (this.filter.tags.length > 0) {
        filteredRiskMeasures = filteredRiskMeasures.filter((x) =>
          this.filter.tags.every((y) => x.measure.tags.indexOf(y) >= 0)
        );
      }

      this.filteredRiskMeasures = filteredRiskMeasures;
      this.isLoading = false;
    },
    toggleSelectAll() {
      let isSelected = !this.isAllSelected;
      this.filteredRiskMeasures.forEach((x) => (x.isSelected = isSelected));
    },
    fetchScales: async function () {
      await this.fetchScaleImpacts();
      await this.fetchScaleVulnerabilites();
      await this.fetchScaleThreats();

      // Max values
      this.maxRisk =
        this.getImpactScale.length *
        this.getThreatScale.length *
        this.getImpactScale.length;
      this.maxThreat = this.getThreatScale.length;
      this.maxVulnerability = this.getVulnerabilityScale.length;

      this.loadScaleImpact();
      this.loadScaleThreat();
      this.loadScaleVulnerability();
    },
    deselectRiskMeasure: function (riskMeasure) {
      riskMeasure.isSelected = false;

      this.filteredRiskMeasures.splice(
        this.filteredRiskMeasures.indexOf(riskMeasure),
        1
      );
    },
    computeRisk: async function () {
      await this.$api
        .get("risk/compute-risk-value/" + this.risk.id, {
          params: {
            impactId: this.risk.scaleIId,
            threatId: this.risk.scaleTId,
            vulnerabilityId: this.risk.scaleVId,
          },
        })
        .then(({ data }) => {
          this.computedRisk = data.data;
        });
    },
    loadScaleImpact: function () {
      this.riskScaleI = this.getImpactScale.find(
        (x) => x.id == this.risk.scaleIId
      );
    },
    loadScaleThreat: function () {
      this.riskScaleT = this.getThreatScale.find(
        (x) => x.id == this.risk.scaleTId
      );
    },
    loadScaleVulnerability: function () {
      this.riskScaleV = this.getVulnerabilityScale.find(
        (x) => x.id == this.risk.scaleVId
      );
    },
    prepareRiskMeasuresDataSet: function () {
      let riskMeasures = [];

      this.measures.forEach((measure) => {
        // create risk measure object
        let riskMeasure = {
          isSelected: false,
          id: null,
          measure: measure,
          threatReductionValue: 0,
          vulnerabilityReductionValue: 0,
          applicable: null,
          implemented: null,
          description: null,
        };

        let currentRiskMeasure = this.riskMeasures.find(
          (x) => x.measure.id == measure.id
        );

        if (currentRiskMeasure) {
          riskMeasure.isSelected = true;
          riskMeasure.id = currentRiskMeasure.id;
          riskMeasure.threatReductionValue =
            currentRiskMeasure.threatReductionValue;
          riskMeasure.vulnerabilityReductionValue =
            currentRiskMeasure.vulnerabilityReductionValue;
          riskMeasure.applicable = currentRiskMeasure.applicable;
          riskMeasure.implemented = currentRiskMeasure.implemented;
          riskMeasure.description = currentRiskMeasure.description;
        }
        riskMeasures.push(riskMeasure);
      });

      this.riskMeasures.splice(0, this.riskMeasures.length);

      riskMeasures.forEach((riskMeasure) => {
        this.riskMeasures.push(riskMeasure);
      });
    },
    computeThreatReduction: function () {
      if (!this.riskScaleT) {
        this.threatFullReductionValue = null;
        this.threatReducedValue = null;
        return;
      }

      let selectedRiskMeasures = this.riskMeasures.filter(
        (x) => x.isSelected == true
      );
      let threatReductionValue = 0;
      let threatFullReductionValue = 0;
      if (selectedRiskMeasures.length > 0) {
        threatFullReductionValue = selectedRiskMeasures.reduce(
          (sum, current) =>
            sum +
            (current.threatReductionValue ? current.threatReductionValue : 0),
          0
        );
        threatReductionValue = selectedRiskMeasures
          .filter((x) => x.implemented == true)
          .reduce(
            (sum, current) =>
              sum +
              (current.threatReductionValue ? current.threatReductionValue : 0),
            0
          );
      }

      let fullReducedValue = this.riskScaleT.rank - threatFullReductionValue;
      if (fullReducedValue < 1) fullReducedValue = 1;
      this.threatFullReducedValue = this.roundTwoDecimals(fullReducedValue);
      this.threatFullReductionPercentage = Math.round(
        ((this.riskScaleT.rank - this.threatFullReducedValue) /
          this.riskScaleT.rank) *
          100
      );

      let reducedValue = this.riskScaleT.rank - threatReductionValue;
      if (reducedValue < 1) reducedValue = 1;
      this.threatReducedValue = this.roundTwoDecimals(reducedValue);
      this.threatReductionPercentage = Math.round(
        ((this.riskScaleT.rank - this.threatReducedValue) /
          this.riskScaleT.rank) *
          100
      );
    },
    computeVulnerabilityReduction: function () {
      if (!this.riskScaleV) {
        this.vulnerabilityFullReducedValue = null;
        this.vulnerabilityReducedValue = null;
        return;
      }

      let selectedRiskMeasures = this.riskMeasures.filter(
        (x) => x.isSelected == true
      );
      let vulnerabilityFullReductionValue = 0;
      let vulnerabilityReductionValue = 0;
      if (selectedRiskMeasures.length > 0) {
        vulnerabilityFullReductionValue = selectedRiskMeasures.reduce(
          (sum, current) =>
            sum +
            (current.vulnerabilityReductionValue
              ? current.vulnerabilityReductionValue
              : 0),
          0
        );
        vulnerabilityReductionValue = selectedRiskMeasures
          .filter((x) => x.implemented == true)
          .reduce(
            (sum, current) =>
              sum +
              (current.vulnerabilityReductionValue
                ? current.vulnerabilityReductionValue
                : 0),
            0
          );
      }

      let fullReducedValue =
        this.riskScaleV.rank - vulnerabilityFullReductionValue;
      if (fullReducedValue < 1) fullReducedValue = 1;
      this.vulnerabilityFullReducedValue =
        this.roundTwoDecimals(fullReducedValue);
      this.vulnerabilityFullReductionPercentage = Math.round(
        ((this.riskScaleV.rank - this.vulnerabilityFullReducedValue) /
          this.riskScaleV.rank) *
          100
      );

      let reducedValue = this.riskScaleV.rank - vulnerabilityReductionValue;
      if (reducedValue < 1) reducedValue = 1;
      this.vulnerabilityReducedValue = this.roundTwoDecimals(reducedValue);
      this.vulnerabilityReductionPercentage = Math.round(
        ((this.riskScaleV.rank - this.vulnerabilityReducedValue) /
          this.riskScaleV.rank) *
          100
      );
    },
    computeRiskReduction: function () {
      this.riskFullReducedValue = null;
      this.riskReducedValue = null;
      this.riskFullReducedValuePercentage = null;
      this.riskReducedValuePercentage = null;
      this.riskFullReductionPercentage = null;
      this.riskReductionPercentage = null;

      if (
        !this.riskScaleI ||
        !this.threatFullReducedValue ||
        !this.vulnerabilityFullReducedValue ||
        !this.threatReducedValue ||
        !this.vulnerabilityReducedValue ||
        !this.computedRisk
      ) {
        return;
      }

      let fullReducedValue =
        this.riskScaleI.rank *
        this.threatFullReducedValue *
        this.vulnerabilityFullReducedValue;
      this.riskFullReducedValue = this.roundTwoDecimals(fullReducedValue);
      this.riskFullReducedValuePercentage = Math.round(
        (this.riskFullReducedValue / this.maxRisk) * 100
      );
      if (this.computedRisk.value) {
        this.riskFullReductionPercentage = Math.round(
          ((this.computedRisk.value - this.riskFullReducedValue) /
            this.computedRisk.value) *
            100
        );
      }

      let reducedValue =
        this.riskScaleI.rank *
        this.threatReducedValue *
        this.vulnerabilityReducedValue;
      this.riskReducedValue = this.roundTwoDecimals(reducedValue);
      this.riskReducedValuePercentage = Math.round(
        (this.riskReducedValue / this.maxRisk) * 100
      );
      if (this.computedRisk.value) {
        this.riskReductionPercentage = Math.round(
          ((this.computedRisk.value - this.riskReducedValue) /
            this.computedRisk.value) *
            100
        );
      }
    },
    roundTwoDecimals(value) {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    },
    ...mapActions("measures", {
      filterMeasures: "filterMeasures",
    }),
    cancelFilter: function () {
      this.watchFilter = false;

      this.filter = {
        name: null,
        tags: [],
        applicable: null,
        implemented: null,
      };

      this.filterRiskMeasuresDataSet();

      this.$nextTick(() => {
        this.watchFilter = true;
      });
    },
    reload: function (showLoading = false) {
      this.filterRiskMeasuresDataSet(showLoading);
    },
    add: function () {
      let measure = {
        id: null,
        name: null,
        tags: [],
        riskPlan: {
          id: null,
        },
      };

      let riskMeasure = {
        isSelected: true,
        id: null,
        measure: measure,
        threatReductionValue: 0,
        vulnerabilityReductionValue: 0,
        applicable: null,
        implemented: null,
        description: null,
      };

      this.toggleAddMeasureMode();
      this.cancelFilter();
      this.filteredRiskMeasures.unshift(riskMeasure);
    },
    cancelAddMeasure: function (item) {
      this.filteredRiskMeasures.splice(
        this.filteredRiskMeasures.indexOf(item),
        1
      );
      this.toggleAddMeasureMode();
    },
  },
};
</script>

<style scoped>
input.measure_name {
  height: 40px;
}
</style>

<style scoped lang="less">
.multiselect {
  font-size: 12px;
  /deep/ .multiselect__tags {
    min-height: 30px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  /deep/ .multiselect__select {
    height: 30px;
  }
  /deep/ .multiselect__placeholder {
    font-size: 12px;
  }
  /deep/ .multiselect__input {
    font-size: 12px;
  }
  /deep/ .multiselect__tag {
    font-size: 12px;
  }
}
</style>
