<template>
    <div class="control">
        <label>{{$t('comp.assetModal.controls.guarantor.label')}}</label>

        <multiselect
            v-model="selected"
            :options="options"
            group-values="people"
            group-label="org_path"
            :group-select="false"
            label="name"
            track-by="id"
            :multiple="false"
        >
        </multiselect>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Multiselect from '../../../Controls/CsaMultiSelect.vue'

    export default {
        name: "asset-guarantor-control",
        components: {
          Multiselect
        },
        data() {
            return {
                selected: null
            };
        },
        props: {
            value: null
        },
        watch: {
            selected: function (v) {
                this.$emit('input', v);
            },
            value: function (v) {
                this.selected = v;
            }
        },
        created() {
            this.fetchGuarantors();
            this.selected = this.value;
        },
        computed: {
            ...mapGetters("guarantor", {
                options: "getGuarantors",
                getById: 'getById'
            })
        },
        methods: {
            ...mapActions("guarantor", {
                fetchGuarantors: 'fetchGuarantors'
            })
        }
    }
</script>
