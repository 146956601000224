<template>
    <div class="vld-parent">
        <ckeditor
                  name="content"
                  id="content"
                  :editor="editor"
                  v-model="data.content"
                  :config="editorConfig"
                  v-validate="'required'"
                  data-vv-validate-on="none"
                  :data-vv-as="$t('comp.measureRiskPlan.riskPlanTimeLine.newMessage.content-data-vv')"></ckeditor>
        <span class="text-red">{{ errors.first('content') }}</span>

        <div class="clearfix"></div>

        <p class="selectFiles">
            <label class="btn btn-primary pull-left btn-xs" for="my-file-selector">
                <input id="my-file-selector" type="file" style="display:none;" @change="fileSelected" ref="selectedFile" multiple>
                <i class="fas fa-paperclip"></i> {{$t('comp.measureRiskPlan.riskPlanTimeLine.newMessage.selectAttachment')}}
            </label>
        </p>

        <p class="create text-center">
            <button type="button" class="btn btn-success" @click="submit">
                <i class="fas fa-plus"></i>
                {{$t('common.add')}}
            </button>
        </p>

        <div class="clearfix"></div>

        <div class="files">
            <template v-if="data.files.length">
                <strong>{{$t('comp.measureRiskPlan.riskPlanTimeLine.newMessage.attachments')}}</strong>
                <table class="table table-striped">
                    <tbody>
                        <tr v-for="(file, index) in data.files" :index="index">
                            <td>{{ file.name }}</td>
                            <td class="text-right">
                                <button class="btn btn-danger btn-xs" @click="removeFile(index)"><i class="fas fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </div>

    </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    export default {
        name: "NewMessage",
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                files: [],
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: {
                        items: [
                            'bold',
                            'italic',
                            'bulletedList',
                            'numberedList',
                        ]
                    }
                }
            }
        },
        props: {
            data: Object
        },
        methods: {
            submit: function () {
                this.$validator.validate().then(result => {
                    if (!result) {
                        $.notify({
                            message: this.$t('comp.measureRiskPlan.riskPlanTimeLine.newMessage.fixErrors'),
                        }, {
                            type: 'danger'
                        });
                    } else {
                        this.$emit('create');
                    }
                });
            },
            fileSelected: function () {
                var files = this.$refs.selectedFile.files;

                for (var i = 0; i < files.length; i++) {
                    this.data.files.push(files[i]);
                }

                files.value = "";
            },
            removeFile: function (index) {
                this.data.files.splice(index, 1);
            }
        }
    }
</script>

<style>
    .create {
        margin-top: 10px;
        float: right;
    }

    .files {
        margin-top: 10px;
    }

    .selectFiles {
        margin-top: 10px;
        float: left;
    }
</style>