<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false"></loading>

        <new-message @create="createMessage" :data="newMessage" />

        <div style="height: 100%;">
            <ul class="timeline timeline-inverse">
                <template v-for="(messages, date) in messagesByDay">
                    <!-- timeline time label -->
                    <li class="time-label">
                      <span class="bg-olive">
                        {{ date }}
                      </span>
                    </li>
                    <!-- /.timeline-label -->
                    <!-- timeline item -->
                    <li v-for="(message, index) in messages" :key="index">
                        <i class="fa fa-envelope bg-blue"></i>

                        <div class="timeline-item">
                            <span class="time">
                                <i class="fa fa-clock-o"></i>
                                <span v-html="getRelativeTime(message.createdAt)">

                                </span>
                            </span>

                            <h3 class="timeline-header"><a>{{ message.author }}</a> {{$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.posted')}}</h3>

                            <div class="timeline-body" v-html="message.content">
                            </div>
                            <div class="timeline-footer">
                                <template v-for="attachment in message.attachments">
                                    <a v-bind:href="attachment.link" class="btn">
                                        <i class="fas fa-file-download"></i> {{ attachment.name }}
                                    </a>
                                </template>
                            </div>
                        </div>
                    </li>

                    <!-- END timeline item -->
                </template>
                <!-- END timeline item -->

                <li v-if="noMoreMessages && messages.length">
                    <i class="fa far fa-clock bg-gray"></i>
                </li>
            </ul>

            <div class="text-center">
                <label class="btn btn-primary btn-xs" v-if="!noMoreMessages" @click="loadMore">
                    {{$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.loadMore')}}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import NewMessage from './newMessage.vue';
    import moment from 'moment';


    export default {
        components: {
            NewMessage
        },
        data() {
            return {
                page: 1,
                isLoading: false,
                messages: [],
                newMessage: {
                    content: null,
                    files: []
                },
                noMoreMessages: false
            }
        },
        props: {
            riskPlanId: String
        },
        computed: {
            messagesByDay(){
                let grouped = this.messages.reduce((acc, message) => {
                    let date = (new Date(message.createdAt)).toLocaleDateString();

                    (acc[date] = acc[date] || []).push(message)
                    return acc
                }, {});

                return grouped;
            }
        },
        mounted: function() {
            this.loadMore();
        },
        methods: {
            createMessage: function () {
                let formData = new FormData();

                formData.append('riskPlan', this.riskPlanId);
                formData.append('content', this.newMessage.content);

                for (var i = 0; i < this.newMessage.files.length; i++) {
                    formData.append('files['+i+']', this.newMessage.files[i]);
                }

                this.isLoading = true;

                let self = this;
                this.$api.post("risk-plan-timeline/create-message",
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                    .then(({data}) => {
                        if(data.result) {
                            $.notify({message: this.$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.messageAddded')}, {type: 'success'});

                            if(this.messages.length === 0) {
                                self.$refs.infiniteLoading.stateChanger.reset();
                            } else {
                                self.messages.unshift(data.message);
                            }
                        } else {
                            $.notify({message: this.$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.messageAddError'), title: this.$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.errorTitle')}, {type: 'danger'});
                        }

                        self.isLoading = false;
                        self.reset();
                    })
                    .catch(function() {
                        $.notify({message: this.$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.messageAddError'), title: this.$t('comp.measureRiskPlan.riskPlanTimeLine.timeline.errorTitle')}, {type: 'danger'});
                        self.isLoading = false;
                    });
            },
            getRelativeTime: function(time) {
                return moment(time).fromNow();
            },
            reset: function() {
                this.$set(this.newMessage, 'content', "");
                this.$set(this.newMessage, 'files', []);
            },
            loadMore() {
                this.$api.get("risk-plan-timeline/load-messages", {
                    params: {
                        riskPlan: this.riskPlanId,
                        page: this.page,
                    },
                }).then(({ data }) => {
                    if (data.length) {
                        this.page += 1;
                        this.messages.push(...data);
                    }

                     if (data.length < 10)  {
                        this.noMoreMessages = true;
                     }
                });
            }
        }
    }
</script>
